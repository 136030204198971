import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
import ViewFiles from "./ViewFiles";
const Documents = (props) => {
  const { t } = useTranslation();

  const [attributes] = useState([
    {
      type: "select",
      placeholder: "employee",
      name: "user",
      tags: [{ item: "title", title: "place", collection: "place" }],
      validation: "",
      default: "",
      label: "employee",
      collection:"user",
      showItem: "fullName",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "user/select",
    },
    {
      type: "text",
      placeholder: "Note",
      name: "note",
      validation: "",
      default: "",
      showItem: "Note",
      label: "Note",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "documentType",
      name: "documentType",
      validation: "",
      collection: "documentType",
      showItem: "locale",
      default: "",
      label: "documentType",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "document-type/select",
    },
    {
      type: "expired",
      placeholder: "status",
      tab: "generalInfo",
      name: "expiryDate",
      validation: "",
      collection: "",
      showItem: "expiryDate",
      default: "",
      value: "Active",
      ifNull: "Active",
      label: "status",
      required: false,
      view: true,
      tag: true,
      add: false,
      update: true,
    },
    {
      type: "date",
      placeholder: "expiryDate",
      name: "expiryDate",
      validation: "datetime",
      default: "empty",
      label: "expiryDate",
      required: false,
      view: true,
      tag: true,
      add: true,
      update: true,
    },

    {
      type: "file",
      placeholder: "file",
      name: "file",
      validation: "",
      showItem: "",
      default: "",
      label: "file",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "adminOnly",
      name: "adminOnly",
      validation: "",
      showItem: "",
      default: "",
      label: "adminOnly",
      required: true,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
  ]);
  const [viewFile, setViewFile] = useState(false);
  const [data, setData] = useState({});
  const [actions] = useState([
    {
      element: "callback",
      type: "callback",
      id: "generatePdf",
      title: "generatePdf",
      icon: "Download",
      callback: (item, data) => {
        setData({ item, data });
        setViewFile(true);
      },
    },
  ]);

  //to update the page title
  useEffect(() => {
    document.title = `${t("documents")} - VSH Security`;
  }, [t]);
  // const [actions] = useState([{ element: "button", icon: "timings", type: "subList", id: "shift-timing", itemTitle: "title", title: "timings", attributes: timingAttributes, params: { api: `timing`, itemTitle: "title", shortName: "Timing", addPrivilege: true, delPrivilege: true, updatePrivilege: true, customClass: "medium" } }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} actions={actions} api={`documents`} itemTitle={`note`} shortName={`documents`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
      {viewFile && <ViewFiles admin={true} customClass="large" page={data} onClose={() => setViewFile(false)} setLoaderBox={props.setLoaderBox} title={"attendanceToday"}></ViewFiles>}
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Documents);
