import styled from "styled-components";

export const Container = styled.div`
  padding: 0em 0em;
  display: flex;
  @media screen and (max-width: 768px) {
    &.fixed {
      position: fixed;
      top: 0px;
      left: 0;
      right: 0;
      background-color: white;
      z-index: 1002;
    }
    &.menu.fixed {
      z-index: 1000;
    }
    &.menu.fixed:hover,
    &.menu.fixed:focus {
      z-index: 1005;
    }
  }
`;
export const Logo = styled.img`
  min-height: auto;
  width: fit-content;
  padding-left: 0.5em;
  padding-top: 0.5em;
  margin-bottom: 10px;
  max-width: 160px;
  && {
    .small & {
      /* visibility: hidden; */
    }
  }
  @media screen and (max-width: 768px) {
    height: 30px;
    &.menu {
      padding: 0;
    }
  }
`;
export const NavIcon = styled.div`
  min-height: auto;
  width: 100%;
  height: auto;
  display: none;
  img {
    width: 3em;
    margin: auto;
  }
  && {
    .small & {
      display: flex;
    }
  }
  @media screen and (max-width: 768px) {
    height: 30px;
    &.menu {
      padding: 0;
    }
  }
`;
export const LogoContaner = styled.div`
  display: flex;
  flex: 1 1 15em;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
  padding: 10px 30px;
  cursor: pointer;
  &.small {
    flex: 1 1 5em;
  }
  &.hd {
    display: none;
  }
  @media screen and (max-width: 768px) {
    justify-content: right;
    padding-right: 15px;
    justify-content: left;
    &.hd {
      display: inherit;
    }
    padding: 5px 10px;
    svg{display:none;}
    div svg{display:flex;}
  }
`;
export const Status = styled.div`
  flex: 1 1 calc(100% - 15em);
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
  @media screen and (max-width: 768px) {
    &.hm {
      display: none;
    }
  }
`;
export const MNav = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    padding-left: 15px;
    font-size: 25px;
  }
`;
export const Title = styled.div`
  margin-right: auto;
  margin-left: 20px;
  font-weight: bold;
  align-items: center;
  display: flex;
  font-size: 18px;
  &.hide {
    display: none;
  }
  svg {
    margin-right: 10px;
  }
  &.inner {
    margin: 0 8px 15px 5px;
  }
  && {
    .popup-child & {
      display: none;
    }
    .tab & {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    &.hm {
      display: none;
    }
  }
`;
export const MNavClose = styled.div`
  margin-right: 1em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: none;
  a {
    text-decoration: none;
  }
  svg {
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: auto;
    display: flex;
    border: 1px solid;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    cursor: pointer;
    text-transform: uppercase;
    svg {
      margin: auto;
    }
  }
`;

export const User = styled.div`
  margin-right: 1em;
  @media screen and (max-width: 768px) {
    &.hm {
      display: none;
    }
  }
`;
export const Logout = styled.div`
  &.hd {
    display: none;
  }
  margin-right: 1em;
  color: red;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    color: red;
  }
  svg {
    margin-right: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: auto;
    border: 1px solid;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    cursor: pointer;
    text-transform: uppercase;
    &.hd {
      display: flex;
      margin-left: 20px;
      margin-top: 10px;
      margin-right: auto;
    }
    svg {
      margin: auto;
    }
  }
`;
export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  flex-direction: column;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: relative;
  background-color: red;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
    margin: auto;
    object-fit: cover;
    border-radius: 50%;
  }
  > span {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h6 {
    text-align: center;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-size: 15px;
  }
  @media (max-width: 768px) {
    padding: 15px;
    width: -webkit-fill-available;
    width: 10px;
    height: 10px;
    &.vertical {
      flex-direction: column;
    }
  }
`;
export const Popbar = styled.div`
  position: absolute;
  right: 10px;
  top: 40px;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1001;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  min-width: 200px;
  .main {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 600;
  }
  .sub {
    font-size: 12px;
    margin-top: 5px;
  }
`;
