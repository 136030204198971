import React from "react";
import { Panel } from "./styles";
import { useTranslation } from "react-i18next";

function SelectTab({ onChange, selected, tabs = [] }) {
  const [t] = useTranslation();
  return (
    <Panel>
      {tabs.map((tab) => (
        <button onClick={() => onChange(tab.id)} className={selected === tab.id && "active"}>
          {t(tab.title)}
        </button>
      ))}
    </Panel>
  );
}
export default SelectTab;
