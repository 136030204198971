import styled from "styled-components";

export const Round = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  margin-top: 2px;
  &.green {
    background-color: green;
  }
`;
export const Disabled = styled.span`
  color: red;
`;
export const CenterBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;
  padding: 2px;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
    position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const DayBox = styled.div`
  width: 100%;
  display: flex;
  height: -webkit-fill-available;
  top: 0;
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  > div {
    width: 4.167%;
    border-left: 1px solid #dad8d8;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  div:first-child {
    border-left: 0;
  }
  @media (max-width: 768px) {
    position: inherit;
    width: 500px;
  }
`;
export const Approval = styled.span`
  font-size: 20px;
  position: absolute;
  right: 5px;
  top: 2px;
  margin: 0px !important;
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  padding: 2px;
  border-radius: 50%;
  svg {
    color: white;
  }
`;
export const ProfileStatus = styled.span`
  font-size: 10px;
  position: absolute;
  right: -2px;
  bottom: -4px;
  margin: 0px !important;
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  padding: 2px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  svg {
    color: white;
  }
`;
