import { PDFDownloadLink } from "@react-pdf/renderer";
import styled from "styled-components";
export const Address = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  font-size: 10px;
  width: 125px;
  margin-right: 0;
  margin-left: auto;
  font-size: 10px;
  div {
    line-height: 20px;
    height: 15px;
  }
  .bold {
    font-weight: bold;
  }
  img {
    margin-left: 0;
    padding: 0;
    margin-top: 0px;
    margin-bottom: 30px;
    width: 100px;
    height: auto;
  }
`;
export const A4 = styled.div`
  width: 170mm; /* A4 width */
  padding: 10mm; /* Adjust padding as needed */
  box-sizing: border-box;
  overflow: auto;
  font-size: 12px;
  page-break-after: always; /* Ensure each content section is on a separate page */
  p {
    text-align: left;
    font-size: 12px;
  }
`;
export const Content = styled.div`
  border: 1px solid;
  padding: 10px;
  font-size: 12px;
  p {
    text-align: left;
  }
  .bold {
    font-weight: bold;
    margin-bottom: 10px;
  }
`;
export const Item = styled.div`
  display: flex;
  margin-bottom: 10px;
  .italic {
    font-style: italic;
  }
  span:first-child::after {
    content: ":";
    margin-left: auto;
  }
  span {
    min-width: 200px;
  }
  p {
    text-align: left;
  }
`;
export const Signature = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  span {
    border-top: 1px solid;
    width: 40%;
    margin-top: 20px;
    padding-top: 5px;
  }
`;
export const FooterText = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 76px;
  span {
    font-size: 10px;
  }
  span:first-child {
    font-weight: bold;
  }
  span:nth-child(2) {
    line-height: 12px;
  }
`;
export const Button = styled(PDFDownloadLink)`
  background: ${(props) => props.theme.themeBackground};
  border-radius: 12px;
  color: ${(props) => props.theme.themeForeground};
  transition: all 0.5s ease;
  margin-top: 10px;
  max-width: 400px;
  padding: 15px 20px;
  min-width: 155px;
  border-color: ${(props) => props.theme.border};
  border-style: solid;
  cursor: pointer;
  border-width: ${(props) => props.theme.borderThinkness};
  &.close {
    background: transparent;
    color: gray;
    margin-right: 1em;
    min-width: 120px;
    border: 1px solid;
  }
  &:disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledForeground};
    cursor: not-allowed;
  }
  text-decoration: none;
  text-align: center;
`;
