import React from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import { logo } from "../../../images";
import { dateFormat } from "../../functions/date";
import { Footer, Overlay, Page as PageView } from "../form/styles";
import FormInput from "../input";
import { useTranslation } from "react-i18next";
import { Button } from "./styles";
import { useSelector } from "react-redux";
import { languageData } from "./languages";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "20px",
    paddingLeft: "2cm",
    paddingRight: "1.5cm",
    fontFamily: "Helvetica",
    fontSize: "12pt",
  },
  logo: { width: "80px", marginBottom: "20px", marginTop: 30 },
  bold: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  title: {
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 600,
  },
  italic: {
    fontFamily: "Helvetica-Oblique",
  },
  address: {
    marginBottom: "20px",
    marginLeft: "auto",
    marginRight: "0",
  },
  addressItem: {
    minHeight: "10px",
  },
  content: {
    marginBottom: 20,
    marginTop: 20,
    border: 1,
    padding: 10,
  },
  signature: {
    marginTop: "20px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureText: {
    borderTop: 1,
    width: 200,
    paddingTop: 5,
  },
  footerText: {
    marginTop: 30,
    textAlign: "center",
    paddingLeft: 30,
    paddingRight: 30,
  },
  singleItem: {
    display: "flex",
    flexDirection: "row",
    marginTop: 3,
  },
  singleItemTitle: {
    width: 90,
  },
  singleItemContent: {
    width: 380,
  },
  small: {
    fontSize: 10,
  },
  p: {
    marginBottom: 0,
    marginTop: 10,
  },
});

const PDFGenerator = ({ data, closeModal }) => {
  const { t } = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  const language = languageData[data.data.language];
  const generatePDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.address}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.addressItem}>VSH Security e.K.</Text>
          <Text style={styles.addressItem}>Ludwig-Thoma-Ring 22</Text>
          <Text style={styles.addressItem}>85241 Hebertshausen</Text>
          <Text style={styles.addressItem}></Text>
          <Text style={styles.addressItem}>{language[3]}: +49 (0) 8131 7579151</Text>
          <Text style={styles.addressItem}>{language[2]}: info@vsh-security.com</Text>
          <Text style={styles.addressItem}>{language[1]}: www.vsh-security.com</Text>
          <Text style={styles.addressItem}></Text>
          <Text style={styles.addressItem}>München, den {dateFormat(new Date(data.data.createdAt))}</Text>
        </View>
        <Text style={[styles.bold, styles.title]}>{language[4]}</Text>
        <Text>{language[5]}</Text>
        <View style={styles.content}>
          <Text style={styles.bold}>{language[6]}:</Text>
          <View style={styles.singleItem}>
            <Text style={styles.singleItemTitle}>{language[7]} </Text>
            <Text>{data.data.salutation}</Text>
          </View>
          <View style={styles.singleItem}>
            <Text style={styles.singleItemTitle}>{language[8]}</Text>
            <Text>{data.data.surname}</Text>
          </View>
          <View style={styles.singleItem}>
            <Text style={styles.singleItemTitle}>{language[9]}</Text>
            <Text>{data.data.firstName}</Text>
          </View>
          <View style={styles.singleItem}>
            <Text style={styles.singleItemTitle}>{language[10]}</Text>
            <Text>{data.data.streetNo}</Text>
          </View>
          <View style={styles.singleItem}>
            <Text style={styles.singleItemTitle}>{language[11]}</Text>
            <Text>{`${data.data.postalCode}, ${data.data.city}`}</Text>
          </View>
          <View style={[styles.singleItem, styles.p]}>
            <Text style={styles.singleItemTitle}>{language[12]}</Text>
            <Text style={[styles.singleItemContent, styles.italic]}>{language[13]}</Text>
          </View>
          <View style={[styles.singleItem, styles.p]}>
            <Text style={styles.singleItemTitle}>{language[14]}</Text>
            <Text style={[styles.singleItemContent, styles.italic]}>{language[15]}</Text>
          </View>
          <View style={styles.singleItem}>
            <Text style={styles.singleItemTitle}>{language[16]}</Text>
            <Text style={styles.singleItemContent}>{t(data.data.bannedStatus)}</Text>
          </View>
          <Text style={[styles.p, { marginTop: 30 }]}>{language[17]}</Text>
          <Text style={styles.p}>{language[18]}</Text>
          <Text style={styles.p}>{language[19]}</Text>
        </View>

        <View style={styles.signature}>
          <Text style={styles.signatureText}>{language[20]}</Text>
          <Text style={styles.signatureText}>{language[21]}</Text>
        </View>
        <View style={styles.footerText}>
          <Text style={styles.bold}>{language[22]}</Text>
          <Text style={styles.small}>{language[23]}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Overlay className={"popup"}>
      <PageView className={"full"}>
        <PDFViewer style={{ width: "100%", height: "600px" }}>{generatePDF()}</PDFViewer>
        <Footer>
          <FormInput type="close" value={t("cancel")} onChange={closeModal} />
          <Button theme={themeColors} document={generatePDF()} fileName={`${data.data.firstName}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? "Loading document..." : t("download") + " PDF")}
          </Button>
        </Footer>
      </PageView>
    </Overlay>
  );
};

export default PDFGenerator;
