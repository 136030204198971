import React, { useEffect, useRef, useState } from "react";
import { Table, Th, Button, Td, Tr, Count, ArrowButton, AddButton, ButtonPanel, Filter, Filters, ToggleContainer, ToggleInput, ToggleSlider, NoData, ScrollLayout, FilterBox, Actions, More, Status, ActionBox, IconBox, GroupHeader, ScrollContainerLayout } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RowContainer } from "../../styles/containers/styles";
import { AddIcon, ExcelIcon, GetIcon, NextIcon, PreviousIcon, UploadIcon } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { deleteData, postData, putData } from "../../../backend/api";
import CrudForm from "./create";
import { useTranslation } from "react-i18next";
import { addPageObject } from "../../../store/actions/pages";
import FormInput from "../input";
import Manage from "./manage";
import Loader from "../loader";
import Search from "../search";
import SubPage from "./subPage";
import DateRangeSelector from "../daterange";
import * as xlsx from "xlsx";
import { convertMinutesToHHMM } from "../../functions/minuteToHour";
import { dateFormat, dateTimeFormat, timeFormat } from "../../functions/date";
import { ToolTip } from "../../styles/list/styles";
import PopupView from "../popupview";
import { DisplayInformations } from "./popup";
import { Title } from "../../private/common/layout/header/styels";
const ListTable = ({ dragList = false, profileImage, viewDetails = true, formView = "normal", referenceId = 0, actions = [], api, setMessage, attributes = [], addPrivilege = true, delPrivilege = true, updatePrivilege = true, shortName = "Item", itemTitle = "title", datefilter = false, bulkUplaod = false, pageKey = "", formMode = "single" }) => {
  const users = useSelector((state) =>
    state.pages[`${api}${referenceId === 0 ? "" : `/${referenceId}`}`]
      ? state.pages[`${api}${referenceId === 0 ? "" : `/${referenceId}`}`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  const { i18n } = useTranslation();
  const [selectedLanguage] = useState(i18n.language || "de");
  const [showSublist, setShowSubList] = useState(false);
  const [currentApi] = useState(`${api}${referenceId === 0 ? "" : `/${referenceId}`}`);
  const [subAttributes, setSubAttributes] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLimit, setCurrentLimit] = useState(15);
  const [count, setCount] = useState(0);
  const [showBulkUplad, setShowBulkUplad] = useState(false);

  const [groupedData, setGroupedData] = useState(null);
  const themeColors = useSelector((state) => state.themeColors);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [showLoader, setShowLoader] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [openData, setOpenData] = useState(false);
  const [pageLimitFilter] = useState({
    type: "select",
    placeholder: "pageLimitFilter",
    name: "pageLimitFilter",
    validation: "",
    default: "",
    label: "pageLimitFilter",
    required: false,
    view: false,
    tag: true,
    add: true,
    update: true,
    apiType: "JSON",
    selectApi: [
      { id: 15, value: "15" },
      { id: 25, value: "25" },
      { id: 50, value: "50" },
      { id: 75, value: "50" },
      { id: 100, value: "50" },
      { id: 0, value: t("all") },
    ],
  });
  /**
   * Function to set the showLoader state.
   * @param {boolean} status The status of the loader.
   */
  const setLoaderBox = (status) => {
    setShowLoader(status);
  };
  // processing attributes
  const [initialized, setInitialized] = useState(false);
  const [prevCrud, setPrevCrud] = useState("");
  const [formInput, setFormInput] = useState([]);
  const [errroInput, setErrorInput] = useState([]);
  const [addValues, setAddValues] = useState({});
  const [updateId, setUpdateId] = useState("");
  const [updateValues, setUpdateValues] = useState({});
  const [udpateView, setUpdateView] = useState(() => {});
  const [filterView, setFilterView] = useState({});
  const [data, setData] = useState(null);
  useEffect(() => {
    const addValuesTemp = {
      addValues: {},
      updateValues: {},
      viewValues: {},
      errorValues: {},
      filterValues: {},
    };
    let tempFilter = false;
    let date = new Date();
    attributes.forEach((item) => {
      if (item.type === "checkbox") {
        let bool = JSON.parse(item.default === "false" || item.default === "true" ? item.default : "false");
        if (item.add) {
          addValuesTemp.addValues[item.name] = bool;
        }
        addValuesTemp.updateValues[item.name] = bool;
      } else if (item.type === "multiSelect") {
        addValuesTemp.addValues[item.name] = [];
        addValuesTemp.errorValues[item.name] = [];
      } else if (item.type === "datetime" || item.type === "date" || item.type === "time") {
        if (item.default === "0") {
          // Set hour to 00:00
          date.setUTCHours(0, 0, 0, 0);
          addValuesTemp.updateValues[item.name] = date.toISOString();
        } else if (item.default === "1") {
          // Set hour to 23:59
          date.setUTCHours(23, 59, 0, 0);
          addValuesTemp.updateValues[item.name] = date.toISOString();
        } else {
          // Set hour to current time
          addValuesTemp.updateValues[item.name] = date.toISOString();
        }
        if (item.add) {
          addValuesTemp.addValues[item.name] = item.default === "empty" ? "" : date.toISOString();
        }
      } else if (item.type === "image" || item.type === "file") {
        if (item.add) {
          addValuesTemp.addValues[item.name] = "";
          addValuesTemp.addValues["selected_" + item.name] = [];
        }
        addValuesTemp.updateValues[item.name] = "";
        addValuesTemp.updateValues["selected_" + item.name] = [];
      } else {
        if (item.add) {
          addValuesTemp.addValues[item.name] = item.default;
        }
        addValuesTemp.updateValues[item.name] = item.default;
        if (item.type === "select") {
          if ((item.filterType ?? "multiSelect") === "multiSelect") {
            addValuesTemp.filterValues[item.name] = item.default?.length > 0 ? [item.default] : [];
          } else {
            addValuesTemp.filterValues[item.name] = item.default;
          }
          tempFilter = true;
        }
      }
      addValuesTemp.errorValues[item.name] = "";
      addValuesTemp.filterValues["searchkey"] = "";
    });
    setFormInput(attributes);
    setAddValues(addValuesTemp.addValues);
    setErrorInput(addValuesTemp.errorValues);
    setUpdateValues(addValuesTemp.updateValues);
    setFilterView(addValuesTemp.filterValues);
    setFilter(tempFilter);
    setInitialized(true);
  }, [attributes, dispatch, setPrevCrud, prevCrud, setFormInput, setAddValues, setUpdateValues, setFilterView]);

  // end processing attributes
  useEffect(() => {
    setLoaderBox(users.isLoading);
    if (currentIndex === 0 && users.data?.count) {
      setCount(users.data.count);
    }
    if (currentIndex === 0 && users.data?.groupedData) {
      setGroupedData(users.data.groupedData ?? []);
      setCount(0);
    }
    users.data?.data && setData(users.data.data);
  }, [users, currentIndex]);

  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject(currentApi, currentIndex, currentLimit, filterView, navigate));
    }
  }, [initialized, currentApi, currentIndex, currentLimit, dispatch, filterView, navigate]);
  const refreshView = (currentIndex) => {
    try {
      dispatch(addPageObject(currentApi, currentIndex, currentLimit, filterView, navigate));
    } catch {}
  };
  const toExcel = (currentIndex) => {
    try {
      exportToExcel();
    } catch (error) {
      console.log(error);
    }
  };
  //crud functions
  const [isCreating, setIsCreating] = useState(false);
  const isCreatingHandler = (value, callback) => {
    if (isCreating) {
      setUpdateView(() => callback);
      setIsCreating(false);
      navigate({}, "", window.location.pathname);
    } else {
      window.location.hash = "add";
      setIsCreating(true);
    }
  };
  const [isEditing, setIsEditing] = useState(false);
  const isEditingHandler = (value, callback) => {
    setLoaderBox(true);
    if (!isEditing) {
      setUpdateView(() => callback);
      const updateValues = {};
      setUpdateId(value._id);
      formInput.forEach((item) => {
        if (item.update) {
          if (item.type === "checkbox") {
            let bool = value[item.name] ? (value[item.name].toString() === "true" ? true : false) : false;
            updateValues[item.name] = bool;
          } else if (item.type === "number") {
            let bool = parseInt(value[item.name]);
            updateValues[item.name] = bool;
          } else if (item.type === "select") {
            updateValues[item.name] = typeof value[item.name] === "undefined" ? "" : typeof value[item.name] === "string" || typeof value[item.name] === "number" ? value[item.name] : value[item.name]?._id ? value[item.name]._id : "";
          } else if (item.type === "multiSelect") {
            updateValues[item.name] = Array.isArray(value[item.name]) ? value[item.name] : [];
          } else if (item.type === "image") {
            updateValues["selected_" + item.name] = [];
            updateValues[item.name] = value[item.name] ? value[item.name] : "";
          } else {
            updateValues[item.name] = value[item.name] ? value[item.name] : "";
          }
        }
      });
      setUpdateValues(updateValues);
      setIsEditing(true);
      window.location.hash = "edit";
    } else {
      setUpdateId("");
      navigate({}, "", window.location.pathname);
      setIsEditing(false);
    }
    setLoaderBox(false);
  };
  const deleteHandler = async (item, id = "") => {
    console.log(item);
    await deleteData({}, currentApi, id)
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: 1, content: t("deleted", { shortName: t(item.title ? item.title : shortName) }), proceed: t("okay") });
          setCount((count) => count - 1);
          setIsCreating(false);
          setIsEditing(false);
          refreshView();
          // udpateView(0);
        } else if (response.status === 404) {
          setMessage({ type: 1, content: "User not found!", proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: "Something went wrong!", proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        setLoaderBox(false);
      });
  };
  const [action, setActions] = useState([]);
  const openAction = (item, data) => {
    // Actions Window
    setActions({ item, data });
    // setMessage({ type: 1, content: item.title + " / " + data._id, proceed: "Okay" });
  };
  const submitHandler = async (data) => {
    setLoaderBox(true);
    await postData(data, currentApi)
      .then((response) => {
        if (response.status === 200) {
          // setMessage({ type: 1, content: `The '${t(shortName)}' saved successfully!`, proceed: "Okay" });
          setMessage({ type: 1, content: t("saved", { shortName: t(shortName) }), proceed: "Okay" });
          setIsCreating(false);
          refreshView();
          // udpateView(0);
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        setLoaderBox(false);
      });
  };

  const updateHandler = async (data) => {
    setLoaderBox(true);

    await putData(data, `${currentApi}/${updateId}`)
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: 1, content: t("updated", { shortName: t(shortName) }), proceed: "Okay" });
          refreshView(currentIndex);
          setIsEditing(false);
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };

  const filterChange = (sl, option, name) => {
    let items = filterView[name];
    if (Array.isArray(items)) {
      const index = items.findIndex((item) => item === option.id);
      if (index === -1) {
        // If option._id doesn't exist, push it to the items array
        items.push(option.id);
      } else {
        // If option._id already exists, remove it from the items array
        items.splice(index, 1);
      }
      const updateValue = {
        ...filterView,
        [name]: items,
      };
      console.log(updateValue);
      setFilterView(updateValue);
    } else {
      const updateValue = {
        ...filterView,
        [name]: option.id,
      };
      console.log(updateValue);
      setFilterView(updateValue);
    }
  };

  const pageLimitFilterChange = (option, name) => {
    setCurrentLimit(option.id);
  };
  const dateRangeChange = (item) => {
    const startDate = new Date(item.startDate);
    startDate.setHours(0, 0, 0, 0); // Set start date to 00:00

    const endDate = new Date(item.endDate);
    endDate.setHours(23, 59, 59, 999); // Set end date to 23:59
    const udpateValue = {
      ...filterView,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    // updating the formm values
    setFilterView(udpateValue);
  };
  const closeManage = () => {
    setActions([]);
  };

  //export to excel
  const exportToExcel = () => {
    // JSON data to be converted to Excel
    const jsonData = users.data?.data;
    if (jsonData) {
      // Convert JSON to worksheet
      const excelData = [];
      let userId = "";
      jsonData.forEach((data) => {
        const excelRow = {};

        attributes.forEach((attribute) => {
          if (attribute.view) {
            const name = t(attribute.label);
            switch (attribute.type) {
              case "view":
                return (excelRow[name] = data[attribute.collection][attribute.showItem] ?? "No Data");
              case "minute":
                return (excelRow[name] = convertMinutesToHHMM(parseFloat(data[attribute.name] ?? 0)));
              case "datetime":
                return (excelRow[name] = data[attribute.name] ? dateTimeFormat(data[attribute.name]) : "N/A");
              case "date":
                return (excelRow[name] = data[attribute.name] ? dateFormat(data[attribute.name]) : "N/A");
              case "time":
                return (excelRow[name] = data[attribute.name] ? timeFormat(data[attribute.name]) : "N/A");
              case "select":
                if (attribute.apiType === "JSON") {
                  return (excelRow[name] = attribute.selectApi.filter((item) => item.id.toString() === data[attribute.name]?.toString()).map((filteredItem, index) => filteredItem.value));
                } else if (attribute.apiType === "CSV") {
                  return (excelRow[name] = data[attribute.name]);
                } else {
                  return (excelRow[name] = data[attribute.name]?.[attribute.showItem] ?? "Nil");
                }

              default:
                switch (typeof data[attribute.name]) {
                  case "undefined":
                    return (excelRow[name] = "Not Found");
                  case "object":
                    return (excelRow[name] = data[attribute.name]?.[attribute.showItem] ?? "Nil");
                  case "boolean":
                    return (excelRow[name] = data[attribute.name].toString());
                  case "string":
                  case "number":
                  default:
                    if (attribute.type === "select" && attribute.apiType === "JSON") {
                      return attribute.selectApi.filter((item) => item.id.toString() === data[attribute.name]?.toString()).map((filteredItem) => (excelRow[name] = filteredItem.value));
                    } else {
                      return (excelRow[name] = data[attribute.name]?.toString().substring(0, 200));
                    }
                }
            }
          }
        });
        if (pageKey === "page-report3") {
          if (userId !== data?._id?.user?._id) {
            if (userId !== "") {
              excelData.push({});
              excelData.push({});
              excelData.push({});
            }
          }

          userId = data?._id?.user?._id;
        }
        excelData.push(excelRow);
      });
      if (pageKey === "page-report3") {
        excelData.push({});
        excelData.push({});
        excelData.push({});
      }
      const worksheet = xlsx.utils.json_to_sheet(excelData);

      // Create workbook
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, t("report"));
      // Convert workbook to Excel binary and download the file
      xlsx.writeFile(workbook, t(shortName) + "-data.xlsx");
    }
  };

  const handleDragStart = (e, id, sequence) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", id + "-" + sequence);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = async (e, index, sequence) => {
    e.preventDefault();

    // Retrieve the dragged data and split it
    let dataValue = e.dataTransfer.getData("text/plain");
    dataValue = dataValue.split("-");
    const draggedRowId = parseInt(dataValue[0]);
    // const draggedSequence = parseFloat(dataValue[1]);

    // Find the index of the dragged and dropped rows
    const lastRow = data[index - 1] ?? { sequence: 0 };
    const droppedRow = data[index];

    // Copy data to update it
    const updatedRows = [...data];

    // Remove the dragged row and get it
    const [draggedRow] = updatedRows.splice(draggedRowId, 1);
    updatedRows.splice(index, 0, draggedRow);
    // Update the sequence of the dragged row
    console.log(draggedRowId, index);

    updatedRows[index].sequence = lastRow.sequence ?? 0;
    updatedRows[index + 1].sequence = (lastRow.sequence ?? 0) + 0.001;

    // Insert the dragged row at the new position

    // Update the state with the modified data

    setData(updatedRows);
    await putData({ sequence: draggedRow.sequence }, `${api}/${draggedRow._id}`);
    await putData({ sequence: droppedRow.sequence }, `${api}/${droppedRow._id}`);
  };

  //export to excel end
  const TableRowWithActions = ({ attributes, data, slNo, pinnedRows }) => {
    // data[attribute.name]?.title ? data[attribute.name]?.title : data[attribute.name]?.toString()
    const [isDragOver, setIsDragOver] = useState(false);

    selectRef.current[slNo] = useRef(null);
    let acctionCount = actions.filter((item) => item.element === "button" && item.type !== "subList").length;
    const openRowData = () => {
      setShowDetails(true);
      setOpenData({ actions, attributes, data });
      setSubAttributes({ actions, attributes, data });
    };
    return (
      <Tr
        draggable={dragList}
        onDragStart={
          dragList
            ? (e) => {
                handleDragStart(e, slNo, data.sequence ?? 0);
              }
            : null
        }
        isDragOver={isDragOver}
        onDragOver={
          dragList
            ? (e) => {
                handleDragOver(e);
                setIsDragOver(true);
              }
            : null
        }
        onDragLeave={() => {
          setIsDragOver(false);
        }}
        onDrop={dragList ? (e) => handleDrop(e, slNo, data.sequence ?? 0) : null}
        key={`${shortName}-${slNo}-${pinnedRows}`}
        className={pinnedRows ? "no-border pinned" : "no-border"}
      >
        {/* <td>{data.sequence}</td> */}
        {attributes.map((attribute, index) => {
          let itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection]?.[attribute.showItem] : data[attribute.name];
          let type = attribute.type;
          if (attribute.view === true) {
            if (attribute.type === "mixed") {
              type = data["fieldType"];
            }
            if (attribute.type === "select" && attribute?.showItem === "locale") {
              itemValue = attribute.showItem?.length > 0 ? data[attribute.name]?.[selectedLanguage] ?? "" : data[attribute.name];
              return (
                <Td onClick={openRowData} key={index}>
                  {t(itemValue)}
                </Td>
              );
            }
            try {
              if (itemValue.length === 0) {
                return (
                  <Td onClick={openRowData} key={index}>
                    {t(`na`)}
                  </Td>
                );
              }
              switch (type) {
                case "translate":
                  return (
                    <Td onClick={openRowData} key={index}>
                      {t(itemValue)}
                    </Td>
                  );
                case "text":
                case "view":
                  return (
                    <Td onClick={openRowData} key={index}>
                      {t(itemValue)}
                    </Td>
                  );
                case "minute":
                  return (
                    <Td onClick={openRowData} key={index}>
                      {convertMinutesToHHMM(parseFloat(itemValue ?? 0))}
                    </Td>
                  );
                case "image":
                  return (
                    <Td onClick={openRowData} key={index}>
                      {/* <Img src={`https://d2ko9fbemypq1w.cloudfront.net/${parent[attribute.name]}`} /> */}
                    </Td>
                  );
                case "file":
                  return (
                    <Td key={index} onClick={openRowData}>
                      <div
                        style={{ width: "20px", cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default link behavior
                          const link = document.createElement("a");
                          link.href = process.env.REACT_APP_CDN + itemValue;
                          link.target = "_blank"; // Open in a new page/tab
                          link.download = ""; // You can set the desired download filename here
                          link.style.display = "none";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      >
                        <GetIcon icon={"Download"}></GetIcon>
                      </div>
                    </Td>
                  );

                case "datetime":
                  let userFriendlyDateTime = itemValue ? dateTimeFormat(itemValue) : "N/A";
                  return (
                    <Td onClick={openRowData} key={index}>
                      {userFriendlyDateTime}
                    </Td>
                  );
                case "icon":
                  return (
                    <Td key={index} className={itemValue}>
                      <GetIcon onClick={openRowData} icon={itemValue}></GetIcon>
                    </Td>
                  );
                case "date":
                  let userFriendlyDate = itemValue ? dateFormat(itemValue) : "N/A";
                  return (
                    <Td onClick={openRowData} key={index}>
                      {userFriendlyDate}
                    </Td>
                  );
                case "expired":
                  let status = itemValue ? new Date(itemValue) > new Date() ? <Status color={"green"}>Active</Status> : <Status color={"red"}>Inactive</Status> : <Status color={"green"}> {t(attribute.ifNull)}</Status>;
                  return (
                    <Td onClick={openRowData} key={index}>
                      {status}
                    </Td>
                  );
                case "time":
                  return (
                    <Td onClick={openRowData} key={index}>
                      {itemValue ? timeFormat(itemValue) : "N/A"}
                    </Td>
                  );
                case "color":
                  return (
                    <Td onClick={openRowData} key={index}>
                      <div style={{ backgroundColor: itemValue, height: "20px", width: "40px" }}></div>
                    </Td>
                  );
                case "textarea":
                  return <Td onClick={openRowData} key={index} dangerouslySetInnerHTML={{ __html: data[attribute.name]?.substring(0, 200) }}></Td>;
                case "checkbox":
                  return (
                    <Td onClick={openRowData} key={index}>
                      <IconBox className={"display"}>{itemValue ? <GetIcon icon={"checked"} /> : <GetIcon icon={"Close"} />}</IconBox>
                    </Td>
                  );

                case "select":
                  if (attribute.apiType === "JSON") {
                    const item = attribute.selectApi
                      .filter((item) => item.id.toString() === data[attribute.name]?.toString())
                      .map((filteredItem, index) => (
                        <Td onClick={openRowData} style={{ color: filteredItem.color }} key={index}>
                          {filteredItem.value}
                        </Td>
                      ));
                    return item.length > 0 ? item : <Td>N/A</Td>;
                  } else if (attribute.apiType === "CSV") {
                    return (
                      <Td onClick={openRowData} key={index}>
                        {data[attribute.name] ?? "Nil"}
                      </Td>
                    );
                  } else {
                    return (
                      <Td onClick={openRowData} key={index}>
                        {data[attribute.name]?.[attribute.showItem] ?? "Nil"}
                      </Td>
                    );
                  }
                default:
                  switch (typeof data[attribute.name]) {
                    case "undefined":
                      return (
                        <Td onClick={openRowData} key={index}>
                          {t(`na`)}
                        </Td>
                      );
                    case "object":
                      return (
                        <Td onClick={openRowData} key={index}>
                          {data[attribute.name]?.[attribute.showItem] ?? "Nil"}
                        </Td>
                      );
                    case "boolean":
                      return (
                        <Td onClick={openRowData} key={index}>
                          {data[attribute.name].toString()}
                        </Td>
                      );
                    case "string":
                    case "number":
                    default:
                      if (attribute.type === "select" && attribute.showItem === "locale") {
                        return (
                          <Td onClick={openRowData} key={index}>
                            {data[attribute.name][localStorage.getItem("language") ?? "de"]}
                          </Td>
                        );
                      } else if (attribute.type === "select" && attribute.apiType === "JSON") {
                        const item = attribute.selectApi
                          .filter((item) => item.id.toString() === data[attribute.name]?.toString())
                          .map((filteredItem) => (
                            <Td onClick={openRowData} style={{ color: filteredItem.color }} key={index}>
                              {filteredItem.value}
                            </Td>
                          ));
                        // console.log(attribute.naneitem);
                        return item ?? <Td>{t(`na`)}</Td>;
                      } else {
                        return (
                          <Td onClick={openRowData} key={index}>
                            {data[attribute.name]?.toString().substring(0, 200)}
                          </Td>
                        );
                      }
                  }
              }
            } catch (error) {
              if (type === "expired") {
                return (
                  <Td onClick={openRowData} key={index}>
                    <Status color={"green"}> {t(attribute.ifNull ?? "N/A")}</Status>
                  </Td>
                );
              } else {
                return <Td onClick={openRowData}>{t(`na`)}</Td>;
              }
            }
          } else {
            return null;
          }
        })}

        <Td key={`actions-${shortName}-${data._id}`} style={{ zIndex: (pinnedRows === true ? count * 2 : count) + 100 - slNo }} className="actions">
          <ActionBox>
            {updatePrivilege && (
              <More
                key={`edit-${data._id}`}
                onClick={() => {
                  isEditingHandler(data, udpateView);
                }}
                className="edit"
              >
                <GetIcon icon={"edit"} />
              </More>
            )}
            {/* {delPrivilege && (
              <More
                key={`delete-${data._id}`}
                onClick={() => {
                  setMessage({
                    type: 2,
                    content: t("deleteRequest", { label: data[itemTitle] ? data[itemTitle].toString() : "Item" }),
                    proceed: t("delete"),
                    onProceed: deleteHandler,
                    data: data,
                  });
                }}
                className="delete"
              >
                <GetIcon icon={"delete"} />
              </More>
            )} */}

            {actions.map((item) => {
              return (
                item.element !== "button" &&
                (item.element === "toggle" ? (
                  <ToggleContainer key={`${item.id}-${data._id}`}>
                    <ToggleInput
                      type="checkbox"
                      checked={data[item.id]}
                      onChange={async (event) => {
                        // item.callback(item, data);
                        setLoaderBox(true);
                        await postData({ status: event.target.checked }, `${item.api}/${data._id}`)
                          .then((response) => {
                            if (response.status === 200) {
                              if (response.data?.message) {
                                setMessage({ type: 1, content: t(response.data?.message), proceed: t("okay") });
                              }
                              //
                              refreshView();
                              // setIsEditing(false);
                            } else if (response.status === 404) {
                              refreshView();
                              setMessage({ type: 1, content: t("error"), proceed: "Okay" });
                            } else {
                              refreshView();
                              setMessage({ type: 1, content: t("error"), proceed: "Okay" });
                            }
                            // setLoaderBox(false);
                          })
                          .catch((error) => {
                            alert(error);
                            // setLoaderBox(false);
                          });
                      }}
                    />
                    <ToggleSlider />
                  </ToggleContainer>
                ) : (
                  <More
                    key={`${slNo}-action-new`}
                    onClick={() => {
                      if (item.type === "callback") {
                        item.callback(item, data, refreshView);
                      } else if (item.type === "call") {
                        window.location.href = `tel:${data.mobileNumber}`;
                      } else if (item.type === "subList") {
                        setSubAttributes({ item, data });
                        setShowSubList(true);
                      } else {
                        openAction(item, data);
                      }
                    }}
                  >
                    <GetIcon icon={item.icon}></GetIcon>
                  </More>
                ))
              );
            })}
            {viewDetails === true && (
              <More onClick={openRowData}>
                <GetIcon icon={"open"}></GetIcon>
              </More>
            )}
            <div
              ref={selectRef.current[slNo]}
              onClick={() => {
                setCurrentAction(slNo);
              }}
            >
              {acctionCount > 0 && (
                <>
                  <More className={currentAction === slNo ? `active` : ``}>
                    <GetIcon icon={"dots"}></GetIcon>
                  </More>

                  <ToolTip className={currentAction === slNo ? `actions` : `actions hide`}>
                    <Actions>
                      {actions.map((item) => {
                        return (
                          item.element === "button" && (
                            <Button
                              key={`custom-${item.id}-${data._id}`}
                              onClick={() => {
                                if (item.type === "callback") {
                                  item.callback(item, data);
                                } else if (item.type === "call") {
                                  window.location.href = `tel:${data.mobileNumber}`;
                                } else if (item.type === "subList") {
                                  setSubAttributes({ item, data });
                                  setShowSubList(true);
                                } else {
                                  openAction(item, data);
                                }
                              }}
                              className="edit"
                            >
                              <GetIcon icon={item.icon} />
                              <span>{t(item.title)}</span>
                            </Button>
                          )
                        );
                      })}
                    </Actions>
                  </ToolTip>
                </>
              )}
            </div>
          </ActionBox>
        </Td>
      </Tr>
    );
  };
  const closeModal = () => {
    setShowSubList(false);
  };
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState(false);
  const searchTimeoutRef = useRef();
  const handleChange = (event) => {
    clearTimeout(searchTimeoutRef.current);
    setSearchValue(event.target.value);
    searchTimeoutRef.current = setTimeout(() => {
      setFilterView({ ...filterView, searchkey: event.target.value });
    }, 300);
  };
  useEffect(() => {
    return () => {
      clearTimeout(searchTimeoutRef.current);
    };
  }, []);
  const selectRef = useRef([]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedInsideRefs = selectRef.current.filter((ref) => ref.current && ref.current.contains(event.target));

      if (clickedInsideRefs.length === 0) {
        setCurrentAction("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const selectedMenu = useSelector((state) => state.selectedMenu);
  //end crud functions
  return (
    <RowContainer className="list">
      <Title className="inner">
        <GetIcon icon={selectedMenu.icon}></GetIcon>
        {t(selectedMenu.label)}
      </Title>
      <ButtonPanel>
        <FilterBox>
          <Search theme={themeColors} placeholder="Search" value={searchValue} onChange={handleChange}></Search>
          {filter && (
            <Filter
              theme={themeColors}
              onClick={() => {
                refreshView(currentIndex);
              }}
            >
              <GetIcon icon={"reload"} />
            </Filter>
          )}
          <Filter
            theme={themeColors}
            onClick={() => {
              setMessage({ type: 2, content: t("export"), proceed: t("exportNow"), onProceed: toExcel, data: currentIndex });
            }}
          >
            <ExcelIcon />
          </Filter>
          {datefilter && <DateRangeSelector onChange={dateRangeChange} themeColors={themeColors}></DateRangeSelector>}
        </FilterBox>
        <Filters>
          {formInput.map((item, index) => {
            return item.type === "select" && (item.filter ?? true) === true && <FormInput customClass={"filter"} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index} id={item.name} {...item} type={item.filterType ?? "multiSelect"} onChange={filterChange} />;
          })}
        </Filters>
        {(addPrivilege ? addPrivilege : false) && (
          <AddButton onClick={() => isCreatingHandler(true, refreshView)}>
            <AddIcon></AddIcon>
            <span>{t("addNew", { label: t(shortName) })}</span>
          </AddButton>
        )}
        {(bulkUplaod ? bulkUplaod : false) && (
          <AddButton onClick={() => setShowBulkUplad((prev) => !prev)}>
            <UploadIcon></UploadIcon>
            <span>{t("bulkUpload", { label: t(shortName) })}</span>
          </AddButton>
        )}
      </ButtonPanel>
      <ScrollContainerLayout>
        <ScrollLayout>
          <Table>
            <thead></thead>
            {groupedData?.length > 0 ? (
              groupedData.map((group) => {
                return (
                  <>
                    <GroupHeader className={group.status}>
                      <GetIcon icon={group.status}></GetIcon>
                      {t(group.status)}
                    </GroupHeader>
                    <thead>
                      <Tr className="no-border">
                        {attributes.map((attribute, index) => {
                          // console.log(attribute.view === true ? attribute.name : "");
                          return attribute.view === true ? <Th key={shortName + attribute.name + index}>{t(attribute.label)}</Th> : "";
                        })}
                      </Tr>
                    </thead>
                    <tbody>
                      {group.items?.map((item, index) => (
                        <React.Fragment key={`${shortName}-${index}`}>
                          <TableRowWithActions slNo={index} attributes={attributes} data={item} pinnedRows={false} />
                        </React.Fragment>
                      ))}
                    </tbody>
                  </>
                );
              })
            ) : (
              <>
                <thead>
                  <Tr className="head no-border">
                    {attributes.map((attribute, index) => {
                      // console.log(attribute.view === true ? attribute.name : "");
                      return attribute.view === true ? <Th key={shortName + attribute.name + index}>{t(attribute.label)}</Th> : "";
                    })}
                  </Tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data.map((item, index) => (
                      <React.Fragment key={`${shortName}-${index}`}>
                        <TableRowWithActions slNo={index} attributes={attributes} data={item} pinnedRows={false} />
                      </React.Fragment>
                    ))}
                </tbody>
              </>
            )}
          </Table>
          {groupedData?.length > 0 ? null : (!users.data || !users.data?.data || users.data?.data.length === 0) && <NoData>{t("notFound", { page: t(shortName) })}</NoData>}
        </ScrollLayout>
      </ScrollContainerLayout>
      {count > currentLimit ? (
        <Filters className="full">
          <Count>
            <ArrowButton
              theme={themeColors}
              onClick={() => {
                setCurrentIndex((prev) => (prev > 9 ? prev - (currentLimit === 0 ? count : currentLimit) : 0));
              }}
            >
              <PreviousIcon />
            </ArrowButton>
            {t("showing", {
              from: currentIndex + 1,
              to: Math.min(currentIndex + (currentLimit === 0 ? count : currentLimit), count),
              count: count,
            })}
            <ArrowButton
              theme={themeColors}
              onClick={() => {
                setCurrentIndex((prev) => (prev + 10 > count ? currentIndex : currentIndex + (currentLimit === 0 ? count : currentLimit)));
              }}
            >
              <NextIcon />
            </ArrowButton>
          </Count>
          <FormInput customClass={"filter records"} placeholder={`show`} value={currentLimit} key={`input0`} id={"itemsPage"} {...pageLimitFilter} onChange={pageLimitFilterChange} />
        </Filters>
      ) : groupedData?.length > 0 ? null : (
        <Filters theme={themeColors} className="full">
          {count === 0 ? <Count>{t("showingZero")}</Count> : <Count>{t("showingData", { count })}</Count>}
        </Filters>
      )}
      {showDetails && (
        <PopupView
          // Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={<DisplayInformations udpateView={udpateView} isEditingHandler={isEditingHandler} actions={actions} refresh={refreshView} setMessage={setMessage} setLoaderBox={setLoaderBox} profileImage={profileImage} formMode={formMode} formView={formView} attributes={openData.attributes} data={openData.data} />}
          themeColors={themeColors}
          closeModal={() => {
            setShowDetails(false);
          }}
          setMessage={setMessage}
          setLoaderBox={setLoaderBox}
          profileImage={profileImage}
          itemTitle={typeof itemTitle === "string" ? { name: itemTitle, type: "text" } : itemTitle}
          openData={openData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={formView}
        ></PopupView>
      )}
      {isCreating && <CrudForm formMode={formMode} formView={formView} api={api} formType={"post"} header={t("addNewTitle", { label: t(shortName ? shortName : "Form") })} formInput={formInput} formValues={addValues} formErrors={errroInput} submitHandler={submitHandler} isOpenHandler={isCreatingHandler} isOpen={isCreating}></CrudForm>}
      {isEditing && <CrudForm delPrivilege={delPrivilege} deleteHandler={deleteHandler} setMessage={setMessage} formMode={formMode} formView={formView} api={api} formType={"put"} updateId={updateId} header={t("update", { label: t(shortName ? shortName : "Form") })} formInput={formInput} formErrors={errroInput} formValues={updateValues} submitHandler={updateHandler} isOpenHandler={isEditingHandler} isOpen={isEditing}></CrudForm>}
      {action.data && <Manage setMessage={setMessage} setLoaderBox={setLoaderBox} onClose={closeManage} {...action}></Manage>}
      {bulkUplaod && showBulkUplad && (
        <CrudForm
          bulkUpload={true}
          delPrivilege={delPrivilege}
          deleteHandler={deleteHandler}
          setMessage={setMessage}
          setLoaderBox={setLoaderBox}
          formMode={"single"}
          formView={formView}
          api={api}
          formType={"put"}
          updateId={updateId}
          header={t("bulkUpload", { label: t(shortName ? shortName : "Form") })}
          formInput={formInput}
          formErrors={errroInput}
          formValues={updateValues}
          currentApi={currentApi}
          submitHandler={() => {
            refreshView(currentIndex);
            setIsEditing(false);
            setShowBulkUplad(false);
          }}
          isOpenHandler={() => setShowBulkUplad(false)}
          isOpen={isEditing}
        ></CrudForm>
      )}
      {showLoader && <Loader></Loader>}
      {showSublist && subAttributes?.item?.attributes?.length > 0 && <SubPage closeModal={closeModal} setMessage={setMessage} setLoaderBox={setLoaderBox} subAttributes={subAttributes}></SubPage>}
    </RowContainer>
  );
};
export default ListTable;
