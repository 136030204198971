import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../../../common/layout/styels";
import ListTable from "../../../../elements/list/list";
import withLayout from "../../../common/layout/indexPlain";
import AttendanceDetails from "../../team/attendance";
const Board = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "fullName",
      name: "firstName",
      validation: "",
      default: "",
      label: "fullName",
      required: true,
      view: true,
      add: false,
      update: true,
    },
    {
      type: "select",
      placeholder: "boardStatus",
      name: "boardStatus",
      validation: "",
      filter: false,
      default: "",
      label: "boardStatus",
      required: true,
      view: true,
      add: false,
      update: false,
      apiType: "JSON",
      selectApi: [
        { value: "Board", id: true },
        { value: "Deboard", id: false },
      ],
    },
    {
      type: "select",
      placeholder: "place",
      name: "place",
      validation: "",
      default: "",
      showItem: "title",
      label: "place",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "place/select",
    },
    {
      type: "select",
      placeholder: "shift",
      name: "shift",
      validation: "",
      default: "",
      label: "shift",
      required: true,
      showItem: "title",
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "shift/select",
    },
  ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("Team")} - VSH Security`;
  }, [t]);

  const [action, setActions] = useState([]);
  const [actionType, setActionType] = useState("attendance");
  const markAttendance = (item, data) => {
    setActions({ item, data });
    setActionType("attendance");
  };
  const [actions] = useState([{ element: "button", type: "callback", id: "ongoing-today", title: "attendanceToday", icon: "attendanceToday", callback: markAttendance }]);
  const closeManage = () => {
    setActions([]);
  };
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} api={`report/onwork`} actions={actions} itemTitle={`title`} shortName={`board`} addPrivilege={false} delPrivilege={false} updatePrivilege={false} attributes={attributes}></ListTable>
      {action.data && actionType === "attendance" && <AttendanceDetails {...action} onClose={closeManage} setLoaderBox={props.setLoaderBox} title={"attendanceToday"}></AttendanceDetails>}
    </Container>
  );
};
// exporting the page with parent container layout..
export default withLayout(Board);
