import React from "react";

import Imprint from "../../public/imprint"; // Imprint page component
import Login from "../../public/login"; // Login page component
import Privacy from "../../public/privacy"; // Privacy page component
import User from "../../private/pages/user";
import Shift from "../../private/pages/shift";
import Place from "../../private/pages/place";
import Attendance from "../../private/pages/attendance";
import Team from "../../private/pages/team";
import Admin from "../../private/pages/admin";
import WorkLog from "../../private/pages/attendance/worklog";
import Holidays from "../../private/pages/holidays";
import Department from "../../private/pages/department";
import Surcharge from "../../private/pages/surcharge";
import Schedules from "../../private/pages/attendance/schedules";
import LeaveType from "../../private/pages/leaveType";
import Religion from "../../private/pages/religion";
import BannedPeople from "../../private/pages/bannedPeople";
import Dashboard from "../../private/pages/dashboard";
import Page404 from "../../private/pages/page404";
import Invitation from "../../public/invitation";
import Employee from "../../private/pages/dashboard/employee";
import Schedule from "../../private/pages/employee/schedule";
import Worktimelog from "../../private/pages/employee/worktimelog";
import Profile from "../../private/pages/employee/profile";
import WageType from "../../private/pages/wageType";
import DocumentType from "../../private/pages/documentType";
import Documents from "../../private/pages/documents";
import LeaveRequest from "../../private/pages/leaveRequest";
import LeaveRequestEmployee from "../../private/pages/employee/LeaveRequestEmployee";
import WorkDays from "../../private/pages/workDays";
import Location from "../../private/pages/location";
import Settings from "../../private/pages/settings";
import News from "../../private/pages/news";
import Organization from "../../private/pages/organization";
import EmployeeNews from "../../private/pages/employee/employeeNews";
import UserTypes from "../../private/pages/userTypes";
import Company from "../../private/pages/company";
import SuperAdmin from "../../private/pages/superAdmin";
import Registration from "../../public/registration";
import EquipmentType from "../../private/pages/equipmentType";
import Language from "../../private/pages/language";
import Subcontractors from "../../private/pages/subcontractors";
import Qualification from "../../private/pages/qualification";
const Switch = ({ page, router }) => {
  console.log(page);
  switch (page) {
    case "login":
      return <Login />;
    case "user":
      return <User />;
    case "shift":
      return <Shift />;
    case "employees":
      return <User />;
    case "place":
      return <Place />;
    case "team":
      return <Team />;
    case "attendance":
      return <Attendance />;
    case "religion":
      return <Religion />;
    case "imprint":
      return <Imprint />;
    case "work-log":
      return <WorkLog />;
    case "privacy":
      return <Privacy />;
    case "admin":
      return <Admin />;
    case "superAdmin":
      return <SuperAdmin />;
    case "holiday":
      return <Holidays />;
    case "department":
      return <Department />;
    case "surcharge":
      return <Surcharge />;
    case "dashboard":
      return <Dashboard />;
    case "dashboardEmployee":
      return <Employee />;
    case "schedule":
      return <Schedules />;
    case "dutySchedule":
      return <Schedule />;
    case "workingTimeLog":
      return <Worktimelog />;
    case "profile":
      return <Profile />;
    case "leaveType":
      return <LeaveType />;
    case "bannedPeople":
      return <BannedPeople />;
    case "invitations":
      return <Invitation />;
    case "wageType":
      return <WageType />;
    case "leaveRequest":
      return <LeaveRequest />;
    case "leaveRequestEmployee":
      return <LeaveRequestEmployee />;
    case "documentType":
      return <DocumentType />;
    case "equipmentType":
      return <EquipmentType />;
    case "documents":
      return <Documents />;
    case "language":
      return <Language />;
    case "subcontractors":
      return <Subcontractors />;
    case "qualification":
      return <Qualification />;
    case "workDays":
      return <WorkDays />;
    case "location":
      return <Location />;
    case "news":
      return <News />;
    case "organizationChart":
      return <Organization />;
    case "employeeNews":
      return <EmployeeNews />;
    case "userRoles":
      return <UserTypes />;
    case "company":
      return <Company />;
    case "signup":
      return <Registration />;
    case "settings":
      return <Settings router={router} />;
    default:
      return <Page404 />;
  }
};

export default Switch;
