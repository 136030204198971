import { useEffect, useState } from "react";
import { getData, putData } from "../../../../../backend/api";
import { dateFormat } from "../../../../functions/date";
import { AddButton, Table, Td, Tr } from "../../../../elements/list/styles";
import { useTranslation } from "react-i18next";
import { Schedule, SchedulesBox } from "../../attendance/schedules/styles";
import moment from "moment";
import { Footer } from "../../../../elements/form/styles";
import { GetIcon } from "../../../../../icons";

const ApproveLeave = ({ openData, setMessage, setLoaderBox, closeModal }) => {
  const { t } = useTranslation();
  const [request] = useState(openData.data);
  const [updateId] = useState(openData.data._id);
  const [result, setResult] = useState(openData.data._id);
  useEffect(() => {
    setLoaderBox(true);
    getData({}, `leave-request/employee/${updateId}`)
      .then((response) => {
        setResult(response);
        setLoaderBox(false);
      })
      .catch(() => {
        setLoaderBox(false);
      });
  }, [updateId, setLoaderBox]);

  return (
    <div>
      <Table style={{ marginTop: "20px", marginBottom: "20px" }}>
        <tbody>
          <Tr>
            <Td>{t("reason")}</Td>
            <Td>{request.note}</Td>
          </Tr>
          <Tr>
            <Td>{t("startDate")}</Td>
            <Td>{dateFormat(request.startDate)}</Td>
          </Tr>
          <Tr>
            <Td>{t("endDate")}</Td>
            <Td>{dateFormat(request.endDate)}</Td>
          </Tr>
          <Tr>
            <Td>{t("dates")}</Td>
            <Td>
              <SchedulesBox className="wrap">
                {result.data &&
                  result.data.data.map((day) => {
                    return (
                      <Schedule color={day.approval === "Confirmed" ? "green" : day.approval === "Rejected" ? "red" : ""}>
                        <span>{t(moment(day.date).format("ddd"))} </span>
                        <span>{dateFormat(day.date)} </span>
                      </Schedule>
                    );
                  })}
              </SchedulesBox>
            </Td>
          </Tr>
          <Tr>
            <Td>{t("status")}</Td>
            <Td>
              {`${t(request?.approval)} `}
              {request?.approval === "Confirmed" && <GetIcon icon={"approved"}></GetIcon>}
            </Td>
          </Tr>
        </tbody>
      </Table>
      {request?.approval === "Pending" && (
        <Footer>
          <>
            <AddButton
              className="red"
              onClick={async () => {
                setLoaderBox(true);
                await putData({ updateId, status: "Rejected" }, `leave-request/employee/${updateId}`);
                setLoaderBox(false);
                closeModal();
              }}
            >
              <GetIcon icon={"bannedPeople"}></GetIcon>
              {`${t("Reject")} ${result?.data?.data.length}`}
            </AddButton>
            <AddButton
              className="green"
              onClick={async () => {
                setLoaderBox(true);
                await putData({ updateId, status: "Confirmed" }, `leave-request/employee/${updateId}`);
                setLoaderBox(false);
                closeModal();
              }}
            >
              <GetIcon icon={"approved"}></GetIcon>
              {`${t("Confirm")} ${result?.data?.data.length}`}
            </AddButton>
          </>
        </Footer>
      )}
    </div>
  );
};
export default ApproveLeave;
