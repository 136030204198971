const initialState = {};

function pages(state = initialState, action) {
  switch (action.type) {
    case "ADD_PAGE_OBJECT":
      if (action.key === "dashboard" || action.key === "dashboard/employee") {
        return {
          ...state,
          [action.key]: action.payload,
          dashboardOrder: action.payload.data?.dashboardOrder ?? [],
        };
      } else {
        return {
          ...state,
          [action.key]: action.payload,
        };
      }
    case "ADD_PAGE_OBJECT_LOADING":
      return {
        ...state,
        [action.key]: { isLoading: true },
      };
    default:
      return state;
  }
}

export { pages };
