import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
import AttendanceDetails from "./attendance";
import AutoForm from "../../../elements/form";
import { postData } from "../../../../backend/api";
const Team = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "show",
      name: "show",
      validation: "",
      default: "",
      label: "show",
      required: false,
      filter: true,
      view: false,
      add: false,
      update: false,
      filterType: "select",
      apiType: "JSON",
      selectApi: [
        { id: "1", value: `${t("firstName")} ${t("lastName")}` },
        { id: "2", value: `${t("lastName")} ${t("firstName")}` },
        { id: "3", value: `${t("firstName")}` },
        { id: "4", value: `${t("lastName")}` },
      ],
    },
    {
      type: "text",
      placeholder: "fullName",
      name: "fullName",
      validation: "",
      default: "",
      label: "fullName",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "place",
      name: "place",
      showItem: "title",
      validation: "",
      default: "",
      label: "place",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "place/select",
    },
    {
      type: "time",
      placeholder: "shiftStart",
      name: "shiftStart",
      collection: "schedules",
      showItem: "startTime",
      validation: "",
      label: "shiftStart",
      required: true,
      interval: 15,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "time",
      placeholder: "shiftEnd",
      name: "shiftEnd",
      collection: "schedules",
      showItem: "endTime",
      label: "shiftEnd",
      required: true,
      interval: 15,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "shift",
      name: "shiftTemplate",
      collection: "shift",
      showItem: "title",
      validation: "",
      default: "",
      label: "shift",
      required: true,
      filter: true,
      view: false,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "shift/select",
    },
    {
      type: "view",
      placeholder: "shift",
      name: "shift",
      collection: "shift",
      showItem: "title",
      validation: "",
      default: "",
      label: "shift",
      required: true,
      filter: false,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "shift/select",
    },
    {
      type: "select",
      placeholder: "status",
      name: "status",
      validation: "",
      default: "",
      label: "status",
      required: false,
      view: false,
      add: false,
      filterType: "select",
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "onboard", value: "Onboard" },
        { id: "scheduled", value: "Ongoing" },
        { id: "today", value: "Today's Schedule" },
        { id: "all", value: "All User" },
      ],
    },
  ]);
  const [note] = useState([
    {
      type: "text",
      placeholder: "note",
      name: "note",
      validation: "",
      default: "",
      label: "note",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("Team")} - VSH Security`;
  }, [t]);
  const [action, setActions] = useState([]);
  const [actionType, setActionType] = useState("attendance");
  const markAttendance = (item, data) => {
    setActions({ item, data });
    setActionType("attendance");
  };
  const addNote = (item, data) => {
    setActions({ item, data });
    setActionType("note");
  };
  const closeManage = () => {
    setActions([]);
  };

  const [actions] = useState([
    { element: "toggle", type: "callback", api: "attendance/mark", id: "boardStatus" },
    { element: "action", type: "call", id: "add-call", title: "call", icon: "call" },
    { element: "button", type: "callback", id: "add-note", title: "note", icon: "note", callback: addNote },
    { element: "button", type: "callback", id: "ongoing-today", title: "attendanceToday", icon: "attendanceToday", callback: markAttendance },
  ]);
  const submitNote = async (post) => {
    await postData(post, `attendance/${action.data._id}/add-note`)
      .then((response) => {
        if (response.status === 200) {
          props.setMessage({ type: 1, content: `The '${t("note")}' saved successfully!`, proceed: t("okay") });

          setActions([]);
        } else {
          props.setMessage({ type: 1, content: "Somethig went wrong", proceed: "Try Again" });
        }
        props.setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: "An error occurred on the server. Please try again later or contact support if the issue persists.", proceed: "Try Again" });
        props.setLoaderBox(false);
      });
  };
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable viewDetails={false} {...props} actions={actions} api={`place/team`} itemTitle={`title`} shortName={`team`} addPrivilege={false} delPrivilege={false} updatePrivilege={false} attributes={attributes}></ListTable>
      {action.data && actionType === "attendance" && <AttendanceDetails {...action} onClose={closeManage} setLoaderBox={props.setLoaderBox} title={"attendanceToday"}></AttendanceDetails>}
      {action.data && actionType === "note" && <AutoForm useCaptcha={false} useCheckbox={false} description={""} key={"add-new-note"} formType={"post"} header={t("addNewTitle", { label: "note" })} formInput={note} submitHandler={submitNote} button={t("submit")} isOpenHandler={closeManage} isOpen={true} plainForm={false}></AutoForm>}
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Team);
