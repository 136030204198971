import { useTranslation } from "react-i18next";
import FormInput from "../../../../../elements/input";
import { Footer, Header, Overlay, Page } from "../../../../../elements/list/manage/styles";
import { Table, Td, Th, Tr } from "../../../../../elements/list/styles";
import { convertMinutesToHHMM } from "../../../../../functions/minuteToHour";
import { dateTimeFormat, timeFormat } from "../../../../../functions/date";
import React, { useEffect, useState } from "react";
import { getData, putData } from "../../../../../../backend/api";
import { Disabled } from "../styles";
import { Schedule, SchedulesBox } from "../../schedules/styles";
const ShowAttendance = ({ updateData, openIndex, attendance, closeModal, header, setLoaderBox, setMessage }) => {
  const { t } = useTranslation();
  const [shift, setShift] = useState({ nightSupplement: attendance.nightSupplement, sundaySurcharge: attendance.sundaySurcharge, holidaySurcharge: attendance.holidaySurcharge, overTime: attendance.overTime, pause: attendance.pause, firstIn: attendance.firstIn, workedHours: attendance.workedHours, workingHours: attendance.workingHours, lastOut: attendance.lastOut, _id: attendance.attendanceTracking, break: attendance.break ?? 0, approval: attendance.String ?? "Pending" });
  const [errroInput] = useState({ firstIn: "", lastOut: "", attendanceTracking: "", break: "", approval: "Pending" });
  const [enableEdit] = useState(attendance?.data?.length ? (attendance?.data.some((item) => item.status === "In") ? false : true) : false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [notes, setNotes] = useState(null);
  const [formInput] = useState([
    {
      type: "datetime",
      placeholder: "shiftStart",
      name: "firstIn",
      validation: "",
      default: "",
      label: "shiftStart",
      required: true,
      half: true,
      customClass: "nowrap",
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "shiftEnd",
      name: "lastOut",
      validation: "",
      default: "",
      half: true,
      customClass: "nowrap",
      label: "shiftEnd",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "break",
      name: "break",
      validation: "",
      default: "0",
      label: "break",
      required: true,
      minimum: 0,
      maximum: 60,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "approval",
      name: "approval",
      validation: "",
      default: "",
      label: "approval",
      required: true,
      apiType: "JSON",
      selectApi: [
        { id: "Pending", value: t("Pending") },
        { id: "Confirmed", value: t("Confirmed") },
        { id: "Rejected", value: t("Rejected") },
      ],
      view: true,
      add: true,
      update: true,
    },
  ]);
  // const navigate = useNavigate();
  const [updateId] = useState(attendance.attendanceTracking);
  const [updateValues, setUpdateValues] = useState({ firstIn: attendance.firstIn, lastOut: attendance.lastOut, attendanceTracking: attendance.attendanceTracking, break: attendance.break, approval: attendance.approval ?? "Pending" });
  // const [udpateView, setUpdateView] = useState(() => {});

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await getData({}, `attendance/day-notes/${attendance.attendanceTracking}`);
      // ...
      setNotes(response.data.data);
    }
    fetchData();
  }, [attendance.attendanceTracking]);

  const onChange = (sl,event, id, type) => {
    if (enableEdit) {
      // Get the current value based on the input field id
      const field = formInput[id];
      let value = "";

      // Create a copy of updateValues with the new value
      const updatedValues = {
        ...updateValues,
      };

      // Handle different input types
      if (type === "select") {
        value = event.id;
      } else if (type === "text" || type === "number" || type === "password" || type === "color") {
        value = event.target.value;
      } else if (type === "datetime" || type === "time") {
        if (event) {
          value = event.toISOString();
        } else {
          value = "";
        }
      }

      // Update the field in the updatedValues object
      updatedValues[field.name] = value;

      // Perform validation for number inputs
      let error = false;
      formInput.forEach((item) => {
        const itemValue = updatedValues[item.name];
        if (itemValue.toString().length === 0) {
          error = true;
        }
        // Check if it's a number input and validate
        if (item.type === "number") {
          if (isNaN(itemValue)) {
            // Set flag to true if it's not a valid number
            error = true;
          }
        }
      });

      // Update state with the new values and disable or enable submit button
      setUpdateValues(updatedValues);
      setSubmitDisabled(error); // Set to true if any number input is invalid, false otherwiseupdateHandler(updateValues);
    } else {
      setMessage({ type: 1, content: t("cannotEdit"), proceed: "Okay" });
    }
  };

  const submitChange = (event) => {
    if (enableEdit) {
      updateHandler(updateValues);
    } else {
      setMessage({ type: 1, content: t("cannotEdit"), proceed: "Okay" });
    }
  };
  const updateHandler = async (data) => {
    setLoaderBox(true);

    await putData(data, `attendance/shift/${updateId}`)
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: 1, content: `The shift updated successfully!`, proceed: "Okay" });
          setShift(response.data.item);
          updateData(openIndex, response.data.item);
          // setIsEditing(false);
          setSubmitDisabled(true);
        } else if (response.status === 404) {
          setMessage({ type: 1, content: "User not found!", proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: "Something went wrong!", proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };
  return (
    <Overlay>
      <Page className="normal sub-page timesheet">
        <Header>{header}</Header>
        <Table style={{ marginTop: "0px", marginBottom: "20px" }}>
          <thead>
            <Tr>
              <Th>{t("title")}</Th>
              <Th>{t("value")}</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>{t("shift")}</Td>
              <Td>{timeFormat(attendance.shiftStart) + ` - ` + timeFormat(attendance.shiftEnd)} </Td>
            </Tr>
            <Tr>
              <Td>{t("startandend")}</Td>
              <Td className="nowrap">
                {updateValues && <FormInput onChange={onChange} {...formInput[0]} error={errroInput[formInput[0].name]} id={0} value={updateValues[formInput[0].name]}></FormInput>}
                {updateValues && <FormInput onChange={onChange} {...formInput[1]} error={errroInput[formInput[1].name]} id={1} value={updateValues[formInput[1].name]}></FormInput>}
              </Td>
            </Tr>
            <Tr>
              <Td>{t("worked")}</Td>
              <Td>{`${convertMinutesToHHMM(shift.workedHours)}`}</Td>
            </Tr>
            {/* <Tr>
              <Td>{t("pause")}</Td>

              <Td>{convertMinutesToHHMM(shift.pause)}</Td>
            </Tr> */}
            <Tr>
              <Td>{t("entries")}</Td>
              <Td>
                <SchedulesBox className="wrap">
                  {attendance.data.map((item) => {
                    const matchingNotes = notes?.filter((noteItem) => {
                      return noteItem.attendanceTrackingEntry === item._id;
                    });
                    const note = matchingNotes?.map((noteItem) => noteItem.note).join(", ") || "";

                    return (
                      <Schedule color={attendance.shiftTemplate.color ?? "black"} className="justifyCenter" key={`work-log-${item._id}`}>
                        <span>{convertMinutesToHHMM(item.totalWorkingTime)}</span>
                        <span>{item.status === "In" ? `${dateTimeFormat(item.startTime)} - (${t("ongoing")})` : `${timeFormat(item.startTime)} - ${timeFormat(item.endTime)}`}</span>
                        <span>{notes ? note : ""}</span>
                      </Schedule>
                    );
                  })}
                </SchedulesBox>
              </Td>
            </Tr>
            <Tr>
              <Td>{t("pause")}</Td>
              <Td>{updateValues ? <FormInput onChange={onChange} {...formInput[2]} error={errroInput[formInput[2].name]} id={2} value={updateValues[formInput[2].name]}></FormInput> : ""}</Td>
            </Tr>

            <Tr>
              <Td>{t("status")}</Td>
              <Td>{updateValues ? <FormInput onChange={onChange} {...formInput[3]} error={errroInput[formInput[3].name]} id={3} value={updateValues[formInput[3].name]}></FormInput> : ""}</Td>
            </Tr>
          </tbody>
        </Table>
        <Table style={{ marginTop: "0px", marginBottom: "20px" }}>
          <thead style={{ marginTop: "10px" }}>
            <Tr>
              <Th>
                {t("overTime")}
                {attendance.overTimeStatus ? "" : <Disabled> ({t("disabled")})</Disabled>}
              </Th>
              <Th>
                {t("nightSupplement")}
                {attendance.nightSupplementStatus ? "" : <Disabled> ({t("disabled")})</Disabled>}
              </Th>
              <Th>
                {t("sundaySurcharge")}
                {attendance.sundaySurchargeStatus ? "" : <Disabled> ({t("disabled")})</Disabled>}
              </Th>
              <Th>
                {t("sundaySurcharge")}
                {attendance.sundaySurchargeStatus ? "" : <Disabled> ({t("disabled")})</Disabled>}
              </Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>{convertMinutesToHHMM(shift.overTime)}</Td>
              <Td>{convertMinutesToHHMM(shift.nightSupplement)}</Td>
              <Td>{convertMinutesToHHMM(shift.sundaySurcharge)}</Td>
              <Td>{convertMinutesToHHMM(shift.holidaySurcharge)}</Td>
            </Tr>
          </tbody>
        </Table>

        <Footer>
          <FormInput type="close" value={"Cancel"} onChange={closeModal} />
          <FormInput disabled={submitDisabled} type="submit" name="submit" value={t("submit")} onChange={submitChange} />
        </Footer>
      </Page>
    </Overlay>
  );
};
export default ShowAttendance;
