import styled, { keyframes } from "styled-components";
export const Form = styled.div`
  border: 1px solid rgb(224, 224, 227);
  padding: 1em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  &.sub {
    border: 0;
    padding-top: 0;
    padding: 0;
  }
  &.double {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    border: 0;
    &.double {
      display: grid;
      grid-template-columns: auto;
    }
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const Overlay = styled.div`
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  overflow: auto;
  z-index: 1001;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    top: 0;
    bottom: 0;
    border-top: 1px solid rgb(224, 224, 227);
  }
`;
export const Page = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  background-color: white;
  border-radius: 10px;
  width: 30%;
  min-width: 250px;
  max-width: 100%;
  height: auto;
  animation: ${fadeIn} 1s ease-in-out;
  animation-duration: 0.2s;
  margin: auto;
  padding: 1em;
  margin-top: 50px;
  z-index: 1;
  &.medium {
    width: 70%;
    min-width: 250px;
    max-width: 100%;
  }
  &.large {
    width: 90%;
    min-width: 250px;
    max-width: 100%;
  }
  padding: 1em;
  &.normal {
    width: auto;
    min-width: 250px;
    max-width: 100%;
  }
  &.fullpage,
  &.tabs {
    width: 90%;
    height: 90vh;
    position: fixed;
    margin: 0;
  }
  &.double {
    width: 60%;
    height: auto;
    position: fixed;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    top: auto;
    padding: 10px;
    margin-top: 0px;
    bottom: 0;
    &.medium,
    &.normal,
    &.large {
      width: 100%;
      min-width: 250px;
      max-width: 100%;
      padding: 10px;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 1.3em;
  /* border-bottom: 1px solid #dfdfdf; */
  margin-bottom: 5px;
  > span {
    align-items: center;
    display: flex;
  }
  &.form {
    padding: 0.5em 0.5em;
  }
  &.small {
    padding: 5px;
    padding: 5px;
    border-bottom: 1px solid rgb(243, 243, 243);
    margin-bottom: 10px;
  }
  &.small button {
    background-color: rgb(243, 243, 243);
    height: 30px;
    padding: 5px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.small > span {
    font-size: 12px;
  }
  svg {
    margin-right: 10px;
  }
  span svg:last-child {
    margin-right: 10px;
    margin-left:10px;
    font-size: 14px;
  }
  /* border-bottom: 1px solid rgb(204, 204, 204); */
  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    border-bottom: 1px solid rgb(224, 224, 227);
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: center;
  @media (max-width: 768px) {
    position: sticky;
    bottom: 0;
    background: white;
    padding-bottom: 10px;
    border-top: 1px solid rgb(224, 224, 227);
  }
`;
export const ErrorMessage = styled.div`
  border-left: 1px solid lightgrey;
  padding-left: 10px;
  margin: 0 0;
  color: #fe7b7b;
  font-size: 12px;
  width: 100%;
  text-align: left;
  float: left;
`;
export const TabContainer = styled.div`
  border-radius: 12px;
  padding: 0px 10px;
  margin: 0;
  flex-direction: row;
  display: flex;
  column-gap: 10px;
  &.column{
    flex-direction: column;
  }
  @media (max-width: 768px) {
    margin: 0;
    &.vertical {
      flex-direction: column;
    }
  }
`;

export const TabHeader = styled.div`
  display: flex;
  margin-bottom: 0;
  align-items: flex-end;
  height: 60px;
  position: sticky;
  min-width: 150px;

  &.vertical {
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    margin-bottom: auto;
    height: auto;
    margin-right: 0px;
  }
  @media (max-width: 768px) {
    min-width: inherit;
    position: inherit;
    &.vertical {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: auto;
      height: auto;
      margin-right: 0px;
      overflow: auto;
      width: 100%;
    }
    &.form {
      margin: 10px 0px 0px;
      overflow: auto;
    }
    &.mh {
      overflow: hidden;
    }
  }
`;

export const Tab = styled.div`
  padding: 0px;
  background-color: white;
  display: none;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border-bottom-right-radius: 10px;
  border-radius: 10px;
  box-shadow: none;
  flex: 1;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  &.sub {
    flex-direction: column;
  }
  ${(props) =>
    props.active &&
    `
    display: flex;
  `}
`;

export const TabLink = styled.div`
  cursor: pointer;
  flex: 1 1 50%;
  background-color: #eef4fe;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 35px;
  padding: 5px;
  width: 100%;
  font-size: 14px;
  && {
    .vertical & {
      height: auto;
      justify-content: left;
      text-align: left;
      border-radius: 0;
      padding: 0px;
    }
  }

  &:first-child {
    border-top-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
  }
  &.active {
    background-color: #ffffff;
    color: black;
    height: 40px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    padding: 5px;
    @media (max-width: 768px) {
      font-size: 14px;
      && {
        .vertical & {
          height: auto;
          justify-content: left;
          text-align: left;
          padding: 0px;
          text-wrap: nowrap;
        }
      }
    }
  }
  @media (max-width: 768px) {
    text-wrap: nowrap;
  }
`;

export const Section = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  flex: 1 1 100%;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  padding: 0;
  margin-bottom: 1em;
  margin: 1em;
  border-radius: 10px;
  background: #f3f8fb;
  padding-bottom: 10px;
`;
export const PlainSection = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 1em;
  margin-bottom: 1em;
`;
