import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const Location = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  useEffect(() => {
    document.title = `${t("location")} - VSH Security`;
  }, [t]);
  return (
    <Container className="noshadow">
      <ListTable formMode="double" {...props} api={`location`} itemTitle={`title`} shortName={`location`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Location);
