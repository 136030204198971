import React from "react";
import styled from "styled-components";

const ProgressPain = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  width: -webkit-fill-available;
  position: relative;

  article {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    flex-direction: column;
  }
  article span:first-child {
    font-size: 15px;
    font-weight: bold;
  }
`;

const Bar = styled.div`
  height: 40px;
  width: ${({ percentage }) => (percentage ? `${percentage}%` : "0%")};
  background-color: #1b9dd9;
  border-radius: 5px;
`;

const Progress = ({ total, achieved, totalDisplay, achievedDisplay }) => {
  const percentage = ((achieved > total ? total : achieved) / total) * 100;

  return (
    <ProgressPain>
      <article>
        <span>{achievedDisplay}</span>
        <span>{totalDisplay}</span>
      </article>
      <Bar percentage={percentage}></Bar>
    </ProgressPain>
  );
};

export default Progress;
