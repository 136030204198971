import { Form, Header, Overlay, Page, Tab, TabHeader, TabLink } from "../manage/styles";
// import FormInput from "../../input";
import { GetValue } from "../functions";
import { GetIcon } from "../../../../icons";
import { CloseButton, DataHead, DataItem, Head, Profile, TabContainer, TabHead, TabItems, TabList, TabText, Td, Title, TrBody } from "./styles";
import Tabs from "../../tab";
import React, { useCallback, useEffect, useState } from "react";
import { ColumnContainer, RowContainer } from "../../../styles/containers/styles";
import ListTable from "../list";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormInput from "../../input";
import { postData } from "../../../../backend/api";
export const DisplayInformations = ({ udpateView, isEditingHandler, customClass, profileImage, attributes, data, formMode, formView, setMessage, setLoaderBox, actions, refresh = () => {} }) => {
  const [currentData, setCurrentData] = useState(data);
  const sendInvitation = (user) => {
    setLoaderBox(true);
    postData({ user }, "user/send-invitation")
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          setCurrentData((prevCurrentData) => ({
            ...prevCurrentData,
            invitationSent: true,
          }));
          refresh();
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        setLoaderBox(false);
      });
  };
  const [t] = useTranslation();
  const { i18n } = useTranslation();
  const [selectedLanguage] = useState(i18n.language || "de");
  const [groupedTabs] = useState(() => {
    const groupedTabsTemp =
      formView === "tabs"
        ? attributes?.length > 0 &&
          attributes.reduce((result, attribute, index) => {
            const tabName = attribute.tab;
            const existingTab = result.find((tab) => tab.tabName === tabName);
            const attributeTemp = { ...attribute, index };
            if (attribute.view || (attribute.tag ?? false)) {
              if (existingTab) {
                existingTab.tabItems.push(attributeTemp);
              } else {
                result.push({ tabName, tabItems: [attributeTemp] });
              }
            }
            return result;
          }, [])
        : [];

    // Rearrange tabItems within each tab group
    groupedTabsTemp.forEach((tabGroup) => {
      const data1 = [];

      let i = 0;
      let count = Math.ceil(tabGroup.tabItems.length / 2); // Corrected to use Math.ceil
      while (count > i) {
        data1.push(tabGroup.tabItems[i]);
        if (i + count < tabGroup.tabItems.length) {
          data1.push(tabGroup.tabItems[i + count]);
        }
        i++;
      }
      tabGroup.tabItems = data1;
    });

    return groupedTabsTemp;
  });
  const [noGroups] = useState(() => {
    let tabItems = formView !== "tabs" ? attributes?.length > 0 && attributes.filter((attribute) => attribute.view || (attribute.tag ?? false)) : [];

    // Rearrange tabItems within each tab group

    const data1 = [];

    let i = 0;
    let count = Math.ceil(tabItems.length / 2);
    while (count > i) {
      data1.push(tabItems[i]);
      if (i + count < tabItems.length) {
        data1.push(tabItems[i + count]);
      }
      i++;
    }
    tabItems = data1;

    return tabItems; // Return the modified tabItems as the initial state
  });

  console.log("noGroups", noGroups);
  const hasTabs = actions?.some((item) => item.type === "subList" && item.element === "button");
  //theme colors
  const themeColors = useSelector((state) => state.themeColors);
  const [activeTab, setActiveTab] = useState(groupedTabs[0]?.tabName ?? "generalInfo");
  const profileImageLink = profileImage?.length > 2 && currentData["photo"] ? process.env.REACT_APP_CDN.toString() + currentData["photo"]?.toString() : `${process.env.REACT_APP_CDN.toString()}uploads/profile/profile.jpg`;
  console.log("actions  ", actions);
  return (
    <TrBody className={`${formView === "tabs" ? "" : formMode} ${customClass ?? ""}`}>
      {formView === "tabs" ? (
        <TabContainer className="vertical">
          <TabHeader className="vertical mh">
            <Profile>
              <img src={profileImageLink} alt="profile" />
              <h6>{`${currentData["firstName"]} ${currentData["lastName"]}`}</h6>
              <ColumnContainer className="buttons">
                {isEditingHandler && (
                  <button onClick={() => isEditingHandler(currentData, udpateView)}>
                    <GetIcon icon={"edit"}></GetIcon>
                  </button>
                )}
                {isEditingHandler && currentData.mobileNumber && (
                  <button onClick={() => (window.location.href = `tel:${currentData.mobileNumber}`)}>
                    <GetIcon icon={"call"}></GetIcon>
                  </button>
                )}
              </ColumnContainer>
            </Profile>
            <TabList>
              {groupedTabs.map((group) => (
                <React.Fragment key={group.tabName}>
                  {group.tabItems?.length > 0 && (
                    <TabLink
                      key={`main-` + group.tabName}
                      theme={themeColors}
                      className={activeTab === group.tabName && "active"}
                      onClick={() => {
                        setActiveTab(group.tabName);
                      }}
                    >
                      <TabText> {t(group.tabName)}</TabText>
                    </TabLink>
                  )}
                </React.Fragment>
              ))}
              {actions?.map((item) => {
                return (
                  item.type === "subList" &&
                  item.element === "button" && (
                    <TabLink
                      key={`main-` + item.title}
                      theme={themeColors}
                      className={activeTab === item.title && "active"}
                      onClick={() => {
                        setActiveTab(item.title);
                      }}
                    >
                      <TabText> {t(item.title)}</TabText>
                    </TabLink>
                  )
                );
              })}
            </TabList>
          </TabHeader>
          <TabItems>
            {groupedTabs.map((group) => (
              <Tab className="sub" key={`sub-${group.tabName}`} active={activeTab === group.tabName}>
                <TabHead>{t(group.tabName)}</TabHead>
                <Form className={`vertical sub ${formMode}`}>
                  {group.tabItems?.length > 0 &&
                    group.tabItems.map((attribute, index) => {
                      if (attribute.view || (attribute.tag ?? false)) {
                        try {
                          let itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? currentData[attribute.collection][attribute.showItem] : currentData[attribute.name];
                          if (attribute.type === "select" && attribute?.showItem === "locale") {
                            itemValue = attribute.showItem?.length > 0 ? currentData[attribute.name][selectedLanguage] : currentData[attribute.name];
                          }
                          if (attribute.type === "invitation") {
                            return (
                              <Td key={index}>
                                <Title>{t(attribute.label)}</Title>
                                <DataItem>
                                  {currentData["invitationSent"] ?? false ? (
                                    <>
                                      <FormInput
                                        onChange={() => {
                                          sendInvitation(currentData["_id"]);
                                        }}
                                        type="submit"
                                        value={t("resendInvitation")}
                                      ></FormInput>
                                    </>
                                  ) : (
                                    <FormInput
                                      onChange={() => {
                                        sendInvitation(currentData["_id"]);
                                      }}
                                      type="submit"
                                      value={t("sendInvitation")}
                                    ></FormInput>
                                  )}
                                </DataItem>
                              </Td>
                            );
                          }
                          if (attribute.type === "title") {
                            return (
                              <Td className="bold" key={index}>
                                <Title className="bold">{t(attribute.title)}</Title>
                              </Td>
                            );
                          }
                          return (
                            <Td key={index}>
                              <Title>{t(attribute.label)}</Title>
                              <DataItem>{GetValue(attribute, itemValue, true)}</DataItem>
                            </Td>
                          );
                        } catch (error) {
                          return (
                            <Td key={index}>
                              <Title>{t(attribute.label)}</Title>
                              <DataItem>--</DataItem>
                            </Td>
                          );
                        }
                      }

                      return null; // Return null if the condition is not met, to avoid rendering unwanted elements
                    })}
                </Form>
              </Tab>
            ))}
            {actions?.map((item) => {
              return (
                item.type === "subList" &&
                item.element === "button" && (
                  <Tab className="sub" key={`sub-${item.title}`} active={activeTab === item.title}>
                    <ListTable setMessage={setMessage} setLoaderBox={setLoaderBox} referenceId={currentData?._id} attributes={item.attributes} {...item.params}></ListTable>
                  </Tab>
                )
              );
            })}
          </TabItems>
        </TabContainer>
      ) : (
        <TabContainer className="vertical">
          {hasTabs && (
            <TabHeader className="vertical mh">
              <TabList>
                <TabLink
                  key={`main-generalInfo`}
                  theme={themeColors}
                  className={activeTab === "generalInfo" && "active"}
                  onClick={() => {
                    setActiveTab("generalInfo");
                  }}
                >
                  <TabText> {t("generalInfo")}</TabText>
                </TabLink>
                {actions?.map((item) => {
                  return (
                    item.type === "subList" &&
                    item.element === "button" && (
                      <TabLink
                        key={`main-` + item.title}
                        theme={themeColors}
                        className={activeTab === item.title && "active"}
                        onClick={() => {
                          setActiveTab(item.title);
                        }}
                      >
                        <TabText> {t(item.title)}</TabText>
                      </TabLink>
                    )
                  );
                })}
              </TabList>
            </TabHeader>
          )}
          <TabItems className={`${hasTabs}`}>
            <Tab className={`sub ${hasTabs}`} key={`sub-generalInfo`} active={activeTab === "generalInfo"}>
              <TabHead>{t("generalInfo")}</TabHead>
              <Form className={`vertical sub ${formMode}`}>
                {noGroups.map((attribute, index) => {
                  try {
                    const itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? currentData[attribute.collection][attribute.showItem] : currentData[attribute.name];
                    if (attribute.type === "invitation") {
                      return (
                        <Td key={index}>
                          <Title>{t(attribute.label)}</Title>
                          <DataItem>
                            {currentData["invitationSent"] ?? false ? (
                              <>
                                <span>
                                  {t("invitationSent")} <GetIcon icon="approved" />
                                </span>
                              </>
                            ) : (
                              <FormInput
                                onChange={() => {
                                  sendInvitation(currentData["_id"]);
                                }}
                                type="submit"
                                value={t("sendInvitation")}
                              ></FormInput>
                            )}
                          </DataItem>
                        </Td>
                      );
                    }

                    return (
                      <Td key={index}>
                        <Title>{t(attribute.label)}</Title>
                        <DataItem>{GetValue(attribute, itemValue, true)} </DataItem>
                      </Td>
                    );
                  } catch (error) {
                    return (
                      <Td key={index}>
                        <Title>{attribute.label}</Title>
                        <DataItem>{`--`} </DataItem>
                      </Td>
                    );
                  }
                })}
              </Form>
            </Tab>
            {actions?.map((item) => {
              return (
                item.type === "subList" &&
                item.element === "button" && (
                  <Tab className="sub" key={`sub-new-${item.title}`} active={activeTab === item.title}>
                    <ListTable setMessage={setMessage} setLoaderBox={setLoaderBox} referenceId={currentData?._id} attributes={item.attributes} {...item.params}></ListTable>
                  </Tab>
                )
              );
            })}
          </TabItems>
        </TabContainer>
      )}
    </TrBody>
  );
};
const Popup = ({ formMode, viewMode, themeColors, openData, setLoaderBox, setMessage, closeModal, itemTitle }) => {
  const titleValue = (itemTitle.collection?.length > 0 ? openData?.data?.[itemTitle.collection]?.[itemTitle.name] ?? "" : openData?.data?.[itemTitle.name]) || "Please update the itemTitle.";
  console.log("Mesage1", setMessage);
  const tabHandler = useCallback(() => {
    const tempTab = openData.actions
      .filter((item) => item.type === "subList" || item.type === "subItem")
      .map((item, index) => ({
        name: `${item.id}-${index}`,
        title: item.title,
        element: <ListTable viewMode={item.type ?? "subList"} setMessage={setMessage} setLoaderBox={setLoaderBox} parentReference={item?.params?.parentReference} referenceId={openData?.data?._id} attributes={item.attributes} {...item.params}></ListTable>,
      }));
    tempTab.unshift({
      name: `information-${titleValue}`,
      title: "Informations",
      element: (
        <TabContainer>
          <Head>
            <DataHead>
              <GetIcon icon={""}></GetIcon>
              <span>Basic Details</span>
            </DataHead>
          </Head>
          <DisplayInformations setMessage={setMessage} setLoaderBox={setLoaderBox} formMode={formMode} attributes={openData.attributes} data={openData.data} />
        </TabContainer>
      ),
    });
    setTabs(tempTab);
  }, [setMessage, setLoaderBox, openData, formMode, titleValue]);

  const [tabs, setTabs] = useState([]);
  console.log("Tabs", tabs);
  useEffect(() => {
    tabHandler();
  }, [tabHandler]);

  return (
    <Overlay>
      <Page className={`${openData?.item?.params?.customClass ?? "medium"} popup-child sub-page`}>
        <Header>
          <span>{`${GetValue(itemTitle, titleValue)}`}</span>
          <CloseButton theme={themeColors} onClick={closeModal}>
            <GetIcon icon={"Close"} />
          </CloseButton>
        </Header>
        <RowContainer theme={themeColors} className="popup-data">
          {tabs.length > 0 && <Tabs tabs={tabs}></Tabs>}
        </RowContainer>
      </Page>
    </Overlay>
  );
};
export default Popup;
