import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 0px);
flex-direction:column;
  &.center {
    justify-content: center;
    align-items: center;
    flex: 1 1 100%;
  }
`;

export const SideBar = styled.div`
  display: flex;
  flex: 1 1 15em;
  flex-direction: column;
  background-color: #ffffff;
  color: #2a312d;
  overflow: auto;
  && {
    .small & {
      flex: 1 1 5em;
      min-width: 5em;
    }
  }
  @media screen and (max-width: 768px) {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    color: black;
    display: -ms-flexbox;
    display: flex;
    visibility: collapse;
    max-height: 100%;
    padding-bottom: 0;
    z-index: 2000;
    &.active {
      visibility: visible;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1 1 calc(100% - 15em);
  padding: 0em;
  overflow: auto;
  user-select: none;
  box-shadow: inset rgb(0 0 0 / 6%) 0px 0px 8px 7px;
  &.down {
    background: #f8f7fa;
    border-top-left-radius: 29px;
  }
  &.noshadow {
    box-shadow: none;
    width: calc(100% - 50px);
  }
  &.column {
    flex-direction: column;
    padding: 20px;
    width: calc(100% - 70px);
    overflow: auto;
  }

  && {
    .popup-data & {
      padding: 10px;
      width: calc(100% - 20px);
      border-radius: 10px;
      background: #f8f7fa;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 10px;
    padding-bottom: 0px;
    display: block;
    overflow: auto;
    &.noshadow {
      width: calc(100% - 20px);
      height: calc(100vh - 87px);
    }
    &.fixed {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      top: 50px;
      z-index: 1003;
    }
    &.down {
      z-index: 1000;
    }
    &.column {
      width: 100%;
      padding: 0;
    }
    && {
      .tab-container & {
        padding: 0;
      }
    }
  }
`;

export const ProfileIcon = styled.div`
  position: relative;
  color: rgb(255, 255, 255);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
