import React, { useEffect, useRef, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import "./custom-styles.css";
import { DateBox, DateRange, Filter } from "./styles";
import { de } from "date-fns/locale";
import { GetIcon } from "../../../icons";
function DateRangeSelector(props) {
  const [currentLanguage] = useState(
    // If a language is stored in local storage, use it, otherwise use "de"
    localStorage.getItem("_lang") !== null ? localStorage.getItem("_lang") : "de"
  );
  const locale = {
    ...de,
    options: {
      ...de.options,
    },
  };
  const staticRanges = [
    // Add more custom static ranges as needed
  ];
  const setDates = (ranges) => {
    setDateRange(ranges);
    const dates = ranges[0];
    if (dates?.startDate && dates?.endDate) {
      console.log(dates);
      props.onChange(dates);
      setDateRangeStatus(false);
    }
  };
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const formatStartDate = dateRange[0]?.startDate ? moment(dateRange[0].startDate).format("D MMM") : "From";
  const formatEndDate = dateRange[0]?.endDate ? moment(dateRange[0].endDate).format("D MMM") : "To";

  const [dateRangeStatus, setDateRangeStatus] = useState(false);
  const showPricker = (value) => {
    setDateRangeStatus(value);
  };

  const selectRef = useRef(null);

  useEffect(() => {
    function handleClick(event) {
      if (!selectRef.current.contains(event.target)) {
        setDateRangeStatus(false);
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  useEffect(() => {
    // Calculate the start and end of the current month
    if (props?.default === "month") {
      const startOfMonth = moment().startOf("month").toDate();
      const endOfMonth = moment().endOf("month").toDate();
      setDateRange([{ startDate: startOfMonth, endDate: endOfMonth, key: "selection" }]);
    }
  }, [props.default]);
  const ArrowAction = (status) => {
    console.log(status,dateRange)
    if (dateRange?.[0]?.endDate) {
      const startOfMonth = moment(dateRange[0].startDate).startOf("month").toDate();
      const endOfMonth = moment(dateRange[0].endDate).endOf("month").toDate();
      let dates = {};
      if (status === 1) {
        // Increment by 1 month
        let newStartOfMonth = moment(startOfMonth).add(1, "month").toDate();
        let newEndOfMonth = moment(endOfMonth).add(1, "month").endOf("month").toDate();

        // Check if the day component is greater than the number of days in the new month
        if (moment(newStartOfMonth).date() > moment(newEndOfMonth).date()) {
          // Adjust the day component to the last day of the new month
          newStartOfMonth = moment(newStartOfMonth).endOf("month").toDate();
          newEndOfMonth = moment(newEndOfMonth).endOf("month").toDate();
        }

        dates = [{ startDate: newStartOfMonth, endDate: newEndOfMonth, key: "selection" }];
      } else {
        // Decrement by 1 month
        const newStartOfMonth = moment(startOfMonth).subtract(1, "month").toDate();
        const newEndOfMonth = moment(endOfMonth).subtract(1, "month").endOf("month").toDate();
        dates = [{ startDate: newStartOfMonth, endDate: newEndOfMonth, key: "selection" }];
      }

      setDateRange(dates);
      props.onChange(dates[0]);
      setDateRangeStatus(false);
    }
  };

  return (
    <DateBox ref={selectRef}>
      <Filter theme={props.themeColors}>
        <div onClick={() => ArrowAction(0)}>
          <GetIcon icon={"previous"}></GetIcon>
        </div>
        <div
          onClick={() => {
            showPricker(!dateRangeStatus);
          }}
        >
          {formatStartDate} - {formatEndDate}
        </div>
        <div onClick={() => ArrowAction(1)}>
          <GetIcon icon={"next"}></GetIcon>
        </div>
      </Filter>
      {currentLanguage === "de" ? (
        <DateRange
          locale={locale}
          className={dateRangeStatus}
          ranges={dateRange}
          staticRanges={staticRanges}
          showSelectionPreview={false} // Hide the selection preview
          showMonthAndYearPickers={false} // Hide the month and year pickers
          showDateDisplay={false} // Hide the date display in the top bar
          months={2}
          onChange={(ranges) => {
            setDates([ranges.selection]);
          }}
        />
      ) : (
        <DateRange
          className={dateRangeStatus}
          ranges={dateRange}
          staticRanges={staticRanges}
          showSelectionPreview={false} // Hide the selection preview
          showMonthAndYearPickers={false} // Hide the month and year pickers
          showDateDisplay={false} // Hide the date display in the top bar
          months={2}
          onChange={(ranges) => {
            setDates([ranges.selection]);
          }}
        />
      )}
    </DateBox>
  );
}
export default DateRangeSelector;
