import styled from "styled-components";
export const Header = styled.div`
  display: flex;
  padding: 10px 20px;
  &.hd {
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 768px) {
    &.hm {
      display: none;
    }
  }
`;
export const Nav = styled.nav`
  padding-top: 0em;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.foreground};
  overflow-y: auto;
  padding-bottom: 20px;
  margin: 10px 0px 10px 10px;

  a.main,
  .open {
    text-decoration: none;
    color: ${(props) => props.theme.foreground};
    margin-left: 0.5em;
    padding-left: 0.5em;
    margin-right: 1em;
    height: 45px;
    margin-bottom: 2px;
    margin-top: 2px;
    display: flex;
    justify-content: left;
    align-items: center;
    transition: all 0.1s;
    border-radius: 12px;
  }
  @media (max-width: 768px) {
    a.main,
    .open {
      color: black;
    }
  }
  && {
    .down {
      a.main span,
      .open span {
        padding-right: 1em;
      }
      a.main,
      .open {
        margin-left: 0em;
      }
    }
  }

  a.open {
    cursor: unset;
  }
  a.main.active,
  a.main:hover {
    /* background: linear-gradient(102deg, rgb(2, 0, 36) 0%, rgb(232 232 232) 0%, rgb(255, 255, 255) 83%);
    color: black;
    box-shadow: rgba(0, 0, 0, 0.16) -1px 0px 4px; */
    background: #b8d2fa;
  }
  a.main.active:first-child,
  a.main:hover:first-child {
    border-radius: 12px;
  }
  a.main.active:last-child,
  a.main:hover:last-child {
    border-radius: 12px;
  }
  a.main.active::after {
    /* content: ""; */
    width: 6px;
    color: white;
    height: 100%;
    background: rgb(82 103 96);
    margin-left: auto;
  }
  a.main svg,
  .open svg {
    margin-right: 10px;
    width: 30px;
    max-width: 20px;
  }
  && {
    .small & {
      gap:5px;
      a {
        line-break: anywhere;
        flex-direction: column;
        padding: 5px 2px;
        margin: 0;
        font-size: 10px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-align: center;
        border-radius: 0;
        margin-right: 5px;
        svg {
          margin: auto;
          font-size: 14px;
        }
      }
      a.main.active:first-child,
      a.main:hover:first-child {
        border-radius: 6px;
      }
      a.main.active:last-child,
      a.main:hover:last-child {
        border-radius: 6px;
      }
    }
  }
`;
export const SubMenu = styled.nav`
  margin-left: 1.5em;
  margin-right: 1em;
  padding-left: 0.5em;
  border-left: 1px solid lightgrey;
  && {
    .small & {
      line-break: anywhere;
      margin-left: 0em;
      margin-right: 0.5em;
      padding-left: 0em;
      border: 1px solid lightgrey;
      border-radius: 12px;
      padding: 0px;
      margin: 0;
      margin-right: 0.5em;
      justify-content: center;
      text-align: center;
      border-width: 1px 0px 1px 0px;
      border-radius: 0;
      padding: 5px 0px;
      a {
        margin: auto;
      }
      span {
        padding: 0 !important;
      }
    }
  }
  &.close {
    display: none;
  }
`;
export const MenuIcon = styled.button`
  display: flex;
  background: white;
  border: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0;
`;
export const MenuGroup = styled.div`
  cursor: pointer;
  svg:last-child {
    margin-left: auto;
    transition: 0.2s ease-in-out;
  }
  &.active svg:last-child {
    transform: rotate(180deg);
  }
`;
