export const languageData = {
  german: [
    "den",
    "Web",
    "Email",
    "Telefon",
    "Hausverbot",
    "Die ZOB an der Hackerbrücke GmbH & Co.KG, hat uns als Eigentümer der Liegenschaft Zentraler Omnibusbahnhof (ZOB) München, Hackerbrücke 4/6 und Arnulfstraße 17/21, 80335 München beauftragt.",
    "Hiermit erteilen wir ein Hausverbot an folgende Person:",
    "Anrede",
    "Name",
    "Vorname",
    "Straße Nr.",
    "Plz Ort",
    "Hausverbot für",
    "Das gesamte Gelände des Zentraler Omnibusbahnhof (ZOB) München, Hackerbrücke 4/6 und Arnulfstraße 17/21, 80335 München.",
    "Begründung",
    "Betteln auf dem Gelände des Zentralen Omnibusbahnhof (ZOB) München. Dies ist laut der Hausordnung untersagt",
    "Gültigkeit",
    "Gestützt auf Art. 186 StGB erteilen wir Ihnen hiermit mit sofortiger Wirkung ein Verbot das oben genannte Gelände zu betreten.",
    "Wir weisen ausdrücklich darauf hin, dass eine Wiederhandlung gegen dieses Hausverbot unwiderruflich eine Strafanzeige wegen Hausfriedensbruch gemäß Art. 186 StGB zur Folge hat.",
    "Dieses Hausverbot gilt ausdrücklich bis zum schriftlichen Widerruf und kann nur durch uns in schriftlicher Form widerrufen werden.",
    "Name Mitarbeiters VSH Security e.K.",
    "Unterschrift Mitarbeiter",
    "VSH Security e.K.",
    "Registergericht: Amtsgericht München | Registernummer: HRA 99661 | Geschäftsführer: Sascha Hoffmann Deutschebank München | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Finanzamt Dachau | USt-IdNr: DE 274770876",
  ],
  english: [
    "the",
    "Web",
    "E-mail",
    "Phone",
    "House ban; ban on entering the house",
    "ZOB an der Hackerbrücke GmbH & Co.KG has commissioned us as the owner of the property at the central bus station (ZOB) in Munich, Hackerbrücke 4/6 and Arnulfstrasse 17/21, 80335 Munich.",
    "We hereby issue a house ban to the following person:",
    "Salutation",
    "Surname",
    "First name",
    "Street, No.",
    "ZIP / City",
    "House ban for",
    "The entire area of the central bus station (ZOB) Munich, Hackerbrücke 4/6 and Arnulfstraße 17/21, 80335 Munich.",
    "Reason",
    "Begging on the premises of the central bus station (ZOB) Munich. According to the house rules, this is prohibited",
    "Validity",
    "Based on Art. 186 StGB, we hereby issue you with immediate effect a ban on entering the above-mentioned area.",
    "We expressly point out that violating this house ban will irrevocably result in criminal charges for trespassing in accordance with Article 186 of the Criminal Code.",
    "This house ban applies expressly until it is revoked in writing and can only be revoked by us in writing.",
    "Name of employee VSH Security e.K.",
    "Employee signature",
    "VSH Security e.K.",
    "Registration court: District Court Munich | Register number: HRA 99661 | Managing Director: Sascha Hoffmann Deutschebank Munich | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Tax Office Dachau | VAT ID: DE 274770876",
  ], // Assuming you have your English translations here
  french: [
    "le",
    "La toile",
    "E-mail",
    "Téléphone",
    "Interdiction de domicile ; interdiction d'entrer dans la maison",
    "ZOB an der Hackerbrücke GmbH & Co.KG nous a mandatés en tant que propriétaire de l'immeuble à la gare routière centrale (ZOB) de Munich, Hackerbrücke 4/6 et Arnulfstrasse 17/21, 80335 Munich.",
    "Nous délivrons par la présente une interdiction de séjour à la personne suivante :",
    "Salutation",
    "Nom de famille",
    "Prénom",
    "Rue, non.",
    "Zip Ville",
    "Interdiction de maison pour",
    "Toute la zone de la gare routière centrale (ZOB) de Munich, Hackerbrücke 4/6 et Arnulfstraße 17/21, 80335 Munich.",
    "Raison",
    "Mendier dans les locaux de la gare routière centrale (ZOB) de Munich. Selon le règlement intérieur, cela est interdit",
    "Validité",
    "Sur la base de l'art. 186 StGB, nous vous délivrons avec effet immédiat une interdiction d'entrer dans la zone susmentionnée.",
    "Nous précisons expressément que la violation de cette interdiction de séjour entraînera irrévocablement des poursuites pénales pour intrusion conformément à l'article 186 du code pénal.",
    "Cette interdiction de domicile s'applique expressément jusqu'à ce qu'elle soit révoquée par écrit et ne peut être révoquée que par nous par écrit.",
    "Nom de l'employé VSH Security e.K.",
    "Signature de l'employé",
    "VSH Security e.K.",
    "Tribunal d'enregistrement : Tribunal de district de Munich | Numéro d'enregistrement : HRA 99661 | Directeur général : Sascha Hoffmann Deutschebank Munich | IBAN : DE36700700240778038000 | BIC : DEUTDEDBMUC Office des impôts de Dachau | Numéro de TVA : DE 274770876",
  ],
  italian: [
    "IL",
    "Ragnatela",
    "E-mail",
    "Telefono",
    "Divieto di abitare; divieto di entrare in casa",
    "ZOB an der Hackerbrücke GmbH & Co.KG ci ha commissionato come proprietario dell'immobile presso la stazione centrale degli autobus (ZOB) a Monaco, Hackerbrücke 4/6 e Arnulfstrasse 17/21, 80335 Monaco.",
    "Con la presente emettiamo un divieto di casa alla seguente persona:",
    "Saluto",
    "Cognome",
    "Nome di battesimo",
    "Via numero.",
    "CAP / Città",
    "Divieto di casa per",
    "L'intera area della stazione centrale degli autobus (ZOB) Monaco, Hackerbrücke 4/6 e Arnulfstraße 17/21, 80335 Monaco.",
    "Motivo",
    "Mendicare nei locali della stazione centrale degli autobus (ZOB) di Monaco. Secondo le regole della casa, questo è proibito",
    "Validità",
    "In base all'art. 186 StGB, con la presente Vi diffondiamo con effetto immediato il divieto di ingresso nell'area suddetta.",
    "Precisiamo espressamente che la violazione di questo divieto di abitazione comporterà irrevocabilmente l'imputazione penale per violazione di domicilio ai sensi dell'articolo 186 del codice penale.",
    "Questo divieto di casa si applica espressamente fino a quando non viene revocato per iscritto e può essere revocato solo da noi per iscritto.",
    "Nome del dipendente VSH Security e.K.",
    "Firma dell'impiegato",
    "VSH Security e.K.",
    "Tribunale di registrazione: Tribunale distrettuale di Monaco | Numero di registro: HRA 99661 | Amministratore delegato: Sascha Hoffmann Deutschebank Munich | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Ufficio delle imposte Dachau | Partita IVA: DE 274770876",
  ],
  spanish: [
    "el",
    "Web",
    "Correo electrónico",
    "Teléfono",
    "Prohibición de la casa; prohibición de entrar en la casa",
    "ZOB an der Hackerbrücke GmbH & Co.KG nos ha comisionado como propietario de la propiedad en la estación central de autobuses (ZOB) en Munich, Hackerbrücke 4/6 y Arnulfstrasse 17/21, 80335 Munich",
    "Por la presente emitimos una prohibición de casa a la siguiente persona:",
    "Saludo",
    "Apellido",
    "Nombre de pila",
    "Calle no.",
    "Ciudad postal",
    "Prohibición de la casa para",
    "Toda la zona de la estación central de autobuses (ZOB) de Múnich, Hackerbrücke 4/6 y Arnulfstraße 17/21, 80335 Múnich.",
    "Razón",
    "Mendigar en las instalaciones de la estación central de autobuses (ZOB) de Múnich. De acuerdo con las reglas de la casa, esto está prohibido",
    "Validez",
    "Con base en el Art. 186 StGB, por la presente le emitimos con efecto inmediato una prohibición de ingresar al área mencionada anteriormente",
    "Señalamos expresamente que la violación de esta prohibición de la casa dará lugar irrevocablemente a cargos penales por allanamiento de conformidad con el artículo 186 del Código Penal",
    "Esta prohibición de la casa se aplica expresamente hasta que se revoque por escrito y solo podemos revocarla por escrito",
    "Nombre del empleado VSH Security e.K.",
    "Firma del empleado",
    "VSH Security e.K.",
    "Tribunal de registro: Tribunal de distrito de Múnich | Número de registro: HRA 99661 | Director general: Sascha Hoffmann Deutschebank Múnich | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Oficina fiscal de Dachau | NIF: DE 274770876",
  ],
  albanian: [
    "den",
    "Web",
    "Email",
    "Telefon",
    "Ndalim hyrjeje",
    "ZOB an der Hackerbrücke GmbH & Co.KG, na ka porositur si pronarë të ndërtesës në Stacionin Qendror të Autobusëve (ZOB) në Mynih, Hackerbrücke 4/6 dhe Arnulfstraße 17/21, 80335 Mynih.",
    "Me këtë ne jemi duke dhënë një ndalim hyrjeje për personin e mëposhtëm:",
    "Titulli",
    "Mbiemri",
    "Emri",
    "Rruga Nr.",
    "Kodi Postar, Qyteti",
    "Ndalim hyrjeje për",
    "Gjithë zonën e Stacionit Qendror të Autobusëve (ZOB) në Mynih, Hackerbrücke 4/6 dhe Arnulfstraße 17/21, 80335 Mynih.",
    "Arsyeja",
    "Lypje në territorin e Stacionit Qendror të Autobusëve (ZOB) në Mynih. Kjo është e ndaluar sipas rregullave të shtëpisë",
    "Vlefshmëria",
    "Mbështetur në Art. 186 StGB, me këtë ju ndalojmë me efekt të menjëhershëm të hyni në zonën e përmendur më lart.",
    "Ne theksojmë shprehimisht se një shkelje e këtij ndalimi hyrjeje do të sjellë pashmangimisht një padi penale për thyerje të paqes së shtëpisë sipas Art. 186 StGB.",
    "Ky ndalim hyrjeje është në fuqi deri sa të tërhiqet shkrimisht dhe mund të tërhiqet vetëm nga ne në formë të shkruar.",
    "Emri i punonjësit të VSH Security e.K.",
    "Nënshkrimi i punonjësit",
    "VSH Security e.K.",
    "Gjykata e Regjistrimit: Gjykata e Rrethit të Mynihut | Numri i Regjistrimit: HRA 99661 | Drejtori i Përgjithshëm: Sascha Hoffmann Deutschebank Mynih | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Zyra Tatimore Dachau | Nr. Identifikimi i TVSH-së: DE 274770876",
  ],
  bulgarian: [
    "това",
    "Уеб",
    "Имейл",
    "Телефон",
    "Забрана за влизане",
    "ZOB an der Hackerbrücke GmbH & Co.KG ни е възложила като собственици на имота Централна автогара (ZOB) Мюнхен, Hackerbrücke 4/6 и Arnulfstraße 17/21, 80335 Мюнхен.",
    "По този начин издаваме забрана за влизане за следното лице:",
    "Обръщение",
    "Фамилия",
    "Име",
    "Улица №",
    "Пощенски код, Град",
    "Забрана за влизане за",
    "Целият терен на Централната автогара (ZOB) Мюнхен, Hackerbrücke 4/6 и Arnulfstraße 17/21, 80335 Мюнхен.",
    "Причина",
    "Молене за пари на територията на Централната автогара (ZOB) Мюнхен. Това е забранено съгласно домашния ред",
    "Валидност",
    "Съгласно чл. 186 от Наказателния кодекс, Ви забраняваме с незабавно действие да влизате на горепосочената територия.",
    "Изрично посочваме, че нарушение на тази забрана безвъзвратно ще доведе до наказателно обвинение за нарушение на домашния мир съгласно чл. 186 от НК.",
    "Тази забрана важи изрично до писмено отмяна и може да бъде отменена само от нас в писмена форма.",
    "Име на служител от VSH Security e.K.",
    "Подпис на служителя",
    "VSH Security e.K.",
    "Регистрационен съд: Съдилище в Мюнхен | Регистрационен номер: HRA 99661 | Изпълнителен директор: Sascha Hoffmann Deutschebank München | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Данъчна служба Dachau | ДДС номер: DE 274770876",
  ],
  greek: [
    "το",
    "Ιστός",
    "Email",
    "Τηλέφωνο",
    "Απαγόρευση εισόδου",
    "Η εταιρεία ZOB an der Hackerbrücke GmbH & Co.KG, μας έχει αναθέσει ως ιδιοκτήτες του κτηρίου Κεντρικός Σταθμός Λεωφορείων (ZOB) του Μονάχου, Hackerbrücke 4/6 και Arnulfstraße 17/21, 80335 Μόναχο.",
    "Με το παρόν εκδίδουμε απαγόρευση εισόδου στο πρόσωπο που ακολουθεί:",
    "Τίτλος",
    "Επώνυμο",
    "Όνομα",
    "Οδός, Αρ.",
    "Τ.Κ., Πόλη",
    "Απαγόρευση εισόδου για",
    "Ολόκληρο το χώρο του Κεντρικού Σταθμού Λεωφορείων (ZOB) του Μονάχου, Hackerbrücke 4/6 και Arnulfstraße 17/21, 80335 Μόναχο.",
    "Αιτιολογία",
    "Επαιτεία στο χώρο του Κεντρικού Σταθμού Λεωφορείων (ZOB) του Μονάχου. Αυτό απαγορεύεται σύμφωνα με τον κανονισμό του κτιρίου",
    "Εγκυρότητα",
    "Βασισμένοι στο άρθρο 186 StGB, σας εκδίδουμε με άμεση ισχύ απαγόρευση εισόδου στην παραπάνω περιοχή.",
    "Σας επισημαίνουμε ρητά ότι η παράβαση αυτής της απαγόρευσης θα έχει ως αποτέλεσμα μία ποινική δίωξη για παραβίαση της ειρήνης του κτιρίου σύμφωνα με το άρθρο 186 StGB.",
    "Αυτή η απαγόρευση ισχύει ρητά μέχρι να ανακληθεί γραπτώς και μπορεί να ανακληθεί μόνο από εμάς γραπτά.",
    "Όνομα υπαλλήλου της VSH Security e.K.",
    "Υπογραφή υπαλλήλου",
    "VSH Security e.K.",
    "Δικαστήριο Εγγραφής: Δικαστήριο της περιοχής Μονάχου | Αριθμός Εγγραφής: HRA 99661 | Γενικός Διευθυντής: Sascha Hoffmann Deutschebank Μονάχου | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Φορολογική Υπηρεσία Dachau | ΑΦΜ: DE 274770876",
  ],
  croatian: [
    "to",
    "Web",
    "E-mail",
    "Telefon",
    "Zabrana ulaska",
    "Tvrtka ZOB an der Hackerbrücke GmbH & Co.KG, odredila nas je kao vlasnike zgrade Središnji autobusni kolodvor (ZOB) u Münchenu, Hackerbrücke 4/6 i Arnulfstraße 17/21, 80335 München.",
    "Ovim izdajemo zabranu ulaska sljedećoj osobi:",
    "Titula",
    "Prezime",
    "Ime",
    "Ulica Br.",
    "Poštanski broj, Mjesto",
    "Zabrana ulaska za",
    "Cijelo područje Središnjeg autobusnog kolodvora (ZOB) u Münchenu, Hackerbrücke 4/6 i Arnulfstraße 17/21, 80335 München.",
    "Obrazloženje",
    "Prosjačenje na području Središnjeg autobusnog kolodvora (ZOB) u Münchenu. To je zabranjeno prema kućnom redu",
    "Valjanost",
    "Na temelju članka 186. KZ-a, izdajemo vam s trenutačnim učinkom zabranu ulaska na gore navedeno područje.",
    "Izričito napominjemo da će kršenje ove zabrane ulaska neopozivo rezultirati kaznenom prijavom zbog narušavanja javnog reda i mira sukladno članku 186. KZ-a.",
    "Ova zabrana ulaska važi izričito do pisane opozive i može biti opozvana samo od strane nas u pisanoj formi.",
    "Ime zaposlenika VSH Security e.K.",
    "Potpis zaposlenika",
    "VSH Security e.K.",
    "Sud registracije: Okružni sud u Münchenu | Broj registracije: HRA 99661 | Glavni direktor: Sascha Hoffmann Deutschebank München | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Porezna uprava Dachau | Porezni ID: DE 274770876",
  ],
  kosovar: [],
  polish: [
    "to",
    "Web",
    "E-mail",
    "Telefon",
    "Zakaz wstępu",
    "Firma ZOB an der Hackerbrücke GmbH & Co.KG, wyznaczyła nas jako właścicieli nieruchomości Centralny Dworzec Autobusowy (ZOB) w Monachium, Hackerbrücke 4/6 i Arnulfstraße 17/21, 80335 Monachium.",
    "Niniejszym wydajemy zakaz wstępu dla poniższej osoby:",
    "Tytuł",
    "Nazwisko",
    "Imię",
    "Ulica Nr",
    "Kod pocztowy, Miejscowość",
    "Zakaz wstępu dla",
    "Cały obszar Centralnego Dworca Autobusowego (ZOB) w Monachium, Hackerbrücke 4/6 i Arnulfstraße 17/21, 80335 Monachium.",
    "Uzasadnienie",
    "Żebranie na terenie Centralnego Dworca Autobusowego (ZOB) w Monachium. Jest to zabronione zgodnie z regulaminem domu",
    "Ważność",
    "Opierając się na art. 186 Kodeksu Karnego, niniejszym nakładamy na Pana/Panią z natychmiastowym skutkiem zakaz wstępu na wyżej wymieniony teren.",
    "Wyraźnie zaznaczamy, że naruszenie tego zakazu będzie miało nieodwołalne skutki w postaci zawiadomienia o przestępstwie zgodnie z art. 186 Kodeksu Karnego.",
    "Ten zakaz obowiązuje wyraźnie do momentu jego pisemnego odwołania, i może być odwołany tylko przez nas w formie pisemnej.",
    "Nazwisko pracownika VSH Security e.K.",
    "Podpis pracownika",
    "VSH Security e.K.",
    "Sąd Rejestracyjny: Sąd Rejonowy w Monachium | Numer rejestracji: HRA 99661 | Dyrektor Generalny: Sascha Hoffmann Deutschebank Monachium | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Urząd Skarbowy w Dachau | NIP: DE 274770876",
  ],
  romansh: [],
  romanian: [
    "aceasta",
    "Web",
    "E-mail",
    "Telefon",
    "Interdicție de intrare",
    "ZOB an der Hackerbrücke GmbH & Co.KG ne-a desemnat ca proprietari ai imobilului Gara Centrală de Autobuze (ZOB) din München, Hackerbrücke 4/6 și Arnulfstraße 17/21, 80335 München.",
    "Prin prezenta emitem o interdicție de intrare următoarei persoane:",
    "Titlu",
    "Nume",
    "Prenume",
    "Stradă Nr.",
    "Cod Poștal, Oraș",
    "Interdicție de intrare pentru",
    "Întregul teritoriu al Gării Centrale de Autobuze (ZOB) din München, Hackerbrücke 4/6 și Arnulfstraße 17/21, 80335 München.",
    "Motiv",
    "Cerșitul în zona Gării Centrale de Autobuze (ZOB) din München. Acest lucru este interzis conform regulamentului intern",
    "Valabilitate",
    "Bazându-ne pe Art. 186 din Codul Penal, vă interzicem cu efect imediat accesul pe teritoriul menționat mai sus.",
    "Vă informăm expres că orice încălcare a acestei interdicții va duce irevocabil la o plângere penală pentru intruziune, în conformitate cu Art. 186 din Codul Penal.",
    "Această interdicție este valabilă până la revocarea scrisă și poate fi revocată doar de către noi în formă scrisă.",
    "Numele angajatului VSH Security e.K.",
    "Semnătura angajatului",
    "VSH Security e.K.",
    "Tribunalul de înregistrare: Tribunalul Districtual München | Număr de înregistrare: HRA 99661 | Director General: Sascha Hoffmann Deutschebank München | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Finanțe Dachau | Nr. TVA: DE 274770876",
  ],
  serbian: [
    "to",
    "Web",
    "E-mail",
    "Telefon",
    "Zabrana ulaska",
    "ZOB an der Hackerbrücke GmbH & Co.KG je odredila nas kao vlasnike objekta Centralne autobuske stanice (ZOB) u Minhenu, Hackerbrücke 4/6 i Arnulfstraße 17/21, 80335 München.",
    "Ovim izdajemo zabranu ulaska sledećoj osobi:",
    "Naslov",
    "Prezime",
    "Ime",
    "Ulica Br.",
    "Poštanski broj, Mesto",
    "Zabrana ulaska za",
    "Celo područje Centralne autobuske stanice (ZOB) u Minhenu, Hackerbrücke 4/6 i Arnulfstraße 17/21, 80335 München.",
    "Razlog",
    "Prosjačenje na prostoru Centralne autobuske stanice (ZOB) u Minhenu. To je zabranjeno prema kućnom redu",
    "Važenje",
    "Na osnovu čl. 186 Krivičnog zakonika, ovim vam izričemo, sa trenutnim dejstvom, zabranu ulaska na gore navedeni prostor.",
    "Izričito napominjemo da će kršenje ove zabrane nepovratno rezultirati krivičnom prijavom zbog prekršaja u skladu sa čl. 186 KZ.",
    "Ova zabrana važi izričito dok se ne povuče pismenim putem i može se povući samo od strane nas u pisanoj formi.",
    "Ime zaposlenog VSH Security e.K.",
    "Potpis zaposlenog",
    "VSH Security e.K.",
    "Sud za registraciju: Opštinski sud u Minhenu | Registarski broj: HRA 99661 | Izvršni direktor: Sascha Hoffmann Deutschebank München | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Poreska uprava Dachau | PIB: DE 274770876",
  ],
  turkish: [
    "bu",
    "Web",
    "E-posta",
    "Telefon",
    "Yasaklama",
    "ZOB an der Hackerbrücke GmbH & Co.KG, bizi Münih Merkez Otobüs Terminali (ZOB) mülkiyeti, Hackerbrücke 4/6 ve Arnulfstraße 17/21, 80335 München'ın sahipleri olarak atamıştır.",
    "Bu kişiye yasaklama kararı veriyoruz:",
    "Unvan",
    "Soyad",
    "Ad",
    "Sokak No.",
    "Posta Kodu, Şehir",
    "İçin yasaklama",
    "Münih Merkez Otobüs Terminali (ZOB) bölgesi, Hackerbrücke 4/6 ve Arnulfstraße 17/21, 80335 München.",
    "Gerekçe",
    "Münih Merkez Otobüs Terminali'nde (ZOB) dilenme. Bu, ev kurallarına göre yasaktır",
    "Geçerlilik",
    "186. Ceza Kanunu maddeye dayanarak, size belirtilen alana derhal giriş yasağı getiriyoruz.",
    "Bu yasağın ihlal edilmesinin, 186. CK maddesi uyarınca huzur bozma suçundan dolayı kesinlikle bir suç duyurusuna yol açacağını belirtiriz.",
    "Bu yasaklama, yazılı olarak geri alınana kadar geçerlidir ve sadece yazılı olarak tarafımızdan geri alınabilir.",
    "VSH Security e.K. çalışanının adı",
    "Çalışanın imzası",
    "VSH Security e.K.",
    "Kayıt Mahkemesi: München Bölge Mahkemesi | Kayıt Numarası: HRA 99661 | Genel Müdür: Sascha Hoffmann Deutschebank München | IBAN:DE36700700240778038000 | BIC:DEUTDEDBMUC Dachau Maliye Dairesi | Vergi No: DE 274770876",
  ],
};
