import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const User = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "firstName",
      name: "firstName",
      validation: "",
      default: "",
      label: "firstName",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "lastName",
      name: "lastName",
      validation: "",
      default: "",
      label: "lastName",
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "text",
      placeholder: "mobileNumber",
      name: "mobileNumber",
      validation: "mobile",
      default: "",
      label: "mobileNumber",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "email",
      name: "email",
      validation: "email",
      default: "",
      label: "email",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "password",
    //   placeholder: "Password",
    //   name: "password",
    //   validation: "password",
    //   default: "",
    //   label: "Password",
    //   required: true,
    //   show: false,
    //   add: true,
    //   update: false,
    // },
    {
      type: "select",
      placeholder: "userType",
      name: "userType",
      validation: "",
      default: "",
      label: "userType",
      showItem: "title",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "user-type/select",
    },
    {
      type: "select",
      placeholder: "status",
      name: "status",
      validation: "",
      default: "",
      label: "status",
      required: false,
      view: true,
      add: false,
      update: true,
      apiType: "CSV",
      selectApi: "Active,Inactive",
    },
    {
      type: "select",
      placeholder: "shift",
      name: "shiftTemplate",
      validation: "",
      showItem: "title",
      default: "",
      label: "shift",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "shift/select",
    },
    {
      type: "select",
      placeholder: "place",
      name: "place",
      validation: "",
      default: "",
      label: "place",
      showItem: "title",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "place/select",
    },
    {
      type: "text",
      placeholder: "addedBy",
      name: "addedBy",
      showItem: "fullName",
      collection: "addedBy",
      validation: "",
      default: "",
      label: "addedBy",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "invitation",
      placeholder: "invitation",
      tab: "generalInfo",
      name: "invitationSent",
      validation: "",
      showItem: "invitation",
      default: "",
      label: "invitation",
      required: true,
      view: false,
      tag: true,
      add: false,
      update: false,
    },
    {
      type: "checkbox",
      placeholder: "acceptLogin",
      tab: "generalInfo",
      name: "acceptLogin",
      validation: "",
      showItem: "",
      default: "",
      label: "acceptLogin",
      required: true,
      view: false,
      tag: true,
      add: false,
      update: false,
    },
  ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("user")} - VSH Security`;
  }, [t]);

  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} api={`admin`} itemTitle={`firstName`} shortName={`admin`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(User);
