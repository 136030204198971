import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListTable from "../../../../elements/list/list";
import { Container } from "../../../common/layout/styels";
import withLayout from "../../../common/layout";
const EmployeeNews = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "date",
      placeholder: "createdAt",
      name: "createdAt",
      validation: "",
      default: "",
      label: "createdAt",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "addedBy",
      name: "addedBy",
      validation: "addedBy",
      showItem: "fullName",
      collection: "addedBy",
      filter: false,
      default: "",
      label: "addedBy",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "text",
      name: "news",
      validation: "",
      default: "",
      label: "text",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  useEffect(() => {
    document.title = `${t("news")} - VSH Security`;
  }, [t]);
  return (
    <Container className="noshadow">
      <ListTable formMode="double" {...props} api={`news/employee-news`} itemTitle={`title`} shortName={`news`} addPrivilege={false} delPrivilege={false} updatePrivilege={false} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default withLayout(EmployeeNews);
