import styled from "styled-components";

// Style for the button container div
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin as needed */
  button {
    padding: 10px 10px;
    background-color: ${(props) => props.theme.themeBackground}; /* Button background color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    align-items: center;
    display: flex;

    &:hover {
      transform: scale(1.05);
      transition: all 0.2s;
      background-color: ${(props) => props.theme.themeBackground}; /* Button background color on hover */
    }
  }
  button:first-child {
    svg {
      margin-right: 5px;
    }
  }
  button:last-child {
    svg {
      margin-right: 5px;
    }
  }
`;

// Define the style for the table
export const CalendarTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  tr {
  }

  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ccc;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    user-select: none;
    color: #5b5b5b;
    position: relative;
    min-width: 100px;
    width: 14.29%;
    &:hover {
      /* background-color: ${(props) => props.theme.themeBackground};
      color: #fff; */
      /* border: 1px solid; */
    }
  }
  td.disabled {
    background-color: #ebebeb;
  }
  td .dayNumber {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    border-radius: 0;
    border-bottom-left-radius: 50%;
    right: 5px;
    top: 5px;
    border-radius: 50%;
    background-color: #1a9dd9;
    color: white;
    width: 25px;
    height: 25px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  tbody {
    // Styles for the table body
  }

  th {
    padding: 8px;
    text-align: center;
    background-color: #eef4fe;
    border: 1px solid #ccc;
    font-weight: bold;
  }
`;
export const Approval = styled.span`
  background: ${(props) => props.color};
  padding: 8px;
  svg {
    color: white;
  }
`;
