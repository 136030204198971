import React, { useRef } from "react";
import CustomSelect from "../select";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, CloseButton, FileContainer, Info, Input, InputContainer, Label, TextArea } from "./styles";
import { ErrorMessage } from "../form/styles";
import { GetIcon, TickIcon, UploadIcon } from "../../../icons";
import Checkbox from "../checkbox";
import MultiSelect from "../multiSelect";
import { DateTimePicker } from "../datepicker";

function FormInput(props) {
  // Initialize translation function for current language
  const { t } = useTranslation();
  // Create a reference to file input element
  const fileInputRef = useRef(null);

  // Get theme colors from Redux store
  const themeColors = useSelector((state) => state.themeColors);
  // const formatDateTime = (type, value) => {
  //   const today = moment().startOf("day");
  //   const tomorrow = moment().startOf("day").add(1, "days");
  //   const date = moment(value);

  //   if (type === "datetime") {
  //     return date.format("DD.MM.YYYY, H:mm");
  //   } else if (type === "date") {
  //     if (date.isSame(today, "day")) {
  //       return "Today";
  //     } else if (date.isSame(tomorrow, "day")) {
  //       return "Tomorrow";
  //     } else {
  //       return date.format("DD. MM.YYYY");
  //     }
  //   }
  // };

  switch (props.type) {
    // Render a regular text input
    case "text":
    case "password":
    case "email":
    case "color":
      return (
        <InputContainer className={`${props.dynamicClass ?? ""}`} animation={props.animation}>
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <Input autoComplete="on" theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(props.index??0, event, props.id, props.type, props.sub)} />
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a time input with time picker
    case "number":
      const value = isNaN(props.value) ? null : props.value;
      const handleKeyDown = (event) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
          // Prevent the default behavior for up and down arrow keys
          console.log("event", "aborted");
          event.preventDefault();
        }
        console.log("event", event.keyCode);
      };
      return (
        <InputContainer className={`${props.dynamicClass ?? ""}`} animation={props.animation}>
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!value?.toString().length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!value?.toString().length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <Input
            onKeyDown={handleKeyDown} // Attach the onKeyDown event handler
            onWheel={(e) => e.target.blur()}
            autoComplete="on"
            theme={themeColors}
            className={`input ${value?.toString().length > 0 ? "shrink" : ""}`}
            placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`}
            type={props.type}
            value={value}
            onChange={(event) => props.onChange(props.index, event, props.id, props.type, props.sub)}
            min={0}
            maxLength={props.maximum}
          />
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a time input with time picker
    case "time":
      let userFriendlyTime = props.value.length > 0 ? new Date(props.value) : null;
      return (
        <InputContainer className={`${props.dynamicClass ?? ""}`}>
          <DateTimePicker placeholderText={`${t(props.label)}${props.required ? " *" : ""}`} theme={themeColors} showTimeSelect showTimeSelectOnly timeIntervals={props.interval ?? 1} timeFormat="HH:mm" timeCaption="Time" selected={userFriendlyTime} dateFormat="HH:mm" className={`input time ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={props.type} onChange={(event) => props.onChange(props.index, event, props.id, props.type)} />
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a date input with date picker
    case "date":
      let userFriendlyDate = props.value?.length > 0 ? new Date(props.value) : null;
      return (
        <InputContainer className={`${props.dynamicClass ?? ""}`}>
          {!props.required && (
            <CloseButton onClick={(event) => props.onChange(props.index, null, props.id, props.type)}>
              <GetIcon icon={"close"}></GetIcon>
            </CloseButton>
          )}
          <DateTimePicker placeholderText={`${t(props.label)}${props.required ? " *" : ""}`} minDate={props.minDate ? new Date(props.minDate) : null} maxDate={props.maxDate ? new Date(props.maxDate) : null} theme={themeColors} className={`input  ${props.value?.length > 0 ? "shrink" : ""}`} dateFormat={"dd.MM.yyyy"} placeholder={t(props.placeholder)} type={props.type} selected={userFriendlyDate} onChange={(event) => props.onChange(props.index, event, props.id, props.type)} />
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value?.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value?.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
        </InputContainer>
      );
    // Render a datetime input with date and time pickers
    case "datetime":
      let userFriendlyDateTime = props.value.length > 0 ? new Date(props.value) : null;
      return (
        <InputContainer className={props.half && "half"}>
          {!props.required && (
            <CloseButton onClick={(event) => props.onChange(props.index, null, props.id, props.type)}>
              <GetIcon icon={"close"}></GetIcon>
            </CloseButton>
          )}
          <DateTimePicker theme={themeColors} showTimeSelect timeIntervals={props.interval ?? 1} timeFormat="HH:mm" dateFormat={"dd.MM.yyyy HH:mm"} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholderText={`${t(props.label)}${props.required ? " *" : ""}`} type={props.type} value={userFriendlyDateTime} selected={userFriendlyDateTime} onChange={(event) => props.onChange(props.index, event, props.id, props.type)} />
          {props.error?.length ? (
            <>
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
                {`${props.error}`}
              </Label>
              {/* {props.value.length > 0 && <ShowDate>{formatDateTime(props.type, userFriendlyDateTime)}</ShowDate>} */}
            </>
          ) : (
            <>
              <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
                <TickIcon />
                {`${t(props.label)}${props.required ? " *" : ""}`}
              </Label>
              {/* {props.value.length > 0 && <ShowDate>{formatDateTime(props.type, userFriendlyDateTime)}</ShowDate>} */}
            </>
          )}
        </InputContainer>
      );
    // Render a file and image
    case "image":
    case "file":
      function formatSize(sizeInBytes) {
        if (sizeInBytes < 1024) {
          return sizeInBytes + " bytes";
        } else if (sizeInBytes < 1024 * 1024) {
          return (sizeInBytes / 1024).toFixed(2) + " KB";
        } else {
          return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
        }
      }
      const size = formatSize(props.value[0] ? props.value[0].size : 0);
      const handleButtonClick = () => {
        fileInputRef.current.click();
      };
      return (
        <FileContainer theme={themeColors}>
          <button onClick={handleButtonClick}>
            <UploadIcon />
            {t("upload", { label: t(props.label) }) + (props.required ? " *" : "") + (props.value.length > 0 ? ` : ${props.value[0].name} (${size})` : "")}
          </button>
          <Input ref={fileInputRef} style={{ display: "none" }} theme={themeColors} accept={props.type === "image" ? `image/*` : ``} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={t(props.placeholder)} type={`file`} onChange={(event) => props.onChange(props.index, event, props.id, props.type)} />
          {props.error?.length > 0 && <ErrorMessage dangerouslySetInnerHTML={{ __html: props.error }} />}
        </FileContainer>
      );
    // Render a textarea
    case "textarea":
      return (
        <InputContainer className="textarea">
          {props.error?.length ? (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "error shrink" : "error"}`}>
              {props.error}
            </Label>
          ) : (
            <Label theme={themeColors} className={`${!props.value.length > 0 ? "shrink" : ""}`}>
              <TickIcon />
              {`${t(props.label)}${props.required ? " *" : ""}`}
            </Label>
          )}
          <TextArea theme={themeColors} className={`input ${props.value.length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} value={props.value} onChange={(event) => props.onChange(props.index, event, props.id)} />
        </InputContainer>
      );
    // Render a submit button
    case "submit":
      return (
        <Button theme={themeColors} className="submit" disabled={props.disabled} type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a close button
    case "close":
      return (
        <Button theme={themeColors} className="close" type={props.type} onClick={props.onChange}>
          {props.value}
        </Button>
      );
    // Render a cehckbox
    case "checkbox":
      return (
        <InputContainer className={`checkbox ${props.dynamicClass ?? ""}`}>
          <Label className="checkbox">
            <Checkbox
              theme={themeColors}
              label={t(props.placeholder)}
              type={props.type}
              checked={props.value}
              onChange={(event) => {
                // console.log(event.target.checked === false ? false : true);
                props.onChange(event.target.checked === false ? false : true, props.id, props.type);
              }}
            ></Checkbox>
            {/* <span dangerouslySetInnerHTML={{ __html: t(props.placeholder) }}></span> */}
          </Label>
        </InputContainer>
      );
    // Render a select box
    case "select":
      return <CustomSelect bulkUpload={props.bulkUpload ?? false} theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></CustomSelect>;
    case "info":
      return <Info className={` ${props.dynamicClass}`}>{t(props.content ?? "")}</Info>;
    case "title":
      return <Info className={`title ${props.dynamicClass}`}>{t(props.title ?? "")}</Info>;
    case "multiSelect":
      return (
        <>
          <MultiSelect dynamicClass={props.dynamicClass} theme={themeColors} {...props} name={props.id} selected={props.value} onSelect={props.onChange}></MultiSelect>
        </>
      );
    case "hidden":
      return <Input disabled={props.disabled ?? false} autoComplete="on" theme={themeColors} className={`input ${props.value.toString().length > 0 ? "shrink" : ""}`} placeholder={`${t(props.placeholder)}${props.required ? " *" : ""}`} type={props.type} value={props.value} onChange={(event) => props.onChange(event, props.id, props.type, props.sub)} />;

    default:
      return <></>;
  }
}
export default FormInput;
