import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import { Container } from "../../common/layout/styels";
import Tabs from "../../../elements/tab";
import Board from "./board";
import Report from "./report";
import { Title } from "../../common/layout/header/styels";
import { GetIcon } from "../../../../icons";
import { useSelector } from "react-redux";
const Attendance = () => {
  const [t] = useTranslation();
  useEffect(() => {
    document.title = `${t("attendance")} - VSH Security`;
  }, [t]);
  const [tabs] = useState([
    { name: "board", title: "onwork", element: <Board key={"page-board"} /> },
    { name: "report1", title: t("reports", { number: 1 }), element: <Report reportType={1} key={"page-report1"} /> },
    { name: "report2", title: t("reports", { number: 2 }), element: <Report reportType={2} key={"page-report2"} /> },
    { name: "report3", title: t("reports", { number: 3 }), element: <Report reportType={3} pageKey={"page-report3"} key={"page-report3"} /> },
    { name: "report4", title: t("vacation", { number: 4 }), element: <Report reportType={4} api={"report/vacation"} pageKey={"page-report4"} key={"page-report4"} /> },
  ]);

  const selectedMenu = useSelector((state) => state.selectedMenu);
  //end crud functions
  return (
    <div style={{display:'flex',flexDirection:'column',flex:1,marginLeft:20,marginTop:20}}>
      <Title className="inner">
        <GetIcon icon={selectedMenu.icon}></GetIcon>
        {t(selectedMenu.label)}
      </Title>

      <Container className="noshadow">
        <Tabs tabs={tabs}></Tabs>
      </Container>
    </div>
  );
};
// exporting the page with parent container layout..
export default Layout(Attendance);
