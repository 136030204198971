import { useTranslation } from "react-i18next";
import { postData } from "../../../../../backend/api";
import { ToggleContainer, ToggleInput, ToggleSlider } from "../../../../elements/list/styles";
import { Logout, Popbar } from "../header/styels";
import { clearLogin, udpateLogin } from "../../../../../store/actions/login";
import { useDispatch } from "react-redux";
import { generateThumbnail } from "../../../../functions/string";
import { Bottom, ProfileIcon } from "../styels";
import { useNavigate } from "react-router-dom";
import { LogoutIcon } from "../../../../../icons";

const ProfileBar = ({ data, setLoaderBox, setMessage }) => {
  const { t } = useTranslation();
  const user = data.user;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Popbar className="ProfileBar">
      <ProfileIcon>{generateThumbnail(user?.fullName ?? "", null, user.photo ?? "")}</ProfileIcon>
      <span className="main">{user.fullName}</span>
      <span className="sub">{user.email}</span>
      <p></p>
    
      <ToggleContainer key={`${user.username}`}>
        <ToggleInput
          type="checkbox"
          checked={user.boardStatus ?? false}
          onChange={async (event) => {
            // item.callback(item, data);
            setLoaderBox(true);
            await postData({ status: event.target.checked }, `attendance/mark/${user._id}`)
              .then((response) => {
                if (response.status === 200) {
                  if (response.data?.message) {
                    setMessage({ type: 1, content: t(response.data?.message), proceed: t("okay") });
                    let user = { ...data };
                    user.user.boardStatus = response.data?.message === "inMarked" ? true : false;
                    dispatch(udpateLogin(user));
                  }
                } else if (response.status === 404) {
                  setMessage({ type: 1, content: t("error"), proceed: "Okay" });
                } else {
                  setMessage({ type: 1, content: t("error"), proceed: "Okay" });
                }
                setLoaderBox(false);
              })
              .catch((error) => {
                alert(error);
                setLoaderBox(false);
              });
          }}
        />
        <ToggleSlider />
      </ToggleContainer>
      <span className="sub">{t("startClock")}</span>
      <Bottom>
        <Logout
          onClick={() => {
            dispatch(clearLogin());
            navigate("/");
          }}
        >
          <LogoutIcon />
          Signout
        </Logout>
      </Bottom>
    </Popbar>
  );
};
export default ProfileBar;
