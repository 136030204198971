import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import { Container } from "../../common/layout/styels";
import { useDispatch, useSelector } from "react-redux";
import { addPageObject } from "../../../../store/actions/pages";
import { Head, HeadBox, HeadContainer } from "./styels";
import { generateThumbnail } from "../../../functions/string";

const Organization = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const dashboard = useSelector((state) =>
    state.pages[`user/hierarchy`]
      ? state.pages[`user/hierarchy`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject("user/hierarchy", 0, {}));
    }
  }, [initialized, dispatch]);
  useEffect(() => {
    document.title = `${t("organization")} - VSH Security`;
  }, [t]);
  useEffect(() => {
    props.setLoaderBox(dashboard.isLoading);
    dashboard.isLoading && setInitialized(true);
  }, [dashboard, props]);

  return (
    <Container className="noshadow">
      <HeadContainer className="main">
        <HeadContainer>
          <HeadContainer className="row-head">
            {dashboard.data?.admins?.map((admin, index) => {
              const amdinName = `${admin.firstName} ${admin.lastName}`;
              return (
                <HeadContainer key={index}>
                  <HeadBox>
                    <Head className="first">
                      {generateThumbnail(amdinName, null, admin.photo)}
                      <p>{amdinName}</p>
                    </Head>
                  </HeadBox>
                </HeadContainer>
              );
            })}
          </HeadContainer>
          <HeadContainer className="row">
            {dashboard.data?.hierarchy?.map((item, index) => {
              const locationName = typeof item.location === "object" ? item.location.title : item.location;
              return (
                <HeadContainer key={index}>
                  <HeadBox className="box">
                    <Head>
                      {generateThumbnail(locationName)}
                      <p>{locationName}</p>
                    </Head>
                    <HeadContainer className="row">
                      {item.departments?.map((departmentItem, index1) => {
                        const departmentName = typeof departmentItem.department === "object" ? departmentItem.department.title : departmentItem.department;
                        return (
                          <HeadContainer key={index1}>
                            <HeadBox>
                              <Head>
                                {generateThumbnail(departmentName)}
                                <p>{departmentName}</p>
                              </Head>
                              <HeadContainer className="row">
                                {departmentItem.places?.map((placeItem, index2) => {
                                  const placeName = typeof placeItem.place === "object" ? placeItem.place.title : placeItem.place;
                                  return (
                                    <HeadContainer key={index2}>
                                      <HeadBox>
                                        <Head>
                                          {generateThumbnail(placeName)}
                                          <p>{placeName}</p>
                                        </Head>
                                        <HeadContainer className="row">
                                          {placeItem.employees?.map((employee, index3) => {
                                            const employeeName = `${employee.firstName} ${employee.lastName}`;
                                            return (
                                              <HeadContainer key={index3}>
                                                <HeadBox>
                                                  <Head className="last">
                                                    {generateThumbnail(employeeName, null, employee.photo)}
                                                    <p>{employeeName}</p>
                                                    <p>{employee.designation ? employee.designation.title : ""}</p>
                                                  </Head>
                                                </HeadBox>
                                              </HeadContainer>
                                            );
                                          })}
                                        </HeadContainer>
                                      </HeadBox>
                                    </HeadContainer>
                                  );
                                })}
                              </HeadContainer>
                            </HeadBox>
                          </HeadContainer>
                        );
                      })}
                    </HeadContainer>
                  </HeadBox>
                </HeadContainer>
              );
            })}
          </HeadContainer>
        </HeadContainer>
      </HeadContainer>
    </Container>
  );
};

export default Layout(Organization);
