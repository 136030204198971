import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
import moment from "moment";
import PopupView from "../../../elements/popupview";
import ApproveLeave from "./ApproveLeave";
import { useSelector } from "react-redux";
const LeaveRequest = (props) => {
  const { t } = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  let date = moment(new Date());
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "fullName",
      name: "user",
      validation: "",
      default: "",
      collection: "user",
      showItem: "fullName",
      label: "fullName",
      required: false,
      filter: false,
      view: true,
      add: false,
      update: false,
    },

    {
      type: "date",
      placeholder: "startDate",
      name: "startDate",
      validation: "",
      minDate: date.clone(),
      maxDate: date.clone().add(3, "months"),
      default: date.clone().startOf("day").add(7, "hours"),
      label: "startDate",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "date",
      placeholder: "endDate",
      name: "endDate",
      validation: "",
      greater: "endEnd",
      minDate: date.clone().add(1, "days"),
      maxDate: date.clone().add(3, "months"),
      default: date.clone().startOf("day").add(15, "hours"),
      label: "endDate",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "leaveType",
      name: "leaveType",
      validation: "",
      default: "",
      collection: "leaveType",
      label: "leaveType",
      showItem: "locale",
      required: true,
      view: true,
      add: false,
      update: false,
      apiType: "API",
      selectApi: "leave-type/select",
    },
    {
      type: "textarea",
      placeholder: "reason",
      name: "note",
      validation: "",
      default: "",
      label: "reason",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "date",
      placeholder: "appliedOn",
      name: "createdAt",
      validation: "",
      minDate: date.clone(),
      maxDate: date.clone().add(3, "months"),
      default: date.clone().startOf("day").add(7, "hours"),
      label: "appliedOn",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "icon",
      placeholder: "approval",
      name: "approval",
      validation: "",
      default: "",
      label: "approval",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "approval",
      name: "approval",
      validation: "",
      default: "",
      label: "approval",
      required: true,
      apiType: "JSON",
      selectApi: [
        { id: "Confirmed", value: t("Confirmed") },
        { id: "Rejected", value: t("Rejected") },
      ],
      view: false,
      add: false,
      update: false,
    },
  ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("leaveRequest")} - VSH Security`;
  }, [t]);
  const [action, setActions] = useState([]);
  const approval = (item, data, refreshView) => {
    setActions({ item, data, refreshView });
  };

  const [actions] = useState([
    {
      element: "action",
      type: "callback",
      id: "approve",
      title: "Approval",
      icon: "edit",
      condition: { if: "approval", match: "Pending" },
      callback: approval,
    },
  ]);
  return (
    <Container className="noshadow">
      <ListTable {...props} viewDetails={false} actions={actions} api={`leave-request`} itemTitle={`fullName`} shortName={`leaveRequest`} addPrivilege={false} delPrivilege={false} updatePrivilege={false} attributes={attributes}></ListTable>
      {action.data && (
        <PopupView
          themeColors={themeColors}
          openData={action}
          itemTitle={{ name: "fullName", collection: "user" }}
          popupData={
            <ApproveLeave
              closeModal={() => {
                action.refreshView();
                setActions([]);
              }}
              setLoaderBox={props.setLoaderBox}
              setMessage={props.setMessage}
              openData={action}
            />
          }
          closeModal={() => {
            setActions([]);
          }}
        ></PopupView>
      )}
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(LeaveRequest);
