import React from "react";
import { Page as Page1, Overlay, FileContainer } from "./styles";
import { CloseButton } from "../../../../elements/popupview/styles";
import { GetIcon } from "../../../../../icons";
import { useSelector } from "react-redux";
import { Header } from "../../../../elements/list/manage/styles";
import { NoData } from "../../../../elements/list/styles";
import { useTranslation } from "react-i18next";
const ViewFiles = ({ setMessage, setLoaderBox, customClass, title, page, item, onClose, admin=false }) => {
  const closeModal = () => {
    onClose();
  };
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const fileType = page.data.fileType?.toLowerCase();
  const Download = () => {
    return (
      <a href={`${process.env.REACT_APP_CDN}${page.data?.file}`} download>
        <GetIcon icon={"Download"}></GetIcon>
        {t('Download File')}
      </a>
    );
  };
  return (
    <Overlay className={`${customClass ?? "medium"}`}>
      <Page1 className={`${customClass ?? "medium"} popup-child`}>
        <Header>
          <span>{`${page.data?.user?.fullName??t('download')}`}</span>
          <CloseButton theme={themeColors} onClick={closeModal}>
            <GetIcon icon={"Close"} />
          </CloseButton>
        </Header>
        <FileContainer>
          {page.data?.adminOnly === true || admin ? (
            fileType ? (
              fileType === "png" || fileType === "jpeg" || fileType === "ppg" ? (
                <>
                  <img src={`${process.env.REACT_APP_CDN}${page.data?.file}`} alt="download" />
                  {Download()}
                </>
              ) : fileType === "pdf" ? (
                <>
                  <NoData>{t("previewNotAvailable")}</NoData>
                  {Download()}
                </>
              ) : (
                <>{Download()}</>
              )
            ) : (
              <NoData>{t("noFileType")}</NoData>
            )
          ) : (
            <NoData>
              <GetIcon icon={"lock"}></GetIcon>
              {t("noAccess")}
            </NoData>
          )}
        </FileContainer>
      </Page1>
    </Overlay>
  );
};

export default ViewFiles;
