import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
import PDFView from "../../../elements/pdf";
const BannedPeople = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "salutation",
      name: "salutation",
      validation: "",
      default: "",
      label: "salutation",
      required: true,
      view: true,
      filter:false,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "Mrs", value: t("Mrs") },
        { id: "Mr", value: t("Mr") },
        { id: "MX", value: t("MX") },
      ],
    },
    {
      type: "text",
      placeholder: "surname",
      name: "surname",
      validation: "",
      default: "",
      label: "surname",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "firstName",
      name: "firstName",
      validation: "",
      default: "",
      label: "firstName",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "streetNo",
      name: "streetNo",
      validation: "",
      default: "",
      label: "streetNo",
      required: true,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "postalCode",
      name: "postalCode",
      validation: "",
      default: "",
      label: "postalCode",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "city",
      name: "city",
      validation: "",
      default: "",
      label: "city",
      required: true,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "country",
      name: "country",
      validation: "",
      default: "",
      label: "country",
      view: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "Germany", value: t("Germany") },
        { id: "Austria", value: t("Austria") },
        { id: "Switzerland", value: t("Switzerland") },
        { id: "Albania", value: t("Albania") },
        { id: "Bulgaria", value: t("Bulgaria") },
        { id: "France", value: t("France") },
        { id: "Greece", value: t("Greece") },
        { id: "Italy", value: t("Italy") },
        { id: "Croatia", value: t("Croatia") },
        { id: "Kosovo", value: t("Kosovo") },
        { id: "Poland", value: t("Poland") },
        { id: "Romania", value: t("Romania") },
        { id: "Serbia", value: t("Serbia") },
        { id: "Spain", value: t("Spain") },
        { id: "Turkey", value: t("Turkey") },
      ],
    },
    {
      type: "select",
      placeholder: "nationality",
      name: "nationality",
      validation: "",
      default: "",
      label: "nationality",
      view: true,
      add: true,
      update: true,
      filter:false,
      apiType: "JSON",
      selectApi: [
        { id: "german", value: t("german") },
        { id: "austrian", value: t("austrian") },
        { id: "swiss", value: t("swiss") },
        { id: "albanian", value: t("albanian") },
        { id: "bulgarian", value: t("bulgarian") },
        { id: "french", value: t("french") },
        { id: "greek", value: t("greek") },
        { id: "italian", value: t("italian") },
        { id: "croatian", value: t("croatian") },
        { id: "kosovar", value: t("kosovar") },
        { id: "polish", value: t("polish") },
        { id: "romanian", value: t("romanian") },
        { id: "serbian", value: t("serbian") },
        { id: "spanish", value: t("spanish") },
        { id: "turkish", value: t("turkish") },
      ],
    },
    {
      type: "date",
      placeholder: "dateOfBirth",
      name: "dateOfBirth",
      validation: "",
      default: "",
      label: "dateOfBirth",
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "idNumber",
      name: "idNumber",
      validation: "",
      default: "",
      label: "idNumber",
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "banStartDate",
      name: "banStartDate",
      validation: "",
      default: "",
      label: "banStartDate",
      required: true,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "banEndDate",
      name: "banEndDate",
      validation: "",
      default: "",
      label: "banEndDate",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "date",
      placeholder: "documentCreatedDate",
      name: "createdAt",
      validation: "",
      default: "",
      label: "documentCreatedDate",
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "language",
      name: "language",
      validation: "",
      default: "",
      label: "language",
      required: true,
      view: false,
      tag: true,
      add: true,
      filter:false,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "german", value: "German/Deutsch" },
        { id: "austrian", value: "Austrian/Österreichisch" },
        { id: "albanian", value: "Albanian/Albanisch" },
        // { id: "bulgarian", value: "Bulgarian/Bulgarisch" },
        { id: "french", value: "French/Französisch" },
        { id: "greek", value: "Greek/Griechisch" },
        { id: "italian", value: "Italian/Italienisch" },
        { id: "croatian", value: "Croatian/Kroatisch" },
        { id: "kosovar", value: "Kosovar/Kosovarisch" },
        { id: "polish", value: "Polish/Polnisch" },
        { id: "romansh", value: "Romanish/Rumänisch" },
        { id: "romanian", value: "Romanian/Rumänisch" },
        { id: "serbian", value: "Serbian/Serbisch" },
        { id: "spanish", value: "Spanish/Spanisch" },
        { id: "turkish", value: "Turkish/Türkisch" },
        { id: "english", value: "English/Englisch" },
      ],
    },
    {
      type: "select",
      placeholder: "bannedStatus",
      name: "bannedStatus",
      validation: "",
      default: "",
      label: "Banned Status",
      required: true,
      view: false,
      add: true,
      tag: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "1year", value: t("1year") },
        { id: "2year", value: t("2year") },
        { id: "permanent", value: t("permanent") },
      ],
    },
    {
      type: "expired",
      placeholder: "status",
      tab: "generalInfo",
      name: "banEndDate",
      validation: "",
      collection: "",
      showItem: "banEndDate",
      ifNull: "active",
      default: "",
      value: "active",
      label: "status",
      required: false,
      view: true,
      tag: true,
      add: false,
      update: true,
    },
  ]);

  //to update the page title
  useEffect(() => {
    document.title = `${t("bannedPeople")} - VSH Security`;
  }, [t]);
  const [pdfView, setPdfView] = useState(false);
  const [data, setData] = useState({});
  const [actions] = useState([
    {
      element: "button",
      type: "callback",
      id: "generatePdf",
      title: "generatePdf",
      icon: "pdf",
      callback: (item, data) => {
        setData({ item, data });
        setPdfView(true);
      },
    },
  ]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable formMode="double" {...props} actions={actions} api={`banned-people`} itemTitle={`firstName`} shortName={`bannedPeople`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
      {pdfView && <PDFView data={data} closeModal={() => setPdfView(false)}></PDFView>}
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(BannedPeople);
