import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const News = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "date",
      placeholder: "createdAt",
      name: "createdAt",
      validation: "",
      default: "",
      label: "createdAt",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "addedBy",
      name: "addedBy",
      validation: "addedBy",
      showItem: "fullName",
      collection: "addedBy",
      filter: false,
      default: "",
      label: "addedBy",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "text",
      name: "news",
      validation: "",
      default: "",
      label: "text",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "status",
      name: "status",
      validation: "",
      default: "",
      showItem: "translate",
      label: "status",
      required: true,
      view: true,
      filter: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "Draft", value: t("Draft") },
        { id: "Published", value: t("Published") },
        { id: "Archive", value: t("Archive") },
      ],
    },
    {
      type: "select",
      placeholder: "sharedTo",
      name: "sharedTo",
      validation: "",
      default: "",
      label: "sharedTo",
      showItem: "translate",
      required: true,
      view: true,
      filter: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "Employees", value: t("Employees") },
        { id: "Department", value: t("Department") },
        { id: "All", value: t("All") },
      ],
    },
    {
      type: "multiSelect",
      placeholder: "department",
      condition: { item: "sharedTo", if: "Department", else: "disabled", then: "enabled" },
      name: "department",
      validation: "",
      showItem: "title",
      default: "",
      label: "department",
      required: true,
      filterType: "multiSelect",
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "department/select",
    },
    {
      type: "multiSelect",
      placeholder: "employee",
      condition: { item: "sharedTo", if: "Employees", else: "disabled", then: "enabled" },
      name: "user",
      validation: "",
      showItem: "title",
      default: "",
      label: "employee",
      required: true,
      filterType: "multiSelect",
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "user/select",
    },
  ]);

  useEffect(() => {
    document.title = `${t("news")} - VSH Security`;
  }, [t]);
  return (
    <Container className="noshadow">
      <ListTable formMode="double" {...props} api={`news`} itemTitle={`title`} shortName={`news`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(News);
