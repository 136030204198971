import React, { useEffect, useState } from "react";
import Layout from "../../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../../elements/list/list";
import { Container } from "../../../common/layout/styels";
import moment from "moment";
const LeaveRequestEmployee = (props) => {
  const { t } = useTranslation();
  let date = moment(new Date());
  const [attributes] = useState([
    {
      type: "date",
      placeholder: "startDate",
      name: "startDate",
      validation: "",
      minDate: date.clone(),
      maxDate: date.clone().add(3, "months"),
      default: date.clone().startOf("day").add(7, "hours"),
      label: "startDate",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "endDate",
      name: "endDate",
      validation: "",
      greater: "endEnd",
      minDate: date.clone().add(1, "days"),
      maxDate: date.clone().add(3, "months"),
      default: date.clone().startOf("day").add(15, "hours"),
      label: "endDate",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "leaveType",
      name: "leaveType",
      validation: "",
      default: "",
      collection: "leaveType",
      label: "leaveType",
      showItem: "locale",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "leave-type/select",
    },
    {
      type: "number",
      placeholder: "nodays",
      name: "days",
      validation: "",
      default: "",
      label: "nodays",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "textarea",
      placeholder: "reason",
      name: "note",
      validation: "",
      default: "",
      label: "reason",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "appliedOn",
      name: "createdAt",
      validation: "",
      minDate: date.clone(),
      maxDate: date.clone().add(3, "months"),
      default: date.clone().startOf("day").add(7, "hours"),
      label: "appliedOn",
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "approval",
      name: "approval",
      validation: "",
      default: "",
      label: "approval",
      required: true,
      apiType: "JSON",
      selectApi: [
        { id: "Confirmed", value: t("Confirmed") },
        { id: "Rejected", value: t("Rejected") },
      ],
      view: false,
      add: false,
      update: false,
    },
    {
      type: "icon",
      placeholder: "approval",
      name: "approval",
      validation: "",
      default: "",
      label: "approval",
      required: false,
      view: true,
      add: false,
      update: false,
    },
  ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("leaveRequest")} - VSH 1Security`;
  }, [t]);
  // const [actions] = useState([{ element: "button", icon: "timings", type: "subList", id: "shift-timing", itemTitle: "title", title: "timings", attributes: timingAttributes, params: { api: `timing`, itemTitle: "title", shortName: "Timing", addPrivilege: true, delPrivilege: true, updatePrivilege: true, customClass: "medium" } }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} api={`leave-request/employee`} itemTitle={`startDate`} shortName={`leaveRequest`} addPrivilege={true} delPrivilege={false} updatePrivilege={false} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(LeaveRequestEmployee);
