import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const EquipmentType = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "en",
      name: "en",
      validation: "",
      default: "",
      label: "en",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "de",
      name: "de",
      validation: "",
      default: "",
      label: "de",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  //to update the page title
  useEffect(() => {
    document.title = `${t("equipmentType")} - VSH Security`;
  }, [t]);
  // const [actions] = useState([{ element: "button", icon: "timings", type: "subList", id: "shift-timing", itemTitle: "title", title: "timings", attributes: timingAttributes, params: { api: `timing`, itemTitle: "title", shortName: "Timing", addPrivilege: true, delPrivilege: true, updatePrivilege: true, customClass: "medium" } }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable dragList={true} {...props} api={`equipment-type`} itemTitle={`title`} shortName={`equipmentType`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(EquipmentType);
