import styled from "styled-components";
export const Panel = styled.div`
  display: flex;
  button {
    outline: none !important;
    width: 100%;
    border: 0px solid #ddd;
    white-space: nowrap;
    min-height: 40px;
    height: 40px;
    padding: 10px 20px;
    background: #f3f5f9;
    border-left: 1px solid #e4e8ee;
    cursor: pointer;
  }
  button.active {
    background-color: white;
    border-left: 0px solid #ddd;
  }
  button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 0;
  }
  button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
