import { useTranslation } from "react-i18next";
import { GetIcon } from "../../../../icons";
import { dateFormat, dateTimeFormat, timeFormat } from "../../../functions/date";
import { IconBox, Img, Status } from "../styles";
export function convertMinutesToHHMM(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);

  const hoursStr = hours.toString().padStart(2, "0");
  const minsStr = mins.toString().padStart(2, "0");

  return `${hoursStr}:${minsStr}`;
}
export const GetValue = (attribute, itemValue, display = false, language = "de") => {
  const [t] = useTranslation();
  let response = "";
  switch (attribute.type) {
    case "minute":
      response = convertMinutesToHHMM(parseFloat(itemValue ?? 0));
      break;
    case "image":
      response = <Img src={process.env.REACT_APP_CDN + itemValue} />;
      break;
    case "file":
      response = (
        <a href={process.env.REACT_APP_CDN + itemValue} download>
          download
        </a>
      );
      break;
    case "datetime":
      response = dateTimeFormat(itemValue);
      break;
    case "multiSelect":
      response = itemValue.map((item) => item[attribute.showItem].toString()).join(", ");
      break;
    case "date":
      response = dateFormat(itemValue);
      break;
    case "time":
      response = timeFormat(itemValue);
      break;
    case "expired":
      let status = itemValue ? new Date(itemValue) > new Date() ? <Status color={"green"}>Active</Status> : <Status color={"red"}>Inactive</Status> : <Status color={"green"}>{attribute.ifNull}</Status> ?? "N/A";
      response = status;
      break;
    case "color":
      response = <div style={{ backgroundColor: itemValue, height: "20px", width: "40px" }}></div>;
      break;
    case "number":
      response = itemValue?.toString().length === 0 || isNaN(itemValue) ? t(`na`) : itemValue;
      break;
    case "textarea":
    case "htmleditor":
      response = <span dangerouslySetInnerHTML={{ __html: itemValue?.toString()?.substring(0, 200) }}></span>;
      break;
    case "checkbox":
      response = <IconBox className={display && "display"}>{itemValue ? <GetIcon icon={"checked"} /> : <GetIcon icon={"Close"} />}</IconBox>;
      break;
    case "icon":
      response = <i className={`fa-light ${itemValue}`} />;
      break;
    default:
      switch (typeof itemValue) {
        case "undefined":
          response = "--";
          break;
        case "object":
          response = itemValue?.[attribute.showItem] ?? "--";
          break;
        case "boolean":
          response = itemValue.toString();
          break;
        case "number":
          response = itemValue?.toString().length === 0 || isNaN(itemValue) ? t(`na`) : itemValue;
          break;
        case "string":
        default:
          if (attribute.type === "select" && attribute.apiType === "JSON") {
            attribute.selectApi.filter((item) => item.id.toString() === itemValue?.toString()).map((filteredItem) => (response = filteredItem.value));
          } else {
            response = itemValue?.toString().substring(0, 200);
          }
          break;
      }
  }
  if (response.length === 0) {
    return t(`na`);
  }
  return response;
};
