// import FormInput from "../../input";

import { useTranslation } from "react-i18next";
import { GetIcon } from "../../../icons";
import { RowContainer } from "../../styles/containers/styles";
import { GetValue } from "../list/functions";
import { Header, Page } from "../list/manage/styles";
import { Overlay } from "../message/styles";
import { CloseButton } from "./styles";

const PopupView = ({ themeColors, closeModal, itemTitle, popupData, customClass, openData, menu = null }) => {
  const [t] = useTranslation();
  const titleValue = itemTitle.collection ? openData?.data?.[itemTitle.collection]?.[itemTitle.name] : openData?.data?.[itemTitle.name];
  return (
    <Overlay className={`${customClass ?? "medium"}`}>
      <Page className={`${customClass ?? "medium"} popup-child`}>
        <Header>
          <span>
            {menu && (
              <>
                <GetIcon icon={menu.icon}></GetIcon> <span> {t(menu.label)}</span> <GetIcon icon={'next'}></GetIcon> 
              </>
            )}

            {`${t(GetValue({ type: "text" }, titleValue))}`}
          </span>
          <CloseButton theme={themeColors} onClick={closeModal}>
            <GetIcon icon={"Close"} />
          </CloseButton>
        </Header>
        <RowContainer theme={themeColors} className="popup-data">
          {popupData}
        </RowContainer>
      </Page>
    </Overlay>
  );
};
export default PopupView;
