import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enUS, de } from "date-fns/locale";
import { DatetimeInput } from "./styles";

// Customizing locales to start the week on Monday
const customEnLocale = {
  ...enUS,
  options: {
    ...enUS.options,
    weekStartsOn: 1 // Start week on Monday
  }
};

const customDeLocale = {
  ...de,
  options: {
    ...de.options,
    weekStartsOn: 1 // Start week on Monday
  }
};

// Registering custom locales
registerLocale("en", customEnLocale);
registerLocale("de", customDeLocale);

export const DateTimePicker = (props) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "de") {
      setDefaultLocale("de");
    } else {
      setDefaultLocale("en");
    }
  }, [i18n.language]);

  return <DatetimeInput {...props} />;
};
