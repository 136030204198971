import moment from "moment";
export const dateTimeFormat = (date) => {
  try {
    return moment(date).format("DD.MM.YYYY HH:mm");
  } catch (error) {
    return "N/A";
  }
};

export const dateFormat = (date) => {
  try {
    if (date) {
      return moment(date).format("DD.MM.YYYY");
    } else {
      return "N/A";
    }
  } catch (error) {
    return "N/A";
  }
};

export const timeFormat = (date) => {
  try {
    return moment(date).format("HH:mm");
  } catch (error) {
    return "N/A";
  }
};
