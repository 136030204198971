import React, { useEffect, useState } from "react";
import { Filter1 } from "./styles";
import { GetIcon } from "../../../icons";
import { DatetimeInput, InputContainer } from "../input/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "moment/locale/de"; // Import the German locale
import { enUS, de } from "date-fns/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import moment from "moment";

function DateSelector(props) {
  const { i18n } = useTranslation();
  const [selectedLanguage] = useState(i18n.language || "de");
  const themeColors = useSelector((state) => state.themeColors);
  const { t } = useTranslation();
  const [currentDateValue, setCurrentDateValue] = useState(new Date(props.value));
  // Customizing locales to start the week on Monday
  const customEnLocale = {
    ...enUS,
    options: {
      ...enUS.options,
      weekStartsOn: 1, // Start week on Monday
    },
  };

  const customDeLocale = {
    ...de,
    options: {
      ...de.options,
      weekStartsOn: 1, // Start week on Monday
    },
  };

  // Registering custom locales
  registerLocale("en", customEnLocale);
  registerLocale("de", customDeLocale);
  const DateChange = (date) => {
    const currentDate = date;
    const startOfMonth = moment(currentDate).startOf("month").toDate();
    const endOfMonth = moment(currentDate).endOf("month").toDate();
    const startOfWeek = moment(currentDate).startOf("week").toDate();
    const endOfWeek = moment(currentDate).endOf("week").toDate();
    setCurrentDateValue(currentDate);
    props.onChange({
      viewMode: props.selected,
      currentDate: currentDate.toISOString(),
      startOfMonth: startOfMonth.toISOString(),
      endOfMonth: endOfMonth.toISOString(),
      startOfWeek: startOfWeek.toISOString(),
      endOfWeek: endOfWeek.toISOString(),
    });
  };
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => {
    let display = "";
    switch (props.selected) {
      case "month":
        display = moment(value).startOf("month").locale(selectedLanguage).format("D MMM") + " - " + moment(value).endOf("month").locale(selectedLanguage).format("D MMM");
        break;
      case "week":
        display = moment(value).startOf("week").locale(selectedLanguage).format("D MMM") + " - " + moment(value).endOf("week").locale(selectedLanguage).format("D MMM");
        break;
      case "day":
        display = moment(value).startOf("day").locale(selectedLanguage).format("D MMM ddd");
        break;
      default:
        display = moment(value).startOf("day").locale(selectedLanguage).format("D MMM");
        break;
    }
    return (
      <div className="custom-date-input" onClick={onClick} ref={ref}>
        <span>{display}</span>
      </div>
    );
  });
  const ArrowAction = (direction) => {
    let newDate;

    switch (props.selected) {
      case "month":
        newDate = moment(currentDateValue).add(direction, "month").toDate();
        break;
      case "week":
        newDate = moment(currentDateValue).add(direction, "week").toDate();
        break;
      case "day":
        newDate = moment(currentDateValue).add(direction, "day").toDate();
        break;
      default:
        break;
    }

    DateChange(newDate);
  };

  let userFriendlyDate = new Date(currentDateValue);
  useEffect(() => {
    if (i18n.language === "de") {
      setDefaultLocale("de");
    } else {
      setDefaultLocale("en");
    }
  }, [i18n.language]);
  return (
    <Filter1 theme={props.themeColors}>
      <div onClick={() => ArrowAction(-1)}>
        <GetIcon icon={"previous"}></GetIcon>
      </div>
      <GetIcon icon={"date"}></GetIcon>
      <InputContainer className={`${props.dynamicClass ?? ""}`}>
        <DatetimeInput
          placeholderText={`${t(props.label)}${props.required ? " *" : ""}`}
          minDate={props.minDate ? new Date(props.minDate) : null}
          maxDate={props.maxDate ? new Date(props.maxDate) : null}
          theme={themeColors}
          className={`input ${props.value.length > 0 ? "shrink" : ""}`}
          placeholder={t(props.placeholder)}
          type={props.type}
          selected={userFriendlyDate}
          customInput={<CustomDateInput />} // Set the custom input component
          onChange={(date) => DateChange(date)} // Call DateChange with the selected date
        />
      </InputContainer>
      <div onClick={() => ArrowAction(1)}>
        <GetIcon icon={"next"}></GetIcon>
      </div>
    </Filter1>
  );
}

export default DateSelector;
