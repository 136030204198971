import React, { useState } from "react";
import i18n from "i18next"; // i18n library for internationalization
import { LanguageContainer } from "../../styles/list/styles";
import { LanguageIcon } from "./styles";
function LanguageTooltip() {
  // State to store the selected language
  const [selectedLanguage, setSelectedLanguage] = useState(
    // If a language is stored in local storage, use it, otherwise use "de"
    localStorage.getItem("_lang") !== null ? localStorage.getItem("_lang") : "de"
  );

  // Function to handle language change
  const handleLanguageChange = (language) => {
    // Change the language in the i18n library
    i18n.changeLanguage(language);
    // Store the selected language in local storage
    localStorage.setItem("_lang", language);
    // Update the selectedLanguage state
    setSelectedLanguage(language);
    window.location.reload();
  };

  return (
    <LanguageContainer>
      <LanguageIcon className={selectedLanguage === "de" ? "active" : ""} onClick={() => handleLanguageChange("de")}>
        DE
      </LanguageIcon>
      <LanguageIcon className={selectedLanguage === "en" ? "active" : ""} onClick={() => handleLanguageChange("en")}>
        EN
      </LanguageIcon>
    </LanguageContainer>
  );
}

export default LanguageTooltip;
