import styled from "styled-components";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-top: 10px;
  &.fit {
    margin: auto 3px;
    max-width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    margin: auto 0px 0px;
    max-width: 100%;
    &.fit {
      margin: auto 0px 00px;
      max-width: 100%;
    }
  }
`;

export const TabHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  align-items: flex-end;
  height: 32px;
  box-shadow: rgb(0 0 0 / 6%) 0px 0px 6px 3px;
    width: fit-content;
    border-radius: 10px;
    margin-left:3px;
  @media (max-width: 768px) {
    overflow: auto;
  }
`;

export const Tab = styled.div`
  padding: 0px;
  display: none;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  &.tab-container {
    box-shadow: none;
  }
  min-height: calc(70vh);
  ${(props) =>
    props.active &&
    `
    display: flex;
  `}
`;

export const TabLink = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  flex: none;
  background-color:#f3f5f9;
  color: #a3a8a9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 20px;
  border-left: 1px solid #d7d7d76b;
  white-space: nowrap;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 0px solid #d7d7d76b;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 0px solid #d7d7d76b;
  }
  &.active {
    background-color: white;
    color: #4f4f4f;
    font-weight: 600;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;
