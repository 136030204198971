import { useTranslation } from "react-i18next";
import FormInput from "../input";
import { Content, Footer, Overlay, Page } from "./styles";
import { useEffect } from "react";

const Message = (props) => {
  const closeModal = () => {
    typeof props.meessage.onClose === "function" && props.meessage.onClose();
    props.closeMessage();
  };
  const { t } = useTranslation();
  useEffect(() => {
    if (props.meessage.type === 1) {
      const timer = setTimeout(() => {
        typeof props.meessage.onClose === "function" && props.meessage.onClose();
        props.closeMessage();
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [props]);
  return (
    <Overlay className={props.meessage.type === 1 ? "notification" : "confirmation"}>
      <Page className={props.meessage.type === 1 ? "notification" : "confirmation"}>
        <Content className={props.meessage.type === 1 ? "notification" : "confirmation"}>{props.meessage.content}</Content>
        <Footer className={props.meessage.type === 1 ? "notification" : "confirmation"}>
          <FormInput type="close" value={props.meessage.type === 2 ? t("cancel") : props.meessage.okay ? t(props.meessage.okay) : t("okay")} onChange={closeModal} />
          {props.meessage.type === 2 && (
            <FormInput
              type="submit"
              name="submit"
              value={props.meessage.proceed ? props.meessage.proceed : "Proceed"}
              onChange={() => {
                closeModal();
                try {
                  props.meessage.onProceed(props.meessage.data ?? "", props.meessage.data?._id ?? "");
                } catch (error) {
                  console.log("MessageBox", error);
                }
              }}
            />
          )}
        </Footer>
      </Page>
    </Overlay>
  );
};
export default Message;
