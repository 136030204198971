import styled from "styled-components";

// Style for the button container div
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin as needed */
  button {
    padding: 10px 10px;
    background-color: ${(props) => props.theme.themeBackground}; /* Button background color */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    align-items: center;
    display: flex;

    &:hover {
      transform: scale(1.05);
      transition: all 0.2s;
      background-color: ${(props) => props.theme.themeBackground}; /* Button background color on hover */
    }
  }
  button:first-child {
    svg {
      margin-right: 5px;
    }
  }
  button:last-child {
    svg {
      margin-right: 5px;
    }
  }
`;

// Define the style for the table
export const CalendarTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: -webkit-fill-available;
  tr {
    background-color: white;
  }

  td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    user-select: none;
    font-weight: bold;
    color: #5b5b5b;
    position: relative;
    min-height: 200px;
    height: 70px;
    min-width: 100px;
    width: 14.29%;
    &:hover {
      /* background-color: ${(props) => props.theme.themeBackground};
      color: #fff; */
      border: 1px solid;
    }
  }
  td.disabled {
    background-color: #f4f4f4;
  }
  td .dayNumber {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    border-radius: 0;
    border-bottom-left-radius: 50%;
    right: 5px;
    top: 5px;
    border-radius: 50%;
    background-color: #1a9dd9;
    color: white;
    width: 25px;
    height: 25px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  tbody {
    // Styles for the table body
  }

  th {
    padding: 8px;
    text-align: center;
    background-color: #eef4fe;
    border: 1px solid #ccc;
    font-weight: bold;
  }
`;
export const SchedulesBox = styled.span`
  display: flex;
  grid-row-gap: 3px;
  position: relative;
  height: auto;
  align-items: flex-start;
  justify-content:baseline;
  margin-bottom: 0;
  margin-top: auto;
  height: 100%;
  flex-direction:column;
  &.wrap {
    display: flex;
    column-gap: 5px;
    flex-flow: wrap;
  }
`;
export const Schedule = styled.button`
  white-space: nowrap;
  position: relative;
  background: white;
  border: 1px solid ${(props) => props.color};
  padding: 0px;
  padding-left: 15px;
  padding-right: 10px;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  width: -webkit-fill-available;
  span:first-child {
    margin-top: 3px;
    margin-bottom: 3px;
    font-weight: 600;
  }
  &:hover .action {
    display: flex;
  }
  span:first-child > span {
    color: white;
    background-color: red;
    padding: 2px 5px;
    border-radius: 12px;
  }
  span:last-child {
    font-size: 10px;
    margin-bottom: 3px;
  }
  &.roster span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  }
  &::after {
    background-color: ${(props) => props.color};
    color: ${(props) => props.color};
    content: "";
    border-radius: 8px 0px 0px 8px;
    width: 10px;
    position: absolute;
    height: 100%;
    left: 0;
    margin: 0px;
  }
  &.expired {
    opacity: 0.4;
  }
  &.active {
    background-color: grey;
    color: white;
  }
  &:hover {
    background-color: black;
    color: white;
  }
  &.selected {
    background-color: red !important;
    color: white !important;
  }
  &.marked {
    background-color: grey !important;
    color: white !important;
  }
`;
