import styled from "styled-components";
export const DateBox = styled.div`
  display: flex;
  position: relative;
`;

export const Filter1 = styled.div`
  background: transparent;
  font-size: 1em;
  outline: none;
  border: 0px solid #ddd;
  text-wrap: nowrap;
  cursor: pointer;
  height: 40px;
  margin-right: 10px;
  padding: 0 12px;
  background: ${(props) => props.theme.background};
  border-radius: 12px;
  cursor: pointer;
  font-size: 13.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:10px;
  &.dashboard{
    justify-content: space-between;
    padding:0;
  }
  > div {
    display: flex;
  }
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;
