import React, { useState, useEffect } from "react";
import FormInput from "../../input";
import { Footer, Form, Page, Overlay, ErrorMessage } from "./styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Header, Tab, TabContainer, TabHeader, TabLink } from "../manage/styles";
import { useDispatch, useSelector } from "react-redux";
import { CloseButton, TabItems, TabList, TabText } from "../popup/styles";
import { DwonlaodIcon, GetIcon } from "../../../../icons";
import { AddButton, ButtonPanel, FileButton, Filters, More, NoData, Table, Td, Tr } from "../styles";
import { RowContainer } from "../../../styles/containers/styles";
import * as xlsx from "xlsx";
import { bulkUploadData, getData } from "../../../../backend/api";
import { addSelectObject } from "../../../../store/actions/select";
const CrudForm = (props) => {
  // Use the useTranslation hook from react-i18next to handle translations
  const { t } = useTranslation();
  const { delPrivilege, setMessage, deleteHandler, updateId, setLoaderBox, currentApi } = props;
  // State to store the form input fields
  const [formState] = useState(props.formInput);

  // State to store the submit button's disabled status
  const [submitDisabled, setSubmitDisabled] = useState(true);

  // State to store the form values
  const [formValues, setFormValues] = useState(props.formValues);

  // State to store the validation messages
  const [formErrors, setFormErrors] = useState(props.formErrors);

  // State to store the validation messages
  const [errorCount, setErrorCount] = useState(0);

  // State to store the validation messages
  const [formBulkErrors, setBulkFormErrors] = useState([]);
  //theme colors
  const themeColors = useSelector((state) => state.themeColors);

  const { i18n } = useTranslation();

  const [selectedLanguage] = useState(i18n.language || "de");
  /**
   * fieldValidation is a callback function to validate a form field based on its properties
   *
   * @param {object} field - The field to be validated
   * @param {string} value - The value of the field
   *
   * @returns {number} flags - The number of validation errors for the field
   */

  const validation = (fields, udpatedValue, formErrors, agreement, useCheckbox, index = null) => {
    const tempformErrors = { ...formErrors };
    let flags = 0;
    fields.forEach((item) => {
      if (item.name !== "_id") {
        if (item.type === "multiple") {
          item.forms.forEach((form, multipleIndex) => {
            form.forEach((inputs, index) => {
              const res = fieldValidation(inputs, typeof udpatedValue[item.name][multipleIndex][inputs.name] === "undefined" ? "" : udpatedValue[item.name][multipleIndex][inputs.name], null, udpatedValue);
              tempformErrors[item.name][multipleIndex][inputs.name] = res.tempformError;
              flags += res.flag; //?res.flag:0;
            });
          });
        } else if (item.validation === "greater") {
          const res = fieldValidation(item, typeof udpatedValue[item.name] === "undefined" ? "" : udpatedValue[item.name], typeof udpatedValue[item.reference] === "undefined" ? new Date() : udpatedValue[item.reference], udpatedValue);
          tempformErrors[item.name] = res.tempformError;
          flags += res.flag; //?res.flag:0;
        } else {
          const res = fieldValidation(item, typeof udpatedValue[item.name] === "undefined" ? "" : udpatedValue[item.name], null, udpatedValue);
          tempformErrors[item.name] = res.tempformError;
          flags += res.flag; //?res.flag:0;
        }
      }
    });

    const agreementRes = agreementValidation(agreement, useCheckbox);
    tempformErrors["captchaError"] = agreementRes.tempformError;
    flags += agreementRes.flag; //?res.flag:0;
    if (index === null) {
      setFormErrors(tempformErrors);
    } else {
      //setSubmitDisabled((prev) => (flags > 0 ? true : index === 0 ? false : prev));
      //console.log("disable", flags);
      return { tempformErrors, flags };
    }

    setSubmitDisabled(flags > 0 ? true : false);
    if (flags === 0) {
      return true;
    } else {
      return false;
    }
  };

  const fieldValidation = (field, value, ref = new Date(), udpatedValue = {}) => {
    let flag = 0;
    let tempformError = "";

    if (!field.update && props.formType === "put") {
      return { flag, tempformError };
    }
    if (!field.add && props.formType === "post") {
      return { flag, tempformError };
    }
    if (field.condition) {
      if (udpatedValue[field.condition.item] === field.condition.if) {
        if (field.condition.then === "disabled") {
          return { flag, tempformError };
        }
      } else {
        if (field.condition.else === "disabled") {
          return { flag, tempformError };
        }
      }
    }
    if (!field.required && value.length === 0) {
      return { flag, tempformError };
    }

    switch (field.validation) {
      case "email":
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!regex.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "cvv":
        if (!/^[0-9]{3}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        } // German credit cards typically have a 3-digit CVV
        break;
      case "svnumber":
        if (!/^\d{8}[A-Za-z]\d{3}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        } // German credit cards typically have a 3-digit CVV
        break;
      case "iban":
        if (!/^[A-Za-z]{2}\d{0,32}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        } // German credit cards typically have a 3-digit CVV
        break;
      case "textonly":
        if (!/^[A-Za-z\s]+$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        } // German credit cards typically have a 3-digit CVV
        break;
      case "ccn":
        if (!/^[0-9]{16}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        let sum = 0;
        for (let i = 0; i < value.length; i++) {
          let digit = parseInt(value[i]);
          if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
              digit -= 9;
            }
          }
          sum += digit;
        }
        if (sum % 10 !== 0) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "expiry":
        if (!validateExpiry(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "fileNumber":
        const fileNumber = /[A-Z0-9-]/;
        if (!fileNumber.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "licensePlate":
        const german = /^[A-Z]{3}[ -]?[A-Z0-9]{2}[ -]?[A-Z0-9]{3,5}$/i;
        if (!german.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "greater":
        const referedDate = new Date(ref);
        if (new Date(value) < referedDate) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "amount":
        const amount = /^\d+([.,]\d{1,2})?$/;
        if (!amount.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "datetime":
        if (!moment(value).isValid()) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "time":
        const date = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        if (!date.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "text":
        break;
      default:
        break;
    }
    if (field.type === "image" || field.type === "file") {
      if (value.length === 0) {
        tempformError = t("validContent", { label: t(field.label) });
        flag += 1;
      }
    } else {
      if (field.required && value?.length === 0) {
        tempformError = t("required", { label: t(field.label) });
        flag += 1;
      } else if (field.minimum > value?.length) {
        tempformError = t("requiredMinimum", { minimum: field.minimum, label: t(field.label) });

        flag += 1;
      } else if (field.maximum < value?.length) {
        tempformError = t("maxLimit", { maximum: field.maximum, label: t(field.label) });
        flag += 1;
      }
    }
    return { flag, tempformError };
  };
  function validateExpiry(expiry) {
    let month = parseInt(expiry.substring(0, 2));
    let year = parseInt("20" + expiry.substring(3));
    let now = new Date();
    let currentYear = now.getFullYear();
    let currentMonth = now.getMonth() + 1; // JavaScript months are 0-11
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return false; // Expiry date is in the past
    }
    if (month < 1 || month > 12) {
      return false; // Invalid month
    }
    return true;
  }

  useEffect(() => {}, [formState]);

  const agreementValidation = (agreement, useCheckbox) => {
    let flag = 0;
    let tempformError = "";
    if (agreement !== true && useCheckbox === true) {
      tempformError = t("required", { label: t("agreement") });
      flag += 1;
    }
    return { flag, tempformError };
  };
  const handleBulkChange = (sl = 0, event, id, type = "text", sub = null, data = {}) => {
    data = jsonData[sl];
    console.log(data);
    const fullData = [...jsonData];
    const field = formState[id];
    let value = "";
    if (type === "checkbox") {
      value = event;
    } else if (type === "select") {
      value = event.id;
    } else if (type === "multiSelect") {
      const items = data[field.name];
      const index = items.findIndex((item) => item === event.id);

      if (index === -1) {
        // If event._id doesn't exist, push it to the items array
        items.push(event.id);
      } else {
        // If event._id already exists, remove it from the items array
        items.splice(index, 1);
      }
      value = items;
    } else if (field.validation === "licensePlate") {
      value = event.target.value
        .toString()
        .toUpperCase()
        .replace(/[^a-zA-Z0-9ÄÖÜß]/g, "");
    } else if (type === "text" || type === "number" || type === "password" || type === "color") {
      value = event.target.value;
    } else if (type === "search") {
      value = JSON.stringify(event);
    } else if (type === "image" || type === "file") {
      value = event.target.files;
    } else if (type === "datetime" || type === "time") {
      if (event) {
        value = event.toISOString();
      } else {
        value = "";
      }
    } else if (type === "date") {
      if (event) {
        event.setHours(14, 0, 0, 0); // Set time to 14:00 (2:00 PM)
        value = event.toISOString();
      } else {
        value = "";
      }
    } else {
      value = event.target.getAttribute("value");
    }

    const udpateValue = {
      ...data,
      [field.name]: value,
    };
    fullData[sl] = udpateValue;
    setJsonData(fullData);
    let isValidate = 0;
    const formErrorTemp = [...formBulkErrors];
    fullData.map((data, index) => {
      const errorItem = validation(formState, data, formErrorTemp[index], false, false, sl);
      formErrorTemp[index] = errorItem.tempformErrors;
      isValidate += errorItem.flags;
      return true;
    });
    setBulkFormErrors(formErrorTemp);
    setErrorCount(isValidate);
    if (isValidate > 0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
    return isValidate;
  };
  const handleChange = (sl = 0, event, id, type = "text", sub = null, data = {}) => {
    // Getting current field
    console.log(formValues);
    const field = formState[id];
    if (sub === null) {
      let value = "";
      if (type === "checkbox") {
        value = event;
      } else if (type === "select") {
        value = event.id;
      } else if (type === "multiSelect") {
        const items = formValues[field.name];
        const index = items.findIndex((item) => item === event.id);

        if (index === -1) {
          // If event._id doesn't exist, push it to the items array
          items.push(event.id);
        } else {
          // If event._id already exists, remove it from the items array
          items.splice(index, 1);
        }
        value = items;
      } else if (field.validation === "licensePlate") {
        value = event.target.value
          .toString()
          .toUpperCase()
          .replace(/[^a-zA-Z0-9ÄÖÜß]/g, "");
      } else if (type === "text" || type === "number" || type === "password" || type === "color") {
        value = event.target.value;
      } else if (type === "search") {
        value = JSON.stringify(event);
      } else if (type === "image" || type === "file") {
        value = event.target.files;
      } else if (type === "datetime" || type === "time") {
        if (event) {
          value = event.toISOString();
        } else {
          value = "";
        }
      } else if (type === "date") {
        if (event) {
          event.setHours(14, 0, 0, 0); // Set time to 14:00 (2:00 PM)
          value = event.toISOString();
        } else {
          value = "";
        }
      } else {
        value = event.target.getAttribute("value");
      }

      const udpateValue = {
        ...formValues,
        [field.name]: value,
      };
      // console.log(udpateValue);
      // Creating an updated field
      // updating the formm values
      setFormValues(udpateValue);
      console.log(udpateValue);
      // Validating the fields
      if (validation(formState, udpateValue, formErrors)) {
        // Here we can write any state updation
      }
    } else {
      const main = formState[sub.index];
      const field = main.forms[sub.multipleIndex][id];
      const udpateValue = { ...formValues };
      udpateValue[main.name][sub.multipleIndex][field.name] = event.target.value;
      setFormValues(udpateValue);
      // Validating the fields
      if (validation(formState, udpateValue, formErrors)) {
        // Here we can write any state updation
      }
    }
  };

  const submitChange = (event) => {
    event.preventDefault();
    if (props.bulkUpload) {
      const fullData = [...jsonData];
      const formErrorTemp = [...formBulkErrors];
      let isValidate = 0;
      fullData.map((data, index) => {
        const errorItem = validation(formState, data, formErrorTemp[index], false, false, index);
        formErrorTemp[index] = errorItem.tempformErrors;
        isValidate += errorItem.flags;
        return true;
      });
      setErrorCount(isValidate);
      if (isValidate > 0) {
        setSubmitDisabled(true);
      } else {
        props.submitHandler(fullData, formState);
      }
    } else {
      if (validation(formState, formValues, formErrors)) {
        props.submitHandler(formValues, formState);
      }
    }
  };
  const closeModal = () => {
    props.isOpenHandler(false);
  };
  const dispatch = useDispatch();
  const [groupedTabs] = useState(
    (props.formView ?? "") === "tabs"
      ? formState?.length > 0 &&
          formState.reduce((result, attribute, index) => {
            const tabName = attribute.tab;
            const existingTab = result.find((tab) => tab.tabName === tabName);
            const attributeTemp = { ...attribute, index };
            if (existingTab) {
              existingTab.tabItems.push(attributeTemp);
            } else {
              result.push({ tabName, tabItems: [attributeTemp] });
            }

            return result;
          }, [])
      : []
  );
  const [activeTab, setActiveTab] = useState(groupedTabs[0]?.tabName);

  // bulk uplaod format
  const [jsonData, setJsonData] = useState(null);
  const uploadData = async (event) => {
    setLoaderBox(true);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const errorData = [];
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        const selectData = {};

        const finalData = await Promise.all(
          json.map(async (item, itemIndex) => {
            const formErrorItem = {};
            const temp = {};
            let date = new Date();

            await Promise.all(
              formState.map(async (attribute) => {
                if (attribute.add) {
                  const itemValue = item[t(attribute.label)] ? item[t(attribute.label)] : item[t(attribute.label, { lng: selectedLanguage === "en" ? "de" : "en" })];
                  let val = "";

                  if (attribute.type === "checkbox") {
                    let bool = JSON.parse(attribute.default === "false" || attribute.default === "true" ? attribute.default : "false");
                    val = bool;
                  } else if (attribute.type === "multiSelect") {
                    val = [];
                  } else if (attribute.type === "text") {
                    val = "";
                  } else if (attribute.type === "datetime" || attribute.type === "date" || attribute.type === "time") {
                    if (attribute.default === "0") {
                      // Set hour to 00:00
                      date.setUTCHours(0, 0, 0, 0);
                      val = date.toISOString();
                    } else if (attribute.default === "1") {
                      // Set hour to 23:59
                      date.setUTCHours(23, 59, 0, 0);
                      val = date.toISOString();
                    } else {
                      // Set hour to current time
                      val = date.toISOString();
                    }
                    if (attribute.add) {
                      val = attribute.default === "empty" ? "" : date.toISOString();
                    }
                  } else if (attribute.type === "image" || attribute.type === "file") {
                    if (attribute.add) {
                      val = "";
                    }
                    val = "";
                  } else {
                    val = attribute.default ?? "";
                    if (attribute.type === "select") {
                      val = attribute.default ?? "";
                    }
                  }
                  temp[attribute.name] = val ?? "";
                  if (attribute.upload) {
                    temp[attribute.name] = itemValue ?? val ?? "";
                    formErrorItem[attribute.name] = "";
                    if (attribute.apiType === "API") {
                      if (!selectData[attribute.selectApi]) {
                        const response = await getData({}, `${attribute.selectApi}`);
                        selectData[attribute.selectApi] = response.data;
                        if (response.status === 200) {
                          dispatch(addSelectObject(response.data, attribute.selectApi));
                        }
                      }
                      const name = attribute.displayValue ? attribute.displayValue : attribute.showItem === "locale" ? selectedLanguage : "value";
                      const foundItem = selectData[attribute.selectApi].find((option) => option[name] === itemValue);
                      if (foundItem) {
                        temp[attribute.name] = foundItem.id;
                      }
                    }
                  }
                }
              })
            );
            errorData.push(formErrorItem);
            return temp;
          })
        );

        let isValidate = 0;
        finalData.forEach((data, index) => {
          const errorItem = validation(formState, data, errorData[index], false, false, index);
          errorData[index] = errorItem.tempformErrors;
          isValidate += errorItem.flags;
        });

        setErrorCount(isValidate);
        setSubmitDisabled(isValidate > 0);
        setBulkFormErrors(errorData);
        setJsonData(finalData);
        setLoaderBox(false);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const bulkUplaodFormat = () => {
    const excelData = [];

    const excelRow = {};
    formState.forEach((attribute) => {
      if (attribute.upload) {
        excelRow[t(attribute.label)] = "";
      }
    });
    excelData.push(excelRow);
    const worksheet = xlsx.utils.json_to_sheet(excelData);

    // Create workbook
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, t("report"));
    // Convert workbook to Excel binary and download the file
    xlsx.writeFile(workbook, t(props.shortName) + "-template.xlsx");
  };
  const bulkUploadHandler = async (event) => {
    setLoaderBox(true);
    await bulkUploadData({ data: JSON.stringify(jsonData) }, `${currentApi}/bulk-upload`)
      .then((response) => {
        if (response.status === 200) {
          setJsonData(response.data.alreadyExist);
          setMessage({ type: 1, content: t("bulkUploadMessage", { exist: response.data.alreadyExist.length, added: response.data.added.length }), proceed: "Okay" });
          if (response.data.alreadyExist.length === 0) {
            submitChange(event);
          }
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };
  // const profileImageLink = profileImage?.length > 2 && formValues["photo"] ? process.env.REACT_APP_CDN.toString() + formValues["photo"]?.toString() : `${process.env.REACT_APP_CDN.toString()}uploads/profile/profile.jpg`;
  return (
    <Overlay>
      <Page className={(props.formMode ?? " ") + " " + props.formView}>
        <Header>
          <span>{props.header ? props.header : "Login"}</span>
          <CloseButton theme={themeColors} onClick={closeModal}>
            <GetIcon icon={"Close"} />
          </CloseButton>
        </Header>
        {props.bulkUpload ? (
          <RowContainer className="list bulk">
            <ButtonPanel>
              <Filters></Filters>
              <AddButton onClick={() => bulkUplaodFormat()}>
                <DwonlaodIcon></DwonlaodIcon>
                <span>{t("Download Template")}</span>
              </AddButton>
              <FileButton type="file" accept=".xlsx, .xls" onChange={uploadData}></FileButton>
            </ButtonPanel>
            <RowContainer className=" bulk">
              {jsonData?.length > 0 ? (
                <Table>
                  {/* <thead>
                    <Tr>
                      {formState &&
                        formState.map((attribute, index) => {
                          return (attribute.upload ?? false) === true ? <Td key={props.shortName + attribute.name + index}>{t(attribute.label)}</Td> : null;
                        })}
                    </Tr>
                  </thead> */}
                  <tbody>
                    {jsonData?.map((data, rowIndex) => (
                      <Tr key={`${props.shortName}-${rowIndex}-${rowIndex}`} className={"no-border bulk"}>
                        {formState &&
                          formState.map((attribute, index) => {
                            const itemValue = data[attribute.name];
                            if (attribute.upload ?? false) {
                            } // ? data[t(attribute.label)] : data[t(attribute.label, { lng: selectedLanguage === "en" ? "de" : "en" })];
                            return (attribute.upload ?? false) === true ? (
                              <Td className="bulk" key={index}>
                                <div>
                                  <FormInput bulkUpload={true} formValues={formValues} updateValue={{}} dynamicClass={"textarea"} placeholder={attribute.placeholder} key={`input` + index} id={index} index={rowIndex} error={formErrors[attribute.name]} value={itemValue} {...attribute} onChange={handleBulkChange} />
                                  {formBulkErrors[rowIndex]?.[attribute.name] && <p>{formBulkErrors[rowIndex][attribute.name]}</p>}
                                </div>
                              </Td>
                            ) : null;
                          })}
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <NoData>No Data Uploaded!</NoData>
              )}
              {errorCount > 0 && <ErrorMessage style={{ marginTop: "10px" }}>{t("errorCount", { count: errorCount })}</ErrorMessage>}
            </RowContainer>
          </RowContainer>
        ) : (
          <React.Fragment>
            {(props.formView ?? "normal") === "normal" && (
              <Form className={props.formMode ?? ""}>
                {formState?.length > 0 &&
                  formState.map((item, index) => {
                    let dynamicClass = "";
                    if (item.condition) {
                      if (formValues[item.condition.item] === item.condition.if) {
                        dynamicClass = item.condition.then;
                      } else {
                        dynamicClass = item.condition.else;
                      }
                    }
                    return (props.formType === "put" && item.update) || (props.formType === "post" && item.add) || item.type === "title" || item.type === "info" ? <FormInput dynamicClass={dynamicClass} placeholder={item.placeHolder} key={`input` + index} id={index} error={formErrors[formState[index].name]} value={formValues[formState[index].name]} {...item} onChange={handleChange} /> : "";
                  })}
              </Form>
            )}

            {(props.formView ?? "") === "tabs" && groupedTabs && (
              <TabContainer className="vertical">
                <TabHeader className="vertical mh">
                  {/* <Profile>
                <img src={profileImageLink} alt="profile" />
                <h6>{`${currentData["firstName"]} ${currentData["lastName"]}`}</h6>
              </Profile> */}
                  <TabList>
                    {groupedTabs.map((group) => (
                      <React.Fragment key={group.tabName}>
                        {group.tabItems?.length > 0 && (
                          <TabLink
                            key={`main-` + group.tabName}
                            theme={themeColors}
                            className={activeTab === group.tabName && "active"}
                            onClick={() => {
                              setActiveTab(group.tabName);
                            }}
                          >
                            <TabText> {t(group.tabName)}</TabText>
                          </TabLink>
                        )}
                      </React.Fragment>
                    ))}
                  </TabList>
                </TabHeader>
                <TabItems>
                  {groupedTabs.map((group) => (
                    <Tab className="sub" key={`sub-` + group.tabName} active={activeTab === group.tabName}>
                      <Form className={`${props.formMode ?? ""} sub`}>
                        {group.tabItems?.length > 0 &&
                          group.tabItems.map((item) => {
                            let dynamicClass = "";
                            if (item.condition) {
                              if (formValues[item.condition.item] === item.condition.if) {
                                dynamicClass = item.condition.then;
                              } else {
                                dynamicClass = item.condition.else;
                              }
                            }

                            try {
                              let updateValue = {};
                              if (item.type === "select" || item.type === "multiSelect") {
                                if (Array.isArray(item.updateOn)) {
                                  updateValue = {};
                                  item.updateOn?.forEach((itemName) => {
                                    updateValue[itemName] = formValues[itemName];
                                  });
                                } else {
                                  updateValue = { [item.updateOn]: formValues[item.updateOn] };
                                }
                              }
                              if ((props.formType === "put" && item.update) || (props.formType === "post" && item.add) || item.type === "title" || item.type === "info") {
                                return <FormInput formValues={formValues} updateValue={updateValue} dynamicClass={dynamicClass} placeholder={item.placeholder} key={`input` + item.index} id={item.index} error={formErrors[item.name]} value={formValues[item.name]} {...item} onChange={handleChange} />;
                              } else {
                                return null;
                              }
                            } catch (error) {
                              console.error("Error rendering FormInput:", error);
                              return null;
                            }
                          })}
                      </Form>
                    </Tab>
                  ))}
                </TabItems>
              </TabContainer>
            )}
          </React.Fragment>
        )}

        <Footer>
          {delPrivilege && !(props.bulkUpload ?? false) && (
            <More
              key={`delete`}
              onClick={() => {
                setMessage({
                  type: 2,
                  content: t("deleteItem"),
                  proceed: t("delete"),
                  onProceed: deleteHandler ? deleteHandler : () => alert("not a function"),
                  data: { ...formValues, _id: updateId },
                });
              }}
              className="delete left"
            >
              <GetIcon icon={"delete"} />
            </More>
          )}
          <FormInput type="close" value={t("cancel")} onChange={closeModal} />
          <FormInput disabled={submitDisabled} type="submit" name="submit" value={props.button ? props.button : t("submit")} onChange={props.bulkUpload ? bulkUploadHandler : submitChange} />
        </Footer>
      </Page>
    </Overlay>
  );
};

export default CrudForm;
