import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const LeaveType = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "en",
      name: "en",
      validation: "",
      default: "",
      label: "en",
      showItem: "title",
      required: true,
      view: true,
      add: true,
      update: false,
    },
    {
      type: "text",
      placeholder: "de",
      name: "de",
      validation: "",
      default: "",
      label: "de",
      showItem: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "sun",
      name: "sun",
      validation: "",
      showItem: "",
      default: "true",
      label: "sun",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "mon",
      name: "mon",
      validation: "",
      showItem: "",
      default: "true",
      label: "mon",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "tue",
      name: "tue",
      validation: "",
      showItem: "",
      default: "true",
      label: "tue",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "wed",
      name: "wed",
      validation: "",
      showItem: "",
      default: "true",
      label: "wed",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "thu",
      name: "thu",
      validation: "",
      showItem: "",
      default: "true",
      label: "thu",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "fri",
      name: "fri",
      validation: "",
      showItem: "",
      default: "true",
      label: "fri",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "sat",
      name: "sat",
      validation: "",
      showItem: "",
      default: "true",
      label: "sat",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "default",
      name: "default",
      validation: "",
      showItem: "",
      default: "false",
      label: "default",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  //to update the page title
  useEffect(() => {
    document.title = `${t("workDays")} - VSH Security`;
  }, [t]);
  // const [actions] = useState([{ element: "button", icon: "timings", type: "subList", id: "shift-timing", itemTitle: "title", title: "timings", attributes: timingAttributes, params: { api: `timing`, itemTitle: "title", shortName: "Timing", addPrivilege: true, delPrivilege: true, updatePrivilege: true, customClass: "medium" } }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} api={`working-days`} itemTitle={`en`} shortName={`workDays`} addPrivilege={true} delPrivilege={false} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(LeaveType);
