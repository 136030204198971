import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Switch from "./switch";
import { useSelector } from "react-redux";
import Page404 from "../private/pages/page404";
import { Container, MainContainer, SideBar } from "../private/common/layout/styels";
import { ColumnContainer, RowContainer } from "../styles/containers/styles";
import Header from "../private/common/layout/header";
import Footer from "../private/common/layout/footer";
import Menu from "../private/common/layout/menu";
import Message from "../elements/message";
import Loader from "../elements/loader";
import { useCallback } from "react";
import { useState } from "react";
// import { useSelectionContainer } from "@air/react-drag-to-select";
const PageRouter = () => {
  const user = useSelector((state) => state.login);
  const menuStatus = useSelector((state) => state.menuStatus);
  const [meessage, setMessage] = useState({});
  // State to keep track of whether to show the message or not.
  const [showMessage, setShowMessage] = useState(false);
  // State to keep track of whether to show the loader or not.
  const [showLoader, setShowLoader] = useState(false);
  /**
   * Function to set the showLoader state.
   * @param {boolean} status The status of the loader.
   */
  const setLoaderBox = useCallback((status) => {
    setShowLoader(status);
  }, []);
  /**
   * Function to set the message to display.
   * @param {object} messageContent The content of the message.
   */
  const setMessageBox = (messageContent) => {
    setMessage(messageContent);
    setShowMessage(true);
  };
  /**
   * Function to close the message.
   */
  const closeMessage = () => {
    setMessage({ ...meessage, onClose: null });
    setShowMessage(false);
  };
  // const createRouter = (router, menu = true) => {
  //   const role = menu ? router.menuRoles[0] : router.subMenuRoles[0];
  //   return <Route key={`${router._id}`} path={`${router.path}`} element={<Switch addPrivilege={role.add ?? false} delPrivilege={role.delete ?? false} updatePrivilege={role.update ?? false} exportPrivilege={role.export ?? false} page={router.element} />} />;
  // };
  // const [selectedItems, setSelectedItems] = useState([]);
  // const { DragSelection, selectables } = useSelectionContainer({
  //   onSelect: (selection) => {
  //     console.log(selectedItems);
  //     setSelectedItems(selection.map((el) => el.id));
  //   },
  //   shouldStartSelecting: (target) => {
  //     // console.log(target);
  //     if (target instanceof HTMLElement) {
  //       let el = target;
  //       while (el.parentElement && !el.dataset.disableselect) {
  //         el = el.parentElement;
  //       }
  //       return el.dataset.disableselect !== "true";
  //     }
  //     return true;
  //   },
  // });
  const themeColors = useSelector((state) => state.themeColors);
  const storedValue = localStorage.getItem("small-menu");
  const [isSmallMenu, setIsSmallMenu] = useState(storedValue === "true" || storedValue === null ? true : false);
  const changeMenu = () => {
    setIsSmallMenu(!isSmallMenu);
    localStorage.setItem("small-menu", !isSmallMenu);
  };

  const createRouter = (router, menu = true) => {
    return <Route key={`${router._id}`} path={`${router.path}`} element={<Switch page={router.element} router={router} />} />;
  };
  return user.data.token ? (
    // BrowserRouter is used for client-side routing
    <BrowserRouter>
      <MainContainer className={`${isSmallMenu ? "small" : ""}`}>
        <Header changeMenu={changeMenu} setLoaderBox={setLoaderBox} setMessage={setMessageBox} user={user.data}></Header>
        <ColumnContainer className="main">
          <SideBar theme={themeColors} className={`${isSmallMenu ? "small" : ""} ${menuStatus && "active"}`}>
            <Menu changeMenu={changeMenu} setLoaderBox={setLoaderBox} setMessage={setMessageBox} user={user.data}></Menu>
            <Footer></Footer>
          </SideBar>
          <RowContainer className="content">
            <Container className="nopadding fixed down" theme={themeColors}>
              <Routes>
                <Route path="/" element={<Switch page="login" />} />
                {user?.data?.menu?.map((menu) => {
                  if (menu.submenus?.length > 0) {
                    return (
                      <React.Fragment key={menu._id}>
                        {createRouter(menu)}
                        {menu.submenus.map((submenu) => createRouter(submenu, false))}
                      </React.Fragment>
                    );
                  }
                  return createRouter(menu);
                })}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </Container>
          </RowContainer>
        </ColumnContainer>
        {showMessage && <Message meessage={meessage} closeMessage={closeMessage} setLoaderBox={setLoaderBox} showMessage={showMessage}></Message>}
        {showLoader && <Loader></Loader>}
      </MainContainer>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Switch page="login" />} />
        <Route path="/invitations/:id1/:id2" element={<Switch page="invitations" />} />
        <Route path="/sign-up" element={<Switch page="signup" />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

// Export PageRouter component as the default export of the module
export default PageRouter;
