import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import withLayout from "../../../common/layout";
import { Container } from "../../../common/layout/styels";
import { RowContainer } from "../../../../styles/containers/styles";
import moment from "moment";
import { ButtonContainer, CalendarTable, Schedule, SchedulesBox } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { GetIcon } from "../../../../../icons";
import { addPageObject } from "../../../../../store/actions/pages";
import {} from "../../attendance/schedules/styles";
import { timeFormat } from "../../../../functions/date";

const Schedules = () => {
  const [currentApi] = useState(`user/schedules`);
  const [t, i18n] = useTranslation();
  const [currentDate, setCurrentDate] = useState(moment().startOf("month"));
  const [calendar, setCalendar] = useState([]);
  const dispatch = useDispatch();
  const themeColors = useSelector((state) => state.themeColors);
  useEffect(() => {
    document.title = `${t("attendance")} - VSH Security`;
  }, [t]);
  const selectedLanguage = i18n.language || "de";
  const daysInMonth = currentDate.daysInMonth();
  const firstDayOfMonth = currentDate.startOf("month").day();
  const [loadedData, setLoadedData] = useState([]);
  const [initialized] = useState(true);
  //Loading Data
  const users = useSelector((state) =>
    state.pages[`${currentApi}`]
      ? state.pages[`${currentApi}`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject(currentApi, 0, 0));
    }
  }, [initialized, currentApi, dispatch]);
  // const [draggedDates, setDraggedDates] = useState([]);
  useEffect(() => {
    setLoadedData(users);
  }, [users]);
  const daysOrder = selectedLanguage === "de" ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  // Memoize the generateCalendar function with useCallback
  const generateCalendar = useCallback(() => {
    const calendarTemp = [];
    let day = 1;
    let blankDays = (firstDayOfMonth === 0 ? 7 : firstDayOfMonth) - 1;
    let week = [];
    console.log(blankDays);
    while (day <= daysInMonth) {
      if (blankDays >= 0 && blankDays < 6) {
        week.push(null);
        blankDays--;
      } else {
        week.push(currentDate.clone().date(day));
        day++;
      }
      if (week.length === 7 || day > daysInMonth) {
        calendarTemp.push(week);
        week = [];
      }
    }
    const lastWeekDays = 7 - calendarTemp[calendarTemp.length - 1].length;

    if (lastWeekDays > 0) {
      for (let i = 0; i < lastWeekDays; i++) {
        calendarTemp[calendarTemp.length - 1].push(null);
      }
    }

    setCalendar(calendarTemp);
  }, [currentDate, daysInMonth, firstDayOfMonth]);

  useEffect(() => {
    generateCalendar();
  }, [generateCalendar]);

  const handlePrevMonth = () => {
    const prevMonth = currentDate.clone().subtract(1, "month");
    setCurrentDate(prevMonth);
    dispatch(addPageObject(currentApi, 0, 0, { date: prevMonth.toDate() }));
  };

  const handleNextMonth = () => {
    const nextMonth = currentDate.clone().add(1, "month");
    setCurrentDate(nextMonth);
    dispatch(addPageObject(currentApi, 0, 0, { date: nextMonth.toDate() }));
  };

  const renderCalendarHeader = () => (
    <tr>
      {daysOrder.map((day) => (
        <th key={day}>{t(day)}</th>
      ))}
    </tr>
  );
  console.log(loadedData);
  return (
    <Container className="noshadow">
      <RowContainer className="custom">
        <ButtonContainer theme={themeColors}>
          <button onClick={handlePrevMonth}>
            <GetIcon icon={"previous"}></GetIcon>
            {t("Previous Month")}
          </button>
          <button onClick={handleNextMonth}>
            {t("Next Month")}
            <GetIcon icon={"next"}></GetIcon>
          </button>
        </ButtonContainer>
        <CalendarTable theme={themeColors}>
          <thead>
            <tr>
              <th colSpan="7">{`${t(`month-${currentDate.format("M")}`)} ${currentDate.format("YYYY")}`}</th>
            </tr>
            {renderCalendarHeader()}
          </thead>
          <tbody>
            {calendar.map((week, weekIndex) => (
              <tr key={weekIndex}>
                {week.map((day, dayIndex) => (
                  <td className={day ? "enable" : "disabled"} key={dayIndex}>
                    {day ? (
                      <>
                        <span className="dayNumber">{day.clone().format("D")}</span>
                        <SchedulesBox key={dayIndex}>
                          {loadedData.data?.schedules
                            ?.filter((item) => moment(item.startTime).format("D") === day.clone().format("D"))
                            .map((schedule, index) => (
                              <Schedule key={index} color={schedule.shiftTemplate?.color ?? "black"}>
                                <span>{schedule.shiftTemplate?.shortForm ? schedule.shiftTemplate?.shortForm + " " + schedule.shiftTemplate?.title : schedule.title}</span>
                                <span>{`${timeFormat(schedule.startTime)} - ${timeFormat(schedule.endTime)} `}</span>
                              </Schedule>
                            ))}
                          {loadedData.data?.absense
                            ?.filter((item) => moment(item.date).format("D") === day.clone().format("D"))
                            .map((absense, index) => (
                              <Schedule key={index} color={absense.leaveType?.color ?? "black"}>
                                <span>{absense.leaveType?.[selectedLanguage]}</span>
                                <span>{absense.note}</span>
                              </Schedule>
                            ))}
                          {loadedData.data?.holidays[day.clone().format("YYYY-MM-DD")] && (
                            <Schedule key={0} color={"red"}>
                              <span>{t("holiday")}</span>
                              <span>{loadedData.data?.holidays[day.clone().format("YYYY-MM-DD")]}</span>
                            </Schedule>
                          )}
                          {/* {loadedData.data?.workDays[day.clone().locale("en").format("ddd").toLowerCase()] === false && (
                            <Schedule key={0} color={"red"}>
                              <span>{t("holiday")}</span>
                              <span>{t("weekend")}</span>
                            </Schedule>
                          )} */}
                        </SchedulesBox>
                      </>
                    ) : // Render nothing for days without data
                    null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </CalendarTable>
      </RowContainer>
    </Container>
  );
};

export default withLayout(Schedules);
