import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { postData } from "../../../backend/api";
import AutoForm from "../../elements/form";
import Footer from "../footer";
import { FormContainer, MainContainer } from "./styles";
//using translation
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "../layout";
import { ColumnContainer } from "../../styles/containers/styles";
import Header from "../header";
import { clearLogin } from "../../../store/actions/login";
import { postData } from "../../../backend/api";
import { SignUpLink } from "../login/styles";

const Registration = (props) => {
  useEffect(() => {
    document.title = `Time Recording Tool`;
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login);
  const { t } = useTranslation();
  const [formInput] = useState([
    {
      type: "text",
      placeholder: "firstName",
      name: "firstName",
      tab: "generalInfo",
      validation: "textonly",
      default: "",
      label: "firstName",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "lastName",
      name: "lastName",
      tab: "generalInfo",
      validation: "textonly",
      default: "",
      label: "lastName",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "organization",
      name: "organization",
      validation: "",
      default: "",
      label: "organization",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "mobileNumber",
      name: "mobileNumber",
      validation: "mobile",
      default: "",
      label: "mobileNumber",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "email",
      name: "email",
      validation: "email",
      default: "",
      label: "email",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "password",
      name: "password",
      validation: "password",
      default: "",
      label: "password",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    // {
    //   type: "number",
    //   placeholder: "mobileNumber",
    //   name: "mobileNumber",
    //   validation: "mobile",
    //   default: "",
    //   label: "mobileNumber",
    //   required: false,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
    // {
    //   type: "text",
    //   placeholder: "role",
    //   name: "role",
    //   validation: "",
    //   default: "",
    //   label: "role",
    //   required: false,
    //   view: false,
    //   add: true,
    //   update: true,
    // },
    // {
    //   type: "textarea",
    //   placeholder: "address",
    //   name: "address",
    //   validation: "",
    //   default: "",
    //   label: "address",
    //   required: true,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
    // {
    //   type: "text",
    //   placeholder: "city",
    //   name: "city",
    //   validation: "",
    //   default: "",
    //   label: "city",
    //   required: true,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
    // {
    //   type: "text",
    //   placeholder: "postalCode",
    //   name: "postalCode",
    //   validation: "",
    //   default: "",
    //   label: "postalCode",
    //   required: true,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
    // {
    //   type: "text",
    //   placeholder: "industry",
    //   name: "industry",
    //   validation: "",
    //   default: "",
    //   label: "industry",
    //   required: false,
    //   view: false,
    //   add: true,
    //   update: true,
    // },

    // {
    //   type: "number",
    //   placeholder: "numberOfSchedule",
    //   name: "numberOfSchedule",
    //   validation: "",
    //   default: "",
    //   label: "numberOfSchedule",
    //   required: false,
    //   view: false,
    //   add: true,
    //   update: true,
    // },
    // {
    //   type: "select",
    //   apiType: "API",
    //   selectApi: "user/countries",
    //   showItem: "locale",
    //   placeholder: "country",
    //   name: "country",
    //   search: true,
    //   validation: "",
    //   default: "",
    //   label: "country",
    //   required: false,
    //   filter: false,
    //   view: false,
    //   tag: true,
    //   add: true,
    //   update: true,
    // },
  ]);
  const { setLoaderBox } = props;
  useEffect(() => {
    if (user.data?.token) {
      navigate("/dashboard");
    }
    // setLoaderBox(user.isLoading);
    if (user.error !== null) {
      props.setMessage({ type: 1, content: t(user.error), proceed: "Okay" });
      dispatch(clearLogin());
    }
  }, [user, navigate, setLoaderBox, t, props, dispatch]);

  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    //call set password with
    props.setLoaderBox(true);
    await postData({ ...post }, "company/signup")
      .then((response) => {
        if (response.status === 200) {
          props.setMessage({
            type: 1,
            content: t("registered"),
            okay: "Login",
            onClose: () => {
              navigate("/");
            },
          });
          props.setLoaderBox(false);
        } else if (response.status === 201) {
          props.setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          props.setLoaderBox(false);
        } else {
          props.setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          props.setLoaderBox(false);
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        setLoaderBox(false);
      });
  };

  return (
    <MainContainer
      style={{
        backgroundImage: `linear-gradient(to right,  #f1f6f8 30%, transparent)`,
        backgroundSize: "cover",
      }}
    >
      <Header> </Header>
      <ColumnContainer className="login">
        <FormContainer>
          {formInput?.length > 0 && <AutoForm key={"form"} formMode={"double"} useCaptcha={false} formType={"post"} description={t("signupdesc")} header={t("signup")} formInput={formInput} submitHandler={submitChange} button={t("create")} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={true}></AutoForm>}
          <SignUpLink>
            {t('Already have an account?')} <Link to="/">{t('Log in')}</Link>
          </SignUpLink>
        </FormContainer>
      </ColumnContainer>
      <Footer></Footer>
    </MainContainer>
  );
};

export default withLayout(Registration);
