import React from "react";
import styled from "styled-components";
import { GetIcon } from "../../../icons";

const CheckboxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CheckboxCheckmark = styled.span`
  position: relative;
  display: flex;
  width: 24px;
  height: 24px;
  border: 2px solid #ccc;
  border-radius: 3px;
  margin-right: 8px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => (props.checked ? props.theme.background : "transparent")};
  color: ${(props) => (props.checked ? props.theme.checked : "transparent")};
  &.medium {
    width: 15px;
    height: 15px;
    svg {
      font-size: 12px;
    }
  }
`;
const CheckboxLabel = styled.label`
  font-size: 16px;
  color: #333;
  cursor: pointer;
  display: flex;
    align-items: center;
`;

const Checkbox = ({ label, checked, onChange, theme, className }) => {
  return (
    <CheckboxWrapper>
      <CheckboxLabel>
        <CheckboxInput type="checkbox" checked={checked} onChange={onChange} />
        <CheckboxCheckmark className={className} theme={theme} checked={checked}>
          {checked && <GetIcon icon={"checked"} />}
        </CheckboxCheckmark>
        {label}
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default Checkbox;
