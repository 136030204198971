import styled from "styled-components";

export const Img = styled.img`
  height: 50px;
  object-fit: cover;
  width: 100px;
`;
export const IconBox = styled.span`
  padding-top: 5px;
  
  &.display {
    padding-top: 0px;
    border: 1px solid;
    display: flex;
    height: 20px;
    width: 20px;
    border-radius:4px;
    justify-content: center;
    align-items: center;
  }
`;
