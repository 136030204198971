import React, { useEffect, useRef, useState } from "react";
import { logo } from "../../../../../images";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container, Logo, LogoContaner, MNav, Profile, Status, Title } from "./styels";
import { menuStatus } from "../../../../../store/actions/common";
import { CloseIcon, GetIcon, MenuIcon } from "../../../../../icons";
import { generateThumbnail } from "../../../../functions/string";
import ProfileBar from "../profile";
import LanguageTooltip from "../../../../elements/tooltip";
// import LanguageTooltip from "../../../../elements/tooltip";
// import { clearLogin } from "../../../../../store/actions/login";
const Header = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const menuCurrentStatus = useSelector((state) => state.menuStatus);
  const selectedMenu = useSelector((state) => state.selectedMenu);

  const [isProfileBarOpen, setIsProfileBarOpen] = useState(false);
  const profileRef = useRef(null);

  // Function to handle clicks outside of the Profile component
  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setIsProfileBarOpen(false);
    }
  };

  // Add a click event listener when the component mounts
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle the ProfileBar when clicking the Profile
  const handleProfileClick = () => {
    setIsProfileBarOpen(!isProfileBarOpen);
  };
  // const navigate = useNavigate();
  return (
    <Container className="fixed menu">
      <LogoContaner
        onClick={() => {
          props.changeMenu();
        }}
      >
        <MenuIcon>
          <GetIcon icon={"menu"} />
        </MenuIcon>
        <MNav
          onClick={() => {
            dispatch(menuStatus(!menuCurrentStatus));
          }}
        >
          {menuCurrentStatus ? <CloseIcon /> : <MenuIcon />}
        </MNav>
        <Logo src={logo} alt="logo" />
      </LogoContaner>
      <Status>
        <Title className="hm hide">
          <GetIcon icon={selectedMenu.icon}></GetIcon>
          {t(selectedMenu.label)}
        </Title>
        {/* <User className="hm">{props.user.user.fullName}</User> */}
        {/* <Logout
          onClick={() => {
            dispatch(clearLogin());
            navigate("/");
          }}  
        >
          <LogoutIcon />
          {t("logout")}
        </Logout>
         */}
        <LanguageTooltip></LanguageTooltip>
        <Profile ref={profileRef} onClick={handleProfileClick}>
          {generateThumbnail(props.user.user?.fullName ?? "", null, props.user.user.photo ?? "")}
          {isProfileBarOpen && (
            <div className="ProfileBar" onClick={(e) => e.stopPropagation()}>
              <ProfileBar setLoaderBox={props.setLoaderBox} setMessage={props.setMessage} data={props.user}></ProfileBar>
            </div>
          )}
        </Profile>
      </Status>
    </Container>
  );
};

export default Header;
