import styled from "styled-components";
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 0 0;
  font-weight: 600;
  thead:first-child {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  tbody.pinned {
    position: sticky;
    top: 42px;
    background: #fffdc4;
    z-index: 999;
  }
  && {
    .timesheet & {
      thead:first-child {
        position: inherit;
      }
    }
  }
  &.auto {
    width: auto;
  }
  &&.plain {
    tr {
      border: 1px solid;
    }
  }
`;
export const ActionBox = styled.div`
  display: flex;
  justify-content: right;
  overflow-wrap: normal;
  white-space: nowrap;
`;

export const Th = styled.th`
  text-align: left;
  padding: 15px 8px 10px;
  background-color: white;
  white-space: nowrap;
  color: #000000;
  font-weight: 600;
  border-radius: 0px;
  position: relative;
  border-left: 1px solid #c8e0de;

  &:first-child {
    border-left: 0px solid #cfcfcf;
  }
  &.name {
    text-align: left;
    position: sticky;
    left: 0;
    z-index: 1;
  }
  &.day {
    border-left: 1px solid #cfcfcf;
  }
  &.total {
    text-align: left;
    position: sticky;
    right: 0;
    z-index: 1;
    width: 100px;
  }
  .small {
    font-size: 10px;
    padding: 5px 0px 0px 30px;
  }
  :first-child {
    border-top-left-radius: 12px;
  }
  :last-child {
    border-top-right-radius: 12px;
  }
  &.secondHead {
    border-radius: 0;
  }
  &.center {
    text-align: center;
  }
  && {
    .plain & {
      background-color: transparent;
    }
    .no-border & {
      border-left: 0px solid #cfcfcf;
    }
  }

  &.actions {
    display: flex;
    justify-content: right;
    padding-right: 20px;
  }
  &.marked {
    background-color: lightgray;
  }
`;
const adjustColorOpacity = (color, opacity = 0.2, blendColor = 2000) => {
  const defaultColor = "#dfdfdf";
  color = color || defaultColor;

  const hex = color.replace("#", "");
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  const blend = (channel, factor) => Math.round(channel + (blendColor - channel) * factor);

  const lightenedR = blend(r, opacity);
  const lightenedG = blend(g, opacity);
  const lightenedB = blend(b, opacity);

  return `rgb(${lightenedR}, ${lightenedG}, ${lightenedB})`;
};

export const Tr = styled.tr`
  border-bottom: 1px solid #dfdfdf;
  &.bulk {
    border-bottom: 1px solid #eef4fe;
  }
  /* border-top: 1px solid #dfdfdf; */
  &.project {
    border-bottom: 1.5px solid ${(props) => props.color ?? "#dfdfdf"};
    th {
      background: ${(props) => adjustColorOpacity(props.color, 0.1) ?? "#dfdfdf"};
    }
  }
  /* &.pinned {
    border: 2px solid black;
    box-shadow: 0 0 0 1px #dfdfdf;
  } */
  &.head {
    position: sticky;
    top: -1px;
    z-index: 999;
  }
  ${(props) =>
    props.isDragOver &&
    `td{
    background-color: #e0e0e0;}
  `}
  &.secondHead th {
    /* padding: 5px 5px 5px; */
  }
  &:hover {
    background-color: white;
    border-radius: 12px;
  }
  && {
    .timesheet & {
      &:hover {
        background-color: initial;
        border-radius: 12px;
      }
    }
  }
`;

export const Td = styled.td`
  text-align: left;
  padding: 8px;
  height: 30px;
  &.bulk {
    padding: 5px 2px;
    border: 0;
    min-height: 40px;
    min-width: 150px;
    > div {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      height: 100%;
      p {
        font-size: 12px;
        color: red;
      }
    }
  }
  position: relative;
  min-width: auto;
  cursor: pointer;

  background: white;
  color: #5d6365;
  border-left: 1px solid #eef4fe;
  &:first-child {
    border-left: 0px solid #cfcfcf;
  }
  && {
    .no-border & {
      border-left: 0px solid #cfcfcf;
    }
  }

  &.month {
    min-width: 120px;
  }

  &.no,
  &.name {
    white-space: nowrap;
  
  }
  &.dragged .draghover {
    display: flex;
  }
  &:-moz-drag-over .drag {
    display: flex;
  }
  .plus-action {
    display: none;
  }
  &:hover .plus-action {
    display: flex;
  }
  &.name > span {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 6px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
  }
  &.Confirmed {
    color: green;
  }
  &.Pending {
    color: orange;
  }
  &.Rejected {
    color: red;
  }
  > svg {
    font-size: 20px;
  }
  &.name {
    position: sticky;
    left: -1px;
    max-width: 200px;
    z-index: 1;
    background: #f3f8fb;
    box-shadow: 0 0 0 1px #dfdfdf;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px;
  }
  &.total {
    text-align: left;
    position: sticky;
    right: 0;
    z-index: 1;
    background: #f3f8fb;
    box-shadow: 0 0 0 1px #dfdfdf;
  }
  &.nowrap {
    flex-direction: row;
    display: flex;
    height: auto;
    column-gap: 10px;
  }
  &.marked {
    background-color: lightgray;
  }
  @media (max-width: 768px) {
    &.name {
      white-space: initial;
    }
  }

  &.has {
    border: 1px solid #dfdfdf;
    cursor: pointer;
    padding: 0px;
  }
  &.no {
    padding: 0px;
  }
  &.no,
  &.has {
    text-align: center;
  }
  &.no svg {
    fill: grey;
  }
  &.name {
    text-overflow: "no-wrap";
  }
  &.actions {
    position: sticky;
    right: 1px;
    z-index: 1;
  }
  &.right {
    text-align: right;
  }
`;

export const Button = styled.button`
  color: #fff;
  border: none;
  padding: 6px 12px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 0px;
  white-space: nowrap;
  margin-bottom: 1px;
  text-align: left;
  &.add {
    background-color: #4caf50;
  }
  &.edit {
    background-color: gray;
  }
  &.delete {
    background-color: red;
  }
  &:hover {
    transform: scale(1);
    transition: 0.2s ease-in-out;
  }
  svg {
    fill: white;
    margin-right: 10px;
    height: 0.9em;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    span {
      display: initial;
    }
    svg {
      margin-left: 0px;
    }
  }
`;
export const Count = styled.div`
  padding: 0.5em;
  margin-left: auto;
  text-align: right;
  padding: 0.5em;
  text-align: right;
  height: 25px;
  justify-content: flex-end;
  display: flex;
  button:nth-child(2) {
    margin-left: 1em;
  }
  @media (max-width: 768px) {
    margin-left: inherit;
  }
`;
export const ArrowButton = styled.button`
  background: transparent;
  padding: 0em;
  margin-right: 0.5em;
  outline: none;
  border: 0px solid #ddd;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
`;
export const ButtonPanel = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  svg {
    background-color: transparent;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: left;
    align-items: baseline;
    overflow: auto;
    height: -webkit-fill-available;
    margin-top: 0;
    margin-bottom: 0;
    && {
      .tab & {
        height: -webkit-fill-available;
        margin: 10px 0;
      }
      .sub-page & {
        height: -webkit-fill-available;
        margin: 0px 0;
        position: absolute;
      }
    }
  }
`;
export const AddButton = styled.button`
  background: #ddebf6;
  color: #0091d6;
  padding: 4px 5px;
  border-radius: 10px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  padding: 0.5em 1em;
  margin-right: 0em;
  outline: none;
  height: 40px;
  border: 0px solid #ddd;
  cursor: pointer;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.red {
    background-color: red;
    color: white;
  }
  &.green {
    background-color: green;
    color: white;
  }
  &.orange {
    background-color: orange;
    color: white;
  }
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
    transform: scale(1.03);
  }
  & > svg {
    margin-right: 10px;
  }
  &.more > svg {
    margin-right: 0px;
  }
  &.more {
    position: relative;
  }
  &.more:hover div {
    display: flex !important;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 2005;
    white-space: nowrap;
    background-color: #f3f8fb;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    white-space: nowrap;
    span {
      display: none;
    }
    svg {
      margin-right: 5px;
    }
  }
`;
export const FileButton = styled.input`
  background: #ddebf6;
  color: #0091d6;
  padding: 4px 5px;
  border-radius: 10px;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  padding-top: 10px;
  margin-right: 0em;
  outline: none;
  height: 30px;
  border: 0px solid #ddd;
  cursor: pointer;
  margin-left: 10px;
  outline: none;
  height: 27px;
  border: 0px solid #ddd;
  cursor: pointer;
  margin-left: 10px;
  &.red {
    background-color: red;
    color: white;
  }
  &.green {
    background-color: green;
    color: white;
  }
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
    transform: scale(1.03);
  }
  & > svg {
    margin-right: 10px;
  }
  &.more > svg {
    margin-right: 0px;
  }
  &.more {
    position: relative;
  }
  &.more:hover div {
    display: flex !important;
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 2005;
    white-space: nowrap;
    background-color: #f3f8fb;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    white-space: nowrap;
    span {
      display: none;
    }
    svg {
      margin-right: 5px;
    }
  }
`;
export const FilterBox = styled.div`
  flex-direction: row;
  display: flex;
`;
export const Filter = styled.button`
  background: transparent;
  padding: 0 0.5em;
  font-size: 1em;
  margin-right: 0.5em;
  outline: none;
  border: 0px solid #ddd;
  border: 0px solid #ddd;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  padding: 12px;
  background: ${(props) => props.theme.background};
  border-radius: 50%;
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
`;
export const Filters = styled.div`
  margin-right: auto;
  display: flex;
  max-width: 80%;
  &.right {
    margin-left: auto;
    margin-right: 0;
  }
  &.center {
    margin-left: auto;
    margin-right: auto;
  }
  &.full {
    max-width: 100%;
    width: 100%;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    max-width: inherit;
    margin-left: 0;
    margin-right: auto;
    &.full {
      background: ${(props) => props.theme.background};
      z-index: 1001;
      max-width: 100%;
      width: 100%;
      margin-top: 5px;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      border-top: 1px solid white;
    }
    && {
      .sub-page & {
        background-color: white;
      }
    }
  }
`;
export const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
  margin-right: 10px;
  &.list {
    margin-left: 10px;
    margin-right: 0;
  }
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 25px;
  cu span {
    box-shadow: 0 0 1px #2196f3;
  }
`;
export const NoData = styled.div`
  padding: 10px;
  border-bottom: 1px solid white;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100px;
  &.cover {
    display: flex;
    flex: 1;
    height: -webkit-fill-available;
  }
  svg {
    margin-right: 10px;
  }
`;
export const ScrollContainerLayout = styled.div`
  padding: 1em;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 0em;
`;
export const ScrollLayout = styled.div`
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
  width: calc(100% - 0em);
  overflow: auto;
  height: 100vh;
  padding-bottom: 1em;
  flex-direction: column;
  min-height: calc(100vh - 17em);
  max-height: calc(100vh - 17em);
  .sub-page & {
    position: inherit;
    height: auto;
    flex-direction: column;
    min-height: 250px;
    max-height: calc(100vh - 12em);
  }
  .popup-child & {
    position: inherit;
    height: auto;
    flex-direction: column;
    min-height: 250px;
    max-height: calc(100vh - 20em);
  }
  .profile & {
    position: inherit;
    height: auto;
    flex-direction: column;
    min-height: 250px;
    max-height: calc(100vh - 20em);
  }
  && {
    .tab & {
      min-height: calc(100vh - 20em);
      max-height: calc(100vh - 20em);
    }
  }
  @media screen and (max-width: 768px) {
    min-height: calc(100% - 12em);
    max-height: calc(100% - 12em);
    min-height: inherit;
    position: fixed;
    top: 160px;
    left: 20px;
    right: 20px;
    width: auto;
    overflow: auto;
    && {
      .tab & {
        position: absolute;
        margin-top: 20px;
        height: calc(100% - 190px);
      }
      .sub-page & {
        position: inherit;
        height: auto;
        flex-direction: column;
        min-height: auto;
        max-height: calc(100vh - 12em);
        left: 0;
        top: 0;
        margin-top: 50px;
      }
    }
  }
`;
export const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7f2f9;
  transition: 0.4s;
  box-shadow: 0 0 1px #2196f3;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    background-color: ${({ disable }) => disable || "red"};
  }

  ${ToggleInput}:checked + &::before {
    transform: translateX(18px);
    background-color: ${({ enable }) => enable || "green"};
  }
`;
export const ToggleSwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  &.list {
    margin-left: 10px;
    margin-right: 0;
  }
`;
export const ToggleSwitch = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s;
  box-shadow: 0 0 1px #00740a;
  border-radius: 10px;
  color: ${({ disable, enable, checked }) => (checked ? (enable ? enable : "green") : disable ? disable : "red")};
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: ${({ checked }) => (checked ? "20px" : "18px")};
  svg {
    left: 4px;
    bottom: 3px;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: column;
  border-radius: 10px;
  div {
    margin: 0px 0px 5px 0;
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
  }

  div:last-child {
    margin-right: 0px;
  }
  div.active {
    margin-right: 10px;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
  }
  @media screen and (max-width: 768px) {
    div {
      color: black;
    }
    div.active {
      color: #198ad6;
    }
  }
`;
export const More = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &.delete.left {
    position: absolute;
    left: 0;
    border-radius: 50%;
    background: red;
    color: white;
    top: auto;
    bottom: 0;
    margin: auto;
    padding: 1px;
    border: 1px solid;
  }
  &.active,
  &:hover {
    color: white;
    background-color: #1a9dd9;
  }
`;
export const IconBox = styled.span`
  padding-top: 5px;
  &.display {
    padding-top: 0px;
    border: 1px solid;
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
  }
`;
export const Img = styled.img`
  height: 50px;
  object-fit: cover;
  width: 100px;
`;
export const Status = styled.span`
  background-color: ${({ color }) => color || "green"};
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
`;
export const GroupHeader = styled.div`
  color: black;
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  &.Confirmed {
    color: green;
  }
  &.Pending {
    color: orange;
  }
  &.Rejected {
    color: red;
  }
  > svg {
  }
`;
