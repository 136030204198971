import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const Department = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  //to update the page title
  useEffect(() => {
    document.title = `${t("department")} - VSH Security`;
  }, [t]);
  const [designation] = useState([
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);
  const [actions] = useState([{ element: "button", type: "subList", id: "designation", itemTitle: "title", icon: "designation", title: "designation", attributes: designation, params: { api: `designation`, itemTitle: "title", shortName: "designation", addPrivilege: true, delPrivilege: true, updatePrivilege: true, datefilter: false, customClass: "medium" } }]);

  return (
    <Container className="noshadow">
      <ListTable {...props} actions={actions} api={`department`} itemTitle={`title`} shortName={`department`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes} ></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Department);
