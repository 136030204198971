import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { addPageObject, addPageObjectOffline } from "../../../../store/actions/pages";
import withLayout from "../../common/layout";
import { AddButton, BoxDiv, CalendarBox, CalendarTable, Count, DashboardSection, DayCell, DropTarget, EmployeeCell, EmployeeCellTD, IconWrapper, SettingsBox, Tile, Title, TitleBox } from "./styles";
import { GetIcon } from "../../../../icons";
import { useTranslation } from "react-i18next";
import { convertMinutesToHHMM } from "../../../functions/dataFormat";
import { Container } from "../../common/layout/styels";
import moment from "moment";
import { generateThumbnail } from "../../../functions/string";
import { Filter1 } from "../../../elements/dateselector/styles";
import { NoData } from "../../../elements/list/styles";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { getData, postData } from "../../../../backend/api";
import Checkbox from "../../../elements/checkbox";
import PopupView from "../../../elements/popupview";
import { dateFormat } from "../../../functions/date";
import i18n from "i18next";
const Dashboard = (props) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const themeColors = useSelector((state) => state.themeColors);
  const [initialized, setInitialized] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [organisedData, setOrganisedData] = useState({});
  const [dashboardDefault, setDashboardDefault] = useState([]);
  const [leavesData, setLeavesData] = useState({});
  const [currentDate, setCurrentDate] = useState(moment());
  const dashboard = useSelector((state) =>
    state.pages[`dashboard`]
      ? state.pages[`dashboard`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  const [selectedLanguage] = useState(i18n.language || "de");
  const dashboardOrder = useSelector((state) => (state.pages[`dashboardOrder`] ? state.pages[`dashboardOrder`] : []));
  // const [birthdays, setBirthday] = useState(dashboard?.data?.birthdays ?? []);
  // const [leaves, setLeaves] = useState(dashboard?.data?.leaves ?? []);
  useEffect(() => {
    // setBirthday(dashboard?.data?.birthdays ?? []);
    // setLeaves(dashboard?.data?.leaves ?? []);
    const data = {};
    if (dashboard?.data?.dashboard) {
      data["dashboard"] = dashboard?.data?.dashboard;
    }
    if (dashboard?.data?.hourlyStatistics) {
      data["hourlyStatistics"] = dashboard?.data?.hourlyStatistics;
    }
    if (dashboard?.data?.leaves) {
      data["leave"] = dashboard?.data?.leaves;
      setLeavesData(dashboard?.data?.leaves);
    }
    if (dashboard?.data?.birthdays) {
      data["birthdays"] = dashboard?.data?.birthdays;
    }
    if (dashboard?.data?.news) {
      data["news"] = dashboard?.data?.news;
    }
    if (dashboard?.data?.leaveRequest) {
      data["leaveRequest"] = dashboard?.data?.leaveRequest;
    }
    if (dashboard?.data?.sickLeave) {
      data["sickLeave"] = dashboard?.data?.sickLeave;
    }
    setOrganisedData(data);
    setDashboardDefault(dashboard?.data?.dashboardDefault ?? []);
  }, [dashboard]);

  useEffect(() => {
    props.setLoaderBox(dashboard.isLoading);
    dashboard.isLoading && setInitialized(true);
  }, [dashboard, props]);

  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject("dashboard", 0, {}));
    }
  }, [initialized, dispatch]);

  useEffect(() => {}, [dashboard]);
  const GetValue = (type, itemValue) => {
    switch (type) {
      case "minute":
        return convertMinutesToHHMM(parseFloat(itemValue ?? 0));
      default:
        return itemValue ?? 0;
    }
  };
  const ArrowAction = async (direction) => {
    const newDate = moment(currentDate).add(direction, "month");
    const data = await getData({ currentDate: newDate.toISOString() }, "dashboard/leaves");
    setLeavesData(data.data);
    setCurrentDate(newDate);
  };
  const getMonthDays = () => {
    const startOfMonth = moment(currentDate).startOf("month");
    const endOfMonth = moment(currentDate).endOf("month");
    const daysInMonth = [];

    let currentDay = startOfMonth;

    while (currentDay.isSameOrBefore(endOfMonth, "day")) {
      daysInMonth.push(moment(currentDay));
      currentDay.add(1, "day");
    }

    return daysInMonth;
  };
  const DraggableDashboard = ({ data, className = "", index }) => {
    const [isDragging, setIsDragging] = useState(false);

    const [, drag] = useDrag({
      type: "ITEM",
      item: { index: index }, // Use 'item' to specify the data being dragged
      collect: (monitor) => {
        setIsDragging(monitor.isDragging());
        return {};
      },
    });

    return (
      <>
        <div> {DropHere(index)}</div>
        <BoxDiv style={{ display: isDragging ? "none" : "flex" }} ref={drag} className="plain">
          <CalendarBox className={className}>{data}</CalendarBox>
        </BoxDiv>
      </>
    );
  };
  const DropHere = (index = 0) => {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem) => {
        const draggedIndex = draggedItem.index;
        if (draggedIndex !== index) {
          console.log("no");
        }
      },
      drop: (draggedItem) => {
        // Logic to handle drop event
        console.log(index, draggedItem.index);
        const draggedIndex = draggedItem.index;
        if (draggedIndex !== index) {
          // Swap the dashboards
          const updatedDashboards = [...dashboardOrder];
          // const temp = updatedDashboards[index];
          // updatedDashboards[index] = updatedDashboards[draggedIndex];
          // updatedDashboards[draggedIndex] = temp;
          const draggedItem = updatedDashboards.splice(draggedIndex, 1)[0];

          // Insert the dragged item at the new position
          updatedDashboards.splice(index, 0, draggedItem);
          postData({ dashboardOrder: updatedDashboards }, "admin/update-dashboard");
          dispatch(addPageObjectOffline("dashboardOrder", updatedDashboards ?? []));
          // Update the dashboard state
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    });

    return (
      canDrop && (
        <DropTarget
          ref={drop}
          style={{
            backgroundColor: isOver && canDrop ? "white" : "transparent",
            height: isOver && canDrop ? "100px" : "0px",
            border: isOver && canDrop ? "1px dashed #aaa;" : "none",
          }}
        >
          {isOver && canDrop ? <span>{t("Move here")}</span> : null}
        </DropTarget>
      )
    );
  };

  const generateView = ({ type, data, index }) => {
    switch (type) {
      case "leave":
        return (
          <DraggableDashboard
            key={"item" + index}
            index={index}
            data={
              <>
                <div className="head">
                  {t("dash-" + type)}
                  <GetIcon icon={"leaveType"} />
                </div>
                <BoxDiv>
                  <CalendarTable>
                    <thead>
                      <tr>
                        <EmployeeCell>
                          <Filter1 className="dashboard" theme={props.themeColors}>
                            <div onClick={() => ArrowAction(-1)}>
                              <GetIcon icon={"previous"}></GetIcon>
                            </div>
                            <span> {currentDate && currentDate.format("MMMM Y")}</span>
                            <div onClick={() => ArrowAction(1)}>
                              <GetIcon icon={"next"}></GetIcon>
                            </div>
                          </Filter1>
                        </EmployeeCell>
                        {getMonthDays().map((day) => (
                          <DayCell active={day.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")} key={day}>{`${day.format("DD")}`}</DayCell>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {leavesData?.length > 0 &&
                        leavesData?.map((employee) => (
                          <tr key={`${employee.firstName}-${employee.lastName}`}>
                            <EmployeeCell className="align">
                              {generateThumbnail(`${employee.firstName} ${employee.lastName}`, true, employee.photo)}
                              {`${employee.firstName} ${employee.lastName}`}
                            </EmployeeCell>
                            {getMonthDays().map((day) => {
                              const getFind = employee.leaves?.some((item) => day.format("YYYY-MM-DD") === moment(item.leaveDate).format("YYYY-MM-DD"));
                              return (
                                <DayCell key={`${employee.firstName}-${day.format("DD")}`}>
                                  {getFind && (
                                    <span className="leave" style={{ color: "red" }}>
                                      <GetIcon icon="leaveType" />
                                    </span>
                                  )}
                                </DayCell>
                              );
                            })}
                          </tr>
                        ))}
                    </tbody>
                    {leavesData?.length === 0 && <NoData className="cover">{t("NoLeaves")}</NoData>}
                  </CalendarTable>
                </BoxDiv>
              </>
            }
          />
        );
      case "dashboard":
      case "hourlyStatistics":
        return (
          <DraggableDashboard
            key={"item" + index}
            index={index}
            className="two"
            data={
              dashboard?.data?.[type].length > 0 ? (
                <BoxDiv className="radious">
                  <div className="head">
                    {t("dash-" + type)}
                    <GetIcon icon={type} />
                  </div>
                  <BoxDiv className="two plain">
                    {dashboard?.data?.[type].map((item, index) =>
                      item.icon === "leaveType" ? (
                        <Tile key={`dashboard-${index}`}>
                          <TitleBox>
                            <Count>
                              {item.data.map((count) => (
                                <span className={count.status} key={count.status}>
                                  <GetIcon icon={count.status} />
                                  {GetValue(item.type, count.count ?? 0)}
                                </span>
                              ))}
                            </Count>
                            <Title>{t(item.title)}</Title>
                          </TitleBox>
                        </Tile>
                      ) : (
                        <Tile key={index}>
                          <TitleBox>
                            <Count>{GetValue(item.type, item.count ?? 0)}</Count>
                            <Title>{t(item.title)}</Title>
                          </TitleBox>
                          <IconWrapper style={{ background: item.background, color: item.color }}>
                            <GetIcon icon={item.icon} />
                          </IconWrapper>
                        </Tile>
                      )
                    )}
                  </BoxDiv>
                </BoxDiv>
              ) : null
            }
          />
        );
      case "birthdays":
        return (
          data && (
            <DraggableDashboard
              key={"item" + index}
              index={index}
              data={
                <>
                  <div className="head">
                    {t("dash-" + type)}
                    <GetIcon icon={"birthday"} />
                  </div>
                  <BoxDiv>
                    <CalendarTable>
                      <tbody>
                        {data.length > 0 &&
                          data.map(
                            (employee) =>
                              employee && (
                                <tr key={`birthday-${employee.firstName} ${employee.lastName}`}>
                                  <EmployeeCellTD className="align">
                                    {generateThumbnail(`${employee.firstName} ${employee.lastName}`, true, employee.photo)}
                                    {`${employee.firstName} ${employee.lastName}`}
                                  </EmployeeCellTD>
                                  <DayCell key={`${employee.firstName}`}>{moment(employee.birthDate).format("DD MMM")}</DayCell>
                                </tr>
                              )
                          )}
                      </tbody>
                    </CalendarTable>
                    {data.length === 0 && (
                      <BoxDiv>
                        <NoData className="cover">{t("noData")}</NoData>
                      </BoxDiv>
                    )}
                  </BoxDiv>
                </>
              }
            />
          )
        );
      case "leaveRequest":
        return (
          data && (
            <DraggableDashboard
              key={"item" + index}
              index={index}
              data={
                <>
                  <div className="head">
                    {t("dash-" + type)}
                    <GetIcon icon={"leaveRequest"} />
                  </div>
                  <BoxDiv>
                    <CalendarTable>
                      <thead>
                        <tr>
                          <EmployeeCell className="nonstick">{t("employee")}</EmployeeCell>
                          <EmployeeCell>{t("leaveType")}</EmployeeCell>
                          <EmployeeCell className="title">{`${t("date")}`}</EmployeeCell>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map(
                            (leave) =>
                              leave && (
                                <tr key={`request-${leave.user.firstName} ${leave.user.lastName}`}>
                                  <EmployeeCellTD className="nonstick align">
                                    {generateThumbnail(`${leave.user.firstName} ${leave.user.lastName}`, true, leave.user.photo)}
                                    {`${leave.user.firstName} ${leave.user.lastName}`}
                                  </EmployeeCellTD>
                                  <EmployeeCellTD>{`${leave.leaveType[selectedLanguage]}`}</EmployeeCellTD>
                                  <DayCell key={`${leave.user.firstName}`}>{`${moment(leave.startDate).format("DD MMM")} - ${moment(leave.endDate).format("DD MMM")}`}</DayCell>
                                </tr>
                              )
                          )}
                      </tbody>
                    </CalendarTable>
                    {data.length === 0 && (
                      <BoxDiv>
                        <NoData className="cover">{t("noData")}</NoData>{" "}
                      </BoxDiv>
                    )}
                  </BoxDiv>
                </>
              }
            />
          )
        );
      case "news":
        return (
          data && (
            <DraggableDashboard
              key={"item" + index}
              index={index}
              data={
                data.length > 0 ? (
                  <>
                    <div className="head">
                      {t("dash-" + type)}
                      <GetIcon icon={"news"} />
                    </div>
                    <BoxDiv>
                      <CalendarTable>
                        <thead>
                          <tr>
                            <EmployeeCell>{t("text")}</EmployeeCell>
                            <EmployeeCell className="title">{`${t("createdAt")}`}</EmployeeCell>
                            <EmployeeCell className="title">{t("addedBy")}</EmployeeCell>
                            <EmployeeCell className="title">{t("status")}</EmployeeCell>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 &&
                            data.map(
                              (leave) =>
                                leave && (
                                  <tr key={`news-${leave.addedBy.firstName} ${leave.addedBy.lastName}`}>
                                    <DayCell className="left">{leave.news}</DayCell>
                                    <DayCell>{dateFormat(leave.createdAt)}</DayCell>
                                    <DayCell>{`${leave.addedBy.firstName} ${leave.addedBy.lastName}`}</DayCell>
                                    <DayCell>{t(leave.status)}</DayCell>
                                  </tr>
                                )
                            )}
                        </tbody>
                      </CalendarTable>
                    </BoxDiv>
                  </>
                ) : (
                  <BoxDiv>
                    <NoData className="cover">{t("noData")}</NoData>
                  </BoxDiv>
                )
              }
            />
          )
        );
      case "sickLeave":
        return (
          data && (
            <DraggableDashboard
              key={"item" + index}
              index={index}
              data={
                <>
                  <div className="head">
                    {t("dash-" + type)}
                    <GetIcon icon={"leaveType"} />
                  </div>
                  <BoxDiv>
                    <CalendarTable>
                      <thead>
                        <tr>
                          <EmployeeCell>{t("employee")}</EmployeeCell>
                          <EmployeeCell>{t("leaveType")}</EmployeeCell>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data.map(
                            (leave) =>
                              leave && (
                                <tr key={`${leave.user.firstName} ${leave.user.lastName}`}>
                                  <EmployeeCellTD className="align">
                                    {generateThumbnail(`${leave.user.firstName} ${leave.user.lastName}`, true, leave.user.photo)}
                                    {`${leave.user.firstName} ${leave.user.lastName}`}
                                  </EmployeeCellTD>
                                  <EmployeeCellTD>{`${leave.leaveType[selectedLanguage]}`}</EmployeeCellTD>
                                </tr>
                              )
                          )}
                      </tbody>
                    </CalendarTable>
                    {data.length === 0 && (
                      <BoxDiv>
                        <NoData className="cover">{t("noData")}</NoData>{" "}
                      </BoxDiv>
                    )}
                  </BoxDiv>
                </>
              }
            />
          )
        );
      default:
        return (
          <DraggableDashboard
            key={"item" + index}
            index={index}
            data={
              <BoxDiv>
                <NoData className="cover">{t("notFound", { page: t(type) })}</NoData>
              </BoxDiv>
            }
          />
        );
    }
  };
  const addDashboard = (title) => {
    let updatedDashboards = [...dashboardOrder];

    // Check if the title is already in the dashboardOrder
    const index = updatedDashboards.findIndex((item) => item === title);

    if (index === -1) {
      // If not in the array, add it
      updatedDashboards.push(title);
    } else {
      // If already in the array, remove it
      updatedDashboards = updatedDashboards.filter((item, i) => i !== index);
    }

    // Assuming postData and setDashboardOrder are defined elsewhere in your code
    postData({ dashboardOrder: updatedDashboards }, "admin/update-dashboard");

    dispatch(addPageObjectOffline("dashboardOrder", updatedDashboards ?? []));
  };
  return (
    <Container className="noshadow column">
      {openSettings && (
        <PopupView
          themeColors={themeColors}
          openData={{ data: { settings: "dashboardSettings" } }}
          itemTitle={{ name: "settings", collection: "" }}
          popupData={
            <SettingsBox>
              {dashboardDefault.map((item) => {
                const has = dashboardOrder.some((order) => order === item);
                return (
                  // <Button onClick={() => addDashboard(item)}>{t(item)}</Button>
                  <Checkbox label={t("dash-" + item)} checked={has} onChange={() => addDashboard(item)} />
                );
              })}
            </SettingsBox>
          }
          customClass={"medium"}
          closeModal={() => {
            setOpenSettings(false);
          }}
        ></PopupView>
      )}

      <DndProvider backend={HTML5Backend}>
        {dashboardOrder?.length > 0 && (
          <DashboardSection count={(dashboardOrder?.length / 2).toFixed(0) * 2} className="two">
            {dashboardOrder.map((page, index) => generateView({ data: organisedData[page] ?? [], type: page, index }))}
          </DashboardSection>
        )}
      </DndProvider>
      <AddButton onClick={() => setOpenSettings((prev) => !prev)}>
        <GetIcon icon={"move"}></GetIcon>
      </AddButton>
    </Container>
  );
};

export default withLayout(Dashboard);
