import styled from "styled-components";

export const Round = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  margin-top: 2px;
`;
export const Disabled = styled.span`
  color: red;
`;
export const Absence = styled.div`
  width: -webkit-fill-available;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: gray;
  font-size: 12px;
  overflow: hidden;
  flex-wrap: wrap;
  background-image: linear-gradient(315deg, #cefad0 25%, transparent 25%, transparent 50%, #cefad0 50%, #cefad0 75%, transparent 75%, transparent), linear-gradient(-45deg, #cefad0 25%, transparent 25%, transparent 50%, #cefad0 50%, #cefad0 75%, transparent 75%, transparent);
  background-size: 10px 10px;
  &.weekend {
    background-image: linear-gradient(315deg, #ebebeb 25%, transparent 25%, transparent 50%, #ebebeb 50%, #ebebeb 75%, transparent 75%, transparent), linear-gradient(-45deg, #ebebeb 25%, transparent 25%, transparent 50%, #ebebeb 50%, #ebebeb 75%, transparent 75%, transparent);
    background-size: 10px 10px;
  }
  span {
    flex-wrap: wrap;
    text-wrap: wrap;
  }
`;
export const Schedule = styled.button`
  white-space: nowrap;
  position: relative;
  background: white;
  border: 1px solid ${(props) => props.color};
  padding: 0px;
  padding-left: 15px;
  padding-right: 10px;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  min-width: 75px;
  min-height: 30px;

  .leave {
    font-size: 12px !important;
  }
  &.absence {
    width: -webkit-fill-available;
  }

  span:first-child {
    margin-top: 3px;
    margin-bottom: 3px;
    font-weight: 600;
    overflow: hidden;
    max-width: 100%;
  }
  &:hover .action {
    display: flex;
  }
  span:first-child > span {
    color: white;
    background-color: red;
    padding: 2px 5px;
    border-radius: 12px;
    margin-left: 5px;
    position: absolute;
    right: 3px;
    left: auto;
    width: auto;
    top: 0px;
  }
  span:last-child {
    font-size: 10px;
    margin-bottom: 3px;
  }
  &.roster span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  }
  &::after {
    background-color: ${(props) => props.color};
    color: ${(props) => props.color};
    content: "";
    border-radius: 8px 0px 0px 8px;
    width: 10px;
    position: absolute;
    height: 100%;
    left: 0;
    margin: 0px;
  }
  &.expired,
  &.Pending {
    background-color: #ffffff;
    color: #7d7d7d;
    > span {
      opacity: 0.7;
    }
    .action {
      opacity: 1;
    }
  }
  &.expired::after,
  &.Pending::after {
    opacity: 0.3;
  }
  &.active {
    background-color: grey;
    color: white;
  }
  &:hover {
    background-color: black;
    color: white;
  }
  &.selected {
    background-color: red !important;
    color: white !important;
  }
  &.marked {
    background-color: grey !important;
    color: white !important;
  }
  &.day {
    width: -webkit-fill-available;
    width: auto;
  }
  &.day.required {
    width: -webkit-fill-available;
    width: inherit;
  }
`;
export const SchedulesBox = styled.span`
  display: grid;
  grid-row-gap: 3px;
  position: relative;
  width: max-content;
  width: -webkit-fill-available;
  margin: 2px;

  &.wrap {
    display: flex;
    column-gap: 5px;
    flex-flow: wrap;
  }
  &.day {
    width: auto;
    overflow: hidden;
  }
  &.week {
    width: auto;
    overflow: hidden;
    margin: 2px;
  }
  &.month {
    width: auto;
    overflow: hidden;
    margin: 2px;
  }
`;

export const HoverActions = styled.span`
  position: absolute;
  left: 0;
  display: none;
  right: 0;
  top: 0;
  bottom: -3px;
  border-radius: 7px;
  background-color: ${(props) => props.color};
  color: white;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  z-index: 1;
  button {
    background-color: white;
    color: ${(props) => props.color};
    border-radius: 5px;
    border: 0;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
`;

export const Collapse = styled.span`
  margin-right: 5px;
  padding: 5px;
  cursor: pointer;
  svg {
    transition: 0.2s ease-out;
    transform: rotate(270deg);
  }
  &.true svg {
    transform: rotate(0deg);
  }
`;
