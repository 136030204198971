import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import { MenuGroup, MenuGroupItem, Heading, MenuItem, MenuBox } from "./styels";
import { GetIcon } from "../../../../icons";
import PopupView from "../../../elements/popupview";
import Switch from "../../../router/switch";
import { useDispatch, useSelector } from "react-redux";
import { clearLogin } from "../../../../store/actions/login";
import AutoForm from "../../../elements/form";
import i18n from "i18next"; // i18n library for internationalization
import { postData } from "../../../../backend/api";
import { Title } from "../../common/layout/header/styels";
import { RowContainer } from "../../../styles/containers/styles";
const Settings = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t("settings")} - VSH Security`;
  }, [t]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [openData, setOpenData] = useState(null);
  const themeColors = useSelector((state) => state.themeColors);
  const selectedMenu = useSelector((state) => state.selectedMenu);
  const [selectedLanguage, setSelectedLanguage] = useState(
    // If a language is stored in local storage, use it, otherwise use "de"
    localStorage.getItem("_lang") !== null ? localStorage.getItem("_lang") : "de"
  );
  const dispatch = useDispatch();
  const handleLanguageChange = (language) => {
    // Change the language in the i18n library
    if (selectedLanguage === "en") {
      i18n.changeLanguage("de");
      setSelectedLanguage("de");
      localStorage.setItem("_lang", "de");
    } else {
      i18n.changeLanguage("en");
      setSelectedLanguage("en");
      localStorage.setItem("_lang", "en");
    }
  };
  const [showResetPassword, setShowResetPassword] = useState(false);
  const isCreatingHandler = (value, callback) => {
    setShowResetPassword(false);
  };
  const submitChange = async (post) => {
    //call set password with
    props.setLoaderBox(true);
    await postData({ ...post }, "user/reset-password")
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          props.setMessage({
            type: 1,
            content: t(response.data.message),
            okay: "Okay",
            onClose: () => {},
          });
          props.setLoaderBox(false);
        } else if (response.status === 201) {
          props.setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          props.setLoaderBox(false);
        } else {
          props.setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          props.setLoaderBox(false);
        }
        setShowResetPassword(false);
        props.setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        props.setLoaderBox(false);
      });
  };
  const formInput = [
    {
      type: "password",
      placeholder: "currentPassword",
      name: "currentPassword",
      validation: "",
      default: "",
      label: "currentPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    {
      type: "password",
      placeholder: "newPassword",
      name: "newPassword",
      validation: "passwordMatch",
      default: "",
      label: "newPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    {
      type: "password",
      placeholder: "confirmPassword",
      name: "confirmPassword",
      validation: "passwordMatch",
      default: "",
      label: "confirmPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
  ];

  return (
    <RowContainer className="list">
      <Title className="inner">
        <GetIcon icon={"settings"}></GetIcon>
        {t("settings")}
      </Title>
      <MenuGroup>
        {props.router.groups.map((item) => (
          <MenuGroupItem key={item.head}>
            <Heading>{t(item.head)}</Heading>
            <MenuBox>
              {item.menuItems.map((menu) => (
                <MenuItem
                  key={menu.label}
                  onClick={() => {
                    if (menu.label === "logout") {
                      dispatch(clearLogin());
                    } else if (menu.label === "languageSwitch") {
                      handleLanguageChange();
                    } else if (menu.label === "resetPassword") {
                      setShowResetPassword(true);
                    } else {
                      setOpenData({ data: { name: menu.label } });
                      setSelectedMenuItem(menu);
                      setShowDetails(true);
                    }
                  }}
                >
                  <GetIcon icon={menu.icon}></GetIcon>
                  {t(menu.label)}
                </MenuItem>
              ))}
            </MenuBox>
          </MenuGroupItem>
        ))}
      </MenuGroup>
      {showDetails && (
        <PopupView
          // Popup data is a JSX element which is binding to the Popup Data Area like HOC
          popupData={selectedMenuItem && <Switch page={selectedMenuItem.element} />}
          themeColors={themeColors}
          closeModal={() => {
            setShowDetails(false);
            setSelectedMenuItem(null);
          }}
          setMessage={props.setMessage}
          setLoaderBox={props.setLoaderBox}
          itemTitle={{ collection: "", name: "name" }}
          openData={openData} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
          customClass={"fullpage nopadding"}
          menu={selectedMenu}
        ></PopupView>
      )}
      {showResetPassword && <AutoForm formMode={"single"} enterSubmit={true} useCaptcha={false} formType={"post"} header={t("resetPassword")} formInput={formInput} submitHandler={submitChange} button={t("confirm")} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={false}></AutoForm>}
    </RowContainer>
  );
};
// exporting the page with parent container layout..
export default Layout(Settings);
