import { useState } from "react";
import { Tab, TabContainer, TabHeader, TabLink } from "./styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Tabs = ({ tabs = [] }) => {
  const [t] = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  const tabColelctionTemp = [...tabs];
  tabColelctionTemp[0].opened = true;
  const [activeTab, setActiveTab] = useState(tabColelctionTemp[0]?.name);
  const [tabColelction, setTabColelction] = useState(tabColelctionTemp);
  console.log(tabColelctionTemp);
  return (
    <TabContainer className="tab">
      <TabHeader>
        {tabColelction?.map((tab, index) => {
          return (
            <TabLink
              key={`main-` + tab.name + tab.title}
              theme={themeColors}
              className={activeTab === tab.name && "active"}
              onClick={() => {
                setActiveTab(tab.name);
                const tabColelctionTemp = [...tabColelction];
                tabColelctionTemp[index].opened = true;
                setTabColelction(tabColelctionTemp);
              }}
            >
              {t(tab.title)}
            </TabLink>
          );
        })}
      </TabHeader>
      {/* tab for parking process */}
      <TabContainer className="fit">
        {tabColelction?.map((tab) => {
          return (
            <Tab className="tab-container" key={`sub-` + tab.name} active={activeTab === tab.name}>
              {(tab.opened ?? false) && tab.element}
            </Tab>
          );
        })}
      </TabContainer>
    </TabContainer>
  );
};
export default Tabs;
