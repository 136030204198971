import React, { useState, useLayoutEffect } from "react";
import { Footer, Form, Header, Page, Overlay } from "./styles";

import FormInput from "../../../../elements/input";
import { NoData, Table, Td, Th, Tr } from "../../../../elements/list/styles";
import { getData } from "../../../../../backend/api";
import { useTranslation } from "react-i18next";
import { dateTimeFormat } from "../../../../functions/date";
const AttendanceDetails = ({ setMessage, setLoaderBox, title, data, item, onClose }) => {
  const closeModal = () => {
    onClose();
  };
  const { t } = useTranslation();
  const [attendance, setAttendance] = useState([]);

  useLayoutEffect(() => {
    setLoaderBox(true);
    const fetchData = () => {
      getData({}, `attendance/today/${data._id}`).then((response) => {
        if (response.status === 200) {
          setAttendance(response.data);
          setLoaderBox(false);
        }
      });
    };
    fetchData();
  }, [data, setLoaderBox]);

  return (
    <Overlay>
      <Page>
        <Header>{`${t(title)} / ${data.fullName}`}</Header>
        <Form>
          {attendance?.data?.length > 0 ? (
            <Table>
              <thead>
                <Tr>
                  <Th>{t("in")}</Th>
                  <Th>{t("out")}</Th>
                  <Th>{t("status")}</Th>
                </Tr>
              </thead>
              <tbody>
                {attendance?.data?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{dateTimeFormat(item.inTime)}</Td>
                    <Td>{item.status === "In" ? ` ${dateTimeFormat(new Date())} (${t("ongoing")})` : dateTimeFormat(item.outTime)}</Td>
                    <Td>{item.status}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <NoData>No {t(title)} found!</NoData>
          )}
        </Form>
        <Footer>
          <FormInput type="close" value={t("cancel")} onChange={closeModal} />
        </Footer>
      </Page>
    </Overlay>
  );
};

export default AttendanceDetails;
