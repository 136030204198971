import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const Shift = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "shortForm",
      name: "shortForm",
      validation: "",
      default: "",
      label: "shortForm",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "description",
      name: "description",
      validation: "",
      default: "",
      label: "description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "time",
      placeholder: "shiftStart",
      name: "shiftStart",
      validation: "",
      interval: 15,
      default: "",
      label: "shiftStart",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "startBuffer",
      name: "startBuffer",
      validation: "",
      default: "",
      showItem: "value",
      label: "startBuffer",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
      apiType: "JSON",
      selectApi: [
        { value: "10 minutes", id: 10 },
        { value: "15 minutes", id: 15 },
        { value: "20 minutes", id: 20 },
        { value: "25 minutes", id: 25 },
        { value: "30 minutes", id: 30 },
        { value: "45 minutes", id: 45 },
        { value: "60 minutes", id: 60 },
      ],
    },
    {
      type: "time",
      placeholder: "shiftEnd",
      name: "shiftEnd",
      validation: "",
      interval: 15,
      default: "",
      label: "shiftEnd",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "endBuffer",
      name: "endBuffer",
      validation: "",
      default: "",
      showItem: "value",
      label: "endBuffer",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
      apiType: "JSON",
      selectApi: [
        { value: "10 minutes", id: 10 },
        { value: "15 minutes", id: 15 },
        { value: "20 minutes", id: 20 },
        { value: "25 minutes", id: 25 },
        { value: "30 minutes", id: 30 },
        { value: "45 minutes", id: 45 },
        { value: "60 minutes", id: 60 },
      ],
    },
    {
      type: "number",
      placeholder: "break",
      name: "break",
      validation: "",
      default: 0,
      label: "break",
      required: true,
      minimum: 0,
      maximum: 60,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "color",
      placeholder: "color",
      name: "color",
      validation: "",
      default: "#000000",
      label: "color",
      required: true,
      minimum: 0,
      maximum: 60,
      view: true,
      add: true,
      update: true,
    },
  ]);
  // const [timingAttributes] = useState([
  //   {
  //     type: "select",
  //     placeholder: "arrivalDay",
  //     name: "arrivalDay",
  //     validation: "",
  //     default: "",
  //     label: "arrivalDay",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //     apiType: "CSV",
  //     selectApi: "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
  //   },

  //   {
  //     type: "text",
  //     placeholder: "arrivalTime",
  //     name: "arrivalTime",
  //     validation: "time",
  //     default: "10:00",
  //     label: "arrivalTime",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //   },
  //   {
  //     type: "select",
  //     placeholder: "arrivalPadding",
  //     name: "arrivalPadding",
  //     validation: "",
  //     default: "",
  //     showItem: "value",
  //     label: "arrivalPadding",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //     filter: false,
  //     apiType: "JSON",
  //     selectApi: [
  //       { value: "10 minutes", id: 10 },
  //       { value: "15 minutes", id: 15 },
  //       { value: "20 minutes", id: 20 },
  //       { value: "25 minutes", id: 25 },
  //       { value: "30 minutes", id: 30 },
  //       { value: "45 minutes", id: 45 },
  //       { value: "60 minutes", id: 60 },
  //     ],
  //   },
  //   {
  //     type: "select",
  //     placeholder: "departureDay",
  //     name: "departureDay",
  //     validation: "",
  //     default: "",
  //     label: "departureDay",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //     apiType: "CSV",
  //     selectApi: "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
  //   },
  //   {
  //     type: "text",
  //     placeholder: "departureTime",
  //     name: "departureTime",
  //     validation: "time",
  //     default: "17:00",
  //     label: "departureTime",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //   },
  //   {
  //     type: "select",
  //     placeholder: "departurePadding",
  //     name: "departurePadding",
  //     validation: "",
  //     showItem: "value",
  //     default: "",
  //     label: "departurePadding",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //     filter: false,
  //     apiType: "JSON",
  //     selectApi: [
  //       { value: "10 minutes", id: 10 },
  //       { value: "15 minutes", id: 15 },
  //       { value: "20 minutes", id: 20 },
  //       { value: "25 minutes", id: 25 },
  //       { value: "30 minutes", id: 30 },
  //       { value: "45 minutes", id: 45 },
  //       { value: "60 minutes", id: 60 },
  //     ],
  //   },
  // ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("shift")} - VSH Security`;
  }, [t]);
  // const [actions] = useState([{ element: "button", icon: "timings", type: "subList", id: "shift-timing", itemTitle: "title", title: "timings", attributes: timingAttributes, params: { api: `timing`, itemTitle: "title", shortName: "Timing", addPrivilege: true, delPrivilege: true, updatePrivilege: true, customClass: "medium" } }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} actions={[]} formMode="double" api={`shift`} itemTitle={`title`} shortName={`Shift`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Shift);
