import { useTranslation } from "react-i18next";
import ListTable from "../list";
import { Header, Overlay, Page } from "./../manage/styles";
import { CloseButton } from "../popup/styles";
import { useSelector } from "react-redux";
import { GetIcon } from "../../../../icons";
const SubPage = ({ subAttributes, setLoaderBox, setMessage, closeModal }) => {
  const [t] = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  console.log("subAttributes",subAttributes);
  return (
    <Overlay>
      <Page className={(subAttributes?.item?.params?.customClass ?? "") + " sub-page"}>
        <Header>
          <span>{`${t(subAttributes?.data?.[subAttributes?.item?.params?.itemTitle])} / ${t(subAttributes?.item?.title)}`}</span>
          <CloseButton theme={themeColors} onClick={closeModal}>
            <GetIcon icon={"Close"} />
          </CloseButton>
        </Header>
        <ListTable setMessage={setMessage} setLoaderBox={setLoaderBox} referenceId={subAttributes?.data?._id} attributes={subAttributes.item.attributes} {...subAttributes.item.params}></ListTable>
        {/* <Footer>
          <FormInput type="close" value={"Cancel"} onChange={closeModal} />
        </Footer> */}
      </Page>
    </Overlay>
  );
};
export default SubPage;
