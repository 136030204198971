import styled from "styled-components";

export const MenuGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: minmax(auto, auto, auto);
  gap: 10px;
  height: fit-content;
  width: 100%;
  margin-top:20px;
  @media (max-width: 768px) {
    grid-template-columns: auto;
    grid-auto-rows: minmax(auto);
    width: auto;
    padding: 20px 10px;
  }
`;

export const MenuGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 10px;
  flex-grow: 1;
  min-width: 150px; /* Adjust min-width as needed */
  padding: 10px;
  margin: 5px;
  position: relative;
`;

export const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  position: absolute;
  background: #f8f7fa;
  top: -17px;
  padding: 5px 20px;
`;
export const MenuBox = styled.div`
  margin: 9px;
`;
export const MenuItem = styled.div`
  margin-bottom: 3px;
  padding: 10px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 0px 1px 1px #839d9424;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
  &:hover {
    background: #e6ecf4;
    cursor: pointer;
  }
`;
