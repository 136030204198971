import styled from "styled-components";

export const HeadBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 5px 0px 5px;
  padding: 0px 10px 0 10px;
`;
export const Head = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  align-items: center;
  padding: 20px 0px 5px 0px;
  margin: auto;
  position: relative;
  margin: auto;
  position: relative;
  > span {
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    width: 50px;
    height: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  img {
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
  }
  &::after {
    content: "";
    width: 1px;
    height: 15px;
    background:#1b9dd9;
    position: absolute;
    bottom: 0;
  }
  &::before {
    content: "";
    width: 1px;
    height: 20px;
    background:#1b9dd9;
    position: absolute;
    top: 0;
  }
  &.last {
    &::after {
      display: none;
    }
  }
  &.first {
    &::before {
      display: none;
    }
  }
  p {
    font-weight: bold;
  }
  &.last p {
    margin-bottom: 0;
  }
  p:nth-child(3) {
    margin-top: 5px;
    font-size: 10px;
    font-weight: normal;
  }
`;
export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  justify-content: center;
  position: relative;
  &.row,
  &.row-head {
    flex-direction: row;
    border-top: 0px solid lightgray;
    border-left: 0px solid lightgray;
    border-right: 0px solid lightgray;
    border-radius: 30px 30px 0 0;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 100%;
    width: 100%;
    flex-flow: wrap;
  }
  &.row > div::before {
    content: "";
    left: 0;
    right: 0;
    height: 1px;
    background:#1b9dd9;
    position: absolute;
    top: 0;
  }
  &.row > div:first-child::before {
    left: 50%;
  }
  &.row > div:last-child::before {
    right: 50%;
  }
  /* &.row > div:first-child::before, .row > div:last-child::before {
    left: 50%;
    right: 50%;
  } */
  &.row-head > div::after {
    content: "";
    left: 0;
    right: 0;
    height: 1px;
    background: #1b9dd9;
    position: absolute;
    bottom: -1px;
  }
  &.row-head > div:first-child::after {
    left: 50%;
  }
  &.row-head > div:last-child::after {
    right: 50%;
  }
  &.main {
    margin: 20px auto auto auto;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
  }
  &.row::before,
  &.main::before,
  &.row::after,
  &.main::after {
    display: none;
  }
`;
