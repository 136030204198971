import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const Surcharge = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "shift",
      name: "shiftTemplate",
      validation: "",
      default: "",
      label: "shift",
      showItem: "title",
      required: true,
      view: true,
      add: true,
      update: false,
      apiType: "API",
      selectApi: "shift/select",
    },
    {
      type: "select",
      placeholder: "place",
      name: "place",
      validation: "",
      showItem: "title",
      default: "",
      label: "place",
      required: true,
      view: true,
      add: true,
      update: false,
      apiType: "API",
      selectApi: "place/select",
    },
    {
      type: "checkbox",
      placeholder: "nightSupplement",
      name: "nightSupplement",
      validation: "",
      showItem: "",
      default: "true",
      label: "nightSupplement",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "sundaySurcharge",
      name: "sundaySurcharge",
      validation: "",
      showItem: "",
      default: "true",
      label: "sundaySurcharge",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "holidaySurcharge",
      name: "holidaySurcharge",
      validation: "",
      showItem: "",
      default: "true",
      label: "holidaySurcharge",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "overTime",
      name: "overTime",
      validation: "",
      showItem: "",
      default: "true",
      label: "overTime",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  //to update the page title
  useEffect(() => {
    document.title = `${t("surcharge")} - VSH Security`;
  }, [t]);
  // const [actions] = useState([{ element: "button", icon: "timings", type: "subList", id: "shift-timing", itemTitle: "title", title: "timings", attributes: timingAttributes, params: { api: `timing`, itemTitle: "title", shortName: "Timing", addPrivilege: true, delPrivilege: true, updatePrivilege: true, customClass: "medium" } }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} api={`surcharge`} itemTitle={`title`} shortName={`surcharge`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Surcharge);
