import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import withLayout from "../../../common/layout";
import { Container } from "../../../common/layout/styels";
import moment from "moment";
import { Approval, ButtonContainer, CalendarTable } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { GetIcon } from "../../../../../icons";
import { addPageObject } from "../../../../../store/actions/pages";
import { dateFormat, timeFormat } from "../../../../functions/date";
import { convertMinutesToHHMM } from "../../../../functions/dataFormat";
import { RowContainer } from "../../../../styles/containers/styles";
import { NoData } from "../../../../elements/list/styles";
const Schedules = () => {
  const [currentApi] = useState(`user/work-logs`);
  const [t] = useTranslation();
  const [currentDate, setCurrentDate] = useState(moment().startOf("month"));
  const dispatch = useDispatch();
  const themeColors = useSelector((state) => state.themeColors);
  useEffect(() => {
    document.title = `${t("attendance")} - VSH Security`;
  }, [t]);
  const [initialized] = useState(true);
  //Loading Data
  const users = useSelector((state) =>
    state.pages[`${currentApi}`]
      ? state.pages[`${currentApi}`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject(currentApi, 0, 0));
    }
  }, [initialized, currentApi, dispatch]);
  // const [draggedDates, setDraggedDates] = useState([]);

  const handlePrevMonth = () => {
    const prevMonth = currentDate.clone().subtract(1, "month");
    setCurrentDate(prevMonth);
    dispatch(addPageObject(currentApi, 0, 0, { date: prevMonth.toDate() }));
  };

  const handleNextMonth = () => {
    const nextMonth = currentDate.clone().add(1, "month");
    dispatch(addPageObject(currentApi, 0, 0, { date: nextMonth.toDate() }));
    setCurrentDate(nextMonth);
  };
  const statusMarker = (status) => {
    switch (status) {
      case "Pending":
        return (
          <Approval color="orange">
            <GetIcon icon={"pending"}></GetIcon>
          </Approval>
        );
      case "Confirmed":
        return (
          <Approval color="green">
            <GetIcon icon={"approved"}></GetIcon>
          </Approval>
        );
      case "Rejected":
        return (
          <Approval color="red">
            <GetIcon icon={"rejected"}></GetIcon>
          </Approval>
        );
      default:
        return (
          <Approval color="orange">
            <GetIcon icon={"pending"}></GetIcon>
          </Approval>
        );
    }
  };
  const renderCalendarHeader = () => (
    <tr>
      <th>{t("date")}</th>
      <th>{t("startTime")}</th>
      <th>{t("endTime")}</th>
      <th>{t("pause")}</th>
      <th>{t("team")}</th>
      <th>{t("shift")}</th>
      <th>{t("worked")}</th>
      <th>{t("status")}</th>
    </tr>
  );
  let totalDuration = 0;
  return (
    <Container className="noshadow">
      <RowContainer className="custom">
        <ButtonContainer theme={themeColors}>
          <button onClick={handlePrevMonth}>
            <GetIcon icon={"previous"}></GetIcon>
            {t("Previous Month")}
          </button>
          <button onClick={handleNextMonth}>
            {t("Next Month")}
            <GetIcon icon={"next"}></GetIcon>
          </button>
        </ButtonContainer>
        <CalendarTable theme={themeColors}>
          <thead>
            <tr>
              <th colSpan="8">{`${t(`month-${currentDate.format("M")}`)} ${currentDate.format("YYYY")}`}</th>
            </tr>
            {renderCalendarHeader()}
          </thead>
          <tbody>
            {users.data?.length > 0 ? (
              <>
                {users.data.map((day, dayIndex) => {
                  totalDuration += day.workedHours;

                  return (
                    <tr key={dayIndex}>
                      <td>{dateFormat(day.date)}</td>
                      <td>{timeFormat(day.firstIn)}</td>
                      <td>{day.status === "Out" ? timeFormat(day.lastOut) : <GetIcon icon={"live"} />}</td>
                      <td>{convertMinutesToHHMM(day.pause)}</td>
                      <td>{day.place ? day.place.title : "No Place"}</td>
                      <td>{`${day.shiftTemplate.shortForm} - ${day.shiftTemplate.title}`}</td>
                      <td>{convertMinutesToHHMM(day.workedHours)}</td>
                      <td>{statusMarker(day.approval)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={5}></td>
                  <td>{t("total")}</td>
                  <td>{convertMinutesToHHMM(totalDuration)}</td>
                  <td></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={9}>
                  <NoData>No Data</NoData>
                </td>
              </tr>
            )}
          </tbody>
        </CalendarTable>
      </RowContainer>
    </Container>
  );
};

export default withLayout(Schedules);
