import React, { useCallback, useEffect, useRef, useState } from "react";
import { AddIcon, GetIcon } from "../../../../../icons";
import { AddButton, ButtonPanel, Count, Filter, FilterBox, Filters, NoData, ScrollContainerLayout, ScrollLayout, Table, Td, Th, ToggleInput, ToggleSwitch, ToggleSwitchContainer, Tr } from "../../../../elements/list/styles";
import Search from "../../../../elements/search";
import { ColumnContainer, RowContainer } from "../../../../styles/containers/styles";
import Layout from "../../../common/layout";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addPageObject } from "../../../../../store/actions/pages";
import moment from "moment";
import { Absence, Collapse, HoverActions, Schedule, SchedulesBox } from "./styles";
import { timeFormat } from "../../../../functions/date";
import { BlankButton } from "../styels";
import { postData, putData } from "../../../../../backend/api";
import AutoForm from "../../../../elements/form";
import { generateThumbnail } from "../../../../functions/string";
import { Container } from "../../../common/layout/styels";
import "./DragSelectDelete.css"; // Import your own CSS file for styling
import { CenterBox, DayBox } from "../worklog/styles";
import SelectTab from "../../../../elements/selectTab";
import DateSelector from "../../../../elements/dateselector";
import { convertMinutesToHHMM } from "../../../../functions/dataFormat";
import Progress from "../../../../elements/progress";
import { Title } from "../../../common/layout/header/styels";
import FormInput from "../../../../elements/input";
// import CrudForm from "../../../../elements/list/create";
// import { useNavigate } from "react-router-dom";
const Schedules = ({ setLoaderBox, setMessage, shortName = "layer" }) => {
  const [currentApi] = useState(`shift/schedules`);
  const [currentIndex] = useState(0);
  const themeColors = useSelector((state) => state.themeColors);
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const searchTimeoutRef = useRef();
  const [count, setCount] = useState(0);
  const [initialized] = useState(true);
  const dispatch = useDispatch();
  const [openData, setOpenData] = useState([]);
  const [openedPlace, setOpenedPlace] = useState(() => {
    const storedProjects = localStorage.getItem("project");
    return storedProjects ? JSON.parse(storedProjects) : {};
  });

  // const [dragOverId, setDragOverId] = useState("");
  const [overid, setOverId] = useState("");
  const [title, setTitle] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const { i18n } = useTranslation();
  const [viewMode, setViewMode] = useState("month");
  const [showWorked, setShowWorked] = useState(false);
  const [selectedLanguage] = useState(i18n.language || "de");
  // const navigate = useNavigate();
  const handleChange = (event) => {
    clearTimeout(searchTimeoutRef.current);
    setSearchValue(event.target.value);
    searchTimeoutRef.current = setTimeout(() => {
      setFilterView({ ...filterView, searchkey: event.target.value });
    }, 300);
  };
  const [workTimeLog, setWorkTimeLog] = useState([]);
  const [dayTimeLog] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]);
  const [filterView, setFilterView] = useState({
    place: "",
    shift: "",
    viewMode: "month",
    show: "1",
    currentDate: new Date().toISOString(),
    startOfMonth: moment(new Date()).startOf("month").toISOString(),
    endOfMonth: moment(new Date()).endOf("month").toISOString(),
    startOfWeek: moment(new Date()).startOf("week").toISOString(),
    endOfWeek: moment(new Date()).endOf("week").toISOString(),
  });

  // const [attributes] = useState([
  //   {
  //     type: "select",
  //     placeholder: "place",
  //     name: "place",
  //     showItem: "title",
  //     validation: "",
  //     default: "",
  //     label: "place",
  //     required: true,
  //     view: true,
  //     add: true,
  //     update: true,
  //     apiType: "API",
  //     selectApi: "place/select",
  //   },
  // ]);
  useEffect(() => {
    localStorage.setItem("project", JSON.stringify(openedPlace));
  }, [openedPlace]);
  const dateRangeChange = (item) => {
    const udpateValue = {
      ...filterView,
      ...item,
      viewMode,
    };
    setFilterView(udpateValue);
  };
  const filterChange = (option, name) => {
    // console.log(option, name);
    const udpateValue = {
      ...filterView,
      [name]: option.id,
    };
    // updating the formm values
    setFilterView(udpateValue);
  };
  const changeWorkLog = useCallback(
    (item) => {
      let startDateTemp, endDateTemp;
      const workLog = [];

      // Using a switch to determine start and end dates based on viewMode
      switch (item.viewMode) {
        case "month":
          startDateTemp = moment(item.startOfMonth).toDate();
          endDateTemp = moment(item.endOfMonth).toDate();
          break;
        case "week":
          startDateTemp = moment(item.startOfWeek).toDate();
          endDateTemp = moment(item.endOfWeek).toDate();
          break;
        case "day":
          startDateTemp = moment(item.currentDate).toDate();
          endDateTemp = moment(item.currentDate).toDate();
          break;
        default:
          startDateTemp = moment(item.startOfMonth).toDate();
          endDateTemp = moment(item.endOfMonth).toDate();
          break;
      }

      // Loop through dates and populate the workLog array
      for (let date = moment(startDateTemp); date.isSameOrBefore(endDateTemp); date.add(1, "day")) {
        workLog.push({
          originalDate: date.toDate(),
          date: date.format("YYYY-MM-DD"),
          day: date.format("DD"),
          dayNameId: date.locale("en").format("ddd"),
          dayName: date.locale(selectedLanguage).format("ddd"),
        });
      }

      // Assuming setWorkTimeLog is a function to update the state with the workLog
      setWorkTimeLog([...workLog]); // Using spread to create a new array and trigger state update
    },
    [setWorkTimeLog, selectedLanguage]
  ); // Adding dependencies for useCallback

  useEffect(() => {
    changeWorkLog(filterView);
  }, [filterView, changeWorkLog]);
  const [loadedData, setLoadedData] = useState([]);
  //Loading Data
  const users = useSelector((state) =>
    state.pages[`${currentApi}`]
      ? state.pages[`${currentApi}`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  // const [draggedDates, setDraggedDates] = useState([]);
  useEffect(() => {
    setLoadedData(users);
  }, [users]);
  const [addAtrributes, setAddAttributes] = useState([]);
  const [saveApiUrl, setSaveApiUrl] = useState("");
  const [updateApiUrl, setUpdateApiUrl] = useState("");
  const [addOverrideUpdate, setAddOverrideUpdate] = useState([]);
  const [condition] = useState({ item: "reccuring", if: true, then: "enabled", else: "disabled" });
  const [shiftCondition] = useState({ item: "shift", if: "", else: "enabled", then: "disabled" });
  const [isAddNewSchedule, setIsAddNewSchedule] = useState(false);
  const [modifySchedule, setModifySchedule] = useState(false);
  const [userId, setUserId] = useState("nil");
  const addNewSchedule = (entry, item) => {
    let date = moment(entry.originalDate);
    setSaveApiUrl("shift/addSchedule");
    setTitle("layer");
    if (item._id) {
      setUserId(item._id);
      setAddAttributes([
        {
          type: "title",
          name: "scheduleTo",
          validation: "",
          title: t("scheduleFor", { name: item.fullName }),
          label: "scheduleTo",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          name: "scheduleTo",
          validation: "",
          title: ``,
          label: "scheduleTo",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "select",
          placeholder: "shift",
          name: "shiftTemplate",
          validation: "",
          updateCondition: [
            { item: "shiftStart", value: "shiftStart" },
            { item: "shiftEnd", value: "shiftEnd" },
            { item: "startBuffer", value: "startBuffer" },
            { item: "endBuffer", value: "endBuffer" },
            { item: "break", value: "break" },
          ],
          tags: [
            { type: "time", item: "shiftStart", title: "shiftStart", collection: "" },
            { type: "time", item: "shiftEnd", title: "shiftEnd", collection: "" },
          ],
          default: "",
          label: "shift",
          showItem: "title",
          required: true,
          view: true,
          add: true,
          update: true,
          apiType: "API",
          selectApi: "shift/select",
          customClass: "half",
        },

        {
          type: "textarea",
          placeholder: "note",
          name: "title",
          validation: "",
          condition: shiftCondition,
          default: "",
          label: "note",
          required: false,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "date",
          placeholder: "scheduleDate",
          condition: shiftCondition,
          name: "startDate",
          validation: "",
          default: moment(entry.date).startOf("day").add(7, "hours"),
          label: "scheduleDate",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "shiftStart",
          name: "shiftStart",
          validation: "",
          condition: shiftCondition,
          default: date.clone().add(7, "hours"),
          label: "shiftStart",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "shiftEnd",
          name: "shiftEnd",
          condition: shiftCondition,
          validation: "",
          default: date.clone().add(19, "hours"),
          label: "shiftEnd",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "number",
          placeholder: "startBuffer",
          condition: shiftCondition,
          name: "startBuffer",
          validation: "",
          default: "",
          showItem: "value",
          label: "startBuffer",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: "10 minutes", id: 10 },
            { value: "15 minutes", id: 15 },
            { value: "20 minutes", id: 20 },
            { value: "25 minutes", id: 25 },
            { value: "30 minutes", id: 30 },
            { value: "45 minutes", id: 45 },
            { value: "60 minutes", id: 60 },
          ],
        },
        {
          type: "number",
          placeholder: "endBuffer",
          condition: shiftCondition,
          name: "endBuffer",
          validation: "",
          default: "",
          showItem: "value",
          label: "endBuffer",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: "10 minutes", id: 10 },
            { value: "15 minutes", id: 15 },
            { value: "20 minutes", id: 20 },
            { value: "25 minutes", id: 25 },
            { value: "30 minutes", id: 30 },
            { value: "45 minutes", id: 45 },
            { value: "60 minutes", id: 60 },
          ],
        },
        {
          type: "number",
          placeholder: "break",
          condition: shiftCondition,
          name: "break",
          validation: "",
          default: "0",
          label: "break",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "hidden",
          placeholder: "employee",
          name: "user",
          validation: "",
          default: [[item._id]],
          label: "employee",
          showItem: "employee",
          required: true,
          view: true,
          add: true,
          update: true,
          apiType: "API",
          selectApi: "user/select",
          customClass: "half",
        },
        {
          type: "info",
          placeholder: "days",
          name: "days",
          validation: "",
          default: false,
          label: "days",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "checkbox",
          placeholder: "reccuring",
          condition: shiftCondition,
          name: "reccuring",
          validation: "",
          default: false,
          label: "reccuring",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          placeholder: "days",
          name: "days",
          content: "selectDays",
          validation: "",
          condition: condition,
          default: false,
          label: "days",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          placeholder: "days",
          name: "days",
          validation: "",
          default: false,
          label: "days",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "multiSelect",
          placeholder: "eligibleDays",
          listView: true,
          name: "eligibleDays",
          validation: "",
          default: "",
          label: "eligibleDays",
          required: true,
          view: true,
          condition: condition,
          customClass: "half",
          add: true,
          update: true,
          apiType: "JSON",
          selectApi: [
            { value: t("Monday"), id: "monday" },
            { value: t("Tuesday"), id: "tuesday" },
            { value: t("Wednesday"), id: "wednesday" },
            { value: t("Thursday"), id: "thursday" },
            { value: t("Friday"), id: "friday" },
            { value: t("Saturday"), id: "saturday" },
            { value: t("Sunday"), id: "sunday" },
          ],
        },
        {
          type: "date",
          placeholder: "scheduleEnd",
          name: "endDate",
          validation: "",
          condition: condition,
          greater: "startDate",
          minDate: date.clone().add(1, "days"),
          maxDate: date.clone().add(3, "months"),
          default: date.clone().startOf("day").add(15, "hours"),
          label: "scheduleEnd",
          required: true,
          view: true,
          add: true,
          update: true,
        },
      ]);
    } else {
      setAddAttributes([
        {
          type: "select",
          placeholder: "shift",
          name: "shiftTemplate",
          validation: "",
          updateCondition: [
            { item: "shiftStart", value: "shiftStart" },
            { item: "shiftEnd", value: "shiftEnd" },
            { item: "startBuffer", value: "startBuffer" },
            { item: "endBuffer", value: "endBuffer" },
            { item: "break", value: "break" },
          ],
          tags: [
            { type: "time", item: "shiftStart", title: "shiftStart", collection: "" },
            { type: "time", item: "shiftEnd", title: "shiftEnd", collection: "" },
          ],
          default: "",
          label: "shift",
          showItem: "title",
          required: true,
          view: true,
          add: true,
          update: true,
          apiType: "API",
          selectApi: "shift/select",
          customClass: "half",
        },

        {
          type: "date",
          placeholder: "scheduleDate",
          condition: shiftCondition,
          name: "startDate",
          validation: "",
          minDate: date.clone(),
          maxDate: date.clone().add(3, "months"),
          default: date.clone().startOf("day").add(7, "hours"),
          label: "scheduleDate",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "shiftStart",
          name: "shiftStart",
          validation: "",
          condition: shiftCondition,
          default: date.clone().add(7, "hours"),
          label: "shiftStart",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "shiftEnd",
          name: "shiftEnd",
          condition: shiftCondition,
          validation: "",
          default: date.clone().add(19, "hours"),
          label: "shiftEnd",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "number",
          placeholder: "startBuffer",
          condition: shiftCondition,
          name: "startBuffer",
          validation: "",
          default: "",
          showItem: "value",
          label: "startBuffer",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: "10 minutes", id: 10 },
            { value: "15 minutes", id: 15 },
            { value: "20 minutes", id: 20 },
            { value: "25 minutes", id: 25 },
            { value: "30 minutes", id: 30 },
            { value: "45 minutes", id: 45 },
            { value: "60 minutes", id: 60 },
          ],
        },
        {
          type: "number",
          placeholder: "endBuffer",
          condition: shiftCondition,
          name: "endBuffer",
          validation: "",
          default: "",
          showItem: "value",
          label: "endBuffer",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: "10 minutes", id: 10 },
            { value: "15 minutes", id: 15 },
            { value: "20 minutes", id: 20 },
            { value: "25 minutes", id: 25 },
            { value: "30 minutes", id: 30 },
            { value: "45 minutes", id: 45 },
            { value: "60 minutes", id: 60 },
          ],
        },
        {
          type: "number",
          placeholder: "break",
          condition: shiftCondition,
          name: "break",
          validation: "",
          default: "0",
          label: "break",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          customClass: "half",
          update: true,
        },
        {
          type: "multiSelect",
          placeholder: "employee",
          name: "user",
          condition: shiftCondition,
          tags: [
            { item: "title", title: "place", collection: "place" },
            { item: "title", title: "shift", collection: "shiftTemplate" },
          ],
          validation: "",
          default: "",
          label: "employee",
          showItem: "employee",
          required: true,
          view: true,
          add: true,
          update: true,
          apiType: "API",
          selectApi: "user/select",
          customClass: "half",
        },
        {
          type: "textarea",
          placeholder: "note",
          name: "title",
          validation: "",
          condition: shiftCondition,
          default: "",
          label: "note",
          required: false,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          placeholder: "days",
          name: "days",
          validation: "",
          default: false,
          label: "days",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },

        {
          type: "checkbox",
          placeholder: "reccuring",
          condition: shiftCondition,
          name: "reccuring",
          validation: "",
          default: false,
          label: "reccuring",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },

        {
          type: "info",
          placeholder: "days",
          name: "days",
          content: "selectDays",
          validation: "",
          condition: condition,
          default: false,
          label: "days",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "multiSelect",
          placeholder: "eligibleDays",
          name: "eligibleDays",
          listView: true,
          validation: "",
          default: "",
          label: "eligibleDays",
          required: true,
          view: true,
          condition: condition,
          customClass: "half",
          add: true,
          update: true,
          apiType: "JSON",
          selectApi: [
            { value: t("Monday"), id: "monday" },
            { value: t("Tuesday"), id: "tuesday" },
            { value: t("Wednesday"), id: "wednesday" },
            { value: t("Thursday"), id: "thursday" },
            { value: t("Friday"), id: "friday" },
            { value: t("Saturday"), id: "saturday" },
            { value: t("Sunday"), id: "sunday" },
          ],
        },
        {
          type: "date",
          placeholder: "scheduleEnd",
          name: "endDate",
          validation: "",
          customClass: "half",
          condition: condition,
          greater: "startDate",
          minDate: date.clone().add(1, "days"),
          maxDate: date.clone().add(3, "months"),
          default: date.clone().startOf("day").add(15, "hours"),
          label: "scheduleEnd",
          required: true,
          view: true,
          add: true,
          update: true,
        },
      ]);
    }
    setIsAddNewSchedule(true);
    // const addVal = addValues;
    // setAddValues(addVal);
  };
  const addNewAbsence = (entry, item) => {
    let date = moment(entry.originalDate ?? new Date());
    setTitle("absence");
    setSaveApiUrl("user/addAbsence");
    setAddAttributes([
      {
        type: "date",
        placeholder: "scheduleDate",
        name: "startDate",
        customClass: "half",
        validation: "",
        minDate: date.clone(),
        maxDate: date.clone().add(3, "months"),
        default: date.clone().startOf("day").add(7, "hours"),
        label: "scheduleDate",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "date",
        placeholder: "scheduleEnd",
        name: "endDate",
        validation: "",
        customClass: "half",
        greater: "startDate",
        minDate: date.clone().add(1, "days"),
        maxDate: date.clone().add(3, "months"),
        default: date.clone().startOf("day").add(15, "hours"),
        label: "scheduleEnd",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "multiSelect",
        placeholder: "employee",
        name: "user",
        condition: shiftCondition,
        tags: [
          { item: "title", title: "place", collection: "place" },
          { item: "title", title: "shift", collection: "shiftTemplate" },
        ],
        validation: "",
        default: "",
        label: "employee",
        showItem: "employee",
        required: true,
        view: true,
        add: true,
        update: true,
        apiType: "API",
        selectApi: "user/select",
        customClass: "half",
      },
      {
        type: "select",
        placeholder: "leaveType",
        name: "leaveType",
        validation: "",
        default: "",
        label: "leaveType",
        showItem: "locale",
        required: true,
        view: true,
        add: true,
        update: true,
        apiType: "API",
        selectApi: "leave-type/select",
        customClass: "half",
      },
      {
        type: "textarea",
        placeholder: "notes",
        name: "note",
        validation: "",
        default: "",
        label: "notes",
        required: false,
        view: true,
        add: true,
        update: true,
      },
    ]);

    setIsAddNewSchedule(true);
  };
  const addNEmployeesToRequiredSchedule = (entry, item) => {
    setTitle("assignEmployees");
    setSaveApiUrl("shift/assignEmployees");
    setAddAttributes([
      {
        type: "multiSelect",
        placeholder: "employee",
        name: "user",
        condition: shiftCondition,
        tags: [
          { item: "title", title: "place", collection: "place" },
          { item: "title", title: "shift", collection: "shiftTemplate" },
        ],
        validation: "",
        default: "",
        label: "employee",
        showItem: "employee",
        required: true,
        view: true,
        add: true,
        update: true,
        apiType: "API",
        selectApi: "user/select",
        customClass: "half",
      },
      {
        type: "hidden",
        placeholder: "requiredShift",
        name: "requiredShift",
        validation: "",
        default: entry._id,
        label: "requiredShift",
        required: true,
        view: true,
        add: true,
        update: true,
      },
    ]);
    setIsAddNewSchedule(true);
  };
  const addNewRequiredSchedule = (entry, item) => {
    let date = moment(entry.originalDate ?? new Date());
    setTitle("requiredShift");
    setSaveApiUrl("shift/addRequiredShift");
    setAddAttributes([
      {
        type: "select",
        placeholder: "shift",
        name: "shiftTemplate",
        validation: "",
        updateCondition: [
          { item: "shiftStart", value: "shiftStart" },
          { item: "shiftEnd", value: "shiftEnd" },
          { item: "break", value: "break" },
        ],
        tags: [
          { type: "time", item: "shiftStart", title: "shiftStart", collection: "" },
          { type: "time", item: "shiftEnd", title: "shiftEnd", collection: "" },
        ],
        default: "",
        label: "shift",
        showItem: "title",
        required: true,
        view: true,
        add: true,
        update: true,
        apiType: "API",
        selectApi: "shift/select",
        customClass: "half",
      },
      {
        type: "select",
        placeholder: "place",
        name: "place",
        validation: "",
        condition: shiftCondition,
        default: "",
        label: "place",
        showItem: "title",
        required: true,
        view: true,
        add: true,
        update: true,
        apiType: "API",
        selectApi: "place/select",
        customClass: "half",
      },
      {
        type: "date",
        placeholder: "scheduleDate",
        condition: shiftCondition,
        name: "startDate",
        validation: "",
        minDate: date.clone(),
        maxDate: date.clone().add(3, "months"),
        default: date.clone().startOf("day").add(7, "hours"),
        label: "scheduleDate",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "number",
        placeholder: "break",
        condition: shiftCondition,
        name: "break",
        validation: "",
        default: "0",
        label: "break",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        customClass: "half",
        update: true,
      },
      {
        type: "time",
        placeholder: "shiftStart",
        name: "shiftStart",
        validation: "",
        condition: shiftCondition,
        default: date.clone().startOf("day").add(7, "hours"),
        label: "shiftStart",
        required: true,
        interval: 15,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "time",
        placeholder: "shiftEnd",
        name: "shiftEnd",
        condition: shiftCondition,
        validation: "",
        default: date.clone().startOf("day").add(19, "hours"),
        label: "shiftEnd",
        required: true,
        interval: 15,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "select",
        placeholder: "requiredShift",
        condition: shiftCondition,
        name: "requiredType",
        validation: "",
        default: "",
        showItem: "value",
        label: "requiredShift",
        required: true,
        view: true,
        add: true,
        update: true,
        customClass: "half",
        filter: false,
        apiType: "JSON",
        selectApi: [
          { value: t("At least"), id: "At least" },
          { value: t("At most"), id: "At most" },
          { value: t("Exactly"), id: "Exactly" },
        ],
      },
      {
        type: "number",
        placeholder: "serviceCount",
        condition: shiftCondition,
        name: "serviceCount",
        validation: "",
        default: "",
        showItem: "value",
        label: "serviceCount",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "select",
        placeholder: "timeSettings",
        condition: shiftCondition,
        name: "timeSettings",
        validation: "",
        default: "",
        showItem: "value",
        label: "timeSettings",
        required: true,
        view: true,
        add: true,
        update: true,
        customClass: "half",
        filter: false,
        apiType: "JSON",
        selectApi: [
          { id: "any", value: t("any") },
          { id: "outside", value: t("outside") },
          { id: "starting", value: t("starting") },
          { id: "ending", value: t("ending") },
          { id: "exact", value: t("exact") },
        ],
      },
      // {
      //   type: "multiSelect",
      //   placeholder: "employee",
      //   name: "user",
      //   condition: shiftCondition,
      //   tags: [
      //     { item: "title", title: "place", collection: "place" },
      //     { item: "title", title: "shift", collection: "shiftTemplate" },
      //   ],
      //   validation: "",
      //   default: "",
      //   label: "employee",
      //   showItem: "employee",
      //   required: true,
      //   view: true,
      //   add: true,
      //   update: true,
      //   apiType: "API",
      //   selectApi: "user/select",
      //   customClass: "half",
      // },
      {
        type: "textarea",
        placeholder: "note",
        name: "title",
        validation: "",
        condition: shiftCondition,
        default: "",
        label: "note",
        required: false,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "info",
        placeholder: "days",
        name: "days",
        validation: "",
        default: false,
        label: "days",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "checkbox",
        placeholder: "reccuring",
        condition: shiftCondition,
        name: "reccuring",
        validation: "",
        default: false,
        label: "reccuring",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "info",
        placeholder: "days",
        name: "days",
        content: "selectDays",
        validation: "",
        condition: condition,
        default: false,
        label: "days",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "info",
        placeholder: "days",
        name: "days",
        content: "",
        validation: "",
        condition: condition,
        default: false,
        label: "days",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "multiSelect",
        placeholder: "eligibleDays",
        listView: true,
        name: "eligibleDays",
        validation: "",
        default: "",
        label: "eligibleDays",
        required: true,
        view: true,
        condition: condition,
        customClass: "half",
        add: true,
        update: true,
        apiType: "JSON",
        selectApi: [
          { value: t("Monday"), id: "monday" },
          { value: t("Tuesday"), id: "tuesday" },
          { value: t("Wednesday"), id: "wednesday" },
          { value: t("Thursday"), id: "thursday" },
          { value: t("Friday"), id: "friday" },
          { value: t("Saturday"), id: "saturday" },
          { value: t("Sunday"), id: "sunday" },
        ],
      },
      {
        type: "date",
        placeholder: "scheduleEnd",
        name: "endDate",
        validation: "",
        customClass: "half",
        condition: condition,
        greater: "startDate",
        minDate: date.clone().add(1, "days"),
        maxDate: date.clone().add(3, "months"),
        default: date.clone().startOf("day").add(15, "hours"),
        label: "scheduleEnd",
        required: true,
        view: true,
        add: true,
        update: true,
      },
    ]);

    setIsAddNewSchedule(true);
    // const addVal = addValues;
    // setAddValues(addVal);
  };
  // const editAbsence = async (entry, item, scheduleIndex, absence, isDeleted = false, openDataTemp = {}) => {
  //   if (isDeleted) {
  //     setMessage({
  //       type: 2,
  //       content: t("deleteRequest", { label: t("absence") }),
  //       proceed: t("delete"),
  //       onProceed: async () => {
  //         const loadedDataTemp = { ...loadedData };
  //         const deleteData = { ...absence, isDeleted: isDeleted }; // Create a copy of loadedData
  //         try {
  //           const { place, dayIndex, userIndex } = openDataTemp;
  //           const response = await postData(deleteData, "user/updateAbsence");
  //           const schedules = loadedDataTemp.data.userData[place].users[userIndex].absences[dayIndex]?.items ?? null;
  //           if (response.status === 200) {
  //             if (schedules) {
  //               delete schedules[scheduleIndex];
  //             }
  //           }
  //         } catch (error) {
  //           alert(error);
  //         }
  //         setLoaderBox(false);
  //         setLoadedData(loadedDataTemp);
  //       },
  //     });
  //   } else {
  //     setSelectedSchedule({ ...absence, isDeleted: isDeleted });
  //     setUpdateApiUrl("user/updateAbsence");
  //     setAddOverrideUpdate([
  //       {
  //         type: "textarea",
  //         placeholder: "note",
  //         name: "note",
  //         validation: "",
  //         default: "",
  //         label: "note",
  //         required: true,
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "hidden",
  //         placeholder: "scheduleUser",
  //         name: "_id",
  //         validation: "",
  //         default: absence._id,
  //         label: "scheduleUser",
  //         required: true,
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "hidden",
  //         placeholder: "scheduleUser",
  //         name: "user",
  //         validation: "",
  //         default: absence.user,
  //         label: "scheduleUser",
  //         required: true,
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "info",
  //         condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
  //         name: "deleteSchedule",
  //         validation: "",
  //         content: "Check below to delete this schedule",
  //         label: "deleteSchedule",
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "info",
  //         condition: { item: "isDeleted", if: true, then: "enabled", else: "disabled" },
  //         name: "deleteSchedule",
  //         validation: "",
  //         content: "deleteWarning",
  //         label: "deleteSchedule",
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "checkbox",
  //         placeholder: "delete",
  //         name: "isDeleted",
  //         validation: "",
  //         default: false,
  //         label: "delete",
  //         required: true,
  //         minimum: 0,
  //         maximum: 60,
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "hidden",
  //         placeholder: "absenceGroup",
  //         name: "absenceGroup",
  //         validation: "",
  //         default: absence.absenceGroup,
  //         label: "absenceGroup",
  //         required: true,
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "info",
  //         condition: { item: "deleteAll", if: true, then: "enabled", else: "disabled" },
  //         name: "deleteSchedule",
  //         validation: "",
  //         content: "deleteAllWarning",
  //         label: "deleteSchedule",
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //       {
  //         type: "checkbox",
  //         placeholder: "deleteAll",
  //         name: "deleteAll",
  //         validation: "",
  //         condition: { item: "isDeleted", if: true, then: "enabled", else: "disabled" },
  //         default: false,
  //         label: "deleteAll",
  //         required: true,
  //         minimum: 0,
  //         maximum: 60,
  //         view: true,
  //         add: true,
  //         update: true,
  //       },
  //     ]);
  //     setModifySchedule(true);
  //   }
  // };
  const editSchedule = async (entry, item, scheduleIndex, schedule, isDeleted = false, openDataTemp = {}) => {
    // Use formattedOriginalDate as the default value for scheduleDate

    if (isDeleted) {
      setMessage({
        type: 2,
        content: t("deleteRequest", { label: t("layer") }),
        proceed: t("delete"),
        onProceed: async () => {
          const loadedDataTemp = { ...loadedData };
          const deleteData = { ...schedule, isDeleted: isDeleted }; // Create a copy of loadedData
          try {
            const { place, dayIndex, userIndex } = openDataTemp;
            const response = await postData(deleteData, "shift/updateSingleSchedule");
            const schedules = loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex];
            if (response.status === 200) {
              if (schedules?.items?.length > 1) {
                delete schedules.items[scheduleIndex];
              } else {
                delete schedules.items;
              }
            }
          } catch (error) {
            alert(error);
          }
          setLoaderBox(false);
          setLoadedData(loadedDataTemp);
        },
      });
    } else {
      setSelectedSchedule({ ...schedule, isDeleted: isDeleted });
      setUpdateApiUrl("shift/updateSingleSchedule");
      setAddOverrideUpdate([
        {
          type: "time",
          placeholder: "startTime",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "startTime",
          validation: "",
          default: schedule.startTime,
          label: "startTime",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "endTime",
          name: "endTime",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          validation: "",
          default: schedule.endTime,
          label: "endTime",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "number",
          placeholder: "startBuffer",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "startBuffer",
          validation: "",
          default: schedule.startBuffer,
          showItem: "value",
          label: "startBuffer",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: "10 minutes", id: 10 },
            { value: "15 minutes", id: 15 },
            { value: "20 minutes", id: 20 },
            { value: "25 minutes", id: 25 },
            { value: "30 minutes", id: 30 },
            { value: "45 minutes", id: 45 },
            { value: "60 minutes", id: 60 },
          ],
        },
        {
          type: "number",
          placeholder: "endBuffer",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "endBuffer",
          validation: "",
          default: schedule.endBuffer,
          showItem: "value",
          label: "endBuffer",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: "10 minutes", id: 10 },
            { value: "15 minutes", id: 15 },
            { value: "20 minutes", id: 20 },
            { value: "25 minutes", id: 25 },
            { value: "30 minutes", id: 30 },
            { value: "45 minutes", id: 45 },
            { value: "60 minutes", id: 60 },
          ],
        },
        {
          type: "number",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          placeholder: "break",
          name: "break",
          validation: "",
          default: schedule.break,
          label: "break",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "hidden",
          placeholder: "scheduleUser",
          name: "_id",
          validation: "",
          default: schedule._id,
          label: "scheduleUser",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "hidden",
          placeholder: "scheduleUser",
          name: "shiftSchedule",
          validation: "",
          default: schedule.shiftSchedule,
          label: "scheduleUser",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "hidden",
          placeholder: "scheduleUser",
          name: "user",
          validation: "",
          default: schedule.user,
          label: "scheduleUser",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "deleteSchedule",
          validation: "",
          content: "Check below to delete this schedule",
          label: "deleteSchedule",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          condition: { item: "isDeleted", if: true, then: "enabled", else: "disabled" },
          name: "deleteSchedule",
          validation: "",
          content: "deleteWarning",
          label: "deleteSchedule",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "checkbox",
          placeholder: "delete",
          name: "isDeleted",
          validation: "",
          default: false,
          label: "delete",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          condition: { item: "deleteAll", if: true, then: "enabled", else: "disabled" },
          name: "deleteSchedule",
          validation: "",
          content: "deleteAllWarning",
          label: "deleteSchedule",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "checkbox",
          placeholder: "deleteAll",
          name: "deleteAll",
          validation: "",
          condition: { item: "isDeleted", if: true, then: "enabled", else: "disabled" },
          default: false,
          label: "deleteAll",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
      ]);
      setModifySchedule(true);
    }
    // const addVal = addValues;
    // setAddValues(addVal);
  };
  const editrequiredShift = (entry, item, scheduleIndex, schedule, isDeleted = false, openDataTemp = {}) => {
    if (isDeleted) {
      setMessage({
        type: 2,
        content: t("deleteRequest", { label: t("requiredShift") }),
        proceed: t("delete"),
        onProceed: async () => {
          const loadedDataTemp = { ...loadedData };
          const deleteData = { ...schedule, isDeleted: isDeleted }; // Create a copy of loadedData
          try {
            const { place, dayIndex } = openDataTemp;
            const response = await postData(deleteData, "shift/updateSingleRequiredShift");
            const schedules = loadedDataTemp.data.userData[place].requiredShifts[dayIndex] ?? null;
            if (response.status === 200) {
              if (schedules?.items?.length > 1) {
                delete schedules.items[scheduleIndex];
              } else {
                delete schedules.items;
              }
            }
          } catch (error) {
            alert(error);
          }
          setLoaderBox(false);
          setLoadedData(loadedDataTemp);
        },
      });
    } else {
      // Use formattedOriginalDate as the default value for scheduleDate
      setUpdateApiUrl("shift/updateSingleRequiredShift");
      setSelectedSchedule(schedule);
      let date = moment(schedule.startDate ?? new Date());
      setAddOverrideUpdate([
        {
          type: "select",
          placeholder: "shift",
          name: "shiftTemplate",
          validation: "",
          updateCondition: [
            { item: "startTime", value: "shiftStart" },
            { item: "endTime", value: "shiftEnd" },
            { item: "break", value: "break" },
          ],
          tags: [
            { type: "time", item: "shiftStart", title: "shiftStart", collection: "" },
            { type: "time", item: "shiftEnd", title: "shiftEnd", collection: "" },
          ],
          default: "",
          label: "shift",
          showItem: "title",
          required: true,
          view: true,
          add: true,
          update: true,
          apiType: "API",
          selectApi: "shift/select",
          customClass: "half",
        },
        {
          type: "select",
          placeholder: "place",
          name: "place",
          validation: "",
          condition: shiftCondition,
          default: "",
          label: "place",
          showItem: "title",
          required: true,
          view: true,
          add: true,
          update: true,
          apiType: "API",
          selectApi: "place/select",
          customClass: "half",
        },
        {
          type: "date",
          placeholder: "scheduleDate",
          condition: shiftCondition,
          name: "startDate",
          validation: "",
          minDate: date.clone(),
          maxDate: date.clone().add(3, "months"),
          default: date.clone().startOf("day").add(7, "hours"),
          label: "scheduleDate",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "startTime",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "startTime",
          validation: "",
          default: schedule.startTime,
          label: "startTime",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "time",
          placeholder: "endTime",
          name: "endTime",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          validation: "",
          default: schedule.endTime,
          label: "endTime",
          required: true,
          interval: 15,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "number",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          placeholder: "break",
          name: "break",
          validation: "",
          default: schedule.break,
          label: "break",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "hidden",
          placeholder: "scheduleUser",
          name: "_id",
          validation: "",
          default: schedule._id,
          label: "scheduleUser",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "hidden",
          placeholder: "requiredShift",
          name: "requiredShift",
          validation: "",
          default: schedule.requiredShift,
          label: "requiredShift",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "select",
          placeholder: "requiredShift",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "requiredType",
          validation: "",
          default: schedule.requiredType,
          showItem: "value",
          label: "requiredShift",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { value: t("At least"), id: "At least" },
            { value: t("At most"), id: "At most" },
            { value: t("Exactly"), id: "Exactly" },
          ],
        },
        {
          type: "number",
          placeholder: "serviceCount",
          name: "serviceCount",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          validation: "",
          default: "",
          showItem: "value",
          label: "serviceCount",
          required: true,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "select",
          placeholder: "timeSettings",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "timeSettings",
          validation: "",
          default: "",
          showItem: "value",
          label: "timeSettings",
          required: true,
          view: true,
          add: true,
          update: true,
          customClass: "half",
          filter: false,
          apiType: "JSON",
          selectApi: [
            { id: "any", value: t("any") },
            { id: "outside", value: t("outside") },
            { id: "starting", value: t("starting") },
            { id: "ending", value: t("ending") },
            { id: "exact", value: t("exact") },
          ],
        },

        {
          type: "textarea",
          placeholder: "note",
          name: "title",
          validation: "",
          condition: shiftCondition,
          default: "",
          label: "note",
          required: false,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          condition: { item: "isDeleted", if: false, then: "enabled", else: "disabled" },
          name: "deleteSchedule",
          validation: "",
          content: "Check below to delete this schedule",
          label: "deleteSchedule",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          condition: { item: "isDeleted", if: true, then: "enabled", else: "disabled" },
          name: "deleteSchedule",
          validation: "",
          content: "deleteWarning",
          label: "deleteSchedule",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "checkbox",
          placeholder: "delete",
          name: "isDeleted",
          validation: "",
          default: false,
          label: "delete",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
        {
          type: "info",
          condition: { item: "deleteAll", if: true, then: "enabled", else: "disabled" },
          name: "deleteSchedule",
          validation: "",
          content: "deleteAllWarning",
          label: "deleteSchedule",
          view: true,
          add: true,
          update: true,
        },
        {
          type: "checkbox",
          placeholder: "deleteAll",
          name: "deleteAll",
          validation: "",
          condition: { item: "isDeleted", if: true, then: "enabled", else: "disabled" },
          default: false,
          label: "deleteAll",
          required: true,
          minimum: 0,
          maximum: 60,
          view: true,
          add: true,
          update: true,
        },
      ]);
      setModifySchedule(true);
      // const addVal = addValues;
      // setAddValues(addVal);
    }
  };
  const submitHandler = async (data) => {
    setLoaderBox(true);
    await postData(data, saveApiUrl)
      .then((response) => {
        if (response.status === 200) {
          setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          refreshView(currentIndex);
          setIsAddNewSchedule(false);
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };
  const updateHandler = async (data) => {
    setLoaderBox(true);

    await postData(data, updateApiUrl)
      .then((response) => {
        if (response.status === 200) {
          // setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          if (updateApiUrl === "shift/updateSingleSchedule") {
            const loadedDataTemp = loadedData;
            const schedules = loadedDataTemp.data.userData[openData.place].users[openData.userIndex]?.schedulesDates[openData.dayIndex]?.items ?? null;
            const newSchedule = response.data.item;
            if (newSchedule) {
              if (schedules) {
                schedules[openData.scheduleIndex] = { ...schedules[openData.scheduleIndex], updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user };
              }
            } else {
              if (schedules) {
                delete schedules[openData.scheduleIndex];
              }
            }
          } else {
            const loadedDataTemp = loadedData;
            const schedules = loadedDataTemp.data.userData[openData.place].requiredShifts[openData.dayIndex]?.items ?? null;
            const newSchedule = response.data.item;
            if (newSchedule) {
              if (schedules) {
                schedules[openData.scheduleIndex] = { ...schedules[openData.scheduleIndex], updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, place: newSchedule.place, requiredType: newSchedule.requiredType, serviceCount: newSchedule.serviceCount, timeSettings: newSchedule.timeSettings };
              }
            } else {
              if (schedules) {
                delete schedules[openData.scheduleIndex];
              }
            }
          }
          if (data.deleteAll) {
            refreshView(0);
          }
          // refreshView(currentIndex);
          setModifySchedule(false);
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };
  useEffect(() => {
    setLoaderBox(users.isLoading);
    if (currentIndex === 0 && users.data?.userData) {
      setCount(users.data.userData.length);
    }
  }, [users, currentIndex, setLoaderBox]);

  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject(currentApi, currentIndex, 0, filterView));
    }
  }, [initialized, currentApi, currentIndex, dispatch, filterView]);
  const refreshView = (currentIndex) => {
    try {
      dispatch(addPageObject(currentApi, currentIndex, 0, filterView));
    } catch {}
  };
  // const [isCreating, setIsCreating] = useState(false);

  const handleDrop = async (dropType = "copy", isUpcoming, type, place, event, date, userIndex, dayIndex, user, placeId) => {
    stopDrag();
    const schedule = JSON.parse(event.dataTransfer.getData("text/plain"));
    const originalStartTime = new Date(schedule.schedule.startTime);
    const originalEndTime = new Date(schedule.schedule.endTime);
    const loadedDataTemp = { ...loadedData }; // Create a copy of loadedData
    if (!isUpcoming) {
      setMessage({ content: "Not able to drop here!" });
      return;
    }
    if (type === schedule.type && isUpcoming && date !== schedule.entry.date) {
      if (schedule.type === "schedule") {
        setLoaderBox(true);

        const updatedStartTime = new Date(date);
        updatedStartTime.setHours(originalStartTime.getHours());
        updatedStartTime.setMinutes(originalStartTime.getMinutes());
        updatedStartTime.setSeconds(originalStartTime.getSeconds());

        const updatedEndTime = new Date(date);
        updatedEndTime.setHours(originalEndTime.getHours());
        updatedEndTime.setMinutes(originalEndTime.getMinutes());
        updatedEndTime.setSeconds(originalEndTime.getSeconds());

        const updatedSchedule = {
          user,
          _id: schedule.schedule._id,
          startTime: updatedStartTime.toISOString(),
          endTime: updatedEndTime.toISOString(),
          dropType,
        };

        try {
          const response = await postData(updatedSchedule, "shift/copySchedule");
          const schedules = loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex]?.items ?? null;
          if (response.status === 200) {
            const newSchedule = response.data.newSchedule;
            if (dropType === "copy" || dropType === "expand") {
              if (schedules) {
                schedules.push({ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user });
              } else {
                loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex] = { items: [{ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user }] };
              }
            } else {
              if (schedules) {
                schedules.push({ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user });
              } else {
                loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex] = { items: [{ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user }] };
              }
              delete loadedDataTemp.data.userData[place].users[schedule.userIndex].schedulesDates[schedule.dayIndex].items[schedule.scheduleIndex];
            }
          } else if (response.status === 404) {
            setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          } else {
            setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          }
        } catch (error) {
          alert(error);
        }

        setLoaderBox(false);
        setLoadedData(loadedDataTemp);
      } else if (schedule.type === "requiredShift") {
        const loadedDataTemp = { ...loadedData }; // Create a copy of loadedData

        setLoaderBox(true);

        const updatedStartTime = new Date(date);
        updatedStartTime.setHours(originalStartTime.getHours());
        updatedStartTime.setMinutes(originalStartTime.getMinutes());
        updatedStartTime.setSeconds(originalStartTime.getSeconds());

        const updatedEndTime = new Date(date);
        updatedEndTime.setHours(originalEndTime.getHours());
        updatedEndTime.setMinutes(originalEndTime.getMinutes());
        updatedEndTime.setSeconds(originalEndTime.getSeconds());

        const updatedSchedule = {
          place: placeId,
          _id: schedule.schedule._id,
          startTime: updatedStartTime.toISOString(),
          endTime: updatedEndTime.toISOString(),
          dropType,
        };
        try {
          const response = await postData(updatedSchedule, "shift/copyRequiredShift");
          const shifts = loadedDataTemp.data.userData[place].requiredShifts[dayIndex]?.items ?? null;
          if (response.status === 200) {
            const newSchedule = response.data.newSchedule;
            if (shifts) {
              shifts.push({ ...schedule.schedule, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, place: newSchedule.place });
            } else {
              loadedDataTemp.data.userData[place].requiredShifts[dayIndex] = { items: [{ ...schedule.schedule, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, place: newSchedule.place }] };
            }
          } else if (response.status === 404) {
            setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          } else {
            setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          }
        } catch (error) {
          alert(error);
        }
        if (workTimeLog) setLoaderBox(false);
        setLoadedData(loadedDataTemp);
      }
    } else if (type === "schedule" && schedule.type === "requiredShift") {
      const loadedDataTemp = { ...loadedData }; // Create a copy of loadedData

      setLoaderBox(true);

      const updatedStartTime = new Date(date);
      updatedStartTime.setHours(originalStartTime.getHours());
      updatedStartTime.setMinutes(originalStartTime.getMinutes());
      updatedStartTime.setSeconds(originalStartTime.getSeconds());

      const updatedEndTime = new Date(date);
      updatedEndTime.setHours(originalEndTime.getHours());
      updatedEndTime.setMinutes(originalEndTime.getMinutes());
      updatedEndTime.setSeconds(originalEndTime.getSeconds());

      const updatedSchedule = {
        user,
        _id: schedule.schedule._id,
        startTime: updatedStartTime.toISOString(),
        endTime: updatedEndTime.toISOString(),
        dropType,
      };

      try {
        const response = await postData(updatedSchedule, "shift/copyRequiredToSchedule");
        const schedules = loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex]?.items ?? null;
        // console.log("schedules", schedules);
        if (response.status === 200) {
          const newSchedule = response.data.newSchedule;

          // console.log("new", { items: [{ ...newSchedule, updated: true, shiftDetails: schedule.schedule.shiftDetails, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user }] });
          if (schedules) {
            schedules.push({ ...newSchedule, updated: true, shiftDetails: schedule.schedule.shiftDetails, user: newSchedule.user });
          } else {
            loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex] = { items: [{ ...newSchedule, updated: true, shiftDetails: schedule.schedule.shiftDetails, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user }] };
          }
          // if (schedules) {
          //   schedules.push({ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user });
          // } else {
          //   loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[dayIndex] = { items: [{ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user }] };
          // }
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoadedData(loadedDataTemp);
      } catch (error) {
        alert(error);
      }

      setLoaderBox(false);
      setLoadedData(loadedDataTemp);
    } else {
    }

    if (dropType === "expand") {
      setLoaderBox(true);
      const updatedStartTime = new Date(date);
      const copyStartDate = moment(originalStartTime);
      const copyEndDate = moment(updatedStartTime);
      const numDaysBetween = copyEndDate.diff(copyStartDate, "days");

      // hwo to check how many dates between startDate and endDate
      if (originalStartTime < updatedStartTime && numDaysBetween > 0 && user === schedule.schedule.user) {
        const updatedStartTime = new Date(date);
        updatedStartTime.setHours(originalStartTime.getHours(), originalStartTime.getMinutes(), originalStartTime.getSeconds());

        const updatedEndTime = new Date(date);
        updatedEndTime.setHours(originalEndTime.getHours(), originalEndTime.getMinutes(), originalEndTime.getSeconds());

        const updatedSchedule = {
          user,
          _id: schedule.schedule._id,
          shiftScheduleId: schedule.schedule.shiftSchedule,
          startTime: updatedStartTime.toISOString(),
          endTime: updatedEndTime.toISOString(),
          startDate: copyStartDate.add(1, "days").startOf("day").add(7, "hours").toISOString(),
          endDate: copyEndDate.add(-1, "days").startOf("day").add(15, "hours").toISOString(),
        };

        try {
          // const loadedDataTemp = { ...loadedData }; // Create a copy of loadedData
          const response = await postData(updatedSchedule, "shift/copyScheduleBetween");
          try {
            if (response.status === 200) {
              response.data?.savedItems?.forEach((newSchedule) => {
                const newdayIndex = moment(new Date(newSchedule.startTime)).format("YYYY-MM-DD");
                const schedules = loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[newdayIndex]?.items ?? null;
                if (schedules) {
                  schedules.push({ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user });
                } else {
                  loadedDataTemp.data.userData[place].users[userIndex].schedulesDates[newdayIndex] = { items: [{ ...schedule.schedule, updated: true, _id: newSchedule._id, startTime: newSchedule.startTime, endTime: newSchedule.endTime, endBuffer: newSchedule.endBuffer, startBuffer: newSchedule.startBuffer, user: newSchedule.user }] };
                }
              });
            }
          } catch (error) {
            setLoaderBox(false);
          }
          setLoaderBox(false);
          setLoadedData(loadedDataTemp);
        } catch {}
      }
    }
  };

  const ActionBox = ({ onEdit, onDelete, onAdd, onSelect, isSelected = false, color, addDate }) => {
    return (
      <HoverActions className="action" color={color}>
        <button onMouseUp={onEdit}>
          <GetIcon icon={"edit"} />
        </button>
        <button onMouseUp={onDelete}>
          <GetIcon icon={"delete"} />
        </button>
        {/* <button onClick={onSelect}>{isSelected ? <GetIcon icon={"tick"} /> : <GetIcon icon={"box"} />}</button> */}
        {onAdd && addDate >= new Date() && (
          <button onMouseUp={onAdd}>
            <GetIcon icon={"add"} />
          </button>
        )}
      </HoverActions>
    );
  };
  const DropActionBox = ({ isUpcoming, type, place, date, userIndex, dayIndex, user, placeId, color, addDate }) => {
    return isUpcoming ? (
      <HoverActions
        onMouseEnter={() => {
          setDragOver("");
        }}
        className="draghover"
        color={color}
      >
        {"schedule" === dragData.type && (
          <button
            onDrop={(event) => {
              handleDrop("move", isUpcoming, type, place, event, date, userIndex, dayIndex, user, placeId);
              setDragOver("");
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <GetIcon icon={"move"} />
          </button>
        )}
        <button
          onDrop={(event) => {
            handleDrop("copy", isUpcoming, type, place, event, date, userIndex, dayIndex, user, placeId);
            setDragOver("");
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <GetIcon icon={"copy"} />
        </button>
        {addDate && addDate >= new Date() && user === dragData.user && (
          <button
            onDrop={(event) => {
              handleDrop("expand", isUpcoming, type, place, event, date, userIndex, dayIndex, user, placeId);
              setDragOver("");
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <GetIcon icon={"expand"} />
          </button>
        )}
      </HoverActions>
    ) : (
      <HoverActions
        onMouseEnter={() => {
          setDragOver("");
        }}
        className="draghover"
      ></HoverActions>
    );
  };
  const [dragOver, setDragOver] = useState("");
  const [dragData, setDragData] = useState({});
  // const countedShifts = {};
  function countShiftsByType(value, entry, requiredShift) {
    try {
      let value1 = 0;
      value.users.forEach((user) => {
        const userDates = user.schedulesDates[entry.date]?.items ?? [];

        userDates?.forEach((s) => {
          if (moment(new Date(s.startTime)).format("YYYY-MM-DD") !== entry.date) {
            return s;
          }
          // if ((countedShifts[s._id] ?? false) === true) {
          //   return s;
          // }
          // if (requiredShift.serviceCount === value1) {
          //   return s;
          // }
          if (requiredShift.shiftTemplate !== s.shiftTemplate) {
            return s;
          }
          switch (requiredShift.timeSettings) {
            case "any":
              if (moment(new Date(s.startTime)).format("YYYY-MM-DD") === entry.date) {
                value1 += 1;
                // countedShifts[s._id] = true;
              }
              break;
            case "outside":
              if (moment(new Date(s.endTime)).isAfter(moment(new Date(requiredShift.endTime))) && moment(new Date(s.endTime)).isBefore(moment(new Date(requiredShift.endTime)))) {
                value1 += 1;
                // countedShifts[s._id] = true;
              }
              break;
            case "starting":
              if (moment(new Date(s.startTime)).isSame(moment(new Date(requiredShift.startTime)))) {
                value1 += 1;
                // countedShifts[s._id] = true;
              }
              break;
            case "ending":
              if (moment(new Date(s.endTime)).isSame(moment(new Date(requiredShift.endTime)))) {
                value1 += 1;
                // countedShifts[s._id] = true;
              }
              break;
            case "exact":
              if (moment(new Date(s.startTime)).isSame(moment(new Date(requiredShift.startTime))) && moment(new Date(s.endTime)).isSame(moment(new Date(requiredShift.endTime)))) {
                value1 += 1;
                // countedShifts[s._id] = true;
              }
              break;
            default:
              break;
          }
          return s;
        });
      });

      return value1;
    } catch (error) {
      return 0; // Return a default value or handle the error case accordingly
    }
  }
  ///drag select
  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const [selectedReqShift, setSelectedReqShift] = useState([]);
  const [selectedReqShiftKeys, setSelectedReqShiftKeys] = useState({});
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragEnd, setDragEnd] = useState({ x: 0, y: 0 });
  const [dragStatus, setDragStatus] = useState(false); // Added dragStatus state
  const [dragMoving, setDragMoving] = useState(false); // Added dragStatus state
  function handleDragStart(event) {
    if (!dragStatus) {
      const { clientX, clientY } = event;
      setDragStatus(true); // Update dragStatus when starting drag
      setDragStart({ x: clientX, y: clientY });
      setDragEnd({ x: clientX, y: clientY });
      document.body.focus();
    }
  }

  function handleDragMove(event) {
    if (dragStatus) {
      if (!dragStart.x) return;
      setDragMoving(true);
      const { clientX, clientY } = event;
      setDragEnd({ x: clientX, y: clientY });
    } else {
      setDragStart({ x: 0, y: 0 });
      setDragEnd({ x: 0, y: 0 });
    }
  }

  function handleDragEnd() {
    // Add a condition to check before running the main logic
    if (!isConditionMet()) {
      setSelectedElements([]);
      setSelectedKeys({});
      setSelectedReqShift([]);
      setSelectedReqShiftKeys({});
      stopDrag();
      return; // Stop execution if the condition is not met
    }

    const scheduleElements = document.querySelectorAll(".schedule");
    const selectedKeysTemp = {};
    let selected = Array.from(scheduleElements)
      .map((element) => isElementInSelection(element))
      .filter((value) => {
        if (value !== null) {
          selectedKeysTemp[value] = true;
          return true;
        } else {
          return false;
        }
      });
    setSelectedElements(selected);
    setSelectedKeys(selectedKeysTemp);

    const selectedReqShiftTemp = document.querySelectorAll(".required");
    const selectedReqShiftKeysTemp = {};
    selected = Array.from(selectedReqShiftTemp)
      .map((element) => isElementInSelection(element))
      .filter((value) => {
        if (value !== null) {
          selectedReqShiftKeysTemp[value] = true;
          return true;
        } else {
          return false;
        }
      });
    setSelectedReqShift(selected);
    setSelectedReqShiftKeys(selectedReqShiftKeysTemp);

    stopDrag();
  }
  const handleDragStartProject = (e, id, sequence, prev, next) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/plain", id + "-" + sequence + prev + "-" + next);
  };

  const handleDragOverProject = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const handleDropProject = async (e, id, sequence, prev, next) => {
    e.preventDefault();
    setLoaderBox(true);
    // Retrieve the dragged data and split it
    let dataValue = e.dataTransfer.getData("text/plain");
    dataValue = dataValue.split("-");
    const draggedRowId = parseInt(dataValue[0]);
    // const oldSequence = parseFloat(dataValue[1]);
    // const prev = parseFloat(dataValue[1]);
    // const next = parseFloat(dataValue[2]);
    const droppedRow = loadedData.data?.userData[id];

    // Copy data to update it

    const updatedRows = { ...loadedData };

    const draggedSequence = droppedRow.sequence ?? 0;
    const dropedSequence = droppedRow.sequence ?? 0 + 0.001;

    updatedRows.data.userData[draggedRowId].sequence = draggedSequence;
    updatedRows.data.userData[id].sequence = dropedSequence;

    const [draggedRow] = updatedRows.data?.userData.splice(draggedRowId, 1);
    updatedRows.data?.userData.splice(id, 0, draggedRow);

    // Update the state with the modified data

    setLoadedData(updatedRows);
    setSelectedElements([]);
    setSelectedKeys({});
    setSelectedReqShift([]);
    setSelectedReqShiftKeys({});
    stopDrag();
    setLoaderBox(false);
    await putData({ sequence: draggedSequence }, `place/${draggedRow.placeId}`);
    await putData({ sequence: dropedSequence + 0.001 }, `place/${droppedRow.placeId}`);
  };
  function isConditionMet() {
    const minX = Math.min(dragStart.x, dragEnd.x);
    const maxX = Math.max(dragStart.x, dragEnd.x);
    const minY = Math.min(dragStart.y, dragEnd.y);
    const maxY = Math.max(dragStart.y, dragEnd.y);

    // Define your condition based on minX, maxX, minY, and maxY
    const conditionMet = maxX - minX >= 50 || maxY - minY >= 50;

    return conditionMet;
  }
  const stopDrag = () => {
    setOverId(null);
    setDragStatus(false); // Reset dragStatus when ending drag
    setDragStart({ x: 0, y: 0 });
    setDragEnd({ x: 0, y: 0 });
  };
  function isElementInSelection(element, minWidth = 50, minHeight = 50) {
    const minX = Math.min(dragStart.x, dragEnd.x);
    const maxX = Math.max(dragStart.x, dragEnd.x);
    const minY = Math.min(dragStart.y, dragEnd.y);
    const maxY = Math.max(dragStart.y, dragEnd.y);

    const elementRect = element.getBoundingClientRect();

    const elementMinX = elementRect.left;
    const elementMaxX = elementRect.right;
    const elementMinY = elementRect.top;
    const elementMaxY = elementRect.bottom;

    const width = elementMaxX - elementMinX;
    const height = elementMaxY - elementMinY;

    const status = (width >= minWidth || height >= minHeight) && elementMaxX >= minX && elementMinX <= maxX && elementMaxY >= minY && elementMinY <= maxY;

    if (status) {
      return element.value;
    } else {
      return null;
    }
  }
  const selectedMenu = useSelector((state) => state.selectedMenu);
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "show",
      name: "show",
      validation: "",
      default: "",
      label: "show",
      required: false,
      filter: true,
      view: false,
      add: false,
      update: false,
      filterType: "select",
      apiType: "JSON",
      selectApi: [
        { id: "1", value: `${t("firstName")} ${t("lastName")}` },
        { id: "2", value: `${t("lastName")} ${t("firstName")}` },
        { id: "3", value: `${t("firstName")}` },
        { id: "4", value: `${t("lastName")}` },
      ],
    },
  ]);
  ///drag select
  return (
    <Container className="noshadow">
      <RowContainer className="list">
        <Title className="inner">
          <GetIcon icon={selectedMenu.icon}></GetIcon>
          {t(selectedMenu.label)}
        </Title>
        <ButtonPanel>
          <FilterBox>
            <Search theme={themeColors} placeholder="Search" value={searchValue} onChange={handleChange}></Search>

            <Filter
              theme={themeColors}
              onClick={() => {
                refreshView(currentIndex);
              }}
            >
              <GetIcon icon={"reload"} />
            </Filter>
          </FilterBox>
          <Filters>
            {attributes.map((item, index) => {
              return item.type === "select" && (item.filter ?? true) === true && <FormInput customClass={"filter"} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index} id={item.name} {...item} onChange={filterChange} />;
            })}
          </Filters>
          <Filters style={{ marginRight: 0 }}>
            <SelectTab
              selected={viewMode}
              onChange={(item) => {
                setViewMode(item);
                filterChange({ id: item }, "viewMode");
              }}
              tabs={[
                { id: "day", title: "dayView" },
                { id: "week", title: "weekView" },
                { id: "month", title: "monthView" },
              ]}
            ></SelectTab>
          </Filters>
          <Filters className="center">
            <DateSelector dynamicClass="filter" label={"Select Date"} placeholder={"Select Date"} selected={viewMode} value={new Date().toISOString()} onChange={dateRangeChange} themeColors={themeColors}></DateSelector>
          </Filters>
          {selectedElements.length > 0 && (
            <AddButton
              className="red"
              onClick={async () => {
                await postData({ selectedElements }, "shift/delete-schedules");
                setSelectedElements([]);
                setSelectedKeys({});
                refreshView();
              }}
            >
              <GetIcon icon={"delete"}></GetIcon>
              {`(${selectedElements.length} ${t("layer")})`}
            </AddButton>
          )}
          {selectedReqShift.length > 0 && (
            <AddButton
              className="red"
              onClick={async () => {
                await postData({ selectedReqShift }, "shift/delete-required-schedules");
                setSelectedReqShift([]);
                setSelectedReqShiftKeys({});
                refreshView();
              }}
            >
              <GetIcon icon={"delete"}></GetIcon> {`${selectedReqShift.length} (${t("requiredShift")})`}
            </AddButton>
          )}
          {(selectedElements.length > 0 || selectedReqShift.length > 0) && (
            <AddButton
              className="green more"
              onClick={async () => {
                setSelectedElements([]);
                setSelectedKeys({});
                setSelectedReqShift([]);
                setSelectedReqShiftKeys({});
              }}
            >
              <GetIcon icon={"close"}></GetIcon>
            </AddButton>
          )}
          <AddButton className="more">
            <AddIcon></AddIcon>
            <div style={{ display: "none" }}>
              <AddButton onClick={() => addNewSchedule({}, {})}>{t("layer")}</AddButton>
              <AddButton onClick={() => addNewRequiredSchedule({}, {})}>{t("requiredShift")}</AddButton>
              <AddButton onClick={() => addNewAbsence({}, {})}>{t("absence")}</AddButton>
            </div>
          </AddButton>

          <ToggleSwitchContainer className="list">
            <ToggleInput
              type="checkbox"
              checked={showWorked}
              icon={"open-book"}
              onChange={async (event) => {
                setShowWorked(event.target.checked);
              }}
            />
            <ToggleSwitch checked={showWorked} disable={"gray"} enable={"green"}>
              <GetIcon icon={"open-book"} />
            </ToggleSwitch>
          </ToggleSwitchContainer>
        </ButtonPanel>
        <ScrollContainerLayout>
          <ScrollLayout onMouseDown={handleDragStart} onMouseMove={handleDragMove} onMouseUp={handleDragEnd}>
            <Table>
              {loadedData.data?.userData?.length > 0 ? (
                loadedData.data?.userData.map((value, palceIndex) => {
                  // Perform operations on each key-value pair
                  const collapse = openedPlace[`place-${value.placeId}`] ?? loadedData.data.userData[palceIndex].isShow;
                  const prev = loadedData.data.userData[palceIndex - 1]?.sequence ?? 0;
                  const next = loadedData.data.userData[palceIndex + 1]?.sequence ?? value.sequence;
                  let result = [];
                  result.push(
                    <React.Fragment key={palceIndex}>
                      <thead>
                        <Tr
                          draggable={true}
                          onDragStart={(e) => {
                            handleDragStartProject(e, palceIndex, value.sequence, prev, next);
                          }}
                          onDragOver={(e) => {
                            handleDragOverProject(e);
                          }}
                          onDragLeave={() => {
                            // setIsDragOver(false);
                          }}
                          onDrop={(e) => handleDropProject(e, palceIndex, value.sequence, prev, next)}
                          color={value.color}
                          className={"project " + (palceIndex === 0 ? "" : "secondHead")}
                        >
                          <Th style={viewMode === "day" ? { width: "calc(10%)" } : {}} className="name">
                            <Collapse
                              className={collapse}
                              onClick={() => {
                                // const loadedDataTemp = { ...loadedData };
                                setOpenedPlace({ ...openedPlace, [`place-${value.placeId}`]: !collapse });
                              }}
                            >
                              {collapse ? <GetIcon icon={"down"}></GetIcon> : <GetIcon icon={"down"}></GetIcon>}
                            </Collapse>
                            <span>{value.title}</span>
                            <div className="small">{`${value.users.filter((item) => new Date(item.contractEnd) > new Date() || item.contractEnd !== null).length}/${value.users.length} ${t("employees")}`}</div>
                          </Th>
                          {viewMode !== "day" && workTimeLog.map((entry) => <Th className="center" key={entry.date}>{`${t(entry.dayName)}, ${entry.day}`}</Th>)}
                          {viewMode === "day" && (
                            <Th colSpan={24} className="center day">
                              <DayBox>
                                {dayTimeLog.map((entry) => (
                                  <div key={entry}>{entry}</div>
                                ))}
                              </DayBox>
                            </Th>
                          )}
                        </Tr>
                      </thead>
                      {collapse && (
                        <React.Fragment>
                          <tbody>
                            <Tr className="project1" color={value.color} key={`work-log-${palceIndex}`}>
                              <Td className="name">{t("requiredShift")}</Td>
                              {workTimeLog.map((entry) => {
                                const daySchedules = value.requiredShifts[entry.date]?.items?.length > 0 ? value.requiredShifts[entry.date]?.items : [];
                                const isUpcoming = true;
                                const isHoliday = loadedData.data?.holidays?.[entry.date] ?? false;
                                const isWeekend = ["sun", "sat"].includes(entry.dayNameId.toLowerCase());
                                return (
                                  <Td
                                    colSpan={viewMode === "day" && 24}
                                    onDragOver={(e) => setDragOver(`${palceIndex}-${entry.date}`)}
                                    onMouseOver={() => {
                                      setDragOver("");
                                    }}
                                    key={entry.date}
                                    className={(daySchedules?.length > 0 ? "has" : "no") + ` ${viewMode} ` + (dragOver === palceIndex + "-" + entry.date ? " dragged" : "")}
                                  >
                                    {viewMode === "day" && (
                                      <DayBox className="days">
                                        {dayTimeLog.map((entry) => (
                                          <div key={entry}></div>
                                        ))}
                                      </DayBox>
                                    )}
                                    {isHoliday && (
                                      <Absence className="absence" color={"red "}>
                                        {/* <span> {t("holiday")}</span> */}
                                        <span> {loadedData.data?.holidays?.[entry.date] ?? t("weekend")}</span>
                                      </Absence>
                                    )}
                                    {isWeekend && (
                                      <Absence className="weekend" color={"red "}>
                                        {/* <span> {t("holiday")}</span> */}
                                        {/* <span> {loadedData.data?.holidays?.[entry.date] ?? t("weekend")}</span> */}
                                      </Absence>
                                    )}
                                    {daySchedules?.length > 0 ? (
                                      <SchedulesBox className={viewMode}>
                                        {/* {isHoliday && (
                                        <Schedule className="absence" color={"red"}>
                                          <span> {t("holiday")}</span>
                                          <span> {loadedData.data?.holidays?.[entry.date]}</span>
                                        </Schedule>
                                      )} */}
                                        {daySchedules.map((schedule, scheduleIndex) => {
                                          const shiftCount = countShiftsByType(value, entry, schedule);

                                          let percentageLeftMarginStart = 0;
                                          let percentageLeftMarginEnd = 0;
                                          if (viewMode === "day") {
                                            const totalSecondsInDay = 24 * 60 * 60; // Total seconds in a day

                                            const startMoment = moment(schedule.startTime);
                                            const endMoment = moment(schedule.endTime);
                                            const secondsStart = startMoment.hour() * 3600 + startMoment.minute() * 60 + startMoment.second();
                                            const secondsEnd = endMoment.hour() * 3600 + endMoment.minute() * 60 + endMoment.second();

                                            percentageLeftMarginStart = (secondsStart / totalSecondsInDay) * 100;
                                            percentageLeftMarginEnd = ((totalSecondsInDay - secondsEnd) / totalSecondsInDay) * 100;
                                          }
                                          return (
                                            <Schedule
                                              data-disableselect={true}
                                              style={{
                                                marginLeft: `${percentageLeftMarginStart}%`,
                                                marginRight: `${percentageLeftMarginEnd}%`,
                                                width: percentageLeftMarginStart > 80 ? "fit-content" : "auto",
                                              }}
                                              color={schedule.shiftDetails[0]?.color ?? "black"}
                                              key={schedule._id}
                                              value={schedule._id}
                                              title={`${shiftCount ?? 0} out of ${schedule.timeSettings} ${schedule.serviceCount} are planned`}
                                              onMouseEnter={() => {
                                                setOverId(schedule.place + "-" + schedule.requiredShift);
                                              }}
                                              onMouseLeave={() => {
                                                setOverId("");
                                              }}
                                              draggable
                                              onDragStart={(e) => {
                                                setDragData({ type: "requiredShift", originalDate: entry.originalDate, user: null });
                                                e.dataTransfer.setData("text/plain", JSON.stringify({ type: "requiredShift", schedule, entry, value: value.requiredShifts, scheduleIndex }));
                                                stopDrag();
                                              }}
                                              className={(new Date(entry.originalDate) >= new Date() || new Date(entry.originalDate).toDateString() === new Date().toDateString() ? " non-selectable-area" : "expired non-selectable-area") + (overid === schedule.place + "-" + schedule.requiredShift ? " active" : "") + " required " + (selectedReqShiftKeys[schedule._id] ? " selected " : "") + viewMode + " required"}
                                            >
                                              <span>
                                                {viewMode === "month" ? schedule.shiftDetails[0]?.shortForm : schedule.shiftDetails[0]?.title}
                                                <span style={shiftCount === schedule.serviceCount ? { backgroundColor: "green", color: "white" } : shiftCount >= schedule.serviceCount ? { backgroundColor: "orange", color: "black" } : { backgroundColor: "red", color: "white" }}>
                                                  {shiftCount ?? 0}
                                                  {schedule.requiredType === "At most" ? " ≤ " : ""}
                                                  {schedule.requiredType === "Exactly" ? " / " : ""}
                                                  {schedule.requiredType === "At least" ? " ≥ " : ""}
                                                  {schedule.serviceCount}
                                                </span>
                                              </span>

                                              <span>
                                                {schedule.timeSettings === "ending" ? `${t("End at")} ${timeFormat(schedule.startTime)}` : schedule.timeSettings === "starting" ? `${t("Starts at")} ${timeFormat(schedule.startTime)}` : `${timeFormat(schedule.startTime)} - ${timeFormat(schedule.endTime)}`}
                                                {/* {schedule.updated && <GetIcon icon={"edited"}></GetIcon>} */}
                                              </span>

                                              <ActionBox
                                                color={schedule.shiftDetails[0]?.color ?? "black"}
                                                onEdit={() => {
                                                  setOpenData({ place: palceIndex, dayIndex: entry.date, scheduleIndex });
                                                  editrequiredShift(entry, value.requiredShifts, scheduleIndex, schedule);
                                                }}
                                                onDelete={() => {
                                                  setOpenData({ place: palceIndex, dayIndex: entry.date, scheduleIndex });
                                                  editrequiredShift(entry, value.requiredShifts, scheduleIndex, schedule, true, { place: palceIndex, dayIndex: entry.date, scheduleIndex });
                                                }}
                                                addDate={new Date(entry.originalDate)}
                                                onAdd={() => {
                                                  //addNewRequiredSchedule(entry, value.requiredShifts);
                                                  addNEmployeesToRequiredSchedule(schedule, value.requiredShifts);
                                                }}
                                              ></ActionBox>
                                            </Schedule>
                                          );
                                        })}
                                      </SchedulesBox>
                                    ) : new Date(entry.originalDate).setHours(1) >= new Date().setHours(0) ? (
                                      <CenterBox>
                                        {/* {isHoliday && <div>{t("holiday")}</div>} */}
                                        <BlankButton
                                          onClick={() => {
                                            addNewRequiredSchedule(entry, value.requiredShifts);
                                          }}
                                        >
                                          <GetIcon icon="add" />
                                        </BlankButton>
                                      </CenterBox>
                                    ) : (
                                      <BlankButton
                                        onClick={() => {
                                          addNewRequiredSchedule(entry, value.requiredShifts);
                                        }}
                                      >
                                        <GetIcon icon="add" />
                                      </BlankButton>
                                    )}

                                    {/* handleDrop(isUpcoming, "requiredShift", palceIndex, event, entry.date, 0, entry.date, 0, value.placeId) */}
                                    <DropActionBox color={"black"} isUpcoming={isUpcoming} type={"requiredShift"} place={palceIndex} date={entry.date} userIndex={0} dayIndex={entry.date} user={0} placeId={value.placeId} addDate={new Date(entry.originalDate)}></DropActionBox>
                                  </Td>
                                );
                              })}
                            </Tr>
                          </tbody>
                          {/* <thead>
                            <Tr color={value.color} className="project secondHead">
                              <Th className="name  secondHead">{t("fullName")}</Th>
                              {viewMode !== "day" && workTimeLog.map((entry) => <Th className="center" key={entry.date}>{`${t(entry.dayName)} ${entry.day}`}</Th>)}
                              {viewMode === "day" && (
                                <Th colSpan={24} className="center day">
                                  <DayBox>
                                    {dayTimeLog.map((entry) => (
                                      <div key={entry}>{entry}</div>
                                    ))}
                                  </DayBox>
                                </Th>
                              )}
                              {showWorked && (
                                <Th className="total">
                                  <RowContainer className="date">{t("total")}</RowContainer>
                                </Th>
                              )}
                            </Tr>
                          </thead> */}
                          <tbody>
                            {value.users.map((item, index) => {
                              const isActive = new Date(item.contractEnd) > new Date() || item.contractEnd === null;
                              const scheduleCount = Object.keys(item.schedulesDates).length;
                              let totalHours = 0;
                              let plannedHours = 0;
                              return isActive || (!isActive && scheduleCount > 0) ? (
                                <Tr key={`work-log-${index}`}>
                                  <Td className="name"  color={value.color} key={index}>
                                    {generateThumbnail(item.fullName, isActive)}
                                    {item.fullName}
                                  </Td>

                                  {workTimeLog.map((entry) => {
                                    const daySchedules = item.schedulesDates[entry.date]?.items ?? [];
                                    const absences = item.absences[entry.date]?.items ?? [];
                                    const workedHours = item.attendanceData[entry.date]?.items ?? [];
                                    const isUpcoming = true; //new Date(entry.originalDate) > new Date();
                                    let hasSchedule = false;
                                    totalHours += workedHours.reduce((acc, current) => acc + current.workedHours, 0);
                                    plannedHours += daySchedules.reduce((acc, current) => acc + current.duration, 0);
                                    const isHoliday = loadedData.data?.holidays?.[entry.date] ?? false;
                                    const isWorkDay = !(item.WorkDays[entry.dayNameId.toLowerCase()] ?? true);
                                    return (
                                      <Td
                                        colSpan={viewMode === "day" && 24}
                                        // onDrop={(event) => handleDrop("copy", isUpcoming, "schedule", palceIndex, event, entry.date, index, entry.date, item._id)}
                                        onDragOver={(e) => setDragOver(`${palceIndex}-${index}-${entry.date}`)}
                                        key={entry.date}
                                        className={(daySchedules?.length > 0 || absences.length > 0 ? "has" : "no") + (dragOver === palceIndex + "-" + index + "-" + entry.date ? " dragged" : "")}
                                      >
                                        {isHoliday && (
                                          <Absence className="absence" color={"red "}>
                                            {/* <span> {t("holiday")}</span> */}
                                            <span> {loadedData.data?.holidays?.[entry.date] ?? t("weekend")}</span>
                                          </Absence>
                                        )}
                                        {isWorkDay && (
                                          <Absence className="weekend" color={"red "}>
                                            {/* <span> {t("weekend")}</span> */}
                                          </Absence>
                                        )}
                                        <CenterBox className={viewMode}>
                                          {viewMode === "day" && (
                                            <DayBox className="days">
                                              {dayTimeLog.map((entry) => (
                                                <div key={entry}></div>
                                              ))}
                                            </DayBox>
                                          )}

                                          {daySchedules.map((schedule, scheduleIndex) => {
                                            hasSchedule = true;
                                            let percentageLeftMarginStart = 0;
                                            let percentageLeftMarginEnd = 0;
                                            if (viewMode === "day") {
                                              const totalSecondsInDay = 24 * 60 * 60; // Total seconds in a day

                                              const startMoment = moment(schedule.startTime);
                                              const endMoment = moment(schedule.endTime);
                                              const secondsStart = startMoment.hour() * 3600 + startMoment.minute() * 60 + startMoment.second();
                                              const secondsEnd = endMoment.hour() * 3600 + endMoment.minute() * 60 + endMoment.second();

                                              percentageLeftMarginStart = (secondsStart / totalSecondsInDay) * 100;
                                              percentageLeftMarginEnd = ((totalSecondsInDay - secondsEnd) / totalSecondsInDay) * 100;
                                            }
                                            return (
                                              <Schedule
                                                color={schedule.shiftDetails[0]?.color ?? "black"}
                                                key={schedule._id}
                                                value={schedule._id}
                                                style={{
                                                  marginLeft: `${percentageLeftMarginStart}%`,
                                                  marginRight: `${percentageLeftMarginEnd}%`,
                                                  width: percentageLeftMarginStart > 80 ? "fit-content" : "-webkit-fill-available",
                                                }}
                                                onMouseEnter={() => {
                                                  setOverId(schedule.user + "-" + schedule.shiftSchedule);
                                                }}
                                                onMouseLeave={() => {
                                                  setOverId("");
                                                }}
                                                draggable
                                                onDragStart={(e) => {
                                                  stopDrag();
                                                  setDragData({ type: "schedule", originalDate: entry.originalDate, user: schedule.user });
                                                  e.dataTransfer.setData("text/plain", JSON.stringify({ type: "schedule", entry: entry.date, userIndex: index, schedule, scheduleIndex, value: value.users, dayIndex: entry.date }));
                                                  stopDrag();
                                                }}
                                                className={(new Date(entry.originalDate) >= new Date() || new Date(entry.originalDate).toDateString() === new Date().toDateString() ? "" : "expired") + (overid === schedule.user + "-" + schedule.shiftSchedule ? " active" : "") + " schedule " + viewMode + (selectedKeys[schedule._id] ? " selected" : "")}
                                              >
                                                <span>{viewMode === "month" ? schedule.shiftDetails[0]?.shortForm ?? schedule.title : schedule.title}</span>
                                                <span>
                                                  {`${timeFormat(schedule.startTime)} - ${timeFormat(schedule.endTime)} `}
                                                  {schedule.updated && <GetIcon icon={"edited"}></GetIcon>}
                                                </span>
                                                <ActionBox
                                                  color={schedule.shiftDetails[0]?.color ?? "black"}
                                                  onEdit={() => {
                                                    setOpenData({ place: palceIndex, userIndex: index, dayIndex: entry.date, scheduleIndex });
                                                    editSchedule(entry, item, scheduleIndex, schedule);
                                                    document.body.focus();
                                                    setDragStatus(false);
                                                  }}
                                                  onDelete={() => {
                                                    setOpenData({ place: palceIndex, userIndex: index, dayIndex: entry.date, scheduleIndex });
                                                    editSchedule(entry, item, scheduleIndex, schedule, true, { place: palceIndex, userIndex: index, dayIndex: entry.date, scheduleIndex });
                                                    document.body.focus();
                                                    setDragStatus(false);
                                                  }}
                                                  onSelect={() => {}}
                                                  addDate={new Date(entry.originalDate)}
                                                  onAdd={() => {
                                                    addNewSchedule(entry, { ...item, user: value.users._id });
                                                    document.body.focus();
                                                    setDragStatus(false);
                                                  }}
                                                ></ActionBox>
                                              </Schedule>
                                            );
                                          })}
                                          {/* Render absences */}
                                          {absences.map((absence, scheduleIndex) => {
                                            hasSchedule = true;
                                            return (
                                              <Schedule value={absence._id} color={absence.LeaveType?.[0]?.color ?? "black"} key={absence._id} onMouseEnter={() => setOverId(absence.user + "-" + absence.leaveType)} onMouseLeave={() => setOverId("")} className={(new Date(entry.originalDate) >= new Date() || new Date(entry.originalDate).toDateString() === new Date().toDateString() ? "" : "expired") + (overid === absence.user + "-" + absence.shiftSchedule ? " active" : "") + " absence schedule " + (selectedElements[absence._id] ? " selected" : "") + " " + absence.approval}>
                                                <span className="leave">{absence.LeaveType?.[0]?.[selectedLanguage]}</span>
                                                <span className="leave">{t(absence.approval)}</span>
                                                {/* <ActionBox
                                                color={absence.LeaveType?.[0]?.color ?? "black"}
                                                onEdit={() => {
                                                  setOpenData({ place: palceIndex, userIndex: index, dayIndex: entry.date, scheduleIndex });
                                                  editAbsence(entry, item, scheduleIndex, absence);
                                                }}
                                                onDelete={() => {
                                                  setOpenData({ place: palceIndex, userIndex: index, dayIndex: entry.date, scheduleIndex });
                                                  editAbsence(entry, item, scheduleIndex, absence, true, { place: palceIndex, userIndex: index, dayIndex: entry.date, scheduleIndex });
                                                }}
                                              ></ActionBox> */}
                                              </Schedule>
                                            );
                                          })}
                                          {!hasSchedule && isActive ? (
                                            <BlankButton
                                              onClick={() => {
                                                addNewSchedule(entry, { ...item, user: value.users._id });
                                              }}
                                            >
                                              <GetIcon icon="add" />
                                            </BlankButton>
                                          ) : (
                                            ""
                                          )}
                                        </CenterBox>
                                        <DropActionBox color={"black"} isUpcoming={isUpcoming && isActive} type={"schedule"} place={palceIndex} date={entry.date} userIndex={index} dayIndex={entry.date} user={item._id} placeId={value.placeId} addDate={new Date(entry.originalDate)}></DropActionBox>
                                      </Td>
                                    );
                                  })}
                                  {showWorked && (
                                    <Td className={"total has"}>
                                      <ColumnContainer className="justifyCenter">
                                        <Progress achievedDisplay={`${convertMinutesToHHMM(totalHours)}`} totalDisplay={`/ ${convertMinutesToHHMM(plannedHours)}`} achieved={totalHours} total={plannedHours}></Progress>
                                      </ColumnContainer>
                                    </Td>
                                  )}
                                </Tr>
                              ) : null;
                            })}
                          </tbody>
                          <div style={{ height: "20px" }}></div>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );

                  return result;
                })
              ) : (
                <NoData>{t("notFound", { page: t(shortName) })}</NoData>
              )}
            </Table>
          </ScrollLayout>
        </ScrollContainerLayout>
        {count > 0 && <Count>{`Showing ${currentIndex + 1} - ${currentIndex + 10 > count ? count : currentIndex + 10} from ${count} ${t("place")}`}</Count>}
        {/* {openData?.data?.length > 0 && (
        <ShowAttendance
          setLoaderBox={setLoaderBox}
          setMessage={setMessage}
          attendance={openData}
          openIndex={openIndex}
          updateData={updateData}
          closeModal={() => {
            setOpenData([]);
          }}
          header={t(openHeader)}
        ></ShowAttendance>
      )} */}
        {isAddNewSchedule && (
          <AutoForm
            userId={userId}
            useCaptcha={false}
            useCheckbox={false}
            description={""}
            setMessage={setMessage}
            key={"type.description"}
            formType={"post"}
            header={t("addNewTitle", { label: t(title) })}
            formInput={addAtrributes}
            submitHandler={submitHandler}
            button={t("submit")}
            isOpenHandler={(value) => {
              setIsAddNewSchedule(value);
            }}
            isOpen={true}
            plainForm={false}
          ></AutoForm>
        )}
        {modifySchedule && (
          <AutoForm
            userId={userId}
            useCaptcha={false}
            useCheckbox={false}
            description={""}
            formValues={selectedSchedule}
            setMessage={setMessage}
            key={"type.description"}
            formType={"post"}
            header={t("update", { label: t(title) })}
            formInput={addOverrideUpdate}
            submitHandler={updateHandler}
            button={t("submit")}
            isOpenHandler={(value) => {
              setModifySchedule(value);
            }}
            isOpen={true}
            plainForm={false}
          ></AutoForm>
        )}
        {dragMoving && dragStart.x ? (
          <div
            className="selection-rectangle"
            style={{
              left: Math.min(dragStart.x, dragEnd.x),
              top: Math.min(dragStart.y, dragEnd.y),
              width: Math.abs(dragEnd.x - dragStart.x),
              height: Math.abs(dragEnd.y - dragStart.y),
            }}
          ></div>
        ) : (
          ""
        )}
      </RowContainer>
      {/* <div className="drag-select-delete"> */}
      {/* <div className="elements">
          {elements.map((element) => (
            <div key={element.id} id={`element-${element.id}`} className={`element ${selectedElements.includes(element) ? "selected" : ""}`}>
              {element.text}
            </div>
          ))}
        </div> */}

      {/* <button className="delete-button" onClick={handleDelete}>
          Delete Selected
        </button> */}
      {/* </div> */}
    </Container>
  );
};
export default Layout(Schedules);
