import React, { useEffect, useState } from "react";
import Layout from "../../common/layout";
import { useTranslation } from "react-i18next";
import ListTable from "../../../elements/list/list";
import { Container } from "../../common/layout/styels";
const Shift = (props) => {
  const { t } = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "title",
      name: "title",
      validation: "",
      default: "",
      label: "title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "color",
      placeholder: "color",
      name: "color",
      validation: "",
      default: "#000000",
      label: "color",
      required: true,
      minimum: 0,
      maximum: 60,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "addedBy",
      name: "addedBy",
      validation: "",
      default: "",
      showItem: "fullName",
      collection: "addedBy",
      label: "addedBy",
      required: false,
      view: true,
      add: false,
      update: false,
    },
  ]);
  //to update the page title
  useEffect(() => {
    document.title = `${t("place")} - VSH Security`;
  }, [t]);
  const [actions] = useState([{ element: "button", type: "subselection", id: "place", ref: "manager", displaySelect: "value", dispaySelected: "title", title: "manager", icon: "manager", SelectionListApi: "place/free-managers", crudApi: "place/manager", add: true, delete: true }]);
  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return (
    <Container className="noshadow">
      <ListTable {...props} dragList={true} actions={actions} api={`place`} itemTitle={`title`} shortName={`place`} addPrivilege={true} delPrivilege={true} updatePrivilege={true} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Shift);
