import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Header, MenuGroup, MenuIcon, Nav, SubMenu } from "./styels";
import { useTranslation } from "react-i18next"; // react-i18next hook for translations
import { useDispatch, useSelector } from "react-redux";
import { clearMenu, currentMenu, menuStatus, openedMenu, selectedMenu } from "../../../../../store/actions/common";
import { CloseIcon, GetIcon } from "../../../../../icons";
import { Logo, MNavClose } from "../header/styels";
import { logo } from "../../../../../images";
import { clearLogin } from "../../../../../store/actions/login";
import i18n from "i18next"; // i18n library for internationalization
import { postData } from "../../../../../backend/api";
import AutoForm from "../../../../elements/form";
const Menu = (props) => {
  const { t } = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  const openedMenus = useSelector((state) => state.openedMenu);
  const selectedMenuItem = useSelector((state) => state.selectedMenu);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || "de");
  const formInput = [
    {
      type: "password",
      placeholder: "currentPassword",
      name: "currentPassword",
      validation: "",
      default: "",
      label: "currentPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    {
      type: "password",
      placeholder: "newPassword",
      name: "newPassword",
      validation: "passwordMatch",
      default: "",
      label: "newPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    {
      type: "password",
      placeholder: "confirmPassword",
      name: "confirmPassword",
      validation: "passwordMatch",
      default: "",
      label: "confirmPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
  ];
  const dispatch = useDispatch();
  const handleLanguageChange = (language) => {
    // Change the language in the i18n library
    if (selectedLanguage === "en") {
      i18n.changeLanguage("de");
      setSelectedLanguage("de");
      localStorage.setItem("_lang", "de");
    } else {
      i18n.changeLanguage("en");
      setSelectedLanguage("en");
      localStorage.setItem("_lang", "en");
    }
  };
  const [showResetPassword, setShowResetPassword] = useState(false);
  const isCreatingHandler = (value, callback) => {
    setShowResetPassword(false);
  };
  const submitChange = async (post) => {
    //call set password with
    props.setLoaderBox(true);
    await postData({ ...post }, "user/reset-password")
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          props.setMessage({
            type: 1,
            content: t(response.data.message),
            okay: "Okay",
            onClose: () => {},
          });
          props.setLoaderBox(false);
        } else if (response.status === 201) {
          props.setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          props.setLoaderBox(false);
        } else {
          props.setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
          props.setLoaderBox(false);
        }
        setShowResetPassword(false);
        props.setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        props.setLoaderBox(false);
      });
  };
  return (
    <>
      <Header style={{ display: "none" }} className="hm">
        <MenuIcon
          onClick={() => {
            props.changeMenu();
          }}
        >
          <GetIcon icon={"menu"} />
        </MenuIcon>
        <Logo className="menu" src={logo} alt="logo" />
        {/* <User>{props.user.user.email}</User> */}
        <MNavClose
          onClick={() => {
            dispatch(menuStatus(false));
          }}
        >
          <CloseIcon />
        </MNavClose>
      </Header>
      {/* <NavIcon className="menu">
        <img src={nav} alt="logo" />
      </NavIcon> */}
      <Nav theme={themeColors}>
        {/* Link to the home page */}
        {props.user.menu?.map((menuItem) => {
          const submenu = menuItem.submenus?.[0];
          return (
            <div key={menuItem._id}>
              {menuItem.submenus?.length > 0 ? (
                <React.Fragment key={`link-${menuItem._id}`}>
                  <MenuGroup>
                    <Link
                      to={submenu.path}
                      onClick={(e) => {
                        console.log("New");
                        const menuItemId = menuItem._id;
                        const openedMenuStatus = openedMenus?.[menuItemId];
                        console.log("First:", "This:", menuItem._id, "Parent:", selectedMenuItem.parent);
                        if (menuItem._id !== selectedMenuItem.parent) {
                          console.log("Item '" + submenu.label + "':", submenu.label, openedMenuStatus);
                          // Dispatch actions
                          dispatch(menuStatus(false));
                          dispatch(selectedMenu({ ...submenu, parent: menuItem._id }));
                          dispatch(currentMenu(submenu.label)); // Use submenu.label in currentMenu dispatch
                        } else {
                          e.preventDefault();
                        }
                        // Dispatch the openedMenu action
                        dispatch(openedMenu(menuItemId));
                      }}
                      className={openedMenus?.[menuItem._id] === true ? "open active" : " open"}
                    >
                      <GetIcon icon={menuItem.icon} /> <span>{t(menuItem.label)} </span>
                      <GetIcon icon={"down"}></GetIcon>
                    </Link>
                  </MenuGroup>
                  <SubMenu className={openedMenus?.[menuItem._id] === true ? "down" : "close"}>
                    {menuItem.submenus?.map((submenu) => (
                      <Link
                        key={submenu._id} // Use submenu.label as the key
                        onClick={() => {
                          if (submenu.label === "logout") {
                            dispatch(clearLogin());
                          } else if (submenu.label === "languageSwitch") {
                            handleLanguageChange();
                          } else if (submenu.label === "resetPassword") {
                            setShowResetPassword(true);
                          } else {
                            dispatch(menuStatus(false));
                            // dispatch(selectedMenu(submenu));
                            dispatch(selectedMenu({ ...submenu, parent: menuItem._id }));
                            dispatch(currentMenu(submenu.label)); // Use submenu.label in currentMenu dispatch
                          }
                        }}
                        className={submenu._id === selectedMenuItem._id ? "main active" : "main"} // Use submenu.path for the active class
                        to={submenu.path} // Use submenu.path for the link destination
                      >
                        <GetIcon icon={submenu.icon} />
                        <span> {t(submenu.label)}</span> {/* Use submenu.label for the link text */}
                      </Link>
                    ))}
                  </SubMenu>
                </React.Fragment>
              ) : (
                <Link
                  key={`link-Main-${menuItem._id}`}
                  onClick={() => {
                    if (menuItem.label === "logout") {
                      dispatch(clearLogin());
                    } else if (menuItem.label === "languageSwitch") {
                      handleLanguageChange();
                    } else {
                      dispatch(menuStatus(false));
                      // dispatch(selectedMenu(menuItem));
                      dispatch(selectedMenu({ ...menuItem, parent: menuItem._id }));
                      dispatch(currentMenu(menuItem.label));
                      dispatch(clearMenu(""));
                    }
                  }}
                  className={menuItem._id === selectedMenuItem._id ? "main active" : "main"}
                  to={menuItem.path}
                >
                  <GetIcon icon={menuItem.icon} /> {t(menuItem.label)}
                </Link>
              )}
            </div>
          );
        })}
      </Nav>
      {showResetPassword && <AutoForm formMode={"single"} enterSubmit={true} useCaptcha={false} formType={"post"} header={t("resetPassword")} formInput={formInput} submitHandler={submitChange} button={t("confirm")} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={false}></AutoForm>}
    </>
  );
};

export default Menu;
