import { useEffect } from "react";
import { DisplayInformations } from "../../../../elements/list/popup";
import withLayout from "../../../common/layout";
import { getData } from "../../../../../backend/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NoData } from "../../../../elements/list/styles";
import { RowContainer } from "../../../../styles/containers/styles";
import ViewFiles from "../../documents/ViewFiles";

const Profile = ({ itemTitle = `firstName`, profileImage = "photo", formMode = "double", formView = "tab", openData, setMessage, setLoaderBox }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    getData({}, "user/me").then((response) => {
      setUser(response.data);
    });
  }, []);
  const [t] = useTranslation();
  const [attributes] = useState([
    {
      type: "text",
      placeholder: "personalNumber",
      name: "personalNumber",
      tab: "generalInfo",
      validation: "",
      default: "",
      label: "personalNumber",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "academicTitle",
      name: "academicTitle",
      tab: "generalInfo",
      validation: "",
      default: "",
      label: "academicTitle",
      required: false,
      view: false,
      filter: false,
      tag: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Prof.,Dr.",
    },
    {
      type: "text",
      placeholder: "fullName",
      name: "fullName",
      tab: "generalInfo",
      validation: "textonly",
      default: "",
      label: "fullName",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "firstName",
      name: "firstName",
      tab: "generalInfo",
      validation: "textonly",
      default: "",
      label: "firstName",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "lastName",
      name: "lastName",
      tab: "generalInfo",
      validation: "textonly",
      default: "",
      label: "lastName",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "birthName",
      name: "birthName",
      tab: "generalInfo",
      validation: "",
      default: "",
      label: "birthName",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "mobileNumber",
      name: "mobileNumber",
      tab: "generalInfo",
      validation: "mobile",
      default: "",
      label: "mobileNumber",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "email",
      tab: "generalInfo",
      name: "email",
      validation: "email",
      default: "",
      label: "email",
      required: false,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "location",
      name: "location",
      tab: "generalInfo",
      validation: "",
      showItem: "title",
      default: "",
      label: "location",
      required: true,
      filter: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "location/select",
    },
    {
      type: "select",
      placeholder: "department",
      name: "department",
      tab: "generalInfo",
      validation: "",
      showItem: "title",
      default: "",
      label: "department",
      required: true,
      filterType: "multiSelect",
      view: true,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "department/select",
    },
    {
      type: "select",
      placeholder: "designation",
      name: "designation",
      updateOn: "department",
      tab: "generalInfo",
      validation: "",
      showItem: "title",
      default: "",
      label: "designation",
      required: false,
      filterType: "multiSelect",
      filter: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "designation/select",
    },
    {
      type: "select",
      placeholder: "dshift",
      tab: "generalInfo",
      name: "shiftTemplate",
      validation: "",
      default: "",
      label: "dshift",
      showItem: "title",
      required: true,
      filter: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "shift/select",
    },
    {
      type: "select",
      placeholder: "place",
      tab: "generalInfo",
      name: "place",
      validation: "",
      showItem: "title",
      default: "",
      label: "place",
      required: true,
      filterType: "multiSelect",
      view: true,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "place/select",
    },
    // {
    //   type: "invitation",
    //   placeholder: "invitation",
    //   tab: "generalInfo",
    //   name: "invitationSent",
    //   validation: "",
    //   showItem: "invitation",
    //   default: "",
    //   label: "invitation",
    //   required: true,
    //   view: false,
    //   tag: true,
    //   add: false,
    //   update: false,
    // },
    // {
    //   type: "checkbox",
    //   placeholder: "acceptLogin",
    //   tab: "generalInfo",
    //   name: "acceptLogin",
    //   validation: "",
    //   showItem: "",
    //   default: "",
    //   label: "acceptLogin",
    //   required: true,
    //   view: false,
    //   tag: true,
    //   add: false,
    //   update: false,
    // },

    {
      type: "expired",
      placeholder: "status",
      tab: "generalInfo",
      name: "contractEnd",
      validation: "",
      collection: "",
      showItem: "contractEnd",
      default: "",
      value: "Active",
      ifNull: "Active",
      label: "status",
      required: false,
      view: true,
      tag: true,
      add: false,
      update: true,
    },
    {
      type: "text",
      placeholder: "addedBy",
      tab: "generalInfo",
      name: "addedBy",
      validation: "",
      showItem: "fullName",
      collection: "addedBy",
      default: "",
      label: "addedBy",
      required: false,
      view: false,
      tag: true,
      add: false,
      update: false,
    },
    //genral info end
    //personal info starts
    {
      type: "text",
      placeholder: "streetNumber",
      name: "streetNumber",
      tab: "personalData",
      validation: "",
      default: "",
      label: "streetNumber",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "postalCode",
      name: "postalCode",
      tab: "personalData",
      validation: "",
      default: "",
      label: "postalCode",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "city",
      name: "city",
      tab: "personalData",
      validation: "",
      default: "",
      label: "city",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "gender",
      name: "gender",
      tab: "personalData",
      validation: "",
      default: "",
      label: "gender",
      required: false,
      view: false,
      filter: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "Male", value: t("Male") },
        { id: "Female", value: t("Female") },
        { id: "Diverse", value: t("Diverse") },
      ],
    },
    {
      type: "date",
      placeholder: "birthDate",
      name: "birthDate",
      tab: "personalData",
      validation: "date",
      default: "empty",
      label: "birthDate",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/nationalities",
      placeholder: "nationality",
      name: "nationality",
      filter: false,
      tab: "personalData",
      validation: "",
      showItem: "locale",
      default: "",
      label: "nationality",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "cityOfBirth",
      name: "cityOfBirth",
      tab: "personalData",
      validation: "",
      default: "",
      label: "cityOfBirth",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/countries",
      showItem: "locale",
      placeholder: "countryOfBirth",
      name: "countryOfBirth",
      tab: "personalData",
      validation: "",
      default: "",
      label: "countryOfBirth",
      required: false,
      filter: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "JSON",
      selectApi: [
        { id: "Yes", value: t("Yes") },
        { id: "No", value: t("No") },
      ],
      placeholder: "severelyDisabled",
      name: "severelyDisabled",
      tab: "personalData",
      filter: false,
      validation: "",
      default: "",
      label: "severelyDisabled",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      tab: "personalData",
      title: "School Graduation / Occupation Training",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "highestSchoolDegree",
      name: "highestSchoolDegree",
      tab: "personalData",
      filter: false,
      validation: "",
      default: "",
      label: "highestSchoolDegree",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "No school degree", value: t("No school degree") },
        { id: "Primary/Elementary School", value: t("Primary/Elementary School") },
        { id: "Intermediate School Certificate or Equivalent", value: t("Intermediate School Certificate or Equivalent") },
        { id: "High School Diploma/University Entrance Qualification", value: t("High School Diploma/University Entrance Qualification") },
      ],
    },
    {
      type: "select",
      placeholder: "highestProfessionalTraining",
      name: "highestProfessionalTraining",
      tab: "personalData",
      filter: false,
      validation: "",
      default: "",
      label: "highestProfessionalTraining",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "No professional training certificate", value: t("No professional training certificate") },
        { id: "Recognized Vocational Training", value: t("Recognized Vocational Training") },
        { id: "Master/Technician or Equivalent", value: t("Master/Technician or Equivalent") },
        { id: "Bachelor's Degree", value: t("Bachelor's Degree") },
        { id: "Diploma/Master's Degree", value: t("Diplom/Magister/Master/Staatsexamen") },
      ],
    },
    //bank details
    {
      type: "text",
      tab: "bankingInformation",
      placeholder: "iban",
      name: "iban",
      validation: "iban",
      default: "",
      label: "iban",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "bic",
      tab: "bankingInformation",
      name: "bic",
      validation: "",
      default: "",
      label: "bic",
      maximum: 12,
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "bank",
      tab: "bankingInformation",
      name: "bank",
      validation: "textonly",
      default: "",
      label: "bank",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "accountHolder",
      tab: "bankingInformation",
      name: "accountHolder",
      validation: "",
      default: "",
      label: "accountHolder",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    // pbacnk details over
    //social secutity and tax
    {
      type: "select",
      placeholder: "maritalStatus",
      name: "maritalStatus",
      tab: "socialInsurance",
      filter: false,
      validation: "",
      default: "",
      label: "maritalStatus",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "single", value: t("single") },
        { id: "married", value: t("married") },
        { id: "divorced", value: t("divorced") },
        { id: "widowed", value: t("widowed") },
        { id: "permanentlySeparated", value: t("permanentlySeparated") },
      ],
    },
    {
      type: "select",
      placeholder: "insuranceStatus",
      name: "insuranceStatus",
      tab: "socialInsurance",
      validation: "",
      filter: false,
      default: "",
      label: "insuranceStatus",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "Statutory", value: t("Statutory") },
        { id: "Private", value: t("Private") },
        { id: "Unspecified", value: t("Unspecified") },
      ],
    },
    {
      type: "text",
      placeholder: "socialSecurityNumber",
      name: "socialSecurityNumber",
      tab: "socialInsurance",
      validation: "svnumber",
      default: "",
      label: "socialSecurityNumber",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "user/insurance",
      showItem: "title",
      placeholder: "healthInsurance",
      name: "healthInsurance",
      tab: "socialInsurance",
      validation: "",
      default: "",
      filter: false,
      label: "healthInsurance",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "taxID",
      name: "taxID",
      tab: "socialInsurance",
      validation: "",
      default: "",
      label: "taxID",
      minimum: 12,
      maximum: 12,
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },

    {
      type: "select",
      placeholder: "taxClass",
      name: "taxClass",
      tab: "socialInsurance",
      validation: "",
      default: "",
      label: "taxClass",
      filter: false,
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "one", value: t("one") },
        { id: "two", value: t("two") },
        { id: "three", value: t("three") },
        { id: "four", value: t("four") },
        { id: "five", value: t("five") },
        { id: "six", value: t("six") },
        { id: "doubleTaxationAgreement", value: t("doubleTaxationAgreement") },
      ],
    },
    {
      type: "select",
      placeholder: "religion",
      name: "religion",
      tab: "socialInsurance",
      showItem: "locale",
      filter: false,
      validation: "",
      default: "",
      label: "religion",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "religion/select",
    },
    {
      type: "number",
      placeholder: "bavMonthly",
      name: "bavMonthly",
      tab: "socialInsurance",
      validation: "number",
      default: "0",
      label: "bavMonthly",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "childAllowance",
      name: "childAllowance",
      tab: "socialInsurance",
      validation: "",
      default: "",
      label: "childAllowance",
      filter: false,
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "monthlyAllowance",
      name: "monthlyAllowance",
      tab: "socialInsurance",
      validation: "",
      default: "",
      label: "monthlyAllowance",
      filter: false,
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    //socialInsurance end
    {
      type: "select",
      placeholder: "status",
      name: "status",
      tab: "socialInsurance",
      validation: "",
      default: "active",
      value: "active",
      label: "status",
      required: false,
      filterType: "select",
      view: false,
      tag: false,
      add: false,
      update: false,
      filter: true,
      apiType: "JSON",
      selectApi: [
        { id: "active", value: t("active") },
        { id: "inactive", value: t("inactive") },
        { id: "all", value: t("all") },
      ],
    },
    //employment info starts
    {
      type: "title",
      tab: "official",
      title: "contract",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "contractFrom",
      tab: "official",
      name: "contractFrom",
      validation: "datetime",
      default: "empty",
      label: "contractFrom",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "contractEnd",
      name: "contractEnd",
      tab: "official",
      validation: "datetime",
      default: "empty",
      label: "contractEnd",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "employmentType",
      tab: "official",
      name: "employmentType",
      validation: "",
      default: "",
      label: "employmentType",
      required: false,
      filterType: "multiSelect",
      view: false,
      tag: true,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "permanentEmployment", value: t("permanentEmployment") },
        { id: "shortTermEmployment", value: t("shortTermEmployment") },
        { id: "miniJob", value: t("miniJob") },
        { id: "studentWorker", value: t("studentWorker") },
        { id: "internship", value: t("internship") },
      ],
    },
   
    {
      type: "number",
      placeholder: "monthlyIncome",
      name: "monthlyIncome",
      tab: "official",
      validation: "number",
      default: "0",
      label: "monthlyIncome",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "allowanceMonth",
      name: "allowanceMonth",
      tab: "official",
      validation: "number",
      default: "0",
      label: "allowanceMonth",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "allowanceYear",
      name: "allowanceYear",
      tab: "official",
      validation: "number",
      default: "0",
      label: "allowanceYear",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    //vacation
    {
      type: "title",
      tab: "official",
      title: "vacation",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "vacationPerYear",
      name: "vacationPerYear",
      tab: "official",
      validation: "number",
      default: "0",
      label: "vacationPerYear",
      required: false,
      view: false,
      tag: true,
      add: false,
      update: true,
    },

    {
      type: "number",
      placeholder: "vacationRemaining",
      name: "vacationRemaining",
      tab: "official",
      validation: "number",
      default: "0",
      label: "vacationRemaining",
      required: true,
      view: false,
      tag: true,
      add: false,
      update: false,
    },
    {
      type: "number",
      placeholder: "hourlyWages",
      name: "hourlyWages",
      tab: "official",
      validation: "number",
      default: "0",
      label: "hourlyWages",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "vacationFromLastYear",
      name: "vacationFromLastYear",
      tab: "official",
      validation: "number",
      default: "0",
      label: "vacationFromLastYear",
      required: false,
      view: false,
      tag: true,
      add: false,
      update: true,
    },
    // {
    //   type: "number",
    //   placeholder: "otherPaymentsMonthly",
    //   name: "otherPaymentsMonthly",
    //   tab: "official",
    //   validation: "number",
    //   default: "0",
    //   label: "otherPaymentsMonthly",
    //   required: false,
    //   view: false,
    //   tag: true,
    //   add: true,
    //   update: true,
    // },
    //Termination
    {
      type: "title",
      tab: "official",
      title: "termination",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "terminationDate",
      name: "terminationDate",
      tab: "official",
      validation: "",
      default: "empty",
      label: "terminationDate",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "view",
      placeholder: "terminationBy",
      name: "terminationBy",
      tab: "official",
      validation: "",
      default: "",
      collection: "terminationBy",
      label: "terminationBy",
      required: false,
      tag: true,
      view: false,
      add: false,
      update: false,
    },
    {
      type: "number",
      placeholder: "noticePeriod",
      name: "noticePeriod",
      tab: "official",
      validation: "",
      default: "",
      label: "noticePeriod",
      required: false,
      tag: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "exitDate",
      name: "exitDate",
      tab: "official",
      validation: "",
      default: "",
      label: "exitDate",
      required: false,
      tag: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "cancellationGet",
      name: "cancellationGet",
      tab: "official",
      validation: "",
      default: "",
      label: "cancellationGet",
      required: false,
      tag: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "terminationReason",
      name: "terminationReason",
      tab: "official",
      validation: "",
      default: "",
      label: "terminationReason",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "terminationType",
      name: "terminationType",
      tab: "official",
      validation: "",
      default: "",
      label: "terminationType",
      required: false,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "lastWorkingDay",
      name: "lastWorkingDay",
      tab: "official",
      validation: "",
      default: "empty",
      label: "lastWorkingDay",
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "photo",
      name: "photo",
      tab: "generalInfo",
      validation: "",
      default: "",
      label: "photo",
      required: false,
      view: false,
      tag: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Monday",
      name: "mon",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Monday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Tuesday",
      name: "tue",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Tuesday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Wednesday",
      name: "wed",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Wednesday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Thursday",
      name: "thu",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Thursday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Friday",
      name: "fri",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Friday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Saturday",
      name: "sat",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Saturday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Sunday",
      name: "sun",
      tab: "workDays",
      validation: "",
      showItem: "",
      default: "true",
      label: "Sunday",
      required: true,
      view: false,
      tag: true,
      add: true,
      update: true,
    },
  ]);
  const [viewFile, setViewFile] = useState(false);
  const [data, setData] = useState({});
  const [fileActions] = useState([
    {
      element: "callback",
      type: "callback",
      id: "generatePdf",
      title: "generatePdf",
      icon: "Download",
      callback: (item, data) => {
        setData({ item, data });
        setViewFile(true);
      },
    },
  ]);
  const [fileUpload] = useState([
    {
      type: "select",
      placeholder: "documentType",
      name: "documentType",
      validation: "",
      filter:false,
      showItem: "locale",
      default: "",
      label: "documentType",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "document-type/select",
    },
    {
      type: "expired",
      placeholder: "status",
      tab: "generalInfo",
      name: "contractEnd",
      validation: "",
      collection: "",
      showItem: "contractEnd",
      default: "",
      value: "Active",
      ifNull: "Active",
      label: "status",
      required: false,
      view: true,
      tag: true,
      add: false,
      update: true,
    },
    {
      type: "date",
      placeholder: "expiryDate",
      name: "expiryDate",
      validation: "datetime",
      default: "empty",
      label: "expiryDate",
      required: false,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "adminOnly",
      name: "adminOnly",
      validation: "",
      showItem: "",
      default: "",
      label: "adminOnly",
      required: true,
      view: false,
      tag: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "Note",
      name: "note",
      validation: "",
      default: "",
      showItem: "Note",
      label: "Note",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "file",
      placeholder: "file",
      name: "file",
      validation: "",
      showItem: "",
      default: "",
      label: "file",
      required: true,
      view: false,
      add: true,
      update: true,
    },
  ]);
  const [actions] = useState([{ element: "button", type: "subList", id: "files", itemTitle: "firstName", icon: "file", title: "files", attributes: fileUpload, params: { api: `files`, itemTitle: "firstName", shortName: "files", addPrivilege: true, delPrivilege: false, updatePrivilege: true, datefilter: false, customClass: "medium", actions: fileActions } }]);
  return user ? (
    <>
      <DisplayInformations actions={actions} customClass="profile" key={"profile"} setMessage={setMessage} setLoaderBox={setLoaderBox} profileImage={profileImage} formMode={formMode} formView={"tabs"} attributes={attributes} data={user} />
      {viewFile && <ViewFiles customClass="large" page={data} onClose={() => setViewFile(false)} setLoaderBox={setLoaderBox} title={"attendanceToday"}></ViewFiles>}
    </>
  ) : (
    <RowContainer>
      <NoData>Loging Profile</NoData>
    </RowContainer>
  );
};
export default withLayout(Profile);
