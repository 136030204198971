import styled from "styled-components";

export const CloseButton = styled.button`
  background: transparent;
  padding: 0 0.5em;
  font-size: initial;
  margin-right: 0.5em;
  outline: none;
  border: 0px solid #ddd;
  border: 0px solid #ddd;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-right: 0px;
  padding: 12px;
  background: ${(props) => props.theme.secBackground};
  border-radius: 50%;
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.bgPrimary};
  }
`;
export const Td = styled.div`
  text-align: left;
  padding: 10px 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  &.no,
  &.name {
    border: 1px solid gray;
  }
  &.has {
    border: 2px solid black;
    cursor: pointer;
  }
  &.no,
  &.has {
    text-align: center;
  }
  &.no svg {
    fill: grey;
  }
  &.name {
    text-overflow: "no-wrap";
  }
  &.bold {
    border-bottom: 1px solid #988b8b;
    border-top: 1px solid #988b8b;
  }
  &.actions {
    display: flex;
    justify-content: right;
    overflow-wrap: normal;
    margin-left: auto;
    margin-right: 5px;
    padding: 0;
  }
  &.right {
    text-align: right;
  }
  &:last-child {
    border-bottom: 0 !important;
  }
  .double & {
    &:nth-child(odd) {
      border-right: 1px solid #ccc;
    }
    &:last-child {
      border-bottom: 0 !important;
    }

    &:last-child,
    &:nth-last-child(2):nth-child(odd) {
      /* Apply styles to second-to-last child in last row (odd index) */
      border-bottom: 0 !important;
      /* Add any additional styles here */
    }
    &:first-child,
    &:nth-child(2) {
      border-top: 1px solid #d9d9d9;
    }
  }
  @media (max-width: 768px) {
    padding: 10px 10px;
    &:nth-child(odd) {
      border-right: 0px solid #ccc !important;
    }
    &:last-child:nth-child(odd) {
      border-bottom: 0px solid #d9d9d9 !important;
    }
  }
`;

export const TrBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  &.profile {
    padding: 30px 20px;
  }
  /* &.double {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } */
  @media (max-width: 768px) {
    &.double {
      display: flex;
    }
  }
`;
export const Title = styled.span`
  margin-right: 5px;
  margin-bottom: 5px;
  color: #838894;
  min-width: 150px;
  &.bold {
    font-weight: bold;
    color: black;
    margin: 0;
  }
`;
export const Head = styled.span`
  font-weight: bold;
  width: "100%";
  display: flex;
  align-items: "center";
  padding: 15px 17px 15px 26px;
  svg {
    margin-right: 10px;
  }
`;
export const DataHead = styled.span`
  padding: 10px 5px;
`;
export const DataItem = styled.span`
  font-weight: bold;
  border-radius: 10px;
  text-align: right;
  button {
    margin: 0;
    padding: 10px;
  }
`;
export const TabContainer = styled.div`
  border-radius: 12px;
  padding: 0px 10px;
  margin: 0;
  flex-direction: row;
  display: flex;
  column-gap: 10px;
  @media (max-width: 768px) {
    margin: 0;
    &.vertical {
      flex-direction: column;
    }
  }
`;
export const TabList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  margin-top: 10px;
  @media (max-width: 768px) {
    flex-direction: row;
    overflow: auto;
    width: 100%;
  }
`;
export const Profile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  flex-direction: column;

  img {
    width: 50px;
    height: 50px;
    margin: auto;
    object-fit: cover;
    border-radius: 50%;
  }
  h6 {
    text-align: center;
    padding: 0px 12px 0px 12px;
    margin: 0;
    margin-bottom: 10px;
    font-size: 15px;
  }
  @media (max-width: 768px) {
    padding: 20px;
    width: -webkit-fill-available;
    &.vertical {
      flex-direction: column;
    }
  }
`;
export const TabItems = styled.div`
  margin: 0px;
  margin-top: 10px;
  flex: 1;
  max-width: calc(100% - 150px);
  &.false {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const TabText = styled.div`
  padding: 10px 10px;
`;
export const TabHead = styled.div`
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 768px) {
    display: none;
  }
`;
