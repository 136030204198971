import { useCallback, useEffect, useRef, useState } from "react";
import { GetIcon } from "../../../../../icons";
import { AddButton, ButtonPanel, Count, Filter, FilterBox, Filters, NoData, ScrollContainerLayout, ScrollLayout, Table, Td, Th, Tr } from "../../../../elements/list/styles";
import Search from "../../../../elements/search";
import { ColumnContainer, RowContainer } from "../../../../styles/containers/styles";
import Layout from "../../../common/layout";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormInput from "../../../../elements/input";
import { addPageObject } from "../../../../../store/actions/pages";
import moment from "moment";
import ShowAttendance from "./subPage";
import { Approval, CenterBox } from "./styles";
import { dateFormat, timeFormat } from "../../../../functions/date";
import { BlankButton } from "../styels";
import { convertMinutesToHHMM } from "../../../../functions/minuteToHour";
import { postData } from "../../../../../backend/api";
import AutoForm from "../../../../elements/form";
import { generateThumbnail } from "../../../../functions/string";
import { Schedule, SchedulesBox } from "../schedules/styles";
import { Container } from "../../../common/layout/styels";
import Checkbox from "../../../../elements/checkbox";
import DateSelector from "../../../../elements/dateselector";
import SelectTab from "../../../../elements/selectTab";
import { Title } from "../../../common/layout/header/styels";
// import CrudForm from "../../../../elements/list/create";
// import { useNavigate } from "react-router-dom";
const WorkLog = ({ setLoaderBox, setMessage, shortName = "employee" }) => {
  const [currentApi] = useState(`report/worklog`);
  const [currentIndex] = useState(0);
  const { i18n } = useTranslation();
  const [selectedLanguage] = useState(i18n.language || "de");
  const themeColors = useSelector((state) => state.themeColors);
  const [t] = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const searchTimeoutRef = useRef();
  const [checkedRecords, setCheckedRecords] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [checkedAttendance, setCheckedAttendance] = useState({});
  const [checkedAttendanceList, setCheckedAttendanceList] = useState([]);
  const [count, setCount] = useState(0);
  const [initialized] = useState(true);
  const dispatch = useDispatch();
  const [openData, setOpenData] = useState([]);
  const [openHeader, setOpenHeader] = useState("");
  const [openIndex, setOpenIndex] = useState({ person: 0, day: 0 });
  const [viewMode, setViewMode] = useState("month");
  const [workTimeLog, setWorkTimeLog] = useState([]);
  const [filterView, setFilterView] = useState({
    place: "",
    shift: "",
    viewMode: "month",
    currentDate: new Date().toISOString(),
    startOfMonth: moment(new Date()).startOf("month").toISOString(),
    endOfMonth: moment(new Date()).endOf("month").toISOString(),
    startOfWeek: moment(new Date()).startOf("week").toISOString(),
    endOfWeek: moment(new Date()).endOf("week").toISOString(),
  });

  const changeWorkLog = useCallback(
    (item) => {
      let startDateTemp, endDateTemp;
      console.log("item", item);
      const workLog = [];

      // Using a switch to determine start and end dates based on viewMode
      switch (item.viewMode) {
        case "month":
          startDateTemp = moment(item.startOfMonth).toDate();
          endDateTemp = moment(item.endOfMonth).toDate();
          break;
        case "week":
          startDateTemp = moment(item.startOfWeek).toDate();
          endDateTemp = moment(item.endOfWeek).toDate();
          break;
        case "day":
          startDateTemp = moment(item.currentDate).toDate();
          endDateTemp = moment(item.currentDate).toDate();
          break;
        default:
          startDateTemp = moment(item.startOfMonth).toDate();
          endDateTemp = moment(item.endOfMonth).toDate();
          break;
      }

      // Loop through dates and populate the workLog array
      for (let date = moment(startDateTemp); date.isSameOrBefore(endDateTemp); date.add(1, "day")) {
        workLog.push({
          originalDate: date.toDate(),
          date: date.format("YYYY-MM-DD"),
          day: date.format("DD"),
          dayNameId: date.locale("en").format("ddd"),
          dayName: date.locale(selectedLanguage).format("ddd"),
        });
      }

      // Assuming setWorkTimeLog is a function to update the state with the workLog
      setWorkTimeLog([...workLog]); // Using spread to create a new array and trigger state update
    },
    [setWorkTimeLog, selectedLanguage]
  ); // Adding dependencies for useCallback

  useEffect(() => {
    changeWorkLog(filterView);
  }, [filterView, changeWorkLog]);
  // const navigate = useNavigate();
  const handleChange = (event) => {
    clearTimeout(searchTimeoutRef.current);
    setSearchValue(event.target.value);
    searchTimeoutRef.current = setTimeout(() => {
      setFilterView({ ...filterView, searchkey: event.target.value });
    }, 300);
  };
  const [attributes] = useState([
    {
      type: "select",
      placeholder: "place",
      name: "place",
      showItem: "title",
      validation: "",
      default: "",
      label: "place",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "place/select",
    },
    {
      type: "select",
      placeholder: "shift",
      name: "shift",
      showItem: "title",
      validation: "",
      default: "",
      label: "shift",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "API",
      selectApi: "shift/select",
    },
  ]);

  // const dateRangeChange = (item) => {
  //   const startDate = new Date(item.startDate);
  //   startDate.setHours(0, 0, 0, 0); // Set start date to 00:00

  //   const endDate = new Date(item.endDate);
  //   endDate.setHours(23, 59, 59, 999); // Set end date to 23:59
  //   const udpateValue = {
  //     ...filterView,
  //     startDate: startDate.toISOString(),
  //     endDate: endDate.toISOString(),
  //   };
  //   setStartDate(moment(startDate));
  //   setEndDate(moment(endDate));
  //   // updating the formm values
  //   setFilterView(udpateValue);
  // };
  const dateRangeChange = (item) => {
    const udpateValue = {
      ...filterView,
      ...item,
    };
    setFilterView(udpateValue);
  };
  const filterChange = (option, name) => {
    const udpateValue = {
      ...filterView,
      [name]: option.id,
    };
    // updating the formm values
    setFilterView(udpateValue);
  };
  const [loadedData, setLoadedData] = useState([]);
  //Loading Data
  const users = useSelector((state) =>
    state.pages[`${currentApi}`]
      ? state.pages[`${currentApi}`]
      : {
          data: null,
          isLoading: true,
          error: null,
        }
  );
  useEffect(() => {
    setLoadedData(users);
  }, [users]);
  const [addAtrributes, setAddAttributes] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [formType, setFormType] = useState(0);
  const [userId, setUserId] = useState("nil");
  const andNewAttendance = (entry, item) => {
    let date = moment(entry.date);
    setUserId(item._id);
    setAddAttributes([
      {
        type: "date",
        placeholder: "date",
        name: "date",
        validation: "",
        default: date.clone().add(7, "hours"),
        label: "date",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "select",
        placeholder: "shift",
        name: "shiftTemplate",
        validation: "",
        updateCondition: [
          { item: "startTime", value: "shiftStart" },
          { item: "endTime", value: "shiftEnd" },
          { item: "shiftStart", value: "shiftStart" },
          { item: "shiftEnd", value: "shiftEnd" },
          { item: "break", value: "break" },
        ],
        tags: [
          { type: "time", item: "shiftStart", title: "shiftStart", collection: "" },
          { type: "time", item: "shiftEnd", title: "shiftEnd", collection: "" },
        ],
        default: "",
        label: "shift",
        showItem: "title",
        required: true,
        view: true,
        add: true,
        update: true,
        apiType: "API",
        selectApi: "shift/select",
        customClass: "half",
      },
      {
        type: "time",
        placeholder: "shiftStart",
        name: "startTime",
        validation: "",
        default: date.clone().add(7, "hours"),
        label: "shiftStart",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "time",
        placeholder: "shiftEnd",
        name: "endTime",
        validation: "",
        default: date.clone().add(19, "hours"),
        label: "shiftEnd",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "number",
        placeholder: "break",
        name: "break",
        validation: "",
        default: "0",
        label: "break",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        update: true,
      },
    ]);
    setIsEditing(true);
    setEditTitle(t("addNewTitle", { label: t("attendance") }));
    setFormType(0);
    // const addVal = addValues;
    // setAddValues(addVal);
  };

  const editBulkAttendance = (ids) => {
    setAddAttributes([
      {
        type: "time",
        placeholder: "shiftStart",
        name: "firstIn",
        validation: "",
        default: "empty",
        label: "shiftStart",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "time",
        placeholder: "shiftEnd",
        name: "lastOut",
        validation: "",
        default: "empty",
        label: "shiftEnd",
        required: true,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "number",
        placeholder: "break",
        name: "break",
        validation: "",
        default: "0",
        label: "break",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: true,
        update: true,
      },
      {
        type: "hidden",
        placeholder: "attendanceTracking",
        name: "attendanceTracking",
        validation: "",
        default: ids,
        label: "attendanceTracking",
        showItem: "attendanceTracking",
        required: true,
        view: true,
        add: true,
        update: true,
      },
    ]);
    setIsEditing(true);
    setEditTitle(t("update", { label: t("attendance") }));
    setFormType(2);
    // const addVal = addValues;
    // setAddValues(addVal);
  };
  const submitHandler = async (data) => {
    if (formType === 1) {
      setLoaderBox(true);

      await postData(data, `attendance/add/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
            refreshView(currentIndex);
            setIsEditing(false);
          } else if (response.status === 404) {
            setMessage({ type: 1, content: "Server not found!", proceed: "Okay" });
          } else {
            setMessage({ type: 1, content: "Something went wrong!", proceed: "Okay" });
          }
          setLoaderBox(false);
        })
        .catch((error) => {
          alert(error);
          setLoaderBox(false);
        });
    } else if (formType === 2) {
      await postData(data, `attendance/bulk-update`)
        .then((response) => {
          if (response.status === 200) {
            setMessage({ type: 1, content: t(response.data.message), proceed: "Okay" });
            refreshView(currentIndex);
            setIsEditing(false);
          } else if (response.status === 404) {
            setMessage({ type: 1, content: "Server not found!", proceed: "Okay" });
          } else {
            setMessage({ type: 1, content: "Something went wrong!", proceed: "Okay" });
          }
          setLoaderBox(false);
        })
        .catch((error) => {
          alert(error);
          setLoaderBox(false);
        });
    }
  };
  useEffect(() => {
    setLoaderBox(users.isLoading);
    if (currentIndex === 0 && users.data?.count) {
      setCount(users.data.count);
    }
  }, [users, currentIndex, setLoaderBox]);

  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject(currentApi, currentIndex, 0, filterView));
    }
  }, [initialized, currentApi, currentIndex, dispatch, filterView]);
  const refreshView = (currentIndex) => {
    try {
      dispatch(addPageObject(currentApi, currentIndex, 0, filterView));
    } catch {}
  };
  // const [isCreating, setIsCreating] = useState(false);
  const updateData = (openIndex, data) => {
    const preData = loadedData;
    const attendanceLogs = preData.data.data[openIndex.person].attendance.filter((log) => log.shiftDate === openIndex.day.date);
    const item = attendanceLogs[openIndex.dayIndex];
    const itemIndex = preData.data.data[openIndex.person].attendance.findIndex((itemData) => itemData.attendanceTracking === item.attendanceTracking);
    preData.data.data[openIndex.person].attendance[itemIndex] = { ...preData.data.data[openIndex.person].attendance[itemIndex], ...data, shiftTemplate: item.shiftTemplate };
    setLoadedData(preData);
  };
  const getStatus = (approval) => {
    switch (approval) {
      case "Pending":
        return (
          <Approval color="orange">
            <GetIcon icon={"pending"}></GetIcon>
          </Approval>
        );
      case "Confirmed":
        return (
          <Approval color="green">
            <GetIcon icon={"approved"}></GetIcon>
          </Approval>
        );
      case "Rejected":
        return (
          <Approval color="red">
            <GetIcon icon={"rejected"}></GetIcon>
          </Approval>
        );
      default:
        return (
          <Approval color="orange">
            <GetIcon icon={"pending"}></GetIcon>
          </Approval>
        );
    }
  };
  const multiUserApproveChecked = (event, value) => {
    const isChecked = event.target.checked;
    const prevCheckRecords = {
      ...checkedRecords,
      [value]: isChecked,
    };
    console.log(prevCheckRecords);
    setCheckedRecords(prevCheckRecords);
    udpateApprovalList(prevCheckRecords);
  };
  const [multiSelectedSchedules, setMultiSelectedSchedules] = useState([]);
  const addMultiSelectedSchedules = (id) => {
    setMultiSelectedSchedules((prev) => {
      const newState = { ...prev };
      if (newState[id]) {
        delete newState[id];
      } else {
        newState[id] = true;
      }
      return newState;
    });
  };

  const udpateApprovalList = (checkedRecordsTemp) => {
    const attendance = {};
    const attendanceLog = [];

    const addToLog = (id) => {
      if (!attendanceLog.includes(id)) {
        attendanceLog.push(id);
      }
    };

    const processElements = (elements) => {
      elements.forEach((element) => {
        attendance[element.value] = true;
        addToLog(element.value);
      });
    };

    // Iterate through both checkDates and checkUsers
    for (const key in checkedRecordsTemp) {
      if (checkedRecordsTemp[key] === true) {
        const logElements = document.querySelectorAll(`.date-${key}, .user-${key}`);
        processElements(logElements);
      }
    }

    setCheckedAttendance(attendance);
    setCheckedAttendanceList(attendanceLog);
  };
  useEffect(() => {
    if (selectAll === true) {
      const tempcheckedRecords = {};
      const attendance = {};
      const attendanceLog = [];
      const users = document.querySelectorAll(`.user,.date`);
      users.forEach((user) => {
        tempcheckedRecords[user.id] = true;
      });
      const addToLog = (id) => {
        if (!attendanceLog.includes(id)) {
          attendanceLog.push(id);
        }
      };
      const processElements = (elements) => {
        elements.forEach((element) => {
          attendance[element.value] = true;
          addToLog(element.value);
        });
      };
      const logElements = document.querySelectorAll(`.record`);
      processElements(logElements);
      setCheckedRecords(tempcheckedRecords);
      setCheckedAttendance(attendance);
      setCheckedAttendanceList(attendanceLog);
    } else {
      setCheckedRecords({});
      setCheckedAttendance({});
      setCheckedAttendanceList([]);
    }
  }, [selectAll]);
  const selectedMenu = useSelector((state) => state.selectedMenu);
  return (
    <Container className="noshadow">
      <RowContainer className="list">
        <Title className="inner">
          <GetIcon icon={selectedMenu.icon}></GetIcon>
          {t(selectedMenu.label)}
        </Title>
        <ButtonPanel>
          <FilterBox>
            <Search theme={themeColors} placeholder="Search" value={searchValue} onChange={handleChange}></Search>
            <Filter
              theme={themeColors}
              onClick={() => {
                refreshView(currentIndex);
              }}
            >
              <GetIcon icon={"reload"} />
            </Filter>
          </FilterBox>
          <Filters>
            {attributes.map((item, index) => {
              return item.type === "select" && (item.filter ?? true) === true && <FormInput customClass={"filter"} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index} id={item.name} {...item} onChange={filterChange} />;
            })}
          </Filters>
          <Filters className="center">
            <DateSelector dynamicClass="filter" label={"Select Date"} placeholder={"Select Date"} selected={viewMode} value={new Date().toISOString()} onChange={dateRangeChange} themeColors={themeColors}></DateSelector>
          </Filters>
          <Filters className="right">
            <SelectTab
              selected={viewMode}
              onChange={(item) => {
                setViewMode(item);
                filterChange({ id: item }, "viewMode");
              }}
              tabs={[
                { id: "day", title: "dayView" },
                { id: "week", title: "weekView" },
                { id: "month", title: "monthView" },
              ]}
            ></SelectTab>
          </Filters>

          {/* <DateRangeSelector onChange={dateRangeChange} default="month" themeColors={themeColors}></DateRangeSelector> */}

          {checkedAttendanceList.length > 0 && (
            <>
              <AddButton
                className="red"
                onClick={async () => {
                  await postData({ checkedAttendanceList, status: "Rejected" }, "attendance/approve-multiple-attendance");
                  setSelectAll(false);
                  refreshView();
                }}
              >
                <GetIcon icon={"bannedPeople"}></GetIcon>
                {`${t("Reject")} ${checkedAttendanceList.length}`}
              </AddButton>
              <AddButton
                className="green"
                onClick={async () => {
                  await postData({ checkedAttendanceList, status: "Confirmed" }, "attendance/approve-multiple-attendance");
                  setSelectAll(false);
                  refreshView();
                }}
              >
                <GetIcon icon={"approved"}></GetIcon>
                {`${t("Confirm")} ${checkedAttendanceList.length}`}
              </AddButton>{" "}
            </>
          )}
          {Object.keys(multiSelectedSchedules).length > 0 && (
            <>
              <AddButton
                className="orange"
                onClick={async () => {
                  //await postData({ checkedAttendanceList, status: "Confirmed" }, "attendance/approve-multiple-attendance");
                  //setSelectAll(false);
                  //refreshView();

                  const items = Object.keys(multiSelectedSchedules).map((key) => {
                    return key;
                  });
                  editBulkAttendance(items);
                }}
              >
                <GetIcon icon={"time"}></GetIcon>
                {`${t("Edit Timing")} ${Object.keys(multiSelectedSchedules).length}`}
              </AddButton>
              <AddButton
                className="red"
                onClick={async () => {
                  setMultiSelectedSchedules({});
                }}
              >
                <GetIcon icon={"close"}></GetIcon>
                {`${t("cancel")}`}
              </AddButton>
            </>
          )}
        </ButtonPanel>
        <ScrollContainerLayout>
          <ScrollLayout>
            <Table>
              <thead>
                <Tr>
                  <Th className="name">
                    <Checkbox theme={themeColors} checked={selectAll ?? false} onChange={(event) => setSelectAll(event.target.checked)} className="medium"></Checkbox>
                    {t("fullName")}
                  </Th>
                  {viewMode !== "day" &&
                    workTimeLog.map((entry) => (
                      <Th className={`center date ${checkedRecords[entry.date] === true ? "marked" : ""}`} value={entry.date} id={entry.date}>
                        <Checkbox theme={themeColors} checked={checkedRecords[entry.date] ?? false} onChange={(event) => multiUserApproveChecked(event, entry.date)} className="medium"></Checkbox>
                        {`${t(entry.dayName)}, ${entry.day}`}
                      </Th>
                    ))}
                  {viewMode === "day" && (
                    <>
                      <Th className="center">{t("startTime")}</Th>
                      <Th className="center">{t("endTime")}</Th>
                      <Th className="center">{t("break")}</Th>
                      <Th className="center">{t("duration")}</Th>
                      <Th className="center">{t("status")}</Th>
                    </>
                  )}
                  {viewMode !== "day" && (
                    <Th className="total">
                      <RowContainer className="date">{t("total")}</RowContainer>
                    </Th>
                  )}
                </Tr>
              </thead>
              {loadedData.data?.data?.length > 0 && (
                <tbody>
                  {loadedData.data.data.map((item, index) => {
                    let totalDuration = 0;
                    const isActive = new Date(item.contractEnd) > new Date() || item.contractEnd === null;
                    return viewMode === "day" ? (
                      (() => {
                        const hasAttendance = item.attendance.some((log) => log.shiftDate === moment(filterView.currentDate).format("YYYY-MM-DD"));
                        const attendanceLogs = item.attendance.filter((log) => log.shiftDate === moment(filterView.currentDate).format("YYYY-MM-DD"));

                        if (hasAttendance) {
                          return attendanceLogs.map((attendanceLog, attendanceLogIndex) => {
                            return (
                              // Additional processing or logic based on attendanceLogs can be added here
                              <Tr key={`work-log-${index}`}>
                                <Td className={`name user ${checkedRecords[item._id] === true ? "marked" : ""}`} id={item._id} key={`user-name-${index}`}>
                                  <Checkbox theme={themeColors} checked={checkedRecords[item._id] ?? false} onChange={(event) => multiUserApproveChecked(event, item._id)} className="medium"></Checkbox>
                                  {generateThumbnail(item.fullName, isActive)}
                                  {item.fullName}
                                </Td>
                                <Td className={`${hasAttendance ? "has " : "no "}`}>{timeFormat(attendanceLog.firstIn)}</Td>
                                <Td className={`${hasAttendance ? "has " : "no "}`}>{timeFormat(attendanceLog.lastOut)}</Td>
                                <Td className={`${hasAttendance ? "has " : "no "}`}>{convertMinutesToHHMM(attendanceLog.break)}</Td>
                                <Td className={`${hasAttendance ? "has " : "no "}`}>{convertMinutesToHHMM(attendanceLog.workedHours)}</Td>
                                <Td className={`${hasAttendance ? "has " : "no "} ${attendanceLog.approval}`}>
                                  <GetIcon icon={attendanceLog.approval} /> {attendanceLog.approval}
                                </Td>
                              </Tr>
                            );
                          });
                        } else {
                          return null; // Return null if there's no attendance
                        }
                      })()
                    ) : (
                      <Tr key={`work-log-${index}`}>
                        <Td className={`name user ${checkedRecords[item._id] === true ? "marked" : ""}`} id={item._id} key={`user-name-${index}`}>
                          <Checkbox theme={themeColors} checked={checkedRecords[item._id] ?? false} onChange={(event) => multiUserApproveChecked(event, item._id)} className="medium"></Checkbox>
                          {generateThumbnail(item.fullName, isActive)}
                          {item.fullName}
                        </Td>
                        {workTimeLog.map((entry) => {
                          const hasAttendance = item.attendance.some((log) => log.shiftDate === entry.date);
                          // const attendanceLogs = item.attendance.find((log) => log.shiftDate === entry.date);
                          const attendanceLogs = item.attendance.filter((log) => log.shiftDate === entry.date);
                          // const attendanceLogIndex = item.attendance.findIndex((log) => log.shiftDate === entry.date);

                          return (
                            <Td key={entry.date} className={`${hasAttendance ? "has " : "no "}${checkedRecords[entry.date] === true || checkedRecords[item._id] === true ? "marked" : ""}`}>
                              <SchedulesBox>
                                {hasAttendance
                                  ? attendanceLogs.map((attendanceLog, attendanceLogIndex) => {
                                      totalDuration += hasAttendance ? attendanceLog.workedHours : 0;
                                      return attendanceLog.workedHours < attendanceLog.workingHours ? (
                                        <Schedule
                                          onClick={(event) => {
                                            if (event.ctrlKey || event.metaKey) {
                                              addMultiSelectedSchedules(attendanceLog.attendanceTracking);
                                            } else {
                                              if (hasAttendance) {
                                                setOpenData(attendanceLog);
                                                setOpenIndex({ person: index, day: entry, dayIndex: attendanceLogIndex });
                                                setOpenHeader(`${dateFormat(entry.date)} / ${item.fullName}`);
                                              }
                                            }
                                          }}
                                          key={`record-date-${entry.date}-user-${item._id}-${attendanceLogIndex}`}
                                          className={`record date-${entry.date} user-${item._id} justifyCenter  ${multiSelectedSchedules[attendanceLog.attendanceTracking] === true ? "marked" : ""} ${checkedAttendance[attendanceLog.attendanceTracking] === true ? "marked" : ""}`}
                                          value={attendanceLog.attendanceTracking}
                                          color={attendanceLog.shiftTemplate?.color ?? "black"}
                                        >
                                          <span>
                                            <ColumnContainer className="left">
                                              {convertMinutesToHHMM(attendanceLog.workedHours)}
                                              {/* <Round></Round> */}
                                              {getStatus(attendanceLog.approval)}
                                            </ColumnContainer>
                                          </span>
                                          {attendanceLog.data[attendanceLog.data.length - 1].status === "Out" ? (
                                            <span>{`${timeFormat(attendanceLog.firstIn)} - ${timeFormat(attendanceLog.lastOut)} `}</span>
                                          ) : (
                                            <span style={{ color: "green" }}>
                                              {`${timeFormat(attendanceLog.firstIn)} - `}
                                              <GetIcon icon={"live"} />
                                            </span>
                                          )}
                                        </Schedule>
                                      ) : (
                                        <Schedule
                                          key={`record-date-${entry.date}-user-${item._id}-${attendanceLogIndex}`}
                                          className={`record date-${entry.date} user-${item._id} justifyCenter ${multiSelectedSchedules[attendanceLog.attendanceTracking] === true ? "marked" : ""} ${checkedAttendance[attendanceLog.attendanceTracking] === true ? "marked" : ""}`}
                                          value={attendanceLog.attendanceTracking}
                                          color={attendanceLog.shiftTemplate?.color ?? "black"}
                                          onClick={(event) => {
                                            if (event.ctrlKey || event.metaKey) {
                                              addMultiSelectedSchedules(attendanceLog.attendanceTracking);
                                            } else {
                                              if (hasAttendance) {
                                                setOpenData(attendanceLog);
                                                setOpenIndex({ person: index, day: entry, dayIndex: attendanceLogIndex });
                                                setOpenHeader(`${dateFormat(entry.date)} / ${item.fullName}`);
                                              }
                                            }
                                          }}
                                        >
                                          <span>
                                            <ColumnContainer className="left">
                                              {convertMinutesToHHMM(attendanceLog.workedHours)} {attendanceLog.data[attendanceLog.data.length - 1].status === "In" && "IN"}
                                              {getStatus(attendanceLog.approval)}
                                            </ColumnContainer>
                                          </span>
                                          {attendanceLog.data[attendanceLog.data.length - 1].status === "Out" ? (
                                            <span>{`${timeFormat(attendanceLog.firstIn)} - ${timeFormat(attendanceLog.lastOut)} `}</span>
                                          ) : (
                                            <span style={{ color: "green" }}>
                                              {`${timeFormat(attendanceLog.firstIn)} - `}
                                              <GetIcon icon={"live"} />
                                            </span>
                                          )}
                                        </Schedule>
                                      );
                                    })
                                  : new Date(entry.date) < new Date() && (
                                      <CenterBox key={`record-date-${entry.date}`}>
                                        <BlankButton
                                          onClick={() => {
                                            andNewAttendance(entry, item);
                                          }}
                                        >
                                          <GetIcon icon="add" />
                                        </BlankButton>
                                      </CenterBox>
                                    )}
                              </SchedulesBox>
                            </Td>
                          );
                        })}
                        <Td className={totalDuration > 0 ? "total has" : "total no"}>
                          <ColumnContainer className="justifyCenter">{`${convertMinutesToHHMM(totalDuration)}`}</ColumnContainer>
                        </Td>
                      </Tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
            {loadedData.data?.data?.length === 0 && <NoData>No attendance found!</NoData>}
          </ScrollLayout>
        </ScrollContainerLayout>
        {count > 0 && (
          <Count>
            {t("showingData", {
              title: t(shortName),
              count: count,
            })}
          </Count>
        )}
        {openData?.data?.length > 0 && (
          <ShowAttendance
            setLoaderBox={setLoaderBox}
            setMessage={setMessage}
            attendance={openData}
            openIndex={openIndex}
            updateData={updateData}
            closeModal={() => {
              setOpenData([]);
            }}
            header={t(openHeader)}
          ></ShowAttendance>
        )}
        {isEditing && (
          <AutoForm
            useCaptcha={false}
            useCheckbox={false}
            description={""}
            key={"type.description"}
            formType={"post"}
            header={editTitle}
            formInput={addAtrributes}
            submitHandler={submitHandler}
            button={t("submit")}
            isOpenHandler={(value) => {
              setIsEditing(value);
            }}
            isOpen={true}
            plainForm={false}
          ></AutoForm>
        )}
      </RowContainer>
    </Container>
  );
};
export default Layout(WorkLog);
