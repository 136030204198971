import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../../../common/layout/styels";
import ListTable from "../../../../elements/list/list";
import withLayout from "../../../common/layout/indexPlain";
const Report = (props) => {
  const [reportType] = useState(props.reportType ?? 1);
  const { t } = useTranslation();
  const [common] = useState([
    {
      type: "text",
      placeholder: "firstName",
      name: "firstName",
      validation: "",
      default: "",
      showItem: "firstName",
      label: "firstName",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "lastName",
      name: "lastName",
      validation: "",
      label: "lastName",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "select",
      placeholder: "shift",
      name: "shiftTemplate",
      validation: "",
      default: "",
      showItem: "title",
      label: "shift",
      required: true,
      view: true,
      add: false,
      update: false,
      apiType: "API",
      selectApi: "shift/select",
    },
    {
      type: "select",
      placeholder: "place",
      name: "place",
      validation: "",
      default: "",
      showItem: "title",
      label: "place",
      required: true,
      view: true,
      add: false,
      update: false,
      apiType: "API",
      selectApi: "place/select",
    },
    {
      type: "date",
      placeholder: "date",
      name: "date",
      validation: "",
      default: "",
      label: "date",
      showItem: "date",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "datetime",
      placeholder: "arrivalTime",
      name: "firstIn",
      validation: "",
      default: "",
      label: "arrivalTime",
      showItem: "arrivalTime",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "datetime",
      placeholder: "departureTime",
      name: "lastOut",
      validation: "",
      default: "",
      showItem: "departureTime",
      label: "departureTime",
      required: true,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "minute",
      placeholder: "hours",
      name: "workedHours",
      validation: "",
      default: "0",
      label: "hours",
      required: true,
      minimum: 0,
      maximum: 60,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "minute",
      placeholder: "pause",
      name: "break",
      validation: "",
      default: "0",
      label: "pause",
      required: true,
      minimum: 0,
      maximum: 60,
      view: true,
      add: false,
      update: false,
    },
  ]);

  const [attributes, setAttributes] = useState([]);
  const currentYear = new Date().getFullYear();
  //to update the page title
  useEffect(() => {
    document.title = `${t("Team")} - VSH Security`;
  }, [t]);

  useEffect(() => {
    const surcharge = [
      {
        type: "minute",
        placeholder: "hours23",
        name: "nightSupplement",
        validation: "",
        default: "0",
        label: "hours23",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: false,
        update: false,
      },
      {
        type: "minute",
        placeholder: "hours26",
        name: "sundaySurcharge",
        validation: "",
        default: "0",
        label: "hours26",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: false,
        update: false,
      },
      {
        type: "minute",
        placeholder: "hours100",
        name: "holidaySurcharge",
        validation: "",
        default: "0",
        label: "hours100",
        required: true,
        minimum: 0,
        maximum: 60,
        view: true,
        add: false,
        update: false,
      },
    ];
    switch (reportType) {
      case 1:
        setAttributes([...common]);
        break;
      case 4:
        setAttributes([
          {
            type: "view",
            placeholder: "firstName",
            name: "firstName",
            validation: "",
            default: "",
            collection: "",
            showItem: "firstName",
            label: "firstName",
            required: true,
            view: true,
            add: false,
            update: false,
          },
          {
            type: "view",
            placeholder: "lastName",
            name: "lastName",
            validation: "",
            collection: "",
            showItem: "lastName",
            label: "lastName",
            required: true,
            view: true,
            add: false,
            update: false,
          },
          {
            type: "select",
            placeholder: "shift",
            name: "shiftTemplate",
            validation: "",
            default: "",
            showItem: "title",
            label: "shift",
            required: true,
            view: true,
            add: false,
            update: false,
            apiType: "API",
            selectApi: "shift/select",
          },
          {
            type: "select",
            placeholder: "place",
            name: "place",
            validation: "",
            default: "",
            showItem: "title",
            label: "place",
            required: true,
            view: true,
            add: false,
            update: false,
            apiType: "API",
            selectApi: "place/select",
          },
          {
            type: "select",
            placeholder: "year",
            name: "year",
            validation: "",
            default: "",
            collection: "vacation",
            showItem: "year",
            label: "year",
            filterType: "select",
            required: true,
            value: 2023,
            view: false,
            add: true,
            update: true,
            apiType: "JSON",
            selectApi: [
              { value: currentYear, id: new Date().getFullYear() },
              { value: currentYear - 1, id: currentYear - 1 },
            ],
          },
          {
            type: "view",
            placeholder: "year",
            name: "year",
            validation: "",
            collection: "vacation",
            showItem: "year",
            default: "0",
            label: "year",
            required: true,
            view: true,
            tag: true,
            add: true,
            update: true,
          },
          {
            type: "view",
            placeholder: "vacationPerYear",
            name: "thisYear",
            validation: "",
            collection: "vacation",
            showItem: "thisYear",
            default: "0",
            label: "vacationPerYear",
            required: true,
            view: true,
            tag: true,
            add: true,
            update: true,
          },
          {
            type: "view",
            placeholder: "vacationFromLastYear",
            name: "fromLastYear",
            collection: "vacation",
            showItem: "fromLastYear",
            validation: "number",
            default: "0",
            label: "vacationFromLastYear",
            required: true,
            view: true,
            tag: true,
            add: true,
            update: true,
          },
          {
            type: "view",
            placeholder: "vacationTaken",
            name: "taken",
            collection: "vacation",
            showItem: "taken",
            validation: "number",
            default: "0",
            label: "vacationTaken",
            required: true,
            view: true,
            tag: true,
            add: false,
            update: false,
          },
          {
            type: "view",
            placeholder: "vacationRemaining",
            name: "vacationRemaining",
            collection: "",
            showItem: "vacationRemaining",
            validation: "number",
            default: "0",
            label: "vacationRemaining",
            required: true,
            view: true,
            tag: true,
            add: false,
            update: false,
          },
        ]);
        break;
      default:
        setAttributes([...common, ...surcharge]);

        break;
    }
  }, [common, reportType, currentYear]);

  // Use the useTranslation hook from react-i18next to handle translations
  // const parkingDuration = totalDuration > 120 ? (days > 0 ? days + `d, ` : ``) + (hours > 0 ? hours + `h, ` : ``) + (minutes + t("m")) : totalDuration.toFixed(0) + ` ` + t("minutes");
  return <Container className="noshadow">{attributes.length > 0 && <ListTable datefilter={true} {...props} api={props.api ?? `report/allEmployees`} itemTitle={`title`} shortName={t("reports", { number: reportType })} addPrivilege={false} delPrivilege={false} updatePrivilege={false} attributes={attributes}></ListTable>}</Container>;
};
// exporting the page with parent container layout..
export default withLayout(Report);
