import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { postData } from "../../../backend/api";
import AutoForm from "../../elements/form";
import Footer from "../footer";
import { FormContainer, MainContainer } from "./styles";
//using translation
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import withLayout from "../layout";
import { ColumnContainer } from "../../styles/containers/styles";
import Header from "../header";
import { clearLogin } from "../../../store/actions/login";
import { postData } from "../../../backend/api";

const Invitation = (props) => {
  useEffect(() => {
    document.title = `Time Recording Tool`;
  }, []);
  const { id1, id2 } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login);
  const { t } = useTranslation();
  const [formInput] = useState([
    {
      type: "password",
      placeholder: "newPassword",
      name: "newPassword",
      validation: "passwordMatch",
      default: "",
      label: "newPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    {
      type: "password",
      placeholder: "confirmPassword",
      name: "confirmPassword",
      validation: "passwordMatch",
      default: "",
      label: "confirmPassword",
      minimum: 6,
      maximum: 16,
      required: true,
    },
    {
      type: "hidden",
      placeholder: "user",
      name: "user",
      default: id1,
      required: true,
    },
    {
      type: "hidden",
      placeholder: "token",
      name: "token",
      default: id2,
      required: true,
    },
  ]);
  const { setLoaderBox } = props;
  useEffect(() => {
    if (user.data?.token) {
      navigate("/dashboard");
    }
    setLoaderBox(user.isLoading);
    if (user.error !== null) {
      props.setMessage({ type: 1, content: t(user.error), proceed: "Okay" });
      dispatch(clearLogin());
    }
  }, [user, navigate, setLoaderBox, t, props, dispatch]);

  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    //call set password with
    props.setLoaderBox(true);
    await postData({ ...post }, "user/activate-invitation")
      .then((response) => {
        if (response.status === 200) {
          props.setMessage({
            type: 1,
            content: t(response.data),
            okay: "Login",
            onClose: () => {
              navigate("/");
            },
          });
          props.setLoaderBox(false);
        } else if (response.status === 201) {
          props.setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          props.setLoaderBox(false);
        } else {
          props.setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
          props.setLoaderBox(false);
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        props.setMessage({ type: 1, content: error.message + "Something went wrong!", proceed: "Okay" });
        setLoaderBox(false);
      });
  };

  return (
    <MainContainer>
      <Header> </Header>
      <ColumnContainer
        className="login"
        style={{
          backgroundImage: `linear-gradient(to right,  #f1f6f8 30%, transparent)`,
          backgroundSize: "cover",
        }}
      >
        <FormContainer>
          <AutoForm enterSubmit={true} useCaptcha={false} formType={"post"} description={t("setPasswordDescription")} header={t("setPasswordHead")} formInput={formInput} submitHandler={submitChange} button={t("confirm")} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={true}></AutoForm>
        </FormContainer>
      </ColumnContainer>
      <Footer></Footer>
    </MainContainer>
  );
};

export default withLayout(Invitation);
