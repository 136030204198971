import styled, { css } from "styled-components";
// Container for the dashboard section

export const DashboardSection = styled.div`
  margin: 0px 0;
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 250px);
  height: auto;
  align-content: flex-start;
  &.two {
    display: grid;
    grid-template-rows: repeat(${(props) => props.count}, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
  @media screen and (max-width: 560px) {
    grid-auto-flow: row !important;
    grid-template-rows: 1fr, auto;
    grid-template-columns: inherit !important;
  }
`;

export const Tile = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  padding: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: auto;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const TitleBox = styled.div`
  margin-top: 0;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 15px;
  color: gray;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
`;

export const Count = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  column-gap: 10px;
  > span {
    white-space: nowrap;
    margin-right: 5px;
  }
  > span.Confirmed {
    color: green;
  }
  > span.Pending {
    color: orange;
  }
  > span.Rejected {
    color: red;
  }
  > span > svg {
    margin-right: 5px;
    font-size: 25px;
  }
`;

export const IconWrapper = styled.div`
  font-size: 25px;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  right: 10px;
  top: 10px;
  position: absolute;
`;
export const CalendarBox = styled.div`
  width: 100%;
  border-collapse: collapse;
  margin: 0px 0;
  overflow: auto;
  max-width: 100%;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
`;
export const CalendarTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  background-color: white;
  color: black;
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 0px solid rgb(221, 221, 221);
  td:first-child {
    border-left: none !important;
  }
`;
export const BoxDiv = styled.div`
  border-collapse: collapse;
  overflow: auto;
  background-color: white;
  color: black;
  border-radius: 0px;
  box-shadow: 1px 0px 1px 1px #839d9424;
  position: relative;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(221, 221, 221);
  min-height: 200px;
  &.plain {
    background-color: white;
    border: 1px solid #ebebeb;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
  }
  .head {
    text-align: center;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 15px 10px;
    border: 1px solid #dddddd;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
    svg {
      font-size: 22px;
    }
  }
  &.radious .head {
    border: 0;
  }
  &.radious {
    border-radius: 10px;
  }
  &.two {
    background-color: transparent;
    border: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  @media screen and (max-width: 560px) {
    &.two {
      grid-auto-flow: row !important;
      grid-template-rows: 1fr, auto;
      grid-template-columns: inherit !important;
    }
  }
`;
export const EmployeeCell = styled.th`
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  text-align: left;
  font-weight: bold;
  position: sticky;
  left: 0;
  text-wrap: nowrap;
  background-color: white;
  color: black;
  padding: 5px 10px;
  font-size: 14px;
  &.title {
    text-align: center;
  }
  &.align {
    display: flex;
    align-items: center;
    font-weight: normal;
    padding: 10px;
  }
  > span {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 6px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
  }
  &.nonstick {
    position: inherit;
    padding: 10px 10px;
  }
  img {
    border-radius: 50%;
  }
`;
export const EmployeeCellTD = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  text-align: left;
  text-align: left;
  position: sticky;
  left: 0;
  text-wrap: nowrap;
  background-color: white;
  color: black;
  padding: 5px 10px;
  font-weight: normal;
  font-size: 14px;
  &.nonstick {
    position: inherit;
  }
  &.align {
    display: flex;
    align-items: center;
  }
  > span {
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 6px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
  }
  img {
    border-radius: 50%;
  }
`;

export const DayCell = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
  border-top: 0;
  text-align: center;
  min-width: 30px;
  z-index: 1000;
  font-weight: normal;
  font-size: 14px;
  .leave {
    border-radius: 50%;
    padding: 5px;
    height: 15px;
    width: 15px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.left {
    text-align: left;
  }
  ${({ active }) =>
    active &&
    css`
      /* Apply styles for active state */
      background-color: #f0f0f0;
      font-weight: bold;
      /* Add more styles as needed */
    `};
`;
export const DropTarget = styled.div`
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${(props) => (props.isOver ? "blue" : "#aaa")};
  background-color: ${(props) => (props.isOver ? "#f0f0f0" : "white")};
  margin: 10px 0;
  padding: 5px;
`;
export const AddButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  cursor: pointer;
  font-size: 22px;
  background: #1b9dd9;
  color: white;
  font-weight: bold;
`;
export const SettingsBox = styled.div`
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
  margin: 20px;
`;
